import React, { useContext, useEffect, useState } from 'react'
import LoginForm from './components/LoginForm'
import './App.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Overview from './Overview'
import AdminPanel from './AdminPanel'

import { createHashRouter, RouterProvider, Navigate } from 'react-router-dom'
import { GlobalContext, GlobalProvider } from './context/GlobalContext'
import SalesShow from './components/compact/SalesShow'
import RMPanel from './RMPanel'
import BMPanel from './BMPanel'
import HRPanel from './components/hr/HRPanel'
import { createTheme as createThemeMantine, MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import modals from './components/newholidays/lib/modals'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import { Toaster } from 'react-hot-toast'
import useApi from './components/newholidays/hooks/useApi'
import { DatesProvider } from '@mantine/dates'

const theme = createTheme({
  palette: {
    mode: 'dark'
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  }
})

const MainWindow = () => {
  const { operatorData, isLoggedIn } = useContext(GlobalContext)
  if (!isLoggedIn) return <LoginForm mode="compact" />
  return <SalesShow />
}

const FullWindow = () => {
  const { operatorData, isLoggedIn } = useContext(GlobalContext)

  if (!isLoggedIn) return <LoginForm mode="full" />
  if (operatorData?.Status === 'admin') return <AdminPanel />
  if (operatorData?.Status === 'hr') return <HRPanel />
  if (operatorData?.Status === 'rm') return <RMPanel />
  if (operatorData?.Status === 'bm') return <BMPanel />
  return <Overview />
}

const router = createHashRouter([
  {
    path: '/',
    element: <FullWindow />
  },
  {
    path: '/mainWindow',
    element: <MainWindow />
  },
  {
    path: '/fullWindow',
    element: <FullWindow />
  }
])

const mantineTheme = createThemeMantine({
  /** Put your mantine theme override here */
})

// keeps our matt token alive by pinging expired every minute
const RefreshToken = () => {
  //useApi({ url: 'https://api.aa-vision.com/api/authenticate/expired', refetch: 5 * 60 * 1000 })
  return null
}

function App() {
  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#181a1f', overflow: 'hidden' }}>
      <GlobalProvider>
        <MantineProvider theme={mantineTheme} defaultColorScheme="dark">
          <DatesProvider settings={{ timezone: 'UTC' }}>
            <ModalsProvider modals={modals}>
              <ThemeProvider theme={theme}>
                <RefreshToken />
                <FullWindow />
                <Toaster />
              </ThemeProvider>
            </ModalsProvider>
          </DatesProvider>
        </MantineProvider>
      </GlobalProvider>
    </div>
  )
}

export default App
