import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Alert,
    Box,
    Autocomplete
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/locale/en-gb';


moment.locale('en-gb');

const AddSickDayModal = ({ open, onClose, onSubmit, token, staffMembers }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setError('');
            setLoading(true);

            if (!startDate || !endDate || !selectedStaff) {
                setLoading(false);
                setError('Please fill in all required fields');
                return;
            }

            const sickId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
            const payload = {
                // SickID: sickId,
                StartDatetime: moment(startDate).format('DD/MM/YYYY'),
                EndDatetime: moment(endDate).format('DD/MM/YYYY'),
                StaffID: selectedStaff?.StaffId,
                TotalDays: endDate.diff(startDate, 'days') + 1,
                Paid: true,
                HoursPerDay: '8',
                AddedUserID: '988cca25-74f8-4908-ab96-f2ac9be8d9b3',
                AddedDatetime: moment().format('DD/MM/YYYY HH:mm:ss'),
                Reason: reason
            };

            console.log('Payload:', payload);

            const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickday', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': token
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Failed to add sick day');
            }

            onSubmit();
            handleReset();
            onClose();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        setSelectedStaff(null);
        setReason('');
        setError('');
    };


    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="en-gb">
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        bgcolor: '#1A1A1A',
                        color: '#FFFFFF'
                    }
                }}
            >
                <DialogTitle sx={{ color: '#90CAF9', borderBottom: '1px solid #404040' }}>
                    Add Sick Day
                </DialogTitle>
                <DialogContent sx={{ mt: 2 }}>
                    {error && (
                        <Alert
                            severity="error"
                            sx={{
                                mt: 2,
                                mb: 2,
                                bgcolor: '#D32F2F',
                                color: '#FFFFFF',
                                '& .MuiAlert-icon': { color: '#FFFFFF' }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <Box sx={{ mt: 2 }}>
                        <Autocomplete
                            fullWidth
                            options={staffMembers || []}
                            getOptionLabel={(option) => option ? `${option.FName} ${option.SName} [${option.Branch}]` : ""}
                            value={selectedStaff} // Store the entire object, not just the ID
                            isOptionEqualToValue={(option, value) => option.StaffID === value?.StaffID} // Fix comparison
                            onChange={(_, newValue) => setSelectedStaff(newValue)} // Store full object
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Staff Member"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            color: '#FFFFFF',
                                            bgcolor: '#2D2D2D',
                                            '& fieldset': {
                                                borderColor: '#404040'
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#90CAF9'
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#90CAF9'
                                            }
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#B0BEC5',
                                            '&.Mui-focused': {
                                                color: '#90CAF9'
                                            }
                                        }
                                    }}
                                />
                            )}
                        />

                        <Box sx={{ mb: 2 }}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                format="DD/MM/YYYY"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                color: '#FFFFFF',
                                                bgcolor: '#2D2D2D',
                                                '& fieldset': {
                                                    borderColor: '#404040'
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#90CAF9'
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#90CAF9'
                                                }
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#B0BEC5',
                                                '&.Mui-focused': {
                                                    color: '#90CAF9'
                                                }
                                            },
                                            '& .MuiIconButton-root': {
                                                color: '#B0BEC5'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                minDate={startDate}
                                format="DD/MM/YYYY"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                color: '#FFFFFF',
                                                bgcolor: '#2D2D2D',
                                                '& fieldset': {
                                                    borderColor: '#404040'
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#90CAF9'
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#90CAF9'
                                                }
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#B0BEC5',
                                                '&.Mui-focused': {
                                                    color: '#90CAF9'
                                                }
                                            },
                                            '& .MuiIconButton-root': {
                                                color: '#B0BEC5'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <TextField
                            fullWidth
                            label="Reason"
                            multiline
                            rows={4}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            sx={{
                                '& .MuiInputBase-root': {
                                    color: '#FFFFFF',
                                    bgcolor: '#2D2D2D',
                                    '& fieldset': {
                                        borderColor: '#404040'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#90CAF9'
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#90CAF9'
                                    }
                                },
                                '& .MuiInputLabel-root': {
                                    color: '#B0BEC5',
                                    '&.Mui-focused': {
                                        color: '#90CAF9'
                                    }
                                }
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ borderTop: '1px solid #404040', p: 2 }}>
                    <Button
                        onClick={() => {
                            handleReset();
                            onClose();
                        }}
                        sx={{ color: '#B0BEC5' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={loading}
                        sx={{
                            bgcolor: '#90CAF9',
                            color: '#1A1A1A',
                            '&:hover': { bgcolor: '#64B5F6' }
                        }}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default AddSickDayModal;