import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

const DailyPerformanceCalendar = ({ selectedBranch, selectedRegion, operatorData }) => {
  const [tooltip, setTooltip] = useState(null);
  const [fullData, setFullData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useContext(GlobalContext);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const firstDayOfWeek = firstDayOfMonth.getDay();
  const dayLetters = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const dayInitials = [...dayLetters.slice(firstDayOfWeek), ...dayLetters.slice(0, firstDayOfWeek)];
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  useEffect(() => {
    const fetchMonthlyData = async () => {
      setIsLoading(true);
      try {
        const startOfMonth = new Date(currentYear, currentMonth, 1).toISOString().split('T')[0];
        const endOfYesterday = new Date(currentYear, currentMonth + 1, 0).toISOString().split('T')[0];

        let url = `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByDateRange?fromDate=${startOfMonth}&toDate=${endOfYesterday}`;

        if (operatorData?.Status === 'rm') {
          url += `&regionalManager=${encodeURIComponent(operatorData.Name)}`;
        } else if (operatorData?.Status === 'admin' && selectedRegion && selectedRegion !== 'ALL') {
          url += `&regionalManager=${encodeURIComponent(selectedRegion)}`;
        } else if (['bm', 'op'].includes(operatorData?.Status)) {
          url += `&branch=${encodeURIComponent(operatorData.Branch)}`;
        }

        const response = await fetch(url, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (!response.ok) throw new Error('Network response was not ok');
        const responseData = await response.json();
        setFullData(responseData);
        setFilteredData(responseData?.branches || []);
      } catch (error) {
        console.error('Error fetching calendar data:', error);
        setFullData(null);
        setFilteredData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMonthlyData();
  }, [token, selectedRegion, operatorData, selectedBranch, currentYear, currentMonth]);

  useEffect(() => {
    if (!fullData) return;

    let data = fullData.branches || fullData;
    if (selectedRegion && selectedRegion !== 'ALL') {
      data = data.filter(b => b.region === selectedRegion);
    }
    if (selectedBranch) {
      data = data.filter(b => b.branch === selectedBranch);
    }
    setFilteredData(data);
  }, [fullData, selectedBranch, selectedRegion]);

  if (isLoading) {
    return (
      <div className="w-full p-2 text-center">
        <h4 className="text-white">LOADING...</h4>
      </div>
    );
  }

  if (!filteredData || filteredData.length === 0) {
    return <div className="w-full p-2 text-gray-400">No data available</div>;
  }

  const getPerformanceColor = (performance, isToday, isClosed) => {
    if (isClosed) return 'rgb(75, 85, 99)';
    if (isToday) return 'rgb(233, 213, 255)';

    if (performance >= 100) return '#1eaa59';
    if (performance >= 80) return '#f1c40f';
    return '#e84c3d';
  };

  const getTradingMultiplier = (dayOfWeek, branch) => {
    if (dayOfWeek === 6) return branch.saturdayTrade || 0;
    if (dayOfWeek === 0) return branch.sundayTrade || 0;
    return 1;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value);
  };

  const formatPercentage = (value) => {
    return `${value.toFixed(1)}%`;
  };

  return (
    <div className="w-auto bg-[#2b2b3d] p-4 rounded-lg">
      <div className="flex justify-evenly gap-0">
        {Array.from({ length: daysInMonth }, (_, i) => {
          const date = new Date(currentYear, currentMonth, i + 1);
          const dayOfWeek = date.getDay();
          const isToday = date >= currentDate;

          const dailyStats = filteredData
            .filter(branch => !selectedBranch || branch.branch === selectedBranch)
            .reduce((stats, branch) => {
              const tradingMultiplier = getTradingMultiplier(dayOfWeek, branch);
              const adjustedDailySalesTarget = branch.salesTarget * tradingMultiplier;

              const branchDailyStats = branch.dailyStats?.[i] || {};
              const netSales = (branchDailyStats.sales || 0) + (branchDailyStats.credits || 0);
              const netMargin = (branchDailyStats.margin || 0) + (branchDailyStats.creditsMargin || 0);

              return {
                sales: stats.sales + netSales,
                margin: stats.margin + netMargin,
                targetSales: stats.targetSales + adjustedDailySalesTarget,
                targetMargin: stats.targetMargin + branch.marginTarget
              };
            }, { sales: 0, margin: 0, targetSales: 0, targetMargin: 0 });



          const marginPercentage = dailyStats.sales !== 0 ?
            (dailyStats.margin / dailyStats.sales) * 100 : 0;
          const targetMarginPercentage = filteredData.length > 1 ?
            (dailyStats.targetMargin / filteredData.length) * 100 :
            dailyStats.targetMargin * 100;

          const salesPerformance = dailyStats.targetSales > 0 ?
            (dailyStats.sales / dailyStats.targetSales) * 100 : 0;
          const marginPerformance = targetMarginPercentage > 0 ?
            (marginPercentage / targetMarginPercentage) * 100 : 0;

          const salesColor = getPerformanceColor(salesPerformance, isToday, dailyStats.targetSales === 0);
          const marginColor = getPerformanceColor(marginPerformance, isToday, dailyStats.targetSales === 0);

          return (
            <div key={i} className="flex flex-col items-center w-5 mb-1.5">
              <div className="relative w-full h-10">
                <div
                  className="w-full h-1/2 rounded-t-sm"
                  style={{ backgroundColor: salesColor }}
                  onMouseEnter={(e) => setTooltip({
                    x: e.clientX,
                    y: e.clientY,
                    label: dailyStats.targetSales === 0 ? 'Closed' :
                      isToday ? 'No data' :
                        `Sales: ${formatCurrency(dailyStats.sales)}\nTarget: ${formatCurrency(dailyStats.targetSales)}\nPerformance: ${formatPercentage(salesPerformance)}`
                  })}
                  onMouseLeave={() => setTooltip(null)}
                />
                <div
                  className="w-full h-1/2 rounded-b-sm"
                  style={{ backgroundColor: marginColor }}
                  onMouseEnter={(e) => setTooltip({
                    x: e.clientX,
                    y: e.clientY,
                    label: dailyStats.targetSales === 0 ? 'Closed' :
                      isToday ? 'No data' :
                        `Margin: ${formatPercentage(marginPercentage)}\nTarget: ${formatPercentage(targetMarginPercentage)}\nPerformance: ${formatPercentage(marginPerformance)}`
                  })}
                  onMouseLeave={() => setTooltip(null)}
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xs font-bold">
                  {i + 1}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex justify-evenly gap-0 mt-1.5">
        {Array.from({ length: daysInMonth }, (_, i) => {
          const date = new Date(currentYear, currentMonth, i + 1);
          const dayOfWeek = date.getDay();
          return (
            <span key={i} className="text-white text-[10px] font-bold text-center w-5">
              {dayLetters[dayOfWeek]}
            </span>
          );
        })}
      </div>

      {tooltip && (
        <div
          className="fixed z-50 p-2 bg-gray-800 text-white rounded shadow-lg pointer-events-none"
          style={{
            top: `${tooltip.y + 10}px`,
            left: `${tooltip.x + 10}px`
          }}
        >
          <pre className="m-0 whitespace-pre-line text-xs">{tooltip.label}</pre>
        </div>
      )}
    </div>
  );
};

export default DailyPerformanceCalendar;