import React, { useState, useContext, useEffect, useCallback } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import BranchDropdown from '../newholidays/components/BranchDropdown';
import { GlobalContext } from '../../context/GlobalContext';
import useBranchStore from '../newholidays/stores/useBranchStore';

function SalesTracker() {
    const { token } = useContext(GlobalContext);
    const selectedBranch = useBranchStore((state) => state.selectedBranch);

    // Set default start date to first day of current month
    const defaultStartDate = dayjs().startOf('month');

    // Set default end date to current date
    const defaultEndDate = dayjs();

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const fetchTodayData = useCallback(() => {
        // Your existing fetchTodayData logic here
    }, []);

    useEffect(() => {
        fetchTodayData();
    }, [fetchTodayData]);

    const sharedDatePickerSx = {
        '& .MuiInputBase-root': {
            color: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.3)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
            },
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
        },
        '& .MuiSvgIcon-root': {
            color: 'rgba(255, 255, 255, 0.7)',
        },
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ marginTop: 5 }} className="flex items-center space-x-4">
                <BranchDropdown />
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue);
                        if (newValue > endDate) {
                            setEndDate(newValue);
                        }
                    }}
                    maxDate={endDate}
                    slots={{
                        textField: (params) => (
                            <TextField
                                {...params}
                                size="small"
                                sx={sharedDatePickerSx}
                            />
                        )
                    }}
                />
                <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                        setEndDate(newValue);
                        if (newValue < startDate) {
                            setStartDate(newValue);
                        }
                    }}
                    minDate={startDate}
                    slots={{
                        textField: (params) => (
                            <TextField
                                {...params}
                                size="small"
                                sx={sharedDatePickerSx}
                            />
                        )
                    }}
                />
            </div>
        </LocalizationProvider>
    );
}

export default SalesTracker;