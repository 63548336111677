import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button, TextField, CircularProgress, Avatar } from '@mui/material';
import moment from 'moment-timezone';

const CommentModalGE = ({ selectedGELead, setSelectedGELead, addComment, isPosting, newComment, setNewComment, user }) => {
    const messagesEndRef = useRef(null);
    const [newImage, setNewImage] = useState('');

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [selectedGELead?.Comments]);

    if (!selectedGELead) return null;

    const handleSendComment = () => {
        if (!newComment.trim() && !newImage) return;

        // Match the payload structure from your legacy code
        addComment({
            comment: newComment,
            user: user,
            image: newImage,
            dateTime: new Date(),
            pdf: null  // Include this to match legacy structure
        });

        setNewComment('');
        setNewImage('');
    };

    const handlePaste = (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (const item of items) {
            if (item.type.indexOf('image') === 0) {
                const blob = item.getAsFile();
                const reader = new FileReader();
                reader.onload = function (event) {
                    setNewImage(event.target.result);
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendComment();
        }
    };

    const comments = Array.isArray(selectedGELead.Comments) ? selectedGELead.Comments : [];

    return (
        <div
            style={{
                width: '35%',
                backgroundColor: '#121212',
                height: 'calc(100vh - 170px)',
                padding: '16px',
                transition: 'width 0.3s',
                borderLeft: '1px solid #222',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                color: 'white'
            }}
        >
            {/* Header */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Comments for {selectedGELead.Acct} - {selectedGELead.Branch}, {selectedGELead.Name}
                </Typography>
                <Button onClick={() => setSelectedGELead(null)} style={{ color: 'white' }}>✖</Button>
            </div>

            {/* Chat Messages */}
            <div style={{ flex: 1, overflowY: 'auto', padding: '8px' }}>
                {comments.length > 0 ? (
                    comments.map((comment, index) => {
                        const isCurrentUser = comment.User === user;
                        return (
                            <div key={comment._id || `temp-key-${index}`}
                                style={{
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'flex-start',
                                    marginBottom: '16px',
                                    justifyContent: isCurrentUser ? 'flex-end' : 'flex-start'
                                }}>
                                {!isCurrentUser && (
                                    <Avatar style={{ backgroundColor: '#444' }}>
                                        {typeof comment.User === "string" && comment.User.trim().length > 0
                                            ? comment.User.charAt(0)
                                            : "?"}
                                    </Avatar>
                                )}
                                <div style={{
                                    backgroundColor: isCurrentUser ? '#1a73e8' : '#1e1e1e',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    maxWidth: '80%'
                                }}>
                                    <Typography style={{ fontSize: '0.875rem', fontWeight: 'bold', color: '#bbb' }}>
                                        {comment.User || "Unknown"}
                                    </Typography>
                                    {comment.Comment && (
                                        <Typography style={{ fontSize: '0.875rem', color: 'white', marginTop: '4px' }}>
                                            {comment.Comment}
                                        </Typography>
                                    )}
                                    {comment.Image && (
                                        <div style={{ marginTop: '8px' }}>
                                            <img
                                                src={comment.Image}
                                                alt="Shared"
                                                style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px' }}
                                            />
                                        </div>
                                    )}
                                    <Typography style={{ fontSize: '0.75rem', color: isCurrentUser ? '#ccc' : '#666', marginTop: '4px' }}>
                                        {moment(comment.DateTime).fromNow()}
                                    </Typography>
                                </div>
                                {isCurrentUser && (
                                    <Avatar style={{ backgroundColor: '#1a73e8' }}>
                                        {user.charAt(0)}
                                    </Avatar>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <Typography style={{ color: '#666', textAlign: 'center', marginTop: '16px' }}>
                        No comments yet.
                    </Typography>
                )}
                <div ref={messagesEndRef} />
                {isPosting && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        padding: '8px',
                        backgroundColor: 'rgba(26, 115, 232, 0.1)',
                        borderRadius: '10px',
                        marginTop: '8px'
                    }}>
                        <CircularProgress size={16} style={{ color: '#1a73e8' }} />
                        <Typography style={{ color: '#1a73e8', fontSize: '0.875rem' }}>Sending message...</Typography>
                    </div>
                )}
            </div>

            {/* Image Preview */}
            {newImage && (
                <div style={{ padding: '8px', textAlign: 'center' }}>
                    <img src={newImage} alt="Pasted" style={{ maxHeight: '200px', borderRadius: '8px' }} />
                </div>
            )}

            {/* Input Bar */}
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '16px' }}>
                <TextField
                    fullWidth
                    placeholder="Type a message..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    disabled={isPosting}
                    style={{
                        backgroundColor: '#222',
                        color: 'white',
                        borderRadius: '20px',
                        border: 'none',
                        padding: '8px',
                        opacity: isPosting ? 0.7 : 1
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleSendComment}
                    disabled={isPosting}
                    style={{
                        minWidth: '60px',
                        backgroundColor: isPosting ? '#333' : '#1a73e8',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px'
                    }}
                >
                    {isPosting ? <CircularProgress size={24} style={{ color: 'white' }} /> : '➤'}
                </Button>
            </div>
        </div>
    );
};

export default CommentModalGE;