import React, { useState, useEffect } from 'react'
import {
    CardContent,
    Typography,
    Button,
    TextField,
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    List,
    ListItem,
    IconButton,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import moment from 'moment-timezone'
import { modals } from '@mantine/modals'
import haxios from '../../components/newholidays/lib/haxios'

const SupervisorInfringementHandler = ({
    selectedDetail,
    setHighlightedRecord,
    onClose,
    refreshClockingRecords,
    token,
    fetchSelectedMonthClockingRecords,
    staffBranch,
    fetchAcceptedInfringements,
    refreshAnalysisData,
    markAsHoliday,
    markAsSick,
    staffList
}) => {
    const [localClockingRecords, setLocalClockingRecords] = useState([])
    const [selectedClockingRecord, setSelectedClockingRecord] = useState(null)
    const [editedClockIn, setEditedClockIn] = useState('')
    const [editedClockOut, setEditedClockOut] = useState('')
    const [error, setError] = useState(null)
    const [infringementStatus, setInfringementStatus] = useState('')
    const [reason, setReason] = useState('')
    const [acceptReason, setAcceptReason] = useState('')
    const [fixing, setFixing] = useState(false)
    const [casualCoveringFor, setCasualCoveringFor] = useState('')
    const [approvedBy, setApprovedBy] = useState('')

    const infringementReasons = [
        'ABSENT',
        'ALTERNATE SHIFT PATTERN',
        'STARTED LATE',
        'DELIVERY ON WAY HOME',
        'DELIVERY ON TO WORK',
        'MATERNITY / PATERNITY',
        'FINISHED EARLY',
        'NOT DUE FOR WORK',
        'BEREAVEMENT',
        'STARTED EARLY',
        'FINISHED LATE',
        'WORKED NOSHIFT'
    ].sort()

    // Check if the current record is a ZERHO shift
    const isZerhoShift = selectedDetail?.reason?.toLowerCase().includes('zerho')

    useEffect(() => {
        if (selectedDetail) {
            const records = selectedDetail.clockingRecords || [selectedDetail]
            setLocalClockingRecords(records)
            setSelectedClockingRecord(records[0])

            const shiftStart = selectedDetail.shift?.Start || ''
            const shiftFinish = selectedDetail.shift?.Finish || ''
            setEditedClockIn(shiftStart)
            setEditedClockOut(shiftFinish)
            setError(null)
            setInfringementStatus(selectedDetail.status)
            setReason(selectedDetail.reason)
            setCasualCoveringFor(selectedDetail.coveredFor || '')
            setApprovedBy('') // Reset approvedBy when detail changes
        }
    }, [selectedDetail])

    const analyzeClockRecord = (record, shift) => {
        if (!shift || !record) return { status: 'ERROR', message: 'Missing shift or record data' }

        const clockIn = moment(record.ClockInTime)
        const clockOut = moment(record.ClockOutTime)
        const shiftDate = clockIn.format('YYYY-MM-DD')
        const shiftStart = moment(`${shiftDate} ${shift.Start}`)
        const shiftEnd = moment(`${shiftDate} ${shift.Finish}`)

        // Handle overnight shifts
        if (shiftEnd.isBefore(shiftStart)) {
            shiftEnd.add(1, 'day')
        }

        const issues = []
        let status = 'OK'

        // Check late clock-in
        if (clockIn.isAfter(shiftStart.clone().add(15, 'minutes'))) {
            issues.push(`Late clock-in (${clockIn.format('HH:mm')} vs shift start ${shiftStart.format('HH:mm')})`)
            status = 'INFRINGEMENT'
        }

        // Check early clock-out
        if (clockOut.isBefore(shiftEnd.clone().subtract(15, 'minutes'))) {
            issues.push(`Early clock-out (${clockOut.format('HH:mm')} vs shift end ${shiftEnd.format('HH:mm')})`)
            status = 'INFRINGEMENT'
        }

        const expectedWorkMinutes = shiftEnd.diff(shiftStart, 'minutes') - 10
        const actualWorkMinutes = clockOut.diff(clockIn, 'minutes')

        if (actualWorkMinutes < expectedWorkMinutes) {
            issues.push(
                `Insufficient work time (${Math.floor(actualWorkMinutes / 60)}h ${actualWorkMinutes % 60
                }m vs expected ${Math.floor(expectedWorkMinutes / 60)}h ${expectedWorkMinutes % 60}m)`
            )
            status = 'INFRINGEMENT'
        }

        return {
            status,
            issues,
            workDuration: `${Math.floor(actualWorkMinutes / 60)}h ${actualWorkMinutes % 60}m`,
            expectedDuration: `${Math.floor(expectedWorkMinutes / 60)}h ${expectedWorkMinutes % 60}m`
        }
    }

    const handleDeleteRecord = async (clockId) => {
        try {
            const recordToDelete = localClockingRecords.find(
                record => record?.ClockID === clockId || record?.clockingId === clockId
            )

            modals.openContextModal({
                modal: 'warningModal',
                withCloseButton: false,
                centered: true,
                innerProps: {
                    message: `You are about to delete the clock record for ${recordToDelete?.ClockInTime
                        ? moment(recordToDelete.ClockInTime).format('HH:mm')
                        : 'Unknown time'
                        }.`,
                    submit: async () => {
                        try {
                            await haxios.delete(`https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/${clockId}`)
                            const updatedRecords = localClockingRecords.filter(
                                record => record?.ClockID !== clockId && record?.clockingId !== clockId
                            )
                            setLocalClockingRecords(updatedRecords)
                            if (updatedRecords.length > 0) {
                                setSelectedClockingRecord(updatedRecords[0])
                            } else {
                                setSelectedClockingRecord(null)
                            }

                            // Call the refreshClockingRecords function to update the parent component
                            if (refreshClockingRecords) {
                                refreshClockingRecords()
                                setHighlightedRecord(null)
                            }
                        } catch (e) {
                            console.log(e)
                            setError('Error deleting clock record.')
                        }
                    }
                }
            })
        } catch (error) {
            console.error('Error deleting record:', error)
            setError('Error deleting record: ' + error.message)
        }
    }

    const calculateMinsOut = () => {
        if (!selectedDetail.shift) return 0
        const shiftStart = moment(`${selectedDetail.date} ${selectedDetail.shift.Start}`)
        const shiftEnd = moment(`${selectedDetail.date} ${selectedDetail.shift.Finish}`)
        return shiftEnd.diff(shiftStart, 'minutes')
    }

    const handleFixRecord = async () => {
        setFixing(true)
        let isNewRecord
        try {
            if (!editedClockIn || !editedClockOut) {
                setError('Clock-in and clock-out times are required.')
                return
            }

            const formattedDate = moment(selectedDetail.date).format('YYYY-MM-DD')
            const clockInDateTime = moment(`${formattedDate}T${editedClockIn}`)
            const clockOutDateTime = moment(`${formattedDate}T${editedClockOut}`)

            if (!clockInDateTime.isValid() || !clockOutDateTime.isValid()) {
                setError('Invalid clock-in or clock-out time.')
                return
            }

            // Determine if we're editing an existing record or creating a new one
            const isNewRecord = !selectedClockingRecord?.ClockID

            const baseRecord = {
                Branch: selectedDetail.ClockBranch || staffBranch?.code,
                StaffId: selectedDetail.staffId,
                AttendanceTypeID: '33f9a591-466a-471d-9bf6-44d326d796a5'
            }

            let endpoint
            let record

            if (isNewRecord) {
                // Prepare payload for new record
                endpoint = 'https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/clockin/manual'
                record = {
                    ...baseRecord,
                    CompanyID: '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b',
                    ClockInTime: clockInDateTime.format('YYYY-MM-DDTHH:mm:ss'),
                    ClockOutTime: clockOutDateTime.format('YYYY-MM-DDTHH:mm:ss')
                }
            } else {
                // Prepare payload for existing record update
                endpoint = 'https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/'
                record = {
                    ...baseRecord,
                    ClockId: selectedClockingRecord.ClockID || selectedDetail.clockingId,
                    ClockInTime: clockInDateTime.format('YYYY-MM-DDTHH:mm:ss'),
                    ClockOutTime: clockOutDateTime.format('YYYY-MM-DDTHH:mm:ss'),
                    ModifiedUserId: 'a12c937c-4428-4a82-8540-3e916bfe14e7',
                    ModifiedDatetime: moment().format('YYYY-MM-DDTHH:mm:ss')
                }
            }

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Token': token
                },
                body: JSON.stringify(record)
            })

            if (response.ok) {
                if (refreshAnalysisData) await refreshAnalysisData()
                if (fetchSelectedMonthClockingRecords) await fetchSelectedMonthClockingRecords()
                onClose() // Add this line to close/reset the detail view after successful update
            } else {
                const errorText = await response.text()
                setError(`Failed to ${isNewRecord ? 'create' : 'update'} clocking record: ${response.status} ${errorText}`)
            }
        } catch (error) {
            console.error(`Error ${isNewRecord ? 'creating' : 'fixing'} the clocking record:`, error)
            setError(`Error ${isNewRecord ? 'creating' : 'fixing'} the clocking record: ${error.message}`)
        } finally {
            setFixing(false)
        }
    }

    const handleApproveZerhoShift = async () => {
        if (!casualCoveringFor) {
            setError('Please specify who this shift is covering for.')
            return
        }

        if (!approvedBy) {
            setError('Please specify who approved this shift.')
            return
        }

        try {
            const infringementDate = moment(selectedDetail.date).format('YYYY-MM-DD')
            const minsOut = calculateMinsOut()

            const approvalData = {
                ClockId: selectedClockingRecord?.ClockID || selectedDetail.clockingId || '00000000-0000-0000-0000-000000000000',
                StaffId: selectedDetail.staffId,
                ApprovedBy: approvedBy,
                DateApproved: `${infringementDate}T00:00:00.000Z`,
                ApprovedType: 'Casual', // Changed from ZERHO_APPROVAL to match legacy code
                CreatedBy: 'Manager',
                MinsOut: minsOut,
                CoveredFor: casualCoveringFor,
                CompanyID: '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b'
            }

            const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(approvalData)
            })

            if (response.ok) {
                if (refreshAnalysisData) await refreshAnalysisData()
                onClose()
            } else {
                const errorText = await response.text()
                setError(`Failed to approve ZERHO shift: ${errorText}`)
            }
        } catch (error) {
            console.error('Error approving ZERHO shift:', error)
            setError(`Error approving ZERHO shift: ${error.message}`)
        }
    }

    const handleAcceptInfringement = async () => {
        if (!acceptReason) {
            setError('Please select an acceptance reason.')
            return
        }

        if (!approvedBy) {
            setError('Please specify who approved this infringement.')
            return
        }

        try {
            const infringementDate = moment(selectedDetail.date).format('YYYY-MM-DD')
            const minsOut = calculateMinsOut()

            const newInfringement = {
                ClockId: selectedClockingRecord?.ClockID || selectedDetail.clockingId || '00000000-0000-0000-0000-000000000000',
                StaffId: selectedDetail.staffId,
                ApprovedBy: approvedBy,
                DateApproved: `${infringementDate}T00:00:00.000Z`,
                ApprovedType: acceptReason,
                CreatedBy: 'Manager',
                MinsOut: minsOut,
                CoveredFor: 'N/A',
                CompanyID: '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b'
            }

            const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newInfringement)
            })

            if (response.ok) {
                if (refreshAnalysisData) await refreshAnalysisData()
                onClose()
            } else {
                const errorText = await response.text()
                setError(`Failed to accept infringement: ${errorText}`)
            }
        } catch (error) {
            console.error('Error accepting infringement:', error)
            setError(`Error accepting infringement: ${error.message}`)
        }
    }

    const removeAcceptedInfringement = async () => {
        try {
            if (!selectedDetail.acceptedInfringementId) {
                setError('No accepted infringement found to remove')
                return
            }

            const response = await fetch(
                `https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements/${selectedDetail.acceptedInfringementId}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            if (response.ok) {
                if (fetchAcceptedInfringements) await fetchAcceptedInfringements()
                if (refreshAnalysisData) await refreshAnalysisData()
                onClose()
            } else {
                const errorText = await response.text()
                setError(`Failed to remove accepted infringement: ${errorText}`)
            }
        } catch (error) {
            console.error('Error removing accepted infringement:', error)
            setError(`Error removing accepted infringement: ${error.message}`)
        }
    }

    if (!selectedDetail) {
        return null
    }

    const hasMultipleRecords = localClockingRecords.length > 1
    const editingDisabled = hasMultipleRecords

    return (
        <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Supervisor View - {selectedDetail.staffName}</Typography>
            </Box>

            <Typography>Date: {moment(selectedDetail.date).format('DD/MM/YYYY')}</Typography>

            {hasMultipleRecords && (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        Multiple Clocking Records Found
                    </Typography>
                    <Typography color="warning.main" sx={{ mb: 2 }}>
                        Please delete extra records to enable editing and infringement handling
                    </Typography>
                    <List sx={{ bgcolor: 'rgba(0, 0, 0, 0.05)', borderRadius: 1 }}>
                        {localClockingRecords.map((record) => {
                            const clockIn = moment(record.ClockInTime)
                            const clockOut = moment(record.ClockOutTime)
                            const duration = moment.duration(clockOut.diff(clockIn))
                            const hours = Math.floor(duration.asHours())
                            const minutes = duration.minutes()

                            const analysis = analyzeClockRecord(record, selectedDetail.shift)

                            return (
                                <ListItem
                                    key={record.ClockID || record.clockingId}
                                    sx={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                        padding: 2,
                                        bgcolor: selectedClockingRecord?.ClockID === record.ClockID ? 'rgba(0, 0, 0, 0.1)' : 'transparent'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 1 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {clockIn.format('HH:mm')} - {clockOut.format('HH:mm')} ({hours}h {minutes}m)
                                        </Typography>
                                        <IconButton
                                            onClick={() => handleDeleteRecord(record.ClockID || record.clockingId)}
                                            color="error"
                                            size="small"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>

                                    {analysis.issues.map((issue, i) => (
                                        <Typography key={i} sx={{ color: 'error.main', fontSize: '0.875rem', mt: 0.5 }}>
                                            • {issue}
                                        </Typography>
                                    ))}

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, fontSize: '0.875rem' }}>
                                        <Typography sx={{ color: 'info.main' }}>Expected: {analysis.expectedDuration}</Typography>
                                        <Typography sx={{ color: analysis.status === 'OK' ? 'success.main' : 'error.main' }}>
                                            Actual: {analysis.workDuration}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            )}

            <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Manage Clocking Record</Typography>

                {editingDisabled ? (
                    <Typography color="warning.main" sx={{ mt: 2, mb: 2 }}>
                        Please delete extra records until only one remains to enable editing
                    </Typography>
                ) : (
                    <>
                        {infringementStatus && (
                            <Typography color={infringementStatus === 'OK' ? 'success.main' : 'error.main'} sx={{ mb: 2 }}>
                                {infringementStatus}: {reason}
                                {(reason === 'On holiday' || reason === 'Sick day') && (
                                    <Button
                                        onClick={() => {
                                            if (reason === 'On holiday' && markAsHoliday) {
                                                markAsHoliday(selectedDetail.staffId, selectedDetail.date)
                                            } else if (reason === 'Sick day' && markAsSick) {
                                                markAsSick(selectedDetail.staffId, selectedDetail.date)
                                            }
                                        }}
                                        variant="contained"
                                        color="error"
                                        sx={{ ml: 2 }}
                                    >
                                        {reason === 'On holiday' ? 'Remove Holiday' : 'Remove Sick'}
                                    </Button>
                                )}
                            </Typography>
                        )}

                        {reason === 'Missing clocking record' && (
                            <Box sx={{ mt: 2, mb: 2 }}>
                                <Button
                                    onClick={() => markAsHoliday && markAsHoliday(selectedDetail.staffId, selectedDetail.date)}
                                    variant="contained"
                                    color="primary"
                                    sx={{ mr: 2 }}
                                >
                                    Mark as Holiday
                                </Button>
                                <Button
                                    onClick={() => markAsSick && markAsSick(selectedDetail.staffId, selectedDetail.date)}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Mark as Sick
                                </Button>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <TextField
                                label={`Clock In ${selectedClockingRecord?.ClockInTime
                                    ? `(Current: ${moment(selectedClockingRecord.ClockInTime).format('HH:mm')})`
                                    : ''
                                    }`}
                                type="time"
                                value={editedClockIn}
                                onChange={(e) => setEditedClockIn(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                            />
                            <TextField
                                label={`Clock Out ${selectedClockingRecord?.ClockOutTime
                                    ? `(Current: ${moment(selectedClockingRecord.ClockOutTime).format('HH:mm')})`
                                    : ''
                                    }`}
                                type="time"
                                value={editedClockOut}
                                onChange={(e) => setEditedClockOut(e.target.value)}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                            />

                            <Button
                                onClick={handleFixRecord}
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, mr: 2 }}
                                disabled={fixing}
                            >
                                {fixing ? 'Updating...' : 'Update Record'}
                            </Button>
                        </Box>

                        {/* {selectedDetail.approvedBy ? (
                            <Box sx={{ mt: 3 }}>
                                <Typography color="success.main" sx={{ mb: 1 }}>
                                    {isZerhoShift
                                        ? `ZERHO Shift Approved - Covering for: ${selectedDetail.coveredFor}`
                                        : `Infringement Accepted: ${selectedDetail.reason}`}
                                </Typography>
                                <Typography color="success.main" sx={{ mb: 2 }}>
                                    Approved By: {selectedDetail.approvedBy}
                                </Typography>
                                <Button onClick={removeAcceptedInfringement} variant="contained" color="error">
                                    Remove {isZerhoShift ? 'Approval' : 'Acceptance'}
                                </Button>
                            </Box>
                        ) : (
                            <>
                                {isZerhoShift ? (
                                    <Box sx={{ mt: 3 }}>
                                        <Typography variant="h6">ZERHO Shift Approval</Typography>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Covering For</InputLabel>
                                            <Select
                                                value={casualCoveringFor}
                                                onChange={(e) => setCasualCoveringFor(e.target.value)}
                                            >
                                                {staffList && staffList.map((staff) => (
                                                    <MenuItem key={staff.StaffId} value={staff.StaffId}>
                                                        {staff.FName} {staff.SName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label="Approved By"
                                            value={approvedBy}
                                            onChange={(e) => setApprovedBy(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <Button
                                            onClick={handleApproveZerhoShift}
                                            variant="contained"
                                            color="primary"
                                            sx={{ mt: 2 }}
                                            disabled={!casualCoveringFor || !approvedBy}
                                        >
                                            Approve ZERHO Shift
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box sx={{ mt: 3 }}>
                                        <Typography variant="h6">Infringement Acceptance</Typography>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Acceptance Reason</InputLabel>
                                            <Select
                                                value={acceptReason}
                                                onChange={(e) => setAcceptReason(e.target.value)}
                                            >
                                                {infringementReasons.map((reason) => (
                                                    <MenuItem key={reason} value={reason}>
                                                        {reason}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            label="Approved By"
                                            value={approvedBy}
                                            onChange={(e) => setApprovedBy(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <Button
                                            onClick={handleAcceptInfringement}
                                            variant="contained"
                                            color="secondary"
                                            sx={{ mt: 2 }}
                                            disabled={!acceptReason || !approvedBy}
                                        >
                                            Accept Infringement
                                        </Button>
                                    </Box>
                                )}
                            </>
                        )} */}
                    </>
                )}

                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>

            <Box sx={{ mt: 3 }}>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </Box>
        </CardContent>
    )
}

export default SupervisorInfringementHandler