import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel } from '@tanstack/react-table'
import {
  ArrowUpRightIcon,
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Button, Spinner, Icon } from '../index'
import { useMemo, useRef, useState } from 'react'
import s from './Table.module.scss'
import { useVirtualizer } from '@tanstack/react-virtual'

const ReactTable = (props) => {
  const dataMemo = useMemo(() => props.data, [props.data])
  const columnsMemo = useMemo(() => props.columns, [props.columns])

  const table = useReactTable({
    data: dataMemo,
    columns: columnsMemo,
    getCoreRowModel: getCoreRowModel(),
    ...(props.isPaginated && {
      manualPagination: true,
      pageCount: Math.ceil(props.totalRowsCount / props.pageRowCount),
      onPaginationChange: props.onPageChange,
      state: {
        pagination: { pageIndex: props.currentPage, pageSize: props.pageRowCount },
        columnVisibility: props.columnVisibility || {}
      }
    })
  })

  return (
    <div>
      <table className={clsx(s.table, props.className)}>
        <THead table={table} />
        <TBody table={table} props={props} />
      </table>
      {props.isPaginated && !(!props.padToPageSize && Math.ceil(props.totalRowsCount / props.pageRowCount) <= 1) && (
        <PaginationControls table={table} props={props} />
      )}
    </div>
  )
}

export const TableVirtualized = (props) => {
  const dataMemo = useMemo(() => props.data, [props.data])
  const columnsMemo = useMemo(() => props.columns, [props.columns])

  const table = useReactTable({
    data: dataMemo,
    columns: columnsMemo,
    getCoreRowModel: getCoreRowModel(),
    ...(props.isPaginated && {
      manualPagination: true,
      pageCount: Math.ceil(props.totalRowsCount / props.pageRowCount),
      onPaginationChange: props.onPageChange,
      state: {
        pagination: { pageIndex: props.currentPage, pageSize: props.pageRowCount },
        columnVisibility: props.columnVisibility || {}
      }
    })
  })

  const { rows } = table.getRowModel()
  const parentRef = useRef(null)

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => props.rowHeight,
    overscan: props.overscan || 5
  })

  return (
    <div ref={parentRef} className="overflow-auto h-full">
      <div className="relative" style={{ height: `${virtualizer.getTotalSize()}px` }}>
        <table className={clsx(s.table, props.className)}>
          <THead table={table} />
          <tbody className="">
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index]
              return (
                <tr
                  key={row.id}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={s.td}
                      key={cell.id}
                      style={{
                        width: cell.column.columnDef.width ? `${cell.column.columnDef.width}px` : undefined,
                        padding: cell.column.columnDef.padding
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )
            })}
            {rows.length === 0 && (
              <tr>
                <td colSpan={1000} className={s.noRows}>
                  No items to display.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {props.isPaginated && !(!props.padToPageSize && Math.ceil(props.totalRowsCount / props.pageRowCount) <= 1) && (
          <PaginationControls table={table} props={props} />
        )}
      </div>
    </div>
  )
}

const THead = ({ table }) => {
  return (
    <thead className={s.thead}>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th
              className={s.th}
              key={header.id}
              style={{
                width:
                  header.column.columnDef.colWidth !== undefined ? `${header.column.columnDef.colWidth}px` : undefined
              }}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  )
}

const TBody = ({ table, props }) => {
  const rows = table.getRowModel().rows

  return (
    <tbody>
      {rows.map((row) => (
        <tr className={s.tr} key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td
              className={s.td}
              key={cell.id}
              style={{
                width: cell.column.columnDef.width ? `${cell.column.columnDef.width}px` : undefined,
                padding: cell.column.columnDef.padding
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
      {rows.length === 0 && (
        <tr>
          <td colSpan={1000} className={s.noRows}>
            No items to display.
          </td>
        </tr>
      )}
    </tbody>
  )
}

const PaginationControls = ({ table, props }) => {
  return (
    <div className={s.paginationCont}>
      <div className={s.buttonSpacer}>
        <Button
          style={{ padding: '0px 10px' }}
          variant="white"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon Comp={ChevronDoubleLeftIcon} size={4} />
        </Button>
        <Button
          style={{ padding: '0px 10px' }}
          variant="white"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon Comp={ChevronLeftIcon} size={4} />
        </Button>
      </div>
      {props.isLoading ? (
        <div>Loading...</div>
      ) : props.padToPageSize ? (
        table.getRowModel().rows.length === 0 ? (
          <div>No items to display.</div>
        ) : (
          <div>
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </div>
        )
      ) : (
        <div>
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
      )}
      <div className={s.buttonSpacer}>
        <Button
          style={{ padding: '0px 10px' }}
          variant="white"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <Icon Comp={ChevronRightIcon} size={4} />
        </Button>
        <Button
          style={{ padding: '0px 10px' }}
          variant="white"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <Icon Comp={ChevronDoubleRightIcon} size={4} />
        </Button>
      </div>
    </div>
  )
}

const TableLink = ({ children, href }) => (
  <a href={href}>
    <div className={s.tableLink}>
      <div>{children}</div>
      <ArrowUpRightIcon className={s.icon} />
    </div>
  </a>
)

export const PaginatedTable = ({ data, columns, pageSize, ...props }) => {
  const [{ pageIndex }, setPagination] = useState({ pageIndex: 0, pageSize })
  const dataSliced = data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

  return (
    <ReactTable
      columns={columns}
      data={dataSliced}
      isPaginated={true}
      currentPage={pageIndex}
      onPageChange={setPagination}
      totalRowsCount={data.length}
      pageRowCount={pageSize}
      {...props}
    />
  )
}

ReactTable.Link = TableLink

export default ReactTable

/*const ReactTableOld = ({
  data,
  cols,
  isPaginated,
  isCompact,
  notFull = false,
  currentPage,
  onPageChange,
  onRowClick = () => { },
  totalRowsCount,
  padToPageSize,
  emptyMessage,
  thinRows,
  isLoading,
  tableFixed = false,
  pageRowCount = 10,
  dummyRowHeight,
  rowHighlight = true,
  showPaginationControls = true,
  columnVisibility = {}
}) => {
  const table = useReactTable({
    data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: !!isPaginated,
    pageCount: isPaginated ? Math.ceil(totalRowsCount / pageRowCount) : -1,
    onPaginationChange: !!isPaginated && onPageChange,
    state: {
      pagination: !!isPaginated && { pageIndex: currentPage, pageSize: pageRowCount },
      columnVisibility
    }
  })

  return (
    <div className={clsx(notFull && 'overflow-x-scroll h-full')}>
      <table className={clsx(!notFull && 'w-full', notFull && 'min-w-full', tableFixed && 'table-fixed')}>
        <thead>
          {
            table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {
                  headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className={clsx(
                        'border-b-2 border-b-grey-400 text-sm font-bold text-left',
                        header.column.columnDef.headerPad !== false && !isCompact && 'py-1.5 px-2',
                        isCompact && 'py-0.5 px-0',
                        header.column.columnDef.noPadAnywhere === true && '!p-0'
                      )}
                    >
                      {
                        header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                      }
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        <tbody className="relative">
          {
            table.getRowModel().rows.length !== 0 && table.getRowModel().rows.map(row => (
              <tr key={row.id} className={clsx(rowHighlight && 'hover:bg-blue-50')} onClick={() => onRowClick(row.original)}>
                {
                  row.getVisibleCells().map(cell => {
                    const text = flexRender(cell.column.columnDef.cell, cell.getContext())

                    return (
                      <td
                        key={cell.id}
                        className={clsx(
                          'border-t border-t-grey-400 relative text-sm',
                          cell.column.columnDef.cellPad !== false && !thinRows && 'py-1.5 px-2',
                          cell.column.columnDef.cellPad !== false && thinRows && 'py-0.5 px-2',
                          cell.column.columnDef.noPadAnywhere === true && '!p-0',
                          cell.column.columnDef.noWrap && 'whitespace-nowrap'
                        )}
                        style={{
                          width: cell.column.columnDef.cellSize ? `${cell.column.columnDef.cellSize}px` : undefined,
                        }}
                      >
                        {
                          cell.column.columnDef.truncate ? (
                            <span className="absolute inset-0 leading-8 truncate" title={cell.getValue()}>{text}</span>
                          ) : text
                        }
                      </td>
                    )
                  })}
              </tr>
            ))
          }
          {
            isPaginated && padToPageSize &&
            table.getRowModel().rows.length < pageRowCount &&
            (new Array(pageRowCount - table.getRowModel().rows.length).fill('ayylmao')).map((_, ind) => (
              <tr key={ind} className={clsx('select-none', ind === 0 && 'border-t-grey-400 border-t-2')}>
                {
                  table.getHeaderGroups().map((headerGroup) => headerGroup.headers.map((header) => (
                    <td key={header.id} className="" style={{ height: `${dummyRowHeight}px` }}>&nbsp;</td>
                  )))
                }
              </tr>
            ))
          }
          {
            isLoading && (
              <div className="flex absolute inset-0 justify-center items-center bg-white">
                <Spinner />
              </div>
            )
          }
        </tbody>
      </table>
      {
        !padToPageSize && table.getRowModel().rows.length === 0 && (
          <div className="flex justify-center items-center p-2 w-full italic">
            {emptyMessage || 'No items to display.'}
          </div>
        )
      }
      {
        (isPaginated && !(!padToPageSize && Math.ceil(totalRowsCount / pageRowCount) <= 1)) ? (
          <div className="flex justify-between items-center px-2 pb-2 mt-2 w-full">
            <div className="space-x-2">
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronDoubleLeftIcon className="w-4 h-4" />
              </Button>
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeftIcon className="w-4 h-4" />
              </Button>
            </div>
            {
              isLoading ? (
                <div>Loading...</div>
              ) : (
                padToPageSize ? (
                  table.getRowModel().rows.length === 0 ? (
                    <div>No items to display.</div>
                  ) : (
                    <div>Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</div>
                  )
                ) : (
                  <div>Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</div>
                )
              )
            }

            <div className="space-x-2">
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRightIcon className="w-4 h-4" />
              </Button>
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <ChevronDoubleRightIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>
        ) : null
      }
    </div>
  )
}*/
