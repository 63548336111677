import React, { useContext, useEffect, useState } from 'react'
import './Gauge.css'
import './styles.css'
import { Box, FormControl, MenuItem, Select } from '@mui/material'
import moment from 'moment-timezone'
import { GlobalContext } from '../../context/GlobalContext'
import { ClipLoader } from 'react-spinners'
import LogoHead from '../../assets/Head.png'
import axios from 'axios'
import CustAnaly from '../CustAnaly'
import { countWorkingDaysUntil, countWorkingDaysLeft } from '../../utils'
import WorkingDaysForecast from '../WorkingDaysForecast'
import SalesForecast from '../SalesForecast'
import SalesForecastContainer from '../SalesForecastContainer'
import DailyPerformanceCalendar from './DailyPerformanceCalendar'
import PromotionsDisplay from '../newholidays/components/PromotionsDisplay'

function AdminOverview({
  rmBranches,
  operatorData,
  drillDownBranch,
  setDrillDownBranch,
  progress,
  setLastUpdated,
  setProgress
}) {
  const { token, isToday } = useContext(GlobalContext)
  const currentDate = new Date()
  const month = currentDate.toLocaleString('default', { month: 'long' })
  const formattedDate =
    currentDate.getDate().toString().padStart(2, '0') +
    '/' +
    (currentDate.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    currentDate.getFullYear()

  const today = new Date()
  const year = today.getFullYear()
  const monthn = today.getMonth() + 1
  const day = today.getDate()
  const dayOfWeek = today.getDay()
  const lastDayOfMonth = new Date(year, month, 0).getDate()
  const countWorkingDaysUntil = (year, month, day) => {
    let workingDays = 0
    for (let d = 1; d <= day; d++) {
      const weekday = new Date(year, month - 1, d).getDay()
      if (weekday >= 1 && weekday <= 5) {
        workingDays += 1
      } else if (weekday === 6) {
        workingDays += 0.5
      }
    }
    return workingDays
  }



  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState('ALL')
  const [gaugePercentage, setGaugePercentage] = useState(0)
  const [needleRotation, setNeedleRotation] = useState('rotate(0deg)')
  const [marginGaugePercentage, setMarginGaugePercentage] = useState(0)
  const [marginNeedleRotation, setMarginNeedleRotation] =
    useState('rotate(0deg)')
  const [cumulativeSalesTarget, setCumulativeSalesTarget] = useState(0)
  const [data, setData] = useState([])
  const [fullData, setFullData] = useState([])
  const [currentSales, setCurrentSales] = useState(0)
  const [salesTarget, setSalesTarget] = useState(0)
  const [currentMargin, setCurrentMargin] = useState(0)
  const [currentMarginPercent, setCurrentMarginPercent] = useState(0)
  const [marginTargetPercent, setMarginTargetPercent] = useState(0)
  const [currentSalesAll, setCurrentSalesAll] = useState(0)
  const [salesTargetAll, setSalesTargetAll] = useState(0)
  const [currentMarginAll, setCurrentMarginAll] = useState(0)
  const [currentMarginPercentAll, setCurrentMarginPercentAll] = useState(0)
  const [marginTargetPercentAll, setMarginTargetPercentAll] = useState(0)
  const [selectedBranch, setSelectedBranch] = useState('ALL')
  const [branches, setBranches] = useState(0)
  const [underSales, setUnderSales] = useState(0)
  const [underMargin, setUnderMargin] = useState(0)
  const [salesDiff, setSalesDiff] = useState(0)
  const [marginDiff, setMarginDiff] = useState(0)
  const [loaderVis, setLoaderVis] = useState(false)
  const [quotesOverview, setQuotesOverview] = useState([])
  const [workingDaysTotal, setWorkingDaysTotal] = useState(0)
  const displayText = isToday
    ? `${formattedDate} - Today's Figures`
    : `${month.toUpperCase()} - Figures up to ${formattedDate} - ${workingDaysTotal} working days - ${countWorkingDaysUntil(year, monthn, day) - 1} gone - ${countWorkingDaysLeft(year, monthn, day) + 1} to go`


  const formatCurrency = (value) => {
    return value.toLocaleString('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  const fetchRegions = async () => {
    try {
      const response = await fetch(
        'https://spar.connectplus.parts/api/salesassist/cplusassist/regions',
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      const data = await response.json()
      setRegions(data)
    } catch (error) {
      console.error('Error fetching regions:', error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoaderVis(true)
      try {
        setCurrentSales(0)
        setCurrentMargin(0)
        setCurrentMarginPercent(0)
        setMarginTargetPercent(0)
        setGaugePercentage(0)
        updateNeedleRotation(0)
        setMarginGaugePercentage(0)
        updateMarginNeedleRotation(0)
        setCumulativeSalesTarget(0)

        let url
        if (isToday) {
          // Use original endpoint for today's data
          if (operatorData.Status === 'rm') {
            const regionalManager = encodeURIComponent(operatorData.Name)
            url = `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranchToday?regionalManager=${regionalManager}`
          } else if (
            operatorData.Status === 'admin' &&
            selectedRegion !== 'ALL'
          ) {
            url = `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranchToday?regionalManager=${encodeURIComponent(
              selectedRegion
            )}`
          } else {
            url =
              'https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranchToday'
          }
        } else {
          // Use cached endpoint for month data
          const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
          const endOfYesterday = moment()
            .subtract(1, 'days')
            .endOf('day')
            .format('YYYY-MM-DD')

          if (operatorData.Status === 'rm') {
            const regionalManager = encodeURIComponent(operatorData.Name)
            url = `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByDateRange?fromDate=${startOfMonth}&toDate=${endOfYesterday}&regionalManager=${regionalManager}`
          } else if (
            operatorData.Status === 'admin' &&
            selectedRegion !== 'ALL'
          ) {
            url = `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByDateRange?fromDate=${startOfMonth}&toDate=${endOfYesterday}&regionalManager=${encodeURIComponent(
              selectedRegion
            )}`
          } else {
            url = `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByDateRange?fromDate=${startOfMonth}&toDate=${endOfYesterday}`
          }
        }

        console.info(url)

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        let data = await response.json()
        setFullData(data)

        // Handle the different response formats
        if (!isToday && data.branches) {
          data = data.branches
        }

        if (operatorData.Status === 'bm' || operatorData.Status === 'op') {
          data = data.filter((branch) => branch.branch === operatorData.Branch)
          setSelectedBranch(operatorData.Branch)
        }

        setData(data)

        const totalSalesSum = data.reduce((acc, branch) => {
          const totalSales = branch.totalSales || 0
          const totalCredits = branch.totalCredits || 0
          return acc + totalSales + totalCredits
        }, 0)
        const totalMarginSum = data.reduce(
          (acc, branch) => acc + (branch.totalMargin + branch.creditsMargin),
          0
        )

        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        const day = today.getDate()
        const dayOfWeek = today.getDay()
        const lastDayOfMonth = new Date(year, month, 0).getDate()

        let totalWorkingDays = 0
        for (let i = 1; i <= lastDayOfMonth; i++) {
          const date = new Date(year, month - 1, i)
          const dayOfWeek = date.getDay()
          if (dayOfWeek > 0 && dayOfWeek < 6) {
            totalWorkingDays++
          } else if (dayOfWeek === 6) {
            totalWorkingDays += 0.5
          }
        }
        setWorkingDaysTotal(totalWorkingDays)
        //alert(totalWorkingDays)

        let totalSalesTargetSum = 0
        let totalMarginTargetSum = 0

        data.forEach((branch) => {
          let salesTargetAdjusted = 0
          let marginTargetAdjusted = 0

          if (isToday) {
            if (dayOfWeek === 0) {
              if (branch.sundayTrade === 0) {
                salesTargetAdjusted = 0
                marginTargetAdjusted = 0
              } else if (branch.sundayTrade === 0.5) {
                salesTargetAdjusted = branch.salesTarget * 0.5
                marginTargetAdjusted = branch.marginTarget * 0.5
              } else if (branch.sundayTrade === 1) {
                salesTargetAdjusted = branch.salesTarget
                marginTargetAdjusted = branch.marginTarget
              }
            } else if (dayOfWeek === 6) {
              if (branch.saturdayTrade === 0) {
                salesTargetAdjusted = 0
                marginTargetAdjusted = 0
              } else if (branch.saturdayTrade === 0.5) {
                salesTargetAdjusted = branch.salesTarget * 0.5
                marginTargetAdjusted = branch.marginTarget * 0.5
              } else if (branch.saturdayTrade === 1) {
                salesTargetAdjusted = branch.salesTarget
                marginTargetAdjusted = branch.marginTarget
              }
            } else {
              salesTargetAdjusted = branch.salesTarget
              marginTargetAdjusted = branch.marginTarget
            }
          } else {
            salesTargetAdjusted = branch.salesTarget
            marginTargetAdjusted = branch.marginTarget
          }

          totalSalesTargetSum += salesTargetAdjusted
          totalMarginTargetSum += marginTargetAdjusted
        })

        const averageMarginTarget = totalMarginTargetSum / data.length

        if (currentSales !== 0) {
          setCurrentSales(totalSalesSum)
          setCurrentMargin(totalMarginSum)
          setCurrentMarginPercent((totalMarginSum / totalSalesSum) * 100)
        }

        setCurrentSalesAll(totalSalesSum)
        setCurrentMarginAll(totalMarginSum)
        setSalesTargetAll(totalSalesTargetSum)
        setCurrentMarginPercentAll((totalMarginSum / totalSalesSum) * 100)
        setMarginTargetPercentAll(averageMarginTarget)

        if (isToday) {
          setSalesTarget(totalSalesTargetSum)
          setMarginTargetPercent(averageMarginTarget)
        } else {
          const workingDaysPassed = countWorkingDaysUntil(year, month, day) - 1

          const newCumulativeSalesTarget = totalSalesTargetSum * workingDaysPassed
          setCumulativeSalesTarget(newCumulativeSalesTarget)
        }

        setLoaderVis(false)
      } catch (error) {
        setLoaderVis(false)
        console.error('Error fetching data:', error)
      }
    }

    fetchData()

    const interval = setInterval(() => {
      if (isToday) fetchData()
    }, 480 * 1000)

    return () => clearInterval(interval)
  }, [token, isToday, selectedRegion])

  useEffect(() => {
    if (operatorData.Status === 'admin') {
      fetchRegions()
    }
  }, [operatorData.Status, token])

  useEffect(() => {
    const fetchData = async () => {
      let quotesOverviewURL =
        'https://spar.connectplus.parts/api/salesassist/cplusassist/getQuotesOverview'

      if (selectedBranch !== 'ALL') {
        quotesOverviewURL += '?branch=' + selectedBranch
      }

      const quotesOverviewResponse = await fetch(quotesOverviewURL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const quotesOverviewData = await quotesOverviewResponse.json()
      setQuotesOverview(quotesOverviewData)
    }

    fetchData()
  }, [selectedBranch, token])



  const countRemainingWorkingDays = (year, month, day) => {
    const totalDays = new Date(year, month, 0).getDate()
    let remainingWorkingDays = 0

    for (let d = day + 1; d <= totalDays; d++) {
      const weekday = new Date(year, month - 1, d).getDay()
      if (weekday === 0) continue
      if (weekday > 0 && weekday < 6) remainingWorkingDays++
      if (weekday === 6) remainingWorkingDays += 0.5
    }

    return remainingWorkingDays
  }

  const calculateMarginGauge = async () => {
    return (currentMargin / (marginTargetPercent * cumulativeSalesTarget)) * 100
  }

  const needleAlign = 3

  const updateNeedleRotation = async (percentage) => {
    const rotationDegree = (percentage / 100) * 180
    setNeedleRotation(
      `rotate(${rotationDegree > 180 ? 180 + needleAlign : rotationDegree}deg)`
    )
  }

  const updateMarginNeedleRotation = async (percentage) => {
    const rotationDegree = (percentage / 100) * 180
    setMarginNeedleRotation(
      `rotate(${rotationDegree > 180 ? 180 + needleAlign : rotationDegree}deg)`
    )
  }

  const calculateSalesGauge = async () => {
    return (currentSales / cumulativeSalesTarget) * 100
  }

  useEffect(() => {
    const fetchData = async () => {
      const newGaugeValue = await calculateSalesGauge()
      setGaugePercentage(newGaugeValue)
      updateNeedleRotation(newGaugeValue)

      const newMarginGaugeValue = await calculateMarginGauge()
      setMarginGaugePercentage(newMarginGaugeValue)
      updateMarginNeedleRotation(newMarginGaugeValue)
    }

    fetchData()
  }, [currentSales, cumulativeSalesTarget])

  useEffect(() => {
    let newCumulativeSalesTarget
    let newSalesTarget

    if (data.message === 'No data found for the specified criteria') {
      newSalesTarget = 0
      setCurrentSales(0)
      setCurrentMargin(0)
      setCurrentMarginPercent(0)
      setMarginTargetPercent(0)
      setGaugePercentage(0)
      updateNeedleRotation(0)
      setMarginGaugePercentage(0)
      updateMarginNeedleRotation(0)
      setCumulativeSalesTarget(0)
      return
    }

    if (selectedBranch && selectedBranch !== 'ALL') {
      const branchData = data.find((branch) => branch.branch === selectedBranch)
      if (branchData) {
        const today = new Date()
        const dayOfWeek = today.getDay()

        if (isToday) {
          if (dayOfWeek === 0) {
            if (branchData.sundayTrade === 0) {
              newSalesTarget = 0
            } else if (branchData.sundayTrade === 0.5) {
              newSalesTarget = branchData.salesTarget * 0.5
            } else if (branchData.sundayTrade === 1) {
              newSalesTarget = branchData.salesTarget
            }
          } else if (dayOfWeek === 6) {
            if (branchData.saturdayTrade === 0) {
              newSalesTarget = 0
            } else if (branchData.saturdayTrade === 0.5) {
              newSalesTarget = branchData.salesTarget * 0.5
            } else if (branchData.saturdayTrade === 1) {
              newSalesTarget = branchData.salesTarget
            }
          } else {
            newSalesTarget = branchData.salesTarget
          }
        } else {
          newSalesTarget = branchData.salesTarget
        }

        setCurrentSales(
          (branchData.totalSales || 0) + (branchData.totalCredits || 0)
        )
        setCurrentMargin(
          Number(
            (
              (branchData.totalMargin || 0) + (branchData.creditsMargin || 0)
            ).toFixed(2)
          )
        )
        setCurrentMarginPercent(
          ((branchData.totalMargin + (branchData.creditsMargin || 0)) /
            (branchData.totalSales + (branchData.totalCredits || 0))) *
          100
        )
        setMarginTargetPercent(branchData.marginTarget)
      }
    } else {
      newSalesTarget = salesTargetAll
      setCurrentSales(currentSalesAll)
      setCurrentMargin(currentMarginAll)
      setCurrentMarginPercent((currentMarginAll / currentSalesAll) * 100)
      setMarginTargetPercent(marginTargetPercentAll)
    }

    if (newSalesTarget !== undefined) {
      setSalesTarget(newSalesTarget)
      if (isToday) {
        newCumulativeSalesTarget = newSalesTarget
      } else {
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        const day = today.getDate()
        // The fix: Subtract 1 from day when calculating working days in monthly mode
        const workingDaysPassed = countWorkingDaysUntil(year, month, day - 1)
        newCumulativeSalesTarget = newSalesTarget * workingDaysPassed
      }
      setCumulativeSalesTarget(newCumulativeSalesTarget)
    }
  }, [
    selectedBranch,
    data,
    isToday,
    salesTargetAll,
    currentSalesAll,
    currentMarginAll
  ])

  const cycleBranches = (direction) => {
    let branchList = ['ALL']
    if (data && data.length > 0) {
      branchList = ['ALL', ...data.map((branch) => branch.branch)]
    }

    let currentIndex = branchList.indexOf(selectedBranch)

    if (direction === 'forward') {
      currentIndex =
        currentIndex === branchList.length - 1 ? 0 : currentIndex + 1
    } else {
      currentIndex =
        currentIndex === 0 ? branchList.length - 1 : currentIndex - 1
    }

    setSelectedBranch(branchList[currentIndex])
  }

  useEffect(() => {
    setMarginDiff(currentMargin - marginTargetPercent * cumulativeSalesTarget)
    setSalesDiff(currentSales - cumulativeSalesTarget)
  }, [currentMargin, marginTargetPercent, cumulativeSalesTarget, currentSales])

  function getGaugeColor(value) {
    if (value < 0 || value > 100) {
      return '#1eaa59'
    }

    if (value < 0) {
      return '#e84c3d'
    }

    if (value <= 25) {
      return '#e84c3d'
    } else if (value <= 50) {
      return '#e67e22'
    } else if (value <= 75) {
      return '#f1c40f'
    } else {
      return '#1eaa59'
    }
  }

  return (
    <div className="woohops">
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          display: loaderVis ? 'flex' : 'none',
          width: '100vw',
          height: '100vh'
        }}
      >
        <ClipLoader color="#123abc" loading={loaderVis} size={150} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h2 style={{ color: 'white', fontSize: 26, fontWeight: 700 }}>
          {displayText}
        </h2>
        <div
          style={{ display: 'flex', flexDirection: 'row', padding: '8px 0' }}
        >
          {operatorData.Status === 'admin' && (
            <FormControl size="small">
              <Select
                value={selectedRegion}
                onChange={(e) => {
                  setSelectedRegion(e.target.value)
                  setSelectedBranch('ALL')
                }}
                label="Select Region"
                style={{
                  fontWeight: 'bold',
                  width: 200,
                  color: 'white',
                  textAlign: 'center',
                  marginRight: '10px'
                }}
                inputProps={{ style: { color: 'red' } }}
                OutlinedInputProps={{ style: { borderColor: 'white' } }}
              >
                <MenuItem value="ALL">ALL REGIONS</MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region.RegionId} value={region.RegionName}>
                    {region.RegionName.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl size="small">
            <Select
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              label="Select Branch"
              style={{
                fontWeight: 'bold',
                width: 200,
                color: 'white',
                textAlign: 'center'
              }}
              inputProps={{ style: { color: 'white' } }}
              OutlinedInputProps={{ style: { borderColor: 'white' } }}
            >
              <MenuItem value="ALL">ALL BRANCHES</MenuItem>
              {data && data.length > 0 ? (
                data
                  .sort((a, b) => a.branch.localeCompare(b.branch))
                  .map((branch, index) => (
                    <MenuItem key={index} value={branch.branch}>
                      {branch.branch.toUpperCase()}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem disabled>No branches available</MenuItem>
              )}
            </Select>
          </FormControl>
          <div style={{ paddingLeft: 10 }}>
            <button
              onClick={() => cycleBranches('backward')}
              style={{ marginRight: '10px', fontSize: '20px' }}
            >
              &#8592;
            </button>
            <button
              onClick={() => cycleBranches('forward')}
              style={{ marginRight: '10px', fontSize: '20px' }}
            >
              &#8594;
            </button>
          </div>
        </div>
      </div>
      <div
        id="header"
        style={{
          borderRadius: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: 'yellow',
          padding: 20,
          paddingLeft: 30,
          paddingRight: 30
        }}
      >
        <YellowStat
          label="SALES"
          value={`£${currentSales.toLocaleString('en-GB', {
            maximumFractionDigits: 2
          })}`}
        />
        <YellowStat
          label="SALES TARGET"
          value={`£${cumulativeSalesTarget.toLocaleString('en-GB', {
            maximumFractionDigits: 2
          })}`}
        />
        <YellowStat
          label="MARGIN"
          value={`£${formatCurrency(currentMargin)}`}
        />
        <YellowStat
          label="MARGIN TARGET"
          value={`£${(
            marginTargetPercent * cumulativeSalesTarget
          ).toLocaleString('en-GB', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`}
        />
        <YellowStat
          label="MARGIN %"
          value={`${isNaN(currentMarginPercent)
            ? '0.00'
            : currentMarginPercent.toFixed(2)
            }%`}
        />
        <YellowStat
          label="MARGIN TARGET %"
          value={`${(marginTargetPercent * 100).toFixed(2)}%`}
        />
      </div>

      <div
        className="gauge-wrapper"
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div>
          <div className="gauge four rischio3">
            <div className="slice-colors">
              <div className="st slice-item"></div>
              <div className="st slice-item"></div>
              <div className="st slice-item"></div>
              <div className="st slice-item"></div>
            </div>
            <div className="needle" style={{ transform: needleRotation }}></div>
            <div className="gauge-center">
              <div className="label">SALES</div>
              <div className="number">
                {isNaN(gaugePercentage)
                  ? '0.00'
                  : Math.min(gaugePercentage, 100).toFixed(2)}
                %
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: 10
            }}
          >
            <div style={{ color: 'white', flex: 1, textAlign: 'left' }}>
              SALES
            </div>
            <div
              style={{
                color: 'white',
                flex: 1,
                textAlign: 'center',
                borderRadius: 15,
                padding: 5
              }}
            >
              DIFFERENCE
            </div>
            <div style={{ color: 'white', flex: 1, textAlign: 'right' }}>
              TARGET
            </div>
          </div>

          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: 5
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                color: 'white',
                flex: 1,
                textAlign: 'left'
              }}
            >
              £
              {currentSales.toLocaleString('en-GB', {
                maximumFractionDigits: 0
              })}
            </div>
            <div
              style={{
                fontWeight: 'bold',
                color: 'white',
                flex: 1,
                textAlign: 'center',
                borderRadius: 15,
                padding: 5,
                backgroundColor: getGaugeColor(gaugePercentage)
              }}
            >
              £
              {salesDiff.toLocaleString('en-GB', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
            </div>
            <div
              style={{
                fontWeight: 'bold',
                color: 'white',
                flex: 1,
                textAlign: 'right'
              }}
            >
              £
              {cumulativeSalesTarget.toLocaleString('en-GB', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
            </div>
          </div>
        </div>
        <div style={{}}>
          <div className="gauge four rischio3">
            <div className="slice-colors">
              <div className="st slice-item"></div>
              <div className="st slice-item"></div>
              <div className="st slice-item"></div>
              <div className="st slice-item"></div>
            </div>
            <div
              className="needle"
              style={{ transform: marginNeedleRotation }}
            ></div>
            <div className="gauge-center">
              <div className="label">MARGIN</div>
              <div className="number">
                {isNaN(marginGaugePercentage)
                  ? '0.00'
                  : Math.min(marginGaugePercentage, 100).toFixed(2)}
                %
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: 10
            }}
          >
            <div style={{ color: 'white', flex: 1, textAlign: 'left' }}>
              MARGIN
            </div>
            <div
              style={{
                color: 'white',
                flex: 1,
                textAlign: 'center',
                borderRadius: 15,
                padding: 5
              }}
            >
              DIFFERENCE
            </div>
            <div style={{ color: 'white', flex: 1, textAlign: 'right' }}>
              TARGET
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: 5
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                color: 'white',
                flex: 1,
                textAlign: 'left'
              }}
            >
              £
              {currentMargin.toLocaleString('en-GB', {
                maximumFractionDigits: 0
              })}
            </div>
            <div
              style={{
                fontWeight: 'bold',
                color: 'white',
                flex: 1,
                textAlign: 'center',
                borderRadius: 15,
                padding: 5,
                backgroundColor: getGaugeColor(marginGaugePercentage)
              }}
            >
              £
              {marginDiff.toLocaleString('en-GB', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}
            </div>
            <div
              style={{
                fontWeight: 'bold',
                color: 'white',
                flex: 1,
                textAlign: 'right'
              }}
            >
              £
              {(marginTargetPercent * cumulativeSalesTarget).toLocaleString(
                'en-GB',
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }
              )}
            </div>
          </div>
        </div>

        {/* chart stuff */}
        {operatorData.Status === 'admin' ||
          operatorData.Status === 'rm' ||
          operatorData.Status === 'bm' ? (
          <div style={{ width: '50%' }}>
            <DailyPerformanceCalendar
              branchData={fullData.branches ? fullData : { branches: fullData }}
              selectedBranch={selectedBranch === 'ALL' ? null : selectedBranch}
            />
            <SalesForecastContainer
              currentSales={currentSales}
              currentMargin={currentMargin}
              salesTarget={salesTarget}
              marginTarget={marginTargetPercent * salesTarget}
              isToday={isToday}
            />
          </div>
        ) : null}
      </div>

      <PromotionsDisplay />

      {/* quotes stuff */}
      {/* <div style={{ paddingTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h5 style={{ fontSize: 20, color: 'grey', margin: 0 }}>📝 QUOTES</h5>
          <h5 style={{ fontSize: 40, color: 'white', margin: 0 }}>
            {quotesOverview && quotesOverview.PartConversionRate ? ((quotesOverview.PartConversionRate * 100)).toFixed(2) : 0}% /
            {quotesOverview && quotesOverview.QuantityConversionRate ? ((quotesOverview.QuantityConversionRate * 100)).toFixed(2) : 0}%
          </h5>
        </div>
        
      </div> */}
      {/* <Box style={{}}>
        <CustAnaly />
      </Box> */}
    </div>
  )
}

export default AdminOverview

const YellowStat = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h5
        style={{
          color: 'gray',
          margin: 0,
          letterSpacing: 1,
          fontSize: 15,
          fontWeight: 700,
          lineHeight: 1.1
        }}
      >
        {label}
      </h5>
      <h4
        style={{
          color: 'black',
          margin: 0,
          fontWeight: 700,
          fontSize: 20,
          lineHeight: 1.1
        }}
      >
        {value}
      </h4>
    </div>
  )
}
