import { useContext, useEffect, useState } from 'react'
import { Card, Icon, Table } from './gsys-ui'
import useApi from './hooks/useApi'
import moment from 'moment-timezone'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import {
  Checkbox,
  NativeSelect,
  TextInput,
  Button,
  Textarea,
  Group,
  Radio,
  Chip,
  ActionIcon
} from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { modals } from '@mantine/modals'
import { GiPalmTree } from 'react-icons/gi'
import { GlobalContext } from '../../context/GlobalContext'
import { ClockIcon, TrashIcon } from 'lucide-react'
import BranchDropdown from './components/BranchDropdown'
import MultiButton from './gsys-ui/MultiButton/MultiButton'
import useBranchStore from './stores/useBranchStore'
import { CircularProgress } from '@mui/material'
import haxios from './lib/haxios'
import { PaginatedTable, TableVirtualized } from './gsys-ui/Table/Table'
import { successToast } from './lib/helpers'

const Holidays = () => {
  const { operatorData } = useContext(GlobalContext)
  const selectedBranch = useBranchStore((state) => state.selectedBranch)
  const selectedRegion = useBranchStore((state) => state.selectedRegion)
  const regions = useBranchStore((state) => state.regions)
  const [selectedPosition, setSelectedPosition] = useState('ALL')
  const [selectedStaff, setSelectedStaff] = useState('ALL')
  const [selectedYear, setSelectedYear] = useState(moment.utc().year())
  const [status, setStatus] = useState('PENDING')
  const { operatorData: uinfo } = useContext(GlobalContext)

  useEffect(() => {
    setSelectedPosition('ALL')
    setSelectedStaff('ALL')
  }, [selectedBranch])

  const {
    data: holidayRequests,
    isLoading,
    refetch
  } = useApi({
    url: '/salesassist/cplusassist/holidayrequests'
  })

  const {
    data: staffData,
    isLoading: isStaffLoading,
    refetch: refetchStaff
  } = useApi({
    url: '/salesassistnew/ustafferators'
  })

  if (isLoading || isStaffLoading) {
    return (
      <div className="flex absolute inset-0 justify-center items-center">
        <CircularProgress />
      </div>
    )
  }

  const handleRefetch = () => {
    return Promise.all([refetch(), refetchStaff()])
  }

  const dataJoined = holidayRequests
    .map((req) => {
      const staff = staffData
        .filter((s) => {
          return s.StaffId === req.staffId
        })
        .pop()
      if (!staff) return null

      return {
        ...req,
        StaffName: `${staff.FName} ${staff.SName}`,
        Position: staff.Position,
        Branch: staff.Branch
      }
    })
    .filter(Boolean)

  const dataFilteredNotPosition = dataJoined.filter((req) => {
    const requestDate = moment.utc(req.dtStart)
    const isInDate = requestDate.year() === selectedYear
    const regionMatch = selectedRegion
      ? regions?.[selectedRegion]
        ? regions[selectedRegion].some((b) => b.MamCode === req.Branch)
        : false
      : true
    const isBranch = selectedBranch === 'ALL' || req.Branch === selectedBranch
    return isInDate && isBranch && regionMatch
  })

  const allPositions = [...new Set(dataFilteredNotPosition.map((s) => s.Position.trim()))]
    .sort()
    .map((p) => ({ label: p, value: p }))

  const dataFilteredNotStaff = dataFilteredNotPosition.filter((req) => {
    const isNotSelf =
      operatorData.staffId === '5f043d68-1e57-1a87-d60e-2dbe719ed017'
        ? true
        : req.staffId !== operatorData.staffId // id is spartan_admin
    const isNotRep = operatorData.Status === 'admin' ? true : req.Branch !== 'REP'
    const isStatus = status === 'ALL' || req.decision.toLowerCase() === status.toLowerCase()
    const isPosition = selectedPosition === 'ALL' || req.Position.trim() === selectedPosition
    const isStaff = selectedStaff === 'ALL' || req.StaffName.trim() === selectedStaff
    return isPosition && isStatus && isNotRep && isNotSelf && isStaff
  })

  const allStaff = [...new Set(dataFilteredNotStaff.map((s) => s.StaffName.trim()))]
    .sort()
    .map((p) => ({ label: p, value: p }))

  const dataFiltered = dataFilteredNotStaff.filter((req) => {
    const isStaff = selectedStaff === 'ALL' || req.StaffName.trim() === selectedStaff
    return isStaff
  })

  const handleAddHoliday = () => {
    modals.openContextModal({
      modal: 'adminHolidayBookingModal',
      title: 'Add holiday request',
      centered: true,
      innerProps: {
        refetch: handleRefetch
      }
    })
  }

  // const doThing = async () => {
  //   const dates = [
  //     moment().month(3).date(18).toDate(),
  //     moment().month(3).date(21).toDate(),
  //     moment().month(4).date(5).toDate(),
  //     moment().month(4).date(26).toDate(),
  //     moment().month(7).date(25).toDate(),
  //     moment().month(11).date(25).toDate(),
  //     moment().month(11).date(26).toDate()
  //   ]

  //   for (const staff of staffData) {
  //     for (const day of dates) {
  //       await haxios.post('/salesassist/cplusassist/holidayrequests', {
  //         staffId: staff.StaffId,
  //         dtStart: moment(day).format('YYYY-MM-DD'),
  //         dtEnd: moment(day).format('YYYY-MM-DD'),
  //         reason: 'Bank holiday',
  //         requestDate: '2025-01-01',
  //         decision: 'APPROVED',
  //         Notes: ''
  //       })

  //       await haxios.post('https://api.aa-vision.com/api/tma/holiday', {
  //         HolidayID: Date.now().toString(36) + Math.random().toString(36).substr(2, 5),
  //         StartDatetime: moment(day).format('YYYY-MM-DD'),
  //         EndDatetime: moment(day).format('YYYY-MM-DD'),
  //         StaffID: staff.StaffId,
  //         TotalDays: 1,
  //         Paid: true,
  //         HoursPerDay: 8,
  //         AddedUserID: '988cca25-74f8-4908-ab96-f2ac9be8d9b3',
  //         AddedDatetime: '2025-01-01 00:00:00'
  //       })

  //       console.log('booked', staff.staffId, day)
  //     }
  //   }
  // }

  return (
    <div className="absolute inset-0">
      <div className="flex flex-col h-full">
        <div className="flex flex-none justify-between items-center px-4 py-1 text-white">
          <div className="flex items-center space-x-2 text-lg font-semibold">
            <div>Holiday requests</div>
          </div>

          <div className="flex items-center space-x-2 font-normal">
            <BranchDropdown />
            <div className="w-[200px]">
              <NativeSelect
                value={selectedStaff}
                onChange={(e) => setSelectedStaff(e.currentTarget.value)}
                data={[{ label: 'All staff', value: 'ALL' }, ...allStaff]}
              />
            </div>
            <div className="w-[200px]">
              <NativeSelect
                value={selectedPosition}
                onChange={(e) => setSelectedPosition(e.currentTarget.value)}
                data={[{ label: 'All positions', value: 'ALL' }, ...allPositions]}
              />
            </div>
            <NativeSelect
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.currentTarget.value))}
              data={Array.from({ length: moment.utc().year() - 2024 + 1 }, (_, i) => 2024 + i).map(
                (num) => ({
                  value: num,
                  label: num
                })
              )}
            />
            <Button onClick={handleAddHoliday}>Add holiday</Button>
          </div>
        </div>

        <div className="flex flex-none justify-end px-4 py-1">
          <MultiButton
            value={status}
            options={[
              { label: 'Pending', value: 'PENDING' },
              { label: 'Approved', value: 'APPROVED' },
              { label: 'Rejected', value: 'REJECTED' },
              { label: 'All', value: 'ALL' }
            ]}
            onChange={setStatus}
          />
        </div>

        <div className="flex-1 pb-2">
          <PaginatedTable
            pageSize={10}
            data={dataFiltered}
            columns={[
              {
                header: 'Date created',
                id: 'reqDate',
                cell: ({ row }) => moment.utc(row.original.requestDate).format('DD/MM/YYYY')
              },
              {
                header: 'Staff',
                accessorKey: 'StaffName'
              },
              {
                header: 'Position',
                accessorKey: 'Position'
              },
              {
                header: 'Branch',
                accessorKey: 'Branch'
              },
              {
                header: 'Start date',
                id: 'start',
                cell: ({ row }) => moment.utc(row.original.dtStart).format('DD/MM/YYYY')
              },
              {
                header: 'End date',
                id: 'end',
                cell: ({ row }) => moment.utc(row.original.dtEnd).format('DD/MM/YYYY')
              },
              {
                header: 'Days',
                id: 'days',
                cell: ({ row }) => {
                  const start = moment.utc(row.original.dtStart)
                  const end = moment.utc(row.original.dtEnd)

                  if (start.hours() === 12) {
                    return `0.5 (PM)`
                  }

                  if (end.hours() === 12) {
                    return `0.5 (AM)`
                  }

                  return end.diff(start, 'days') + 1
                }
              },
              {
                header: 'Reason',
                accessorKey: 'reason'
              },
              {
                header: 'Status',
                id: 'status',
                cell: ({ row }) =>
                  row.original.decision === 'APPROVED' ? (
                    <Chip color="green" checked={true} size="md">
                      Approved
                    </Chip>
                  ) : row.original.decision === 'REJECTED' ? (
                    <Chip
                      color="red"
                      checked={true}
                      icon={<Icon Comp={XMarkIcon} size={4} />}
                      size="md"
                    >
                      Rejected
                    </Chip>
                  ) : (
                    <Chip
                      color="orange"
                      checked={true}
                      icon={<Icon Comp={ClockIcon} size={4} />}
                      size="md"
                    >
                      Pending
                    </Chip>
                  )
              },
              {
                header: 'Notes',
                accessorKey: 'Notes'
              },
              {
                header: '',
                id: 'edit',
                width: 1,
                cell: ({ row }) => (
                  <Actions
                    row={row.original}
                    refetch={refetch}
                    uinfo={uinfo}
                    requests={holidayRequests}
                  />
                )
              }
            ]}
          />
        </div>
      </div>
    </div>
  )
}

const Actions = ({ row, refetch, requests }) => {
  const handleDelete = (id) => {
    modals.openContextModal({
      modal: 'warningModal',
      withCloseButton: false,
      centered: true,
      innerProps: {
        message: `You are about to delete ${row.StaffName}'s holiday request for ${moment
          .utc(row.dtStart)
          .format('DD/MM/YYYY')} to ${moment.utc(row.dtEnd).format('DD/MM/YYYY')}.`,
        submit: async () => {
          await haxios.delete(`/salesassist/cplusassist/holidayrequests/${row._id}`)

          if (row.decision === 'APPROVED') {
            try {
              await haxios.delete(`https://api.aa-vision.com/api/tma/holiday/${row.MattHolId}`)
            } catch (e) {
              console.log(e)
            }
          }

          await refetch()
          successToast(`Holiday request deleted.`)
        }
      }
    })
  }

  const handleReviewRequest = () => {
    modals.openContextModal({
      modal: 'holidayReviewModal',
      centered: true,
      withCloseButton: false,
      padding: 0,
      size: '950px',
      innerProps: {
        thisReq: row,
        requests: requests,
        refetch
      }
    })
  }

  return (
    <div className="flex items-center space-x-2">
      {row.decision === 'PENDING' && <Button onClick={handleReviewRequest}>Review</Button>}
      <ActionIcon variant="transparent" color="rgba(255, 255, 255, 1)" onClick={handleDelete}>
        <Icon Comp={TrashIcon} size={4.5} />
      </ActionIcon>
    </div>
  )
}

export default Holidays
