// src/serviceWorkerRegistration.js

// Register the service worker
export function register() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            navigator.serviceWorker
                .register(swUrl)
                .then((registration) => {
                    console.log('Service Worker registered:', registration);

                    // Listen for updates to the service worker
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;

                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed') {
                                // If there is a new version, force a reload
                                if (navigator.serviceWorker.controller) {
                                    // Inform the user that a new version is available
                                    alert('A new version of the app is available! Reloading...');

                                    // Force the page to reload
                                    window.location.reload();
                                }
                            }
                        };
                    };
                })
                .catch((error) => {
                    console.error('Error registering the service worker:', error);
                });
        });
    }
}

// Unregister service worker when necessary
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error('Error unregistering the service worker:', error);
            });
    }
}
