import React, { useContext, useEffect, useState } from 'react'
import { Container, Typography, Card, CardContent, Tabs, Tab, Box, LinearProgress } from '@mui/material'

import QuoteConversionChart from '../QuoteConversionChart'
import SalesProgress from '../SalesProgress'
import '../../App.css'

import moment from 'moment-timezone'
import { GlobalContext } from '../../context/GlobalContext'
import CustAnaly from '../CustAnaly'
import SalesTab from './SalesTab'
import PromotionsDisplay from '../newholidays/components/PromotionsDisplay'

function OverviewTab({ setProgress, progress }) {
  const { operatorData, username, token } = useContext(GlobalContext)
  const [aggregateData, setAggregateData] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(moment().format('ddd DD/MM/YYYY HH:mm'))

  //console.info(`OperatorData: ${operatorData.Inits}`)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Aggregate Data
        if (operatorData.Inits) {
          const aggregateResponse = await fetch(
            `https://spar.connectplus.parts/api/salesassist/cplusassist/aggregateData/${operatorData.Inits}`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )
          if (!aggregateResponse.ok) {
            throw new Error(`HTTP error! status: ${aggregateResponse.status}`)
          }
          const aggData = await aggregateResponse.json()
          console.log('Aggregate Data:', aggData)
          setAggregateData(aggData)
        }
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchData()
    const interval = setInterval(() => {
      fetchData()
      setLastUpdated(moment().format('ddd DD/MM/YYYY HH:mm'))
    }, 240 * 1000)

    return () => clearInterval(interval)
  }, [username, token])

  return (
    <div style={{ overflow: 'scroll', height: '100%' }}>
      {aggregateData ? (
        <div style={{ overflow: 'scroll', height: '100%' }}>
          <Box>
            <SalesTab />
          </Box>
          <Box style={{ width: '100%' }}>
            <h3 style={{ color: 'white' }}>Overview for {operatorData.Name}</h3>
            <CardContent style={{ padding: 0 }}>
              <SalesProgress sales={aggregateData.GrossSales} target={operatorData.SalesTarget} />
              {/* <Typography><strong>Last Sale: </strong> {aggregateData.LastSale ? `${aggregateData.LastSale.Document}, £${aggregateData.LastSale.Goods}, ${formatDate(aggregateData.LastSale.DateTime)}, ${aggregateData.LastSale.Acct} (${aggregateData.LastSale.Name})` : 'No recent sales'}</Typography>
                        <Typography><strong>Total Invoices: </strong> {aggregateData.TotalInvoices}</Typography>
                        <Typography><strong>Total Lines: </strong> {aggregateData.TotalLines}</Typography> */}
              <br></br>

              <QuoteConversionChart
                quotesConverted={aggregateData?.QuotesConverted}
                quotesOutstanding={aggregateData?.QuotesOutstanding}
              />
            </CardContent>
          </Box>
          {/* <Box variant="outlined" style={{ marginBottom: 5 }}>
            <CardContent
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div
                style={{
                  flex: 1,
                  backgroundColor: '#21242c',
                  borderRadius: 15,
                  padding: 5,
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16
                }}
              >
                📆 Last updated: {lastUpdated}
              </div>
              <div
                style={{
                  flex: 1,
                  backgroundColor: '#21242c',
                  borderRadius: 15,
                  padding: 5,
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16
                }}
              >
                📆 Last Invoice:{' '}
                {aggregateData && moment(aggregateData.LastSale.DateTime).format('ddd DD/MM/YYYY HH:mm')} (£
                {aggregateData.LastSale.Goods ? aggregateData.LastSale.Goods.toFixed(2) : 0})
              </div>
            </CardContent>
          </Box> */}
        </div>
      ) : (
        <p style={{ color: 'white' }}>Loading overview or no data available yet for {operatorData.Name}...</p>
      )}
      <PromotionsDisplay />

    </div>
  )
}

export default OverviewTab
