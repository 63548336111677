import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import { AlertTriangle, Users } from 'lucide-react';
import useMetricsStore from './stores/useMetricsStore';

const MetricSection = ({ title, staff = [], threshold }) => (
    <Box sx={{ mb: 2 }}>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 1
        }}>
            <Users size={14} />
            <Typography color="white" sx={{ fontSize: '0.9rem' }}>
                {title} (Threshold: {threshold})
            </Typography>
        </Box>

        {staff.length > 0 ? (
            staff.map((person, idx) => (
                <Typography
                    key={idx}
                    sx={{
                        ml: 3,
                        color: '#f44336',
                        fontSize: '0.9rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 0.5
                    }}
                >
                    <AlertTriangle size={14} />
                    {person.staffName} ({person.count || person.totalDays}
                    {title.includes('Sick Days') ? ' days' :
                        title.includes('Infractions') ? ' infractions' :
                            ' periods'})
                </Typography>
            ))
        ) : (
            <Typography sx={{ ml: 3, color: '#4CAF50', fontSize: '0.9rem' }}>
                No staff exceeding threshold
            </Typography>
        )}
    </Box>
);

const ExtendedMetrics = ({ periodType }) => {
    const { metrics, isLoading, error } = useMetricsStore();
    console.log('Current metrics:', metrics); // Debug log

    const periodMap = {
        three: { data: metrics?.threeMonth, title: '3 Month Period' },
        six: { data: metrics?.sixMonth, title: '6 Month Period' },
        twelve: { data: metrics?.twelveMonth, title: '12 Month Period' }
    };

    const { data, title } = periodMap[periodType] || {};

    // Debug log
    console.log(`${title} data:`, data);

    if (error) {
        return (
            <Card sx={{ bgcolor: '#1E1E1E', p: 2 }}>
                <Typography color="error" sx={{ fontSize: '0.9rem' }}>
                    Error loading metrics: {error}
                </Typography>
            </Card>
        );
    }

    if (isLoading || !data?.thresholds) {
        return (
            <Card sx={{ bgcolor: '#1E1E1E', p: 2 }}>
                <Typography color="white" sx={{ opacity: 0.7, fontSize: '0.9rem' }}>
                    Loading {title?.toLowerCase()}...
                </Typography>
            </Card>
        );
    }

    return (
        <Card sx={{ bgcolor: '#1E1E1E', p: 2 }}>
            <Typography
                variant="h6"
                color="white"
                gutterBottom
                sx={{
                    fontSize: '1rem',
                    mb: 2,
                    pb: 1,
                    borderBottom: '1px solid rgba(255,255,255,0.1)'
                }}
            >
                {title}
            </Typography>

            <MetricSection
                title="Multiple Sick Periods"
                staff={data.flaggedStaff?.absencePeriods}
                threshold={data.thresholds?.absencePeriods}
            />

            <MetricSection
                title="Total Sick Days"
                staff={data.flaggedStaff?.absenceDays}
                threshold={data.thresholds?.absenceDays}
            />

            <MetricSection
                title="Late/Early Infractions"
                staff={data.flaggedStaff?.infractions}
                threshold={data.thresholds?.infractions}
            />
        </Card>
    );
};

export default ExtendedMetrics;