import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Box,
  IconButton,
  InputLabel,
  FormControl
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { GlobalContext } from '../../context/GlobalContext';
import StaffCard from './StaffCard';
import EditOperatorModal from './EditOperatorModal';

function StaffTable() {
  const { token, username, password, setToken } = useContext(GlobalContext);
  const [operators, setOperators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [onlineOnly, setOnlineOnly] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [newOperator, setNewOperator] = useState({
    Name: '',
    Inits: '',
    Position: '',
    SalesTarget: '',
    Username: '',
    Password: '',
    Status: '',
    Branch: ''
  });
  const [sortBy, setSortBy] = useState('name');
  const [search, setSearch] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [branches, setBranches] = useState([]);



  // Fetch Operators
  useEffect(() => {
    fetchOperators();
    fetchBranches();
  }, [token]);

  const fetchOperators = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/operators`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setOperators(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setBranches(data);
    } catch (error) {
      console.error('Failed to fetch branches', error);
    }
  };

  const handleAddOperatorDialog = () => {
    const generatedPassword = generatePassword();
    setNewOperator((prevState) => ({
      ...prevState,
      Password: generatedPassword,
      Status: 'sales',
      Branch: branches[0]?.MamCode || ''
    }));
    setAddDialogOpen(true);
  };

  const generatePassword = () => {
    const randomWord = Math.random().toString(36).substring(2, 10);
    const capitalizedWord = randomWord.charAt(0).toUpperCase() + randomWord.slice(1);
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    return `${capitalizedWord}${randomNumber}@`;
  };

  const handleDialogClose = () => {
    setEditModalOpen(false);
    setSelectedOperator(null);
  };

  const handleAddOperator = async () => {
    const nameParts = newOperator.Name.split(' ');
    const firstName = nameParts[0] || '';
    const lastName = nameParts[1] || '';
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/addOperator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newOperator)
      });
      if (!response.ok) throw new Error('Failed to add operator');
      const addedOperator = await response.json();
      setOperators([...operators, addedOperator]);

      setNewOperator({
        Name: '',
        Inits: '',
        Position: '',
        SalesTarget: '',
        Username: '',
        Password: '',
        Status: '',
        Branch: ''
      });
      setAddDialogOpen(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleEdit = (operator) => {
    setSelectedOperator(operator);
    setEditModalOpen(true);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const onlineCount = operators.filter(op => op.LoggedIn).length;
  const filteredOperators = operators.filter(op => {
    const matchesSearch = op.Name.toLowerCase().includes(search.toLowerCase());
    const matchesBranch = selectedBranch === '' || op.Branch === selectedBranch;
    const matchesOnlineFilter = !onlineOnly || op.LoggedIn;
    return matchesSearch && matchesBranch && matchesOnlineFilter;
  });

  return (
    <div style={{ height: '80vh', overflowY: 'scroll', backgroundColor: '#1e1e1e', color: '#fff', marginTop: 20 }}>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
        <TextField
          label="Search Staff"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ backgroundColor: '#333', color: 'white' }}
          InputLabelProps={{ style: { color: '#fff' } }}
          InputProps={{ style: { color: '#fff' } }}
        />

        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel style={{ color: '#fff' }}>All Branches</InputLabel>
          <Select
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
            label="All Branches"
            style={{ color: 'white', backgroundColor: '#333' }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: '#333',
                  color: 'white'
                }
              }
            }}
          >
            <MenuItem value="">
              <em>All Branches</em>
            </MenuItem>
            {branches.map(branch => (
              <MenuItem key={branch.MamCode} value={branch.MamCode} style={{ color: '#fff' }}>
                {branch.MamCode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel style={{ color: '#fff' }}>Sort By</InputLabel>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            label="Sort By"
            style={{ color: 'white', backgroundColor: '#333' }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: '#333',
                  color: 'white'
                }
              }
            }}
          >
            <MenuItem value="name" style={{ color: 'white' }}>Name</MenuItem>
            <MenuItem value="lastActivity" style={{ color: 'white' }}>Last Activity</MenuItem>
          </Select>
        </FormControl>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={<Switch checked={onlineOnly} onChange={(e) => setOnlineOnly(e.target.checked)} />}
            label={`Show Online Only (${onlineCount})`}
            style={{ color: '#fff' }}
          />
          <IconButton onClick={fetchOperators} style={{ marginLeft: '10px', color: 'white' }}>
            <RefreshIcon />
          </IconButton>
        </div>
        <Button variant="contained" color="primary" onClick={handleAddOperatorDialog}>
          <AddIcon /> Add Staff
        </Button>
      </div>

      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',  // Adjusts spacing between items
        justifyContent: 'flex-start',
        margin: '20px 0'
      }}>
        {filteredOperators.map((operator) => (
          <div
            key={operator._id}
            style={{
              flex: '1 1 calc(25% - 16px)', // Adjusts width to roughly 4 columns
              maxWidth: 'calc(25% - 16px)', // Limits width to 25% of the container
              minWidth: '200px', // Ensures a minimum size for smaller screens
              boxSizing: 'border-box'
            }}
          >
            <StaffCard
              operator={operator}
              onEdit={() => handleEdit(operator)}
              onDelete={() => console.log(`Delete operator ${operator._id}`)}
            />
          </div>
        ))}
      </div>


      {selectedOperator && (
        <EditOperatorModal
          open={editModalOpen}
          onClose={handleDialogClose}
          operator={selectedOperator}
          token={token}
          onOperatorUpdated={setOperators}
        />
      )}

      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle style={{ backgroundColor: '#1e1e1e', color: 'white' }}>Add New Operator</DialogTitle>
        <DialogContent style={{ backgroundColor: '#1e1e1e', color: 'white' }}>
          <Box component="form" noValidate autoComplete="off" display="flex" flexDirection="column">
            <TextField
              label="Name"
              value={newOperator.Name}
              onChange={(e) => setNewOperator({ ...newOperator, Name: e.target.value })}
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{ style: { color: '#fff' } }}
              InputProps={{ style: { color: '#fff', backgroundColor: '#333' } }}
            />

            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel style={{ color: '#fff' }}>Status</InputLabel>
              <Select
                value={newOperator.Status}
                onChange={(e) => setNewOperator({ ...newOperator, Status: e.target.value })}
                label="Status"
                style={{ color: 'white', backgroundColor: '#333' }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#333',
                      color: 'white'
                    }
                  }
                }}
              >
                <MenuItem value="admin" style={{ color: 'white' }}>Admin</MenuItem>
                <MenuItem value="rm" style={{ color: 'white' }}>RM</MenuItem>
                <MenuItem value="bm" style={{ color: 'white' }}>BM</MenuItem>
                <MenuItem value="sales" style={{ color: 'white' }}>Sales</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel style={{ color: '#fff' }}>Branch</InputLabel>
              <Select
                value={newOperator.Branch}
                onChange={(e) => setNewOperator({ ...newOperator, Branch: e.target.value })}
                label="Branch"
                style={{ color: 'white', backgroundColor: '#333' }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#333',
                      color: 'white'
                    }
                  }
                }}
              >
                {branches.map(branch => (
                  <MenuItem key={branch.MamCode} value={branch.MamCode} style={{ color: 'white' }}>
                    {branch.MamCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Username / Email"
              value={newOperator.Username}
              onChange={(e) => setNewOperator({ ...newOperator, Username: e.target.value })}
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{ style: { color: '#fff' } }}
              InputProps={{ style: { color: '#fff', backgroundColor: '#333' } }}
            />

            <TextField
              label="Password"
              value={newOperator.Password}
              onChange={(e) => setNewOperator({ ...newOperator, Password: e.target.value })}
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{ style: { color: '#fff' } }}
              InputProps={{ style: { color: '#fff', backgroundColor: '#333' } }}
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#1e1e1e', color: '#fff' }}>
          <Button onClick={() => setAddDialogOpen(false)} style={{ color: '#fff' }}>Cancel</Button>
          <Button onClick={handleAddOperator} style={{ color: '#fff' }}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default StaffTable;
