import { Switch, Textarea } from '@mantine/core'
import clsx from 'clsx'
import moment from 'moment-timezone'
import { Icon } from '../gsys-ui'
import { UsersIcon } from '@heroicons/react/24/outline'
import useApi from '../hooks/useApi'
import { useContext, useState } from 'react'
import { UserIcon } from 'lucide-react'
import haxios from '../lib/haxios'
import { errorToast, successToast } from '../lib/helpers'
import Button from '../components/Button'
import MultiButton from '../gsys-ui/MultiButton/MultiButton'
import useBranchStore from '../stores/useBranchStore'
import { GlobalContext } from '../../../context/GlobalContext'

const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

const getMamCodesInRegion = (data, targetMamCode) => {
  let targetRegion = ''
  for (const [region, branches] of Object.entries(data)) {
    const found = branches.find((branch) => branch.MamCode === targetMamCode)
    if (found) {
      targetRegion = region
      break
    }
  }

  if (targetRegion && data[targetRegion]) {
    return data[targetRegion].map((branch) => branch.MamCode)
  }

  return []
}

const HolidayReviewModal = ({ context, id, innerProps }) => {
  const { operatorData } = useContext(GlobalContext)
  const [isLoadingAccept, setIsLoadingAccept] = useState(false)
  const [isLoadingReject, setIsLoadingReject] = useState(false)
  const { thisReq, requests, refetch } = innerProps
  const [branchMulti, setBranchMulti] = useState('branch')
  const [holMulti, setHolMulti] = useState('onlyholiday')
  const [reviewNotes, setReviewNotes] = useState('')
  const regions = useBranchStore((state) => state.regions)

  const { data: ustafferators, isLoading: isLoadingUstaff } = useApi({
    url: `/salesassistnew/ustafferators`
  })

  const dayCount = moment(thisReq.dtEnd).diff(moment(thisReq.dtStart), 'days')
  const middleDay = moment(thisReq.dtStart).add(dayCount / 2, 'days')
  const rangeStart = moment(middleDay).subtract(10, 'days')

  const validRequests = requests.filter((req) => req.Status !== 'REJECTED')
  const requestsByStaff = validRequests.reduce((acc, req) => {
    if (!acc[req.staffId]) {
      acc[req.staffId] = []
    }
    acc[req.staffId].push(req)
    return acc
  }, {})

  if (isLoadingUstaff) return null

  console.log(regions)

  const regionBranches = getMamCodesInRegion(regions, thisReq.Branch)

  const filteredStaff =
    holMulti !== 'onlyholiday'
      ? ustafferators.filter((staff) => {
          const isInBranch = thisReq.Branch === staff.Branch
          const isInRegion = regionBranches.includes(staff.Branch)
          const isSamePosition = staff.Position === thisReq.Position
          return branchMulti === 'branch' ? isInBranch : isInRegion && isSamePosition
        })
      : ustafferators.filter((staff) => {
          const requests = requestsByStaff[staff.StaffId]
          const rangeEnd = moment(rangeStart).add(21, 'days')
          const isInBranch = thisReq.Branch === staff.Branch
          const isInRegion = regionBranches.includes(staff.Branch)
          const isSamePosition = staff.Position === thisReq.Position

          return (
            (branchMulti === 'branch' ? isInBranch : isInRegion && isSamePosition) &&
            (staff.StaffId === thisReq.staffId ||
              requests?.some(
                (req) =>
                  (moment(req.dtEnd).isAfter(rangeStart) && moment(req.dtEnd).isBefore(rangeEnd)) ||
                  (moment(req.dtStart).isAfter(rangeStart) && moment(req.dtStart).isBefore(rangeEnd))
              ))
          )
        })

  const staffByPosition = filteredStaff
    .filter((staff) => staff.StaffId !== thisReq.staffId)
    .reduce((acc, staff) => {
      const position = staff.Position || 'Other'
      if (!acc[position]) {
        acc[position] = []
      }
      acc[position].push(staff)
      return acc
    }, {})

  const handleApprove = async () => {
    setIsLoadingAccept(true)

    try {
      const holidayId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
      const totalDays = moment(thisReq.dtEnd).diff(moment(thisReq.dtStart), 'days') + 1

      await haxios.put(`/salesassist/cplusassist/holidayrequests/${thisReq._id}`, {
        ...thisReq,
        decision: 'APPROVED',
        Notes: reviewNotes
      })

      const res = await haxios.post('https://api.aa-vision.com/api/tma/holiday', {
        HolidayID: holidayId,
        StartDatetime: thisReq.dtStart,
        EndDatetime: thisReq.dtEnd,
        StaffID: thisReq.staffId,
        TotalDays: totalDays,
        Paid: true,
        HoursPerDay: 8,
        AddedUserID: '988cca25-74f8-4908-ab96-f2ac9be8d9b3',
        AddedDatetime: moment().format('YYYY-MM-DD HH:mm:ss')
      })

      await refetch()
      successToast('Holiday approved and booked successfully')
      context.closeModal(id)
    } catch (error) {
      setIsLoadingAccept(false)
      console.error('Error during holiday approval and booking:', error)
      errorToast(error.message)
    }
  }

  const handleReject = async () => {
    setIsLoadingReject(true)
    try {
      await haxios.put(`/salesassist/cplusassist/holidayrequests/${thisReq._id}`, {
        ...thisReq,
        decision: 'REJECTED',
        Notes: reviewNotes
      })

      await refetch()
      successToast('Holiday has been rejected successfully')
      context.closeModal(id)
    } catch (error) {
      setIsLoadingReject(false)
      console.error('Error during holiday approval and booking:', error)
      errorToast(error.message)
    }
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-none items-stretch p-4 border-b-2 border-b-gray-700">
        <div className="flex-none">
          <div className="text-xl font-semibold">{thisReq.StaffName}</div>
          <div className="">
            {thisReq.Position} - {thisReq.Branch}
          </div>
          <div className="">
            {moment(thisReq.dtStart).format('DD/MM/YYYY')} - {moment(thisReq.dtEnd).format('DD/MM/YYYY')}{' '}
            <span className="text-sm">({dayCount} days)</span>
          </div>
          <div className="">Reason: {thisReq.reason}</div>
        </div>
        <div className="flex flex-col flex-1 justify-between items-end space-x-2 select-none">
          <div className="flex space-x-4">
            {(operatorData.Status === 'rm' || operatorData.Status === 'admin') && (
              <MultiButton
                value={branchMulti}
                onChange={setBranchMulti}
                options={[
                  { label: 'Branch', value: 'branch' },
                  { label: 'Region', value: 'region' }
                ]}
              />
            )}
            <MultiButton
              value={holMulti}
              onChange={setHolMulti}
              options={[
                { label: 'With holiday', value: 'onlyholiday' },
                { label: 'All staff', value: 'all' }
              ]}
            />
          </div>
          <div className="flex space-x-2">
            <div className="px-3 py-1 text-sm font-semibold text-white bg-blue-500 rounded-full">This request</div>
            <div className="px-3 py-1 text-sm font-semibold text-white bg-orange-500 rounded-full">Pending request</div>
            <div className="px-3 py-1 text-sm font-semibold text-white bg-green-500 rounded-full">Approved holiday</div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden flex-1">
        <div className="h-[400px] overflow-y-auto">
          <div className="sticky top-0 z-50 border-b shadow-sm bg-stone-900 border-b-stone-700">
            <div className="flex sticky top-0 z-50 font-semibold text-center bg-transparent border-b-2 border-b-gray-700">
              <div className="flex flex-1 items-center px-2 space-x-1 border-r-2 border-r-gray-700">
                <Icon Comp={UserIcon} />
                <div>Requesting staff</div>
              </div>
              <div className="flex flex-none">
                {Array.from({ length: 21 }, (_, ind) => {
                  const thisDate = moment(rangeStart).add(ind, 'days')
                  const isToday = moment().isSame(thisDate, 'day')
                  const dayOfWeek = daysOfWeek[thisDate.day()]
                  const isWeekend = dayOfWeek === 'S'

                  return (
                    <div
                      className={clsx(
                        'pt-0.5 w-8 border-r border-r-gray-700 z-10 relative',
                        isWeekend && 'bg-gray-600'
                      )}
                      key={ind}
                    >
                      <div className="leading-tight">{thisDate.date()}</div>
                      <div className="text-sm font-normal leading-tight">{dayOfWeek}</div>
                      <div
                        className={clsx(
                          'absolute top-[1px] right-[1px] bottom-[1px] left-[1px] pointer-events-none',
                          isToday && 'outline outline-2 outline-yellow-400 z-50'
                        )}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <StaffRow
              thisReq={thisReq}
              staffName={thisReq.StaffName}
              requests={[thisReq, ...(requestsByStaff[thisReq.staffId] || [])]}
              dayCount={21}
              startDate={rangeStart}
            />
          </div>

          <div className="z-40 mt-4">
            {Object.entries(staffByPosition).map(([position, staffList]) => (
              <div key={position}>
                <div className="flex items-center px-2 py-2 space-x-1 font-semibold text-white bg-transparent border-t border-b border-gray-700">
                  <Icon Comp={UsersIcon} />
                  <div>{position}</div>
                </div>
                {staffList
                  .sort((a, b) => a.Branch.localeCompare(b.Branch))
                  .map((staff) => (
                    <StaffRow
                      thisReq={thisReq}
                      key={staff.StaffId}
                      staffName={`${staff.FName} ${staff.SName}`}
                      requests={requestsByStaff[staff.StaffId] || []}
                      dayCount={21}
                      startDate={rangeStart}
                      branchMulti={branchMulti}
                      staffBranch={staff.Branch}
                    />
                  ))}
              </div>
            ))}
          </div>
        </div>

        <div className="p-2 space-y-2 bg-gray-900 border-t-2 border-gray-700">
          <div className="text-sm font-semibold text-gray-400">Review Notes</div>
          <Textarea
            value={reviewNotes}
            onChange={(e) => setReviewNotes(e.target.value)}
            placeholder="Enter your review notes here..."
            maxSize={1}
          />
          <div className="flex justify-end space-x-2">
            <Button size="xs" variant="outline" onClick={() => context.closeModal(id)}>
              Cancel
            </Button>
            <Button size="xs" loading={isLoadingReject} disabled={isLoadingAccept} color="red" onClick={handleReject}>
              Reject
            </Button>
            <Button
              size="xs"
              loading={isLoadingAccept}
              disabled={isLoadingReject}
              color="green"
              onClick={handleApprove}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const StaffRow = ({ thisReq, staffName, requests, dayCount, startDate, branchMulti, staffBranch }) => {
  return (
    <div className="flex h-full">
      <div className="flex-1 px-2 py-1 border-r-2 border-b border-r-gray-700 border-b-gray-700 h-[32px] relative z-40">
        <div className="text-lg font-bold leading-tight">
          {staffName} <span className="text-xs font-semibold">{staffBranch}</span>
        </div>
      </div>

      <div className="flex-none p-0 h-[32px] relative">
        {Array.from({ length: dayCount }, (_, ind) => {
          const thisDate = moment(startDate).add(ind, 'days')
          const dayStart = thisDate.startOf('day')
          const dayEnd = thisDate.endOf('day')

          const holidayRequest = requests.find((req) => {
            const reqStart = moment(req.dtStart).startOf('day')
            const reqEnd = moment(req.dtEnd).endOf('day')
            return (
              dayStart.isBetween(reqStart, reqEnd, undefined, '[]') ||
              dayEnd.isBetween(reqStart, reqEnd, undefined, '[]') ||
              reqStart.isBetween(dayStart, dayEnd, undefined, '[]')
            )
          })

          const isHoliday = !!holidayRequest && holidayRequest?.decision !== 'REJECTED'
          const isApproved = holidayRequest?.decision === 'APPROVED'
          const isPending =
            holidayRequest && holidayRequest._id !== thisReq._id && holidayRequest?.decision !== 'REJECTED'

          return (
            <div
              key={ind}
              className="inline-block relative w-8 h-full border-r border-b cursor-pointer border-r-gray-700 border-b-gray-700"
            >
              <div
                className={clsx(
                  'absolute inset-0 flex flex-col items-center justify-center overflow-hidden text-white',
                  isHoliday && (isApproved ? 'bg-green-500' : isPending ? 'bg-orange-400' : 'bg-blue-500')
                )}
              >
                {isHoliday && !isApproved && !isPending && 'R'}
                {isHoliday && !isApproved && isPending && 'P'}
                {isHoliday && isApproved && isPending && 'A'}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HolidayReviewModal
