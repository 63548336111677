import React, { useContext, useEffect, useState } from 'react'
import { formatCurrency } from '../../utils';
import { GlobalContext } from '../../context/GlobalContext';

function BranchDetails({ drillDownBranch, setDrillDownBranch, progress, setLastUpdated, setProgress }) {
  const { token, isToday } = useContext(GlobalContext)
  const [data, setData] = useState([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const maxPage = Math.ceil(dataForTable.length / itemsPerPage);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedOption, setSelectedOption] = useState('Product Group');
  const getEndpointUrl = (baseUrl, isToday) => {
    if (isToday) {
      return baseUrl.replace('/branchByPG/', '/branchByPGToday/')
        .replace('/branchByC/', '/branchByCToday/')
        .replace('/branchBySO/', '/branchBySOToday/');
    } else {
      return baseUrl.replace('/branchByPG/', '/branchByPGCurrentMonth/')
        .replace('/branchByC/', '/branchByCCurrentMonth/')
        .replace('/branchBySO/', '/branchBySOCurrentMonth/');
    }
  };

  const [apiEndpoint, setApiEndpoint] = useState(
    getEndpointUrl(`https://spar.connectplus.parts/api/salesassist/cplusassist/branchByPG/${drillDownBranch}`, isToday)
  );
  const [refData, setRefData] = useState(false);
  const [fieldMap, setFieldMap] = useState({
    'PG': 'PG',
    'Name': 'PGLabel',
    'Sales £': 'totSales',
    'Sales Qty': 'totQty',
    'Credits £': 'totCredits',
    'Credits Qty': 'totCreditsQty',
    'Margin £': 'margin',
    'Margin %': 'marginPerc',
  })
  const [headerMap, setHeaderMap] = useState(['PG', 'Name', 'Sales £', 'Sales Qty', 'Credits £', 'Credits Qty', 'Margin £', 'Margin %']);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const handleOverstrikeClick = (id, type) => {
    setSelectedID(id);
    setSelectedType(type);
    setModalVisible(true);
  };


  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    // You can also add additional logic here to fetch or display data based on the selection
  };

  const handleSort = (field) => {
    setSortField(fieldMap[field]);
    setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
  };

  useEffect(() => {
    setDataForTable(transformData(data).sort(sortData));
    setCurrentPage(1);
  }, [data, sortField, sortDirection]);

  const sortData = (a, b) => {
    if (!sortField) return 0;

    let valA = a[sortField];
    let valB = b[sortField];

    // Handle string comparison
    if (typeof valA === 'string') {
      return sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    }

    // Handle numeric comparison
    return sortDirection === 'asc' ? valA - valB : valB - valA;
  };

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return dataForTable.slice(startIndex, endIndex);
  };

  function transformData(data) {
    // Check if data is an object
    if (typeof data === 'object' && data !== null) {
      // Convert object values to an array
      return Object.values(data);
    }

    // Log an error and return an empty array if data is not an object
    console.error('Expected an object, received:', data);
    return [];
  }



  useEffect(() => {
    if (selectedOption === "Product Group") {
      setApiEndpoint(getEndpointUrl(`https://spar.connectplus.parts/api/salesassist/cplusassist/branchByPG/${drillDownBranch}`, isToday));
      setHeaderMap(['PG', 'Name', 'Sales £', 'Sales Qty', 'Credits £', 'Credits Qty', 'Margin £', 'Margin %', 'Overstrikes', 'overstrikeValue']);
      setFieldMap({
        'PG': 'PG',
        'Name': 'PGLabel',
        'Sales £': 'totSales',
        'Sales Qty': 'totQty',
        'Credits £': 'totCredits',
        'Credits Qty': 'totCreditsQty',
        'Margin £': 'margin',
        'Margin %': 'marginPerc',
        'Overstrikes': 'overstrikeCount',
        'overstrikeValue': 'overstrikeAmount',
      });
    }

    if (selectedOption === "Customer") {
      setApiEndpoint(getEndpointUrl(`https://spar.connectplus.parts/api/salesassist/cplusassist/branchByC/${drillDownBranch}`, isToday));
      setHeaderMap(['Acct', 'Name', 'Sales £', 'Sales Qty', 'Credits £', 'Credits Qty', 'Margin £', 'Margin %', 'Overstrikes', 'overstrikeValue']);
      setFieldMap({
        'Acct': 'Acct',
        'Name': 'Name',
        'Sales £': 'totSales',
        'Sales Qty': 'totQty',
        'Credits £': 'totCredits',
        'Credits Qty': 'totCreditsQty',
        'Margin £': 'margin',
        'Margin %': 'marginPerc',
        'Overstrikes': 'overstrikeCount',
        'overstrikeValue': 'overstrikeAmount',
      });
    }

    if (selectedOption === "Sales Operator") {
      setApiEndpoint(getEndpointUrl(`https://spar.connectplus.parts/api/salesassist/cplusassist/branchBySO/${drillDownBranch}`, isToday));
      setHeaderMap(['Inits', 'Name', 'Sales £', 'Sales Qty', 'Credits £', 'Credits Qty', 'Margin £', 'Margin %', 'Overstrikes', 'overstrikeValue']);
      setFieldMap({
        'Inits': 'Inits',
        'Name': 'OperatorName',
        'Sales £': 'totSales',
        'Sales Qty': 'totQty',
        'Credits £': 'totCredits',
        'Credits Qty': 'totCreditsQty',
        'Margin £': 'margin',
        'Margin %': 'marginPerc',
        'Overstrikes': 'overstrikeCount',
        'overstrikeValue': 'overstrikeAmount',
      });
    }

    setRefData(!refData);
  }, [selectedOption, isToday]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiEndpoint, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        console.log('token:', token);
        setData(data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token, refData]);

  return (
    <div style={{ width: '100%' }}>
      <table style={styles.table}>
        <thead style={styles.thead}>
          <tr>
            {headerMap.map((key) => (
              <th
                key={key}
                style={{ ...styles.th, ...columnStyles[key] }}
                onClick={() => handleSort(key)}
              >
                {key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentTableData().map((item, index) => (
            <tr key={index} className="tr" style={styles.tr}>
              {headerMap.map((header) => {
                const fieldName = fieldMap[header];
                let itemValue = item[fieldName];
                // Check if the field is a quantity or margin percentage field and format accordingly
                if (fieldName === 'marginPerc') {
                  itemValue = typeof itemValue === 'number' ? itemValue.toFixed(2) : itemValue;
                } else if (fieldName == 'totSales' || fieldName == 'totCredits' || fieldName == 'margin' || fieldName == 'overstrikeAmount') {
                  //console.log(`fieldName: ${fieldName} should be currency`);
                  itemValue = formatCurrency(itemValue);
                }

                return (
                  <td
                    key={fieldName}
                    style={styles.td}
                    onClick={() =>
                      ['overstrikeCount', 'overstrikeAmount'].includes(fieldName)
                        ? handleOverstrikeClick(
                          selectedOption === 'Product Group'
                            ? item.PG
                            : selectedOption === 'Customer'
                              ? item.Acct
                              : item.Inits,
                          selectedOption
                        )
                        : null
                    }
                  >
                    {itemValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <OverstrikeModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        selectedID={selectedID}
        selectedType={selectedType}
        drillDownBranch={drillDownBranch}
        isToday={isToday}
      />

      <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1 }}>
          <h2 style={{ color: 'white', textAlign: 'left' }}>{drillDownBranch}</h2>
          <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'column' }}>
            <label style={{ color: 'white', fontSize: 12, fontWeight: 'bold', textAlign: 'center' }} htmlFor="dataSelect">SELECT DATA </label>
            <select
              id="dataSelect"
              value={selectedOption}
              onChange={handleSelectChange}
              style={{ marginLeft: '10px' }}
            >
              <option value="Product Group">Product Group</option>
              <option value="Customer">Customer</option>
              <option value="Sales Operator">Sales Operator</option>
            </select>
          </div>
        </div>

        <div style={styles.pagination}>
          <button style={styles.butts} onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}>
            Previous
          </button>
          <span style={{ fontWeight: 'bold' }}> Page {currentPage} of {maxPage} </span>
          <button style={styles.butts} onClick={() => setCurrentPage(prev => Math.min(prev + 1, maxPage))}
            disabled={currentPage === maxPage}>
            Next
          </button>
        </div>
        <div onClick={() => setDrillDownBranch(null)} style={{ cursor: 'pointer', flex: 1, alignItems: 'flex-end' }} >
          <h3 style={{ color: 'white', textAlign: 'end', marginRight: 10 }}>{"<BACK"}</h3>
        </div>
      </div>
    </div>
  )
}

const OverstrikeModal = ({ visible, onClose, selectedID, selectedType, drillDownBranch, isToday }) => {
  const [overstrikeData, setOverstrikeData] = useState([]);
  const { token } = useContext(GlobalContext);

  useEffect(() => {
    const fetchOverstrikeData = async () => {
      try {
        let endpoint;
        if (selectedType === 'Product Group') {
          endpoint = isToday
            ? `https://spar.connectplus.parts/api/salesassist/cplusassist/overstrikes/${selectedID}/${drillDownBranch}/today`
            : `https://spar.connectplus.parts/api/salesassist/cplusassist/overstrikes/${selectedID}/${drillDownBranch}/currentMonth`;
        } else if (selectedType === 'Customer') {
          endpoint = isToday
            ? `https://spar.connectplus.parts/api/salesassist/cplusassist/overstrikes/customer/${selectedID}/${drillDownBranch}/today`
            : `https://spar.connectplus.parts/api/salesassist/cplusassist/overstrikes/customer/${selectedID}/${drillDownBranch}/currentMonth`;
        } else {
          endpoint = isToday
            ? `https://spar.connectplus.parts/api/salesassist/cplusassist/overstrikes/operator/${selectedID}/${drillDownBranch}/today`
            : `https://spar.connectplus.parts/api/salesassist/cplusassist/overstrikes/operator/${selectedID}/${drillDownBranch}/currentMonth`;
        }

        const response = await fetch(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.info('Overstrike Data:', data);
        setOverstrikeData(data);
      } catch (error) {
        console.error('Error fetching overstrike data:', error);
      }
    };

    if (selectedID) {
      fetchOverstrikeData();
    }
  }, [selectedID, selectedType, isToday, drillDownBranch, token]);


  if (!visible) return null;

  return (
    <div style={styles.modal}>
      <div style={styles.modalContent}>
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
          <h2>
            Overstrike Details for{' '}
            {selectedType === 'Product Group'
              ? 'PG'
              : selectedType === 'Customer'
                ? 'Customer'
                : 'Sales Operator'}
            : {selectedID}
          </h2>
          <button style={styles.closeButton} onClick={onClose}>
            Close
          </button>
        </div>
        <table style={styles.table}>
          <thead style={styles.thead}>
            <tr>
              <th style={styles.th}>Part</th>
              <th style={styles.th}>Description</th>
              <th style={styles.th}>Qty</th>
              <th style={styles.th}>Unit</th>
              <th style={styles.th}>Price</th>
              <th style={styles.th}>Difference</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(overstrikeData) && overstrikeData.map((item, index) => (
              <tr key={index} style={styles.tr}>
                <td style={styles.td}>{item.Part}</td>
                <td style={styles.td}>{item.BopDes}</td>
                <td style={styles.td}>{item.Qty}</td>
                <td style={styles.td}>{formatCurrency(item.Unit)}</td>
                <td style={styles.td}>{formatCurrency(item.Price)}</td>
                <td style={styles.td}>{formatCurrency(item.Difference)}</td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </div>
  );
};
const columnStyles = {
  PG: { width: '10%' },
  PGLabel: { width: '25%' },
  totSales: { width: '15%' },
  totQty: { width: '5%' },
  totCredits: { width: '15%' },
  totCreditsQty: { width: '5%' },
  margin: { width: '15%' },
  marginPerc: { width: '10%' },
};

const styles = {
  butts: {
    borderRadius: 5,
    padding: 5
  },
  closeButton: {
    padding: 5,
    margin: 15
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    fontSize: '12px',

  },
  thead: {
    backgroundColor: '#21242c',
    textAlign: 'center',
    cursor: 'pointer',
    color: 'white'

  },
  th: {
    padding: '10px',
    border: '1px solid #ddd',
    textAlign: 'center',
  },
  tr: {
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s', // Smooth transition for the glow effect
    '&:hover': {
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // This adds a shadow that creates a glow effect
    }

  },
  td: {
    padding: '8px',
    border: '1px solid #ddd',
    textAlign: 'center',
    color: 'black',
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    color: 'white',
    width: '30%',
    justifyContent: 'space-around'
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'auto',
  },

};



export default BranchDetails