import { useMemo } from 'react'

const getContrastTextColor = (bgColor) => {
  let r = bgColor.substring(1, 3)
  let g = bgColor.substring(3, 5)
  let b = bgColor.substring(5, 7)
  const bgDelta = parseInt(r, 16) * 0.299 + parseInt(g, 16) * 0.587 + parseInt(b, 16) * 0.114
  return 255 - bgDelta < 105 ? '#000' : '#fff'
}

const TypeChip = ({ label, color }) => {
  //const textColor = useMemo(() => getContrastTextColor(color), [color])

  return (
    <div
      className="inline-block px-2 py-1 text-xs font-semibold text-gray-700 uppercase bg-gray-50 rounded outline outline-1"
      style={{ outlineColor: color }}
    >
      {label}
    </div>
  )
}

export default TypeChip
