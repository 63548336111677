import React, { useState, useEffect, useContext } from 'react';
import { NativeSelect } from '@mantine/core';
import { Switch } from '@mui/material';
import moment from 'moment-timezone';
import BranchDropdown from '../newholidays/components/BranchDropdown';
import ClocklogContainer from './ClocklogContainer';
import HROverviewDashboard from './HROverviewDashboard';
import useBranchStore from '../newholidays/stores/useBranchStore';
import useClockStatsStore from './stores/useClockStatsStore';
import { GlobalContext } from '../../context/GlobalContext';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import StatsTooltip from './StatsTooltip';

const HROverview = () => {
    const { token, analyzeClockingRecordsForMonth } = useContext(GlobalContext);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const selectedBranch = useBranchStore((state) => state.selectedBranch);
    const { updateClockStats, setLoading: setClockStatsLoading, machineClocks, manualClocks, isLoading } = useClockStatsStore();

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [staffCount, setStaffCount] = useState(0);
    const [analysisData, setAnalysisData] = useState([]);
    const [viewMode, setViewMode] = useState('overview');
    const [error, setError] = useState(null);
    const [holidayData, setHolidayData] = useState([]);
    const [sickData, setSickData] = useState([]);
    const [headerStats, setHeaderStats] = useState({
        lateClocks: 0,
        earlyClockOuts: 0,
        lateDetails: [],
        earlyDetails: []
    });

    // Function to fetch shift assignments
    const fetchShiftAssignments = async () => {
        try {
            const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shiftassignments', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch shift assignments');
            return await response.json();
        } catch (error) {
            console.error('Error fetching shift assignments:', error);
            return [];
        }
    };

    // Function to fetch shifts
    const fetchShifts = async () => {
        try {
            const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shifts', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch shifts');
            return await response.json();
        } catch (error) {
            console.error('Error fetching shifts:', error);
            return [];
        }
    };

    // Fetch staff list
    const fetchStaffList = async () => {
        try {
            const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/staff', {
                headers: { Token: token }
            });
            if (!response.ok) throw new Error('Failed to fetch staff list');
            const data = await response.json();
            return data.filter(staff =>
                selectedBranch === 'ALL' ? true : staff.Branch === selectedBranch
            );
        } catch (error) {
            console.error('Error fetching staff list:', error);
            return [];
        }
    };

    // Fetch clocking data
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('Starting data fetch for:', {
                    year: selectedYear,
                    month: selectedMonth,
                    branch: selectedBranch
                });

                setClockStatsLoading(true);
                const startDate = moment([selectedYear, selectedMonth]).startOf('month').format('YYYY-MM-DD');
                const endDate = moment([selectedYear, selectedMonth]).endOf('month').format('YYYY-MM-DD');

                // Fetch all required data in parallel
                const [
                    clockingResponse,
                    holidaysResponse,
                    sickResponse,
                    shiftAssignments,
                    shifts,
                    staffList
                ] = await Promise.all([
                    fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': token
                        },
                        body: JSON.stringify({
                            startDate,
                            endDate,
                            Branch: selectedBranch === 'ALL' ? null : selectedBranch
                        })
                    }),
                    fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holidays', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': token
                        },
                        body: JSON.stringify({
                            StartDate: startDate,
                            EndDate: endDate,
                            Branch: selectedBranch === 'ALL' ? null : selectedBranch
                        })
                    }),
                    fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': token
                        },
                        body: JSON.stringify({
                            StartDate: startDate,
                            EndDate: endDate,
                            Branch: selectedBranch === 'ALL' ? null : selectedBranch
                        })
                    }),
                    fetchShiftAssignments(),
                    fetchShifts(),
                    fetchStaffList()
                ]);

                // Check responses
                if (!clockingResponse.ok) throw new Error(`Clocking API error: ${clockingResponse.status}`);
                if (!holidaysResponse.ok) throw new Error(`Holidays API error: ${holidaysResponse.status}`);
                if (!sickResponse.ok) throw new Error(`Sick days API error: ${sickResponse.status}`);

                // Parse JSON responses
                const [clockingRecords, holidays, sick] = await Promise.all([
                    clockingResponse.json(),
                    holidaysResponse.json(),
                    sickResponse.json()
                ]);

                console.log('Data fetched successfully:', {
                    clockingRecords: clockingRecords?.length,
                    holidays: holidays?.length,
                    sick: sick?.length,
                    shiftAssignments: shiftAssignments?.length,
                    shifts: shifts?.length,
                    staffList: staffList?.length
                });

                updateClockStats(clockingRecords);
                setHolidayData(holidays);
                setSickData(sick);
                setAnalysisData(clockingRecords);

                // Run analysis with all required data
                const analysis = analyzeClockingRecordsForMonth(
                    clockingRecords,
                    staffList,
                    shiftAssignments,
                    shifts,
                    holidays,
                    sick,
                    selectedMonth + 1,
                    selectedYear,
                    [] // acceptedInfringements
                );

                // Calculate infringements
                const calculatedStats = {
                    lateClocks: 0,
                    earlyClockOuts: 0,
                    lateDetails: [],
                    earlyDetails: []
                };

                if (analysis) {
                    analysis.forEach(day => {
                        if (day.details) {
                            day.details.forEach(detail => {
                                if (detail.status === 'INFRINGEMENT') {
                                    const reason = (detail.reason || '').toLowerCase();
                                    if (reason.includes('late clock-in')) {
                                        calculatedStats.lateClocks++;
                                        calculatedStats.lateDetails.push({
                                            name: detail.staffName,
                                            branch: detail.clockingRecords[0]?.ClockBranch || '?',
                                            date: day.date
                                        });
                                    }
                                    if (reason.includes('early clock-out')) {
                                        calculatedStats.earlyClockOuts++;
                                        calculatedStats.earlyDetails.push({
                                            name: detail.staffName,
                                            branch: detail.clockingRecords[0]?.ClockBranch || '?',
                                            date: day.date
                                        });
                                    }
                                }
                            });
                        }
                    });
                }

                console.log('Final calculated stats:', calculatedStats);
                setHeaderStats(calculatedStats);
                setClockStatsLoading(false);

            } catch (error) {
                console.error('Error in data fetch:', error);
                setError(error.message);
                setClockStatsLoading(false);
            }
        };

        if (token) {
            fetchData();
        }
    }, [token, selectedMonth, selectedYear, selectedBranch, updateClockStats, setClockStatsLoading, analyzeClockingRecordsForMonth]);

    // Fetch staff count
    useEffect(() => {
        const fetchStaffCount = async () => {
            try {
                const staffList = await fetchStaffList();
                setStaffCount(staffList.length);
            } catch (error) {
                console.error('Error fetching staff count:', error);
                setError(error.message);
                setStaffCount(0);
            }
        };

        if (token) {
            fetchStaffCount();
        }
    }, [token, selectedBranch]);

    // Generate year options
    const yearOptions = Array.from({ length: 3 }, (_, i) => ({
        value: currentYear - 2 + i,
        label: String(currentYear - 2 + i)
    }));

    // Generate month options
    const monthOptions = [
        { value: 0, label: 'January' },
        { value: 1, label: 'February' },
        { value: 2, label: 'March' },
        { value: 3, label: 'April' },
        { value: 4, label: 'May' },
        { value: 5, label: 'June' },
        { value: 6, label: 'July' },
        { value: 7, label: 'August' },
        { value: 8, label: 'September' },
        { value: 9, label: 'October' },
        { value: 10, label: 'November' },
        { value: 11, label: 'December' }
    ];

    if (error) {
        return (
            <div className="p-4 text-red-500 bg-red-100 rounded">
                Error loading HR Overview: {error}
            </div>
        );
    }

    return (
        <div className="h-screen flex flex-col bg-neutral-900">
            {/* Header */}
            <div className="bg-neutral-800 px-4 py-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <div className="flex items-center space-x-2 bg-neutral-700 px-3 py-1 rounded">
                            <span className="text-sm text-white">Table</span>
                            <Switch
                                checked={viewMode === 'overview'}
                                onChange={(e) => setViewMode(e.target.checked ? 'overview' : 'table')}
                                size="small"
                            />
                            <span className="text-sm text-white">Overview</span>
                        </div>
                        <span className="text-sm bg-neutral-700 px-3 py-1 rounded text-white">
                            {staffCount} Active Spartans
                        </span>
                        <StatsTooltip
                            details={[...holidayData, ...sickData]}
                            type="holiday"
                        >
                            <span className="text-sm bg-neutral-700 px-3 py-1 rounded text-white cursor-help">
                                {(sickData?.length || 0) + (holidayData?.length || 0)} Absences
                            </span>
                        </StatsTooltip>
                        <StatsTooltip details={headerStats.lateDetails} type="infringement">
                            <span className="text-sm bg-neutral-700 px-3 py-1 rounded text-white cursor-help">
                                {headerStats.lateClocks} Late Clocks
                            </span>
                        </StatsTooltip>
                        <StatsTooltip details={headerStats.earlyDetails} type="infringement">
                            <span className="text-sm bg-neutral-700 px-3 py-1 rounded text-white cursor-help">
                                {headerStats.earlyClockOuts} Early Clock-outs
                            </span>
                        </StatsTooltip>
                        <StatsTooltip details={sickData} type="sick">
                            <span className="text-sm bg-neutral-700 px-3 py-1 rounded text-white cursor-help">
                                {sickData?.length || 0} Sick Days
                            </span>
                        </StatsTooltip>
                        <StatsTooltip details={holidayData} type="holiday">
                            <span className="text-sm bg-neutral-700 px-3 py-1 rounded text-white cursor-help">
                                {holidayData?.length || 0} Holidays
                            </span>
                        </StatsTooltip>
                        <span className="text-sm bg-neutral-700 px-3 py-1 rounded text-white">
                            {isLoading ? (
                                <span className="opacity-50">Loading counts...</span>
                            ) : (
                                `Machine: ${machineClocks} / Manual: ${manualClocks}`
                            )}
                        </span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <BranchDropdown />
                        <NativeSelect
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(parseInt(e.currentTarget.value))}
                            data={yearOptions}
                        />
                        <NativeSelect
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(parseInt(e.currentTarget.value))}
                            data={monthOptions}
                        />
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 overflow-hidden">
                <ErrorBoundary>
                    {viewMode === 'table' ? (
                        <ClocklogContainer
                            selectedYear={selectedYear}
                            selectedMonth={selectedMonth}
                            clockingRecords={analysisData}
                        />
                    ) : (
                        <HROverviewDashboard
                            selectedBranch={selectedBranch}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            clockingRecords={analysisData}
                            holidayData={holidayData}
                            sickData={sickData}
                            lateClocksCount={headerStats.lateClocks}
                            earlyClockOutsCount={headerStats.earlyClockOuts}
                        />
                    )}
                </ErrorBoundary>
            </div>
        </div>
    );
};

export default HROverview;