import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, Grid, Alert } from '@mantine/core'
import { Form, useForm } from 'react-hook-form'
import { TextInput, DateInput, NativeSelect, NumberInput } from 'react-hook-form-mantine'
import yup from '../lib/yup'
import { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import haxios from '../lib/haxios'
import moment from 'moment-timezone'
import { errorToast, successToast } from '../lib/helpers'
import { modals } from '@mantine/modals'
import Button from '../components/Button'
import HolidayAllocation from '../../admin/HolidayAllocation'

const validation = yup.object().shape({
  FName: yup
    .string()
    .required('This field is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed')
    .max(50, 'Must be shorter than 50 characters'),
  SName: yup
    .string()
    .required('This field is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed')
    .max(50, 'Must be shorter than 50 characters'),
  Dob: yup.date().required('This field is required').max(new Date(), 'Date of birth cannot be in the future'),
  Branch: yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed')
    .required('This field is required'),
  Position: yup.string().required('This field is required').max(50, 'Must be shorter than 50 characters'),
  Email: yup
    .string()
    .email('Invalid email format')
    .when('AllowLogin', {
      is: true,
      then: (schema) => schema.required('Email is required when login is allowed'),
      otherwise: (schema) => schema.notRequired()
    }),
  Inits: yup
    .string()
    .nullable()
    .transform((value) => (value ? value.toUpperCase() : value))
    .matches(/^[A-Z]+$/, 'Only letters allowed')
    .max(10, 'Must be 10 characters or less'),
  SalesTarget: yup.number().emptyStringToZero().nullable().min(0, 'Sales target cannot be negative'),
  HolidayStartDate: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, 'Date must be in MM-DD format')
    .required('This field is required'),
  HolidayAllocation: yup
    .number('Must be a whole number')
    .integer('Must be a whole number')
    .min(0, 'Holiday allocation cannot be negative')
    .required('This field is required')
})

const StaffEditModal = ({ context, id, innerProps }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showKeyCode, setShowKeyCode] = useState(false)

  // Debug: Log props on component mount
  useEffect(() => {
    console.log('StaffEditModal Props:', { context, id, innerProps })
  }, [context, id, innerProps])

  const {
    formState: { errors, isDirty, isSubmitting, touchedFields, dirtyFields },
    control,
    watch,
    setValue,
    getValues,
    trigger
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validation),
    defaultValues: innerProps.defaultValues || {
      FName: '',
      SName: '',
      Dob: null,
      Position: '',
      EmployeeNumber: '',
      Branch: null,
      Email: '',
      Inits: 'NONE',
      SalesTarget: null,
      HolidayStartDate: '01-01',
      HolidayAllocation: 20,
      HolidayAllocationThisYear: 0,
      KeyCode: null,
      holidayMonth: '01',
      holidayDay: '01'
    }
  })

  // Debug: Log form state changes
  useEffect(() => {
    console.log('Form State:', {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      dirtyFields,
      currentValues: getValues()
    })
  }, [errors, isDirty, isSubmitting, touchedFields, dirtyFields, getValues])

  const handleSubmit = async (data) => {
    console.log('Submit handler called with data:', data)
    setIsLoading(true)

    try {
      // Validate all fields manually first
      const isValid = await trigger()
      //console.log('Manual validation result:', { isValid, errors: control.formState.errors })

      if (!isValid) {
        console.log('Form validation failed')
        return
      }

      // Validate using Yup schema directly
      try {
        await validation.validate(data, { abortEarly: false })
        console.log('Yup validation passed')
      } catch (validationError) {
        console.log('Yup validation failed:', {
          errors: validationError.errors,
          details: validationError.inner.map((err) => ({
            path: err.path,
            message: err.message,
            value: err.value
          }))
        })
        return
      }

      const formattedData = {
        ...data,
        AllowLogin: false,
        Dob: moment(data.Dob).format('YYYY-MM-DD'),
        Inits: data.Inits.toUpperCase()
      }

      console.log('Submitting formatted data:', formattedData)

      await innerProps.submit(formattedData)
      console.log('Submit successful')

      await innerProps.refetch()
      console.log('Refetch successful')

      context.closeModal(id)
    } catch (e) {
      setIsLoading(false)
      console.error('Submission error:', e)
      console.error('Error details:', {
        name: e.name,
        message: e.message,
        stack: e.stack,
        inner: e.inner
      })
    }
  }

  const months = Array.from({ length: 12 }, (_, i) => ({
    value: String(i + 1).padStart(2, '0'),
    label: moment().month(i).format('MMMM')
  }))

  const days = Array.from({ length: 31 }, (_, i) => ({
    value: String(i + 1).padStart(2, '0'),
    label: String(i + 1)
  }))

  // Debug: Log whenever holiday date is updated
  const handleHolidayDateUpdate = (month, day) => {
    const dateString = `${month}-${day}`
    console.log('Updating holiday date:', { month, day, dateString })
    setValue('HolidayStartDate', dateString)
  }

  return (
    <Form
      control={control}
      onSubmit={(e) => {
        console.log('Form onSubmit event:', e)
        handleSubmit(e.data)
      }}
      onError={(errors) => {
        console.log('Form onError event:', {
          errors,
          formState: control.formState,
          values: getValues()
        })
      }}
    >
      <Grid gutter="xs">
        <Grid.Col span={6}>
          <TextInput label="Forename" name="FName" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput label="Surname" name="SName" control={control} />
        </Grid.Col>

        <Grid.Col span={6}>
          <DateInput label="Date of Birth" name="Dob" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput label="Inits" name="Inits" control={control} transform={(value) => value.toUpperCase()} />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput label="Employee Number" name="EmployeeNumber" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <BranchSelect label="Main branch" name="Branch" control={control} />
        </Grid.Col>

        <PositionSelect label="Position" name="Position" control={control} />

        <Grid.Col span={6}>
          <NumberInput label="Sales Target" name="SalesTarget" control={control} min={0} />
        </Grid.Col>
        <Grid.Col span={6}>
          <NumberInput label="Holiday Allocation" name="HolidayAllocation" control={control} min={0} />
        </Grid.Col>
        <Grid.Col span={6}>
          <NumberInput label="Holiday Allocation This Year" name="HolidayAllocationThisYear" control={control} min={0} />
        </Grid.Col>

        <Grid.Col span={12}>
          <div className="flex space-x-4">
            <NativeSelect
              label="Holiday Start Month"
              name="holidayMonth"
              control={control}
              data={months}
              style={{ width: '50%' }}
              onChange={(e) => {
                const month = e.target.value || '01'
                const day = watch('holidayDay') || '01'
                handleHolidayDateUpdate(month, day)
              }}
              defaultValue="01"
            />
            <NativeSelect
              label="Holiday Start Day"
              name="holidayDay"
              control={control}
              data={days}
              style={{ width: '50%' }}
              onChange={(e) => {
                const day = e.target.value || '01'
                const month = watch('holidayMonth') || '01'
                handleHolidayDateUpdate(month, day)
              }}
              defaultValue="01"
            />
          </div>
        </Grid.Col>

        <Grid.Col span={12}>
          <div className="text-right">
            <Button loading={isLoading} type="submit" onClick={() => console.log('Submit button clicked')}>
              Submit
            </Button>
          </div>
        </Grid.Col>
      </Grid>
    </Form>
  )
}

const BranchSelect = ({ label, name, control }) => {
  const { data, isLoading } = useApi({ url: 'https://api.aa-vision.com/api/map/branches/' })

  useEffect(() => {
    console.log('BranchSelect:', { data, isLoading })
  }, [data, isLoading])

  const options = [
    { value: '', label: 'Select a branch' },
    ...(isLoading ? [] : data.map((el) => ({ value: el.Branch, label: el.Branch })))
  ]

  return <NativeSelect label={label} name={name} control={control} data={options} placeholder="Select a branch" />
}

const PositionSelect = ({ label, name, control }) => {
  const [freeInput, setFreeInput] = useState(false)
  const { data, isLoading } = useApi({ url: '/salesassistnew/positions' })

  if (freeInput) {
    return (
      <Grid.Col span={12}>
        <div className="flex items-end space-x-2">
          <TextInput label={label} name={name} control={control} style={{ width: 200 }} placeholder="Enter position" />
          <Button variant="outline" onClick={() => setFreeInput(false)} className="w-[96px]">
            Cancel
          </Button>
        </div>
      </Grid.Col>
    )
  }

  const options = [
    { value: '', label: 'Select a position' },
    ...(isLoading ? [] : data.map((pos) => ({ value: pos, label: pos })))
  ]

  return (
    <Grid.Col span={12}>
      <div className="flex items-end space-x-2">
        <NativeSelect
          label={label}
          name={name}
          control={control}
          data={options}
          style={{ width: 200 }}
          placeholder="Select a position"
        />
        <Button onClick={() => setFreeInput(true)} className="w-[96px]">
          Add new
        </Button>
      </div>
    </Grid.Col>
  )
}

const AutopartUserSelect = ({ label, name, control }) => {
  const { data, isLoading } = useApi({ url: 'https://api.aa-vision.com/api/map/codes/operators/' })

  const options = (isLoading ? [] : data).map((el) => ({
    label: `${el.A1} - ${el.KeyCode}`,
    value: el.KeyCode
  }))

  return <NativeSelect label={label} name={name} control={control} data={options} />
}

export default StaffEditModal
