import React, { useContext } from 'react';
import { Box, Typography, LinearProgress, Tooltip, Grid } from '@mui/material';
import '../App.css';
import { GlobalContext } from '../context/GlobalContext';

const HourlySalesBar = ({ salesData }) => {
  const { operatorData } = useContext(GlobalContext)
  const maxSales = Math.max(...Object.values(salesData).map(value => parseFloat(value)));

  const getColor = (sales) => {
    const intensity = sales / maxSales;
    if (intensity > 0.75) return 'green';
    if (intensity > 0.5) return 'yellow';
    if (intensity > 0.25) return 'darkorange';
    return 'red'; // Very low sales - grey
  };

  return (
    <Box sx={{ width: '100%', flexGrow: 1, color: 'white', fontFamily: 'Montserrat' }}>
      <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" gutterBottom>
        Hourly Sales ({operatorData.Name})
      </Typography>
      <Grid container spacing={1}>
        {Object.entries(salesData).map(([hour, sales]) => {
          const salesAmount = parseFloat(sales);
          const barWidth = maxSales === 0 ? 0 : (salesAmount / maxSales) * 100;

          return (
            <Grid item xs key={hour} sx={{ textAlign: 'center' }}>
              <Typography style={{ fontFamily: 'Montserrat' }} variant="caption">{hour}-{Number(hour) + 1}</Typography>
              <Tooltip style={{ fontFamily: 'Montserrat' }} title={`Hour ${hour}: £${sales}`} placement="top">
                <Box sx={{ height: '30px', width: '100%', mt: 1, mb: 1 }}> {/* Adjust this height as desired */}
                  <LinearProgress
                    variant="determinate"
                    value={barWidth}
                    sx={{ height: '30px', '& .MuiLinearProgress-bar': { backgroundColor: getColor(salesAmount) } }} // Ensure the height here matches the Box height
                  />
                </Box>
              </Tooltip>
              <Typography style={{ fontFamily: 'Montserrat', fontWeight: sales > 0 ? 'bold' : 'normal' }} variant="caption">£{sales}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default HourlySalesBar;
