import React, { useState, useEffect } from 'react'
import './styles.css' // Ensure you have this CSS file for custom styles
import LogoHead from '../../assets/Head.png'
import axios from 'axios'

function Sophos() {
  const [message, setMessage] = useState('')
  const [chatHistory, setChatHistory] = useState([])
  const [isChatOpen, setIsChatOpen] = useState(false) // State to control chat visibility
  const [isTyping, setIsTyping] = useState(false) // Indicates if the AI is "thinking"

  const sendMessage = async () => {
    if (!message) return

    // Add user message to chat history
    setChatHistory((prevChatHistory) => [...prevChatHistory, { sender: 'user', text: message }])
    setMessage('') // Clear message input
    setIsTyping(true) // Indicate AI is "working"

    try {
      // Send message to the backend API
      const response = await axios.post('http://localhost:3000/business/analysis', {
        message
      })

      // Display the AI's response word by word
      const aiResponse = response.data.analysis
      await displayWordByWord(aiResponse)
    } catch (error) {
      console.error('Error sending message', error)
      setChatHistory((prevChatHistory) => [...prevChatHistory, { sender: 'ai', text: 'Error processing request.' }])
    }

    setIsTyping(false) // AI has finished "working"
  }

  // Send message when Enter key is pressed
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage()
    }
  }

  // Function to display AI response word by word
  const displayWordByWord = async (text) => {
    const words = text.split(' ')
    let displayText = ''

    for (let i = 0; i < words.length; i++) {
      await new Promise((resolve) => setTimeout(resolve, 100)) // Adjust speed (100ms) between each word
      displayText += `${words[i]} `
      setChatHistory((prevChatHistory) => [...prevChatHistory.slice(0, -1), { sender: 'ai', text: displayText.trim() }])
    }
  }

  return (
    <>
      {/* Logo Button */}
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          right: 25,
          zIndex: 1000,
          cursor: 'pointer'
        }}
        onClick={() => setIsChatOpen(!isChatOpen)} // Toggle chat window
      >
        <img src={LogoHead} alt="Chat Logo" width="50px" />
      </div>

      {/* Chat Popup */}
      {isChatOpen && (
        <div
          className="chat-popup"
          style={{
            position: 'fixed',
            bottom: 90,
            right: 20,
            width: '300px',
            maxHeight: '50vh', // Limit the height to half the screen
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 1001,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            className="chat-header"
            style={{
              padding: '10px',
              backgroundColor: '#333',
              color: '#fff',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h3 style={{ margin: 0 }}>Sophos - Spartan AI</h3>
            <button
              onClick={() => setIsChatOpen(false)}
              style={{
                background: 'transparent',
                border: 'none',
                color: '#fff',
                cursor: 'pointer'
              }}
            >
              ✕
            </button>
          </div>

          <div
            className="chat-container"
            style={{
              flex: 1,
              padding: '10px',
              overflowY: 'auto' // Enable scrolling
            }}
          >
            <div className="chat-history">
              {chatHistory.map((chat, index) => (
                <div
                  key={index}
                  className={`chat-bubble ${chat.sender}`}
                  style={{
                    padding: '5px 10px',
                    borderRadius: '10px',
                    marginBottom: '8px',
                    backgroundColor: chat.sender === 'user' ? '#007bff' : '#e0e0e0',
                    color: chat.sender === 'user' ? '#fff' : '#000',
                    alignSelf: chat.sender === 'user' ? 'flex-end' : 'flex-start',
                    maxWidth: '80%'
                  }}
                >
                  {chat.text}
                </div>
              ))}
            </div>

            {isTyping && <div style={{ fontStyle: 'italic', color: 'gray' }}>Sophos is thinking...</div>}
          </div>

          <div
            className="chat-input"
            style={{
              padding: '10px',
              borderTop: '1px solid #ccc',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown} // Send message on Enter key
              placeholder="Ask something..."
              style={{
                flex: 1,
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                marginRight: '10px'
              }}
            />
            <button
              onClick={sendMessage}
              style={{
                padding: '8px 12px',
                backgroundColor: '#007bff',
                border: 'none',
                borderRadius: '4px',
                color: '#fff',
                cursor: 'pointer'
              }}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Sophos
