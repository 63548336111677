import moment from 'moment-timezone'

const checkInfringement = (clockingRecord, shift, breakMinutes, holidays, sickDays, staffId, date) => {
  let status = 'OK'
  let reason = ''

  // Check for holidays and sick days first
  const isHoliday = holidays.some(
    (holiday) =>
      staffId === holiday.StaffID &&
      moment(date).isBetween(
        moment(holiday.StartDatetime).startOf('day'),
        moment(holiday.EndDatetime).endOf('day'),
        'day',
        '[]'
      )
  )

  const isSickDay = sickDays.some(
    (sick) =>
      staffId === sick.StaffID &&
      moment(date).isBetween(
        moment(sick.StartDatetime).startOf('day'),
        moment(sick.EndDatetime).endOf('day'),
        'day',
        '[]'
      )
  )

  // Return early if it's a holiday or sick day
  if (isHoliday) {
    return { status: 'OK', reason: 'On holiday', totalWorkedMinutes: 0 }
  }

  if (isSickDay) {
    return { status: 'OK', reason: 'Sick day', totalWorkedMinutes: 0 }
  }

  // Handle no shift scenarios
  if (!shift) {
    if (!clockingRecord) {
      return { status: 'OK', reason: 'No shift and no clocking', totalWorkedMinutes: 0 }
    } else {
      return { status: 'INFRINGEMENT', reason: 'Clocking in without a shift', totalWorkedMinutes: 0 }
    }
  }

  // Check if the shift is a casual (ZEROH) shift
  const isZerhoShift = shift?.ShiftName === 'ZEROH'

  // Handle casual shift (ZEROH) with clocking - needs acceptance
  if (isZerhoShift && clockingRecord) {
    return { status: 'OK', reason: 'Casual shift (ZERHO) with clocking - needs acceptance', totalWorkedMinutes: 0 }
  }

  // Handle casual shift (ZERHO) with no clocking
  if (isZerhoShift && !clockingRecord) {
    return { status: 'OK', reason: 'Casual shift (ZERHO) with no clocking', totalWorkedMinutes: 0 }
  }

  // Handle missing clocking record for non-casual shifts
  if (!clockingRecord) {
    return { status: 'INFRINGEMENT', reason: 'Missing clocking record', totalWorkedMinutes: 0 }
  }

  // Calculate shift times
  const shiftDate = moment(clockingRecord.ClockInTime).format('YYYY-MM-DD')
  const shiftStart = moment(`${shiftDate} ${shift.Start}`, 'YYYY-MM-DD HH:mm')
  const shiftEnd = moment(`${shiftDate} ${shift.Finish}`, 'YYYY-MM-DD HH:mm')

  // Handle overnight shifts
  if (shiftEnd.isBefore(shiftStart)) {
    shiftEnd.add(1, 'day')
  }

  // Calculate expected work duration
  const expectedWorkMinutes = shiftEnd.diff(shiftStart, 'minutes') - breakMinutes - 10 // 10 minute grace period
  const clockInTime = moment(clockingRecord.ClockInTime)
  const clockOutTime = moment(clockingRecord.ClockOutTime)
  let totalWorkedMinutes = 0

  // Check for late clock-in and early clock-out for regular shifts
  if (!isZerhoShift) {
    if (clockInTime.isAfter(shiftStart.clone().add(15, 'minutes'))) {
      status = 'INFRINGEMENT'
      reason += 'Late clock-in. '
    }

    if (clockOutTime.isBefore(shiftEnd.clone().subtract(15, 'minutes'))) {
      status = 'INFRINGEMENT'
      reason += 'Early clock-out. '
    }
  }

  // Calculate total worked minutes
  if (clockOutTime.isValid() && clockInTime.isValid()) {
    totalWorkedMinutes = clockOutTime.diff(clockInTime, 'minutes') - breakMinutes

    // Check for insufficient work time
    if (totalWorkedMinutes < expectedWorkMinutes && !isZerhoShift) {
      status = 'INFRINGEMENT'
      reason += 'Insufficient work time. '
    }
  } else {
    totalWorkedMinutes = 0
    if (!isZerhoShift) {
      status = 'INFRINGEMENT'
      reason += 'Invalid clock times. '
    }
  }

  // Handle excessive hours (optional, add if needed)
  const maxWorkMinutes = 720 // 12 hours
  if (totalWorkedMinutes > maxWorkMinutes) {
    status = 'INFRINGEMENT'
    reason += 'Excessive hours worked. '
  }

  return {
    status,
    reason: reason.trim(),
    totalWorkedMinutes,
    expectedWorkMinutes,
    shiftStartTime: shiftStart.format('HH:mm'),
    shiftEndTime: shiftEnd.format('HH:mm'),
    breakMinutes
  }
}

const GRACE_PERIOD_MINUTES = 5

export const checkTimingIssues = (clockingRecord, shift) => {
  if (!clockingRecord || !shift) return { isLate: false, isEarlyOut: false }

  const shiftDate = moment(clockingRecord.ClockInTime).format('YYYY-MM-DD')
  const shiftStart = moment(`${shiftDate} ${shift.Start}`, 'YYYY-MM-DD HH:mm')
  const shiftEnd = moment(`${shiftDate} ${shift.Finish}`, 'YYYY-MM-DD HH:mm')

  if (shiftEnd.isBefore(shiftStart)) {
    shiftEnd.add(1, 'day')
  }

  const clockInTime = moment(clockingRecord.ClockInTime)
  const clockOutTime = clockingRecord.ClockOutTime ? moment(clockingRecord.ClockOutTime) : null

  return {
    isLate: clockInTime.isAfter(shiftStart.clone().add(GRACE_PERIOD_MINUTES, 'minutes')),
    isEarlyOut: clockOutTime && clockOutTime.isBefore(shiftEnd.clone().subtract(GRACE_PERIOD_MINUTES, 'minutes'))
  }
}

export const calculateStatus = (staff, thisDate) => {
  const shift = staff.Shifts
  const todaysShift = (shift?.shifts || []).filter((s) => parseInt(s.day) === thisDate.isoWeekday()).pop()
  const todaysBreak = staff.Breaks?.breaks?.[thisDate.isoWeekday()]
  const clock = staff.Clocklog.filter((cl) => moment(cl.ClockInTime).isSame(thisDate, 'day')).pop()
  const isZerohShift = todaysShift?.shift?.ShiftName === 'ZEROH'

  const sick = staff.Sicks.filter((si) =>
    thisDate.isBetween(moment(si.StartDatetime).startOf('day'), moment(si.EndDatetime).endOf('day'), undefined, '[]')
  ).pop()

  const hol = staff.Holidays.filter((si) =>
    thisDate.isBetween(moment(si.StartDatetime).startOf('day'), moment(si.EndDatetime).endOf('day'), undefined, '[]')
  ).pop()

  const infringement = checkInfringement(
    clock,
    todaysShift?.shift,
    todaysBreak,
    staff.Holidays,
    staff.Sicks,
    staff.StaffId,
    new Date()
  )

  const timingIssues = todaysShift ? checkTimingIssues(clock, todaysShift.shift) : { isLate: false, isEarlyOut: false }

  const isAm = sick || hol ? moment(sick?.EndDatetime || hol?.EndDatetime).hour() === 12 : false
  const isPm = sick || hol ? moment(sick?.StartDatetime || hol?.StartDatetime).hour() === 12 : false

  let status = null
  let clockStatus = null

  if (shift) {
    if (todaysShift) {
      status = 'due'
      clockStatus = 'due'
    } else {
      status = 'noshift'
      clockStatus = 'noshift'
    }
  }

  if (clock) {
    status = 'clocked'
    clockStatus = 'clocked'
  } else {
    if (isZerohShift) {
      status = 'noshift'
      clockStatus = 'noshift'
    } else {
      if (thisDate.isSameOrBefore(moment(), 'day')) {
        if (status === 'due') {
          status = 'absent'
          clockStatus = 'absent'
        }
      }
    }
  }

  if (sick) {
    status = 'sick'
  }

  if (hol) {
    status = 'hol'
  }

  return {
    status,
    clockStatus,
    isAm,
    isPm,
    timingIssues,
    infringement,
    todaysShift: todaysShift?.shift,
    clock,
    isZerohShift
  }
}

export default checkInfringement
