import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, Grid, Alert } from '@mantine/core'
import { Form, useForm } from 'react-hook-form'
import { TextInput, DateInput, NativeSelect, NumberInput } from 'react-hook-form-mantine'
import yup from '../lib/yup'
import { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import haxios from '../lib/haxios'
import moment from 'moment-timezone'
import { errorToast, successToast } from '../lib/helpers'
import { modals } from '@mantine/modals'
import Button from '../components/Button'

const validation = yup.object().shape({
  FName: yup
    .string()
    .required('This field is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed')
    .max(50, 'Must be shorter than 50 characters'),
  SName: yup
    .string()
    .required('This field is required')
    .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed')
    .max(50, 'Must be shorter than 50 characters'),
  Dob: yup.date().required('This field is required').max(new Date(), 'Date of birth cannot be in the future'),
  Branch: yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed')
    .required('This field is required'),
  Position: yup.string().required('This field is required').max(50, 'Must be shorter than 50 characters'),
  AllowLogin: yup.boolean().required('This field is required'),
  Email: yup
    .string()
    .email('Invalid email format')
    .when('AllowLogin', {
      is: true,
      then: (schema) => schema.required('Email is required when login is allowed'),
      otherwise: (schema) => schema.notRequired()
    }),
  Username: yup
    .string()
    .when('AllowLogin', {
      is: true,
      then: (schema) => schema.required('Username is required when login is allowed'),
      otherwise: (schema) => schema.notRequired()
    })
    .min(3, 'Username must be at least 3 characters')
    .matches(/^[a-zA-Z0-9_\.]+$/, 'Only alphanumeric characters, underscore and full stop allowed'),
  Password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      'Password must contain at least one letter, one number, and one special character'
    )
    .when('AllowLogin', {
      is: true,
      then: (schema) => schema.required('Password is required when login is allowed'),
      otherwise: (schema) => schema.notRequired()
    }),
  IsAdmin: yup.boolean().required('This field is required'),
  Inits: yup
    .string()
    .nullable()
    .transform((value) => (value ? value.toUpperCase() : value))
    .matches(/^[A-Z]+$/, 'Only letters allowed')
    .max(10, 'Must be 10 characters or less'),
  SalesTarget: yup.number().emptyStringToZero().nullable().min(0, 'Sales target cannot be negative'),
  HolidayStartDate: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, 'Date must be in MM-DD format')
    .required('This field is required'),
  HolidayAllocation: yup
    .number('Must be a whole number')
    .integer('Must be a whole number')
    .min(0, 'Holiday allocation cannot be negative')
    .required('This field is required')
})

const StaffAddModal = ({ context, id, innerProps }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showKeyCode, setShowKeyCode] = useState(false)
  const [generatedPassword, setGeneratedPassword] = useState('')
  const [existingUsername, setExistingUsername] = useState(innerProps.defaultValues?.Username || '')

  // Debug: Log props on component mount
  useEffect(() => {
    console.log('StaffEditModal Props:', { context, id, innerProps })
  }, [context, id, innerProps])

  const {
    formState: { errors, isDirty, isSubmitting, touchedFields, dirtyFields },
    control,
    watch,
    setValue,
    getValues,
    trigger
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validation),
    defaultValues: innerProps.defaultValues || {
      EmployeeNumber: '',
      FName: '',
      SName: '',
      Dob: null,
      Position: '',
      Branch: null,
      AllowLogin: false,
      Email: '',
      Username: '',
      Password: '',
      IsAdmin: false,
      Inits: 'NONE',
      SalesTarget: null,
      HolidayStartDate: '01-01',
      HolidayAllocation: 20,
      HolidayAllocationThisYear: 0,
      KeyCode: null,
      holidayMonth: '01',
      holidayDay: '01'
    }
  })

  // Debug: Log form state changes
  useEffect(() => {
    console.log('Form State:', {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      dirtyFields,
      currentValues: getValues()
    })
  }, [errors, isDirty, isSubmitting, touchedFields, dirtyFields, getValues])

  const allowLogin = watch('AllowLogin')
  const fName = watch('FName')
  const sName = watch('SName')

  // Debug: Log watched values
  useEffect(() => {
    console.log('Watched Values:', {
      allowLogin,
      fName,
      sName
    })
  }, [allowLogin, fName, sName])

  const generatePassword = () => {
    const randomWord = Math.random().toString(36).substring(2, 10)
    const capitalizedWord = randomWord.charAt(0).toUpperCase() + randomWord.slice(1)
    const randomNumber = Math.floor(1000 + Math.random() * 9000)
    const password = `${capitalizedWord}${randomNumber}@`
    console.log('Generated Password:', password)
    return password
  }

  // Generate credentials when name fields change
  useEffect(() => {
    if (fName && sName) {
      const sanitizedFName = fName.toLowerCase().replace(/[^a-z0-9]/g, '')
      const sanitizedSName = sName.toLowerCase().replace(/[^a-z0-9]/g, '')
      const username = `${sanitizedFName}.${sanitizedSName}${Math.floor(Math.random() * 9000) + 999}`
      const email = `${username}@automm.co.uk`

      console.log('Setting credentials:', { username, email })
      setValue('Username', username)
      setValue('Email', email)
    } else {
      console.log('Clearing credentials')
      setValue('Username', '')
      setValue('Email', '')
    }
  }, [fName, sName, setValue, existingUsername])

  // Generate and set password on initial load
  useEffect(() => {
    const password = generatePassword()
    setGeneratedPassword(password)
    setValue('Password', password)
  }, [setValue])

  const handleSubmit = async (data) => {
    console.log('Submit handler called with data:', data)
    setIsLoading(true)

    try {
      // Validate all fields manually first
      const isValid = await trigger()
      //console.log('Manual validation result:', { isValid, errors: control.formState.errors })

      if (!isValid) {
        console.log('Form validation failed')
        return
      }

      // Validate using Yup schema directly
      try {
        await validation.validate(data, { abortEarly: false })
        console.log('Yup validation passed')
      } catch (validationError) {
        console.log('Yup validation failed:', {
          errors: validationError.errors,
          details: validationError.inner.map((err) => ({
            path: err.path,
            message: err.message,
            value: err.value
          }))
        })
        return
      }

      const formattedData = {
        ...data,
        Dob: moment(data.Dob).format('YYYY-MM-DD'),
        Inits: data.Inits.toUpperCase()
      }

      console.log('Submitting formatted data:', formattedData)

      await innerProps.submit(formattedData)
      console.log('Submit successful')

      await innerProps.refetch()
      console.log('Refetch successful')

      context.closeModal(id)

      if (data.AllowLogin) {
        console.log('Opening credentials modal')
        modals.openContextModal({
          modal: 'upwModal',
          withCloseButton: false,
          centered: true,
          innerProps: {
            message: `The username is ${data.Username} and the password is ${data.Password}. Have you noted these down?`
          }
        })
      }
    } catch (e) {
      setIsLoading(false)
      console.error('Submission error:', e)
      console.error('Error details:', {
        name: e.name,
        message: e.message,
        stack: e.stack,
        inner: e.inner
      })
    }
  }

  const months = Array.from({ length: 12 }, (_, i) => ({
    value: String(i + 1).padStart(2, '0'),
    label: moment().month(i).format('MMMM')
  }))

  const days = Array.from({ length: 31 }, (_, i) => ({
    value: String(i + 1).padStart(2, '0'),
    label: String(i + 1)
  }))

  // Debug: Log whenever holiday date is updated
  const handleHolidayDateUpdate = (month, day) => {
    const dateString = `${month}-${day}`
    console.log('Updating holiday date:', { month, day, dateString })
    setValue('HolidayStartDate', dateString)
  }

  return (
    <Form
      control={control}
      onSubmit={(e) => {
        console.log('Form onSubmit event:', e)
        handleSubmit(e.data)
      }}
      onError={(errors) => {
        console.log('Form onError event:', {
          errors,
          formState: control.formState,
          values: getValues()
        })
      }}
    >
      <Grid gutter="xs">
        <Grid.Col span={6}>
          <TextInput label="Forename" name="FName" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput label="Surname" name="SName" control={control} />
        </Grid.Col>

        <Grid.Col span={6}>
          <DateInput label="Date of Birth" name="Dob" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput label="Inits" name="Inits" control={control} transform={(value) => value.toUpperCase()} />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput label="Employee Number" name="EmployeeNumber" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <BranchSelect label="Main branch" name="Branch" control={control} />
        </Grid.Col>

        <PositionSelect label="Position" name="Position" control={control} />

        <Grid.Col span={6}>
          <NumberInput label="Sales Target" name="SalesTarget" control={control} min={0} />
        </Grid.Col>
        <Grid.Col span={6}>
          <NumberInput label="Holiday Allocation" name="HolidayAllocation" control={control} min={0} />
        </Grid.Col>
        <Grid.Col span={6}>
          <NumberInput label="Holiday Allocation This Year" name="HolidayAllocationThisYear" control={control} min={0} />
        </Grid.Col>

        <Grid.Col span={12}>
          <div className="flex space-x-4">
            <NativeSelect
              label="Holiday Start Month"
              name="holidayMonth"
              control={control}
              data={months}
              style={{ width: '50%' }}
              onChange={(e) => {
                const month = e.target.value || '01'
                const day = watch('holidayDay') || '01'
                handleHolidayDateUpdate(month, day)
              }}
              defaultValue="01"
            />
            <NativeSelect
              label="Holiday Start Day"
              name="holidayDay"
              control={control}
              data={days}
              style={{ width: '50%' }}
              onChange={(e) => {
                const day = e.target.value || '01'
                const month = watch('holidayMonth') || '01'
                handleHolidayDateUpdate(month, day)
              }}
              defaultValue="01"
            />
          </div>
        </Grid.Col>

        <Grid.Col span={12}>
          <Checkbox label="Allow Login" {...control.register('AllowLogin')} />
        </Grid.Col>

        {allowLogin && (
          <>
            <Grid.Col span={12}>
              <Alert color="yellow">
                We have generated a password for you. This will not be displayed again so please make a note of it.
              </Alert>
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="Username" name="Username" control={control} disabled={true} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="Password" name="Password" control={control} disabled={true} />
            </Grid.Col>
            <Grid.Col span={12}>
              <Checkbox
                label="Is Admin"
                name="IsAdmin"
                checked={watch('IsAdmin')}
                onChange={(e) => setValue('IsAdmin', e.currentTarget.checked)}
              />
            </Grid.Col>
          </>
        )}

        <Grid.Col span={12}>
          <div className="text-right">
            <Button loading={isLoading} type="submit" onClick={() => console.log('Submit button clicked')}>
              Submit
            </Button>
          </div>
        </Grid.Col>
      </Grid>
    </Form>
  )
}

const BranchSelect = ({ label, name, control }) => {
  const { data, isLoading } = useApi({ url: 'https://api.aa-vision.com/api/map/branches/' })

  useEffect(() => {
    console.log('BranchSelect:', { data, isLoading })
  }, [data, isLoading])

  const options = [
    { value: '', label: 'Select a branch' },
    ...(isLoading ? [] : data.map((el) => ({ value: el.Branch, label: el.Branch })))
  ]

  return <NativeSelect label={label} name={name} control={control} data={options} placeholder="Select a branch" />
}

const PositionSelect = ({ label, name, control }) => {
  const [freeInput, setFreeInput] = useState(false)
  const { data, isLoading } = useApi({ url: '/salesassistnew/positions' })

  if (freeInput) {
    return (
      <Grid.Col span={12}>
        <div className="flex items-end space-x-2">
          <TextInput label={label} name={name} control={control} style={{ width: 200 }} placeholder="Enter position" />
          <Button variant="outline" onClick={() => setFreeInput(false)} className="w-[96px]">
            Cancel
          </Button>
        </div>
      </Grid.Col>
    )
  }

  const options = [
    { value: '', label: 'Select a position' },
    ...(isLoading ? [] : data.map((pos) => ({ value: pos, label: pos })))
  ]

  return (
    <Grid.Col span={12}>
      <div className="flex items-end space-x-2">
        <NativeSelect
          label={label}
          name={name}
          control={control}
          data={options}
          style={{ width: 200 }}
          placeholder="Select a position"
        />
        <Button onClick={() => setFreeInput(true)} className="w-[96px]">
          Add new
        </Button>
      </div>
    </Grid.Col>
  )
}

const AutopartUserSelect = ({ label, name, control }) => {
  const { data, isLoading } = useApi({ url: 'https://api.aa-vision.com/api/map/codes/operators/' })

  const options = (isLoading ? [] : data).map((el) => ({
    label: `${el.A1} - ${el.KeyCode}`,
    value: el.KeyCode
  }))

  return <NativeSelect label={label} name={name} control={control} data={options} />
}

export default StaffAddModal
