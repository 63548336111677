import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    CircularProgress,
    Alert,
    Grid,
    Chip,
    Avatar,
    Typography,
    Tooltip,
    Button,
    MenuItem
} from '@mui/material';
import {
    CalendarToday,
    EventAvailable,
    EventNote,
    Info,
    Add
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { GlobalContext } from '../context/GlobalContext';
import SickDaysOverview from './SickDaysOverview';
import { isEqual } from 'lodash';
import AddSickDayModal from './AddSickDayModal';



// Set default moment locale to British English
moment.locale('en-GB');

const SickDays = () => {
    const { token } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [sickDays, setSickDays] = useState([]);
    const [error, setError] = useState('');
    const [allBranches, setAllBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('all');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [staff, setStaff] = useState([]);

    const [dateRange, setDateRange] = useState({
        start: moment().startOf('month').locale('en-GB'),
        end: moment().endOf('month').locale('en-GB')
    });

    const fetchBranches = useCallback(async () => {
        if (!token) return;
        try {
            const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                if (!isEqual(data, allBranches)) {
                    setAllBranches(data);
                }
            } else {
                console.error('Error fetching branches:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    }, [token, allBranches]);

    useEffect(() => {
        fetchBranches();
    }, [fetchBranches]);

    const fetchSickDays = async () => {
        try {
            setLoading(true);
            setError('');

            if (!token) throw new Error('Authentication token not found');

            const response = await fetch(
                'https://vision-web-api-test.azurewebsites.net/api/tma/sickdays',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': token
                    },
                    body: JSON.stringify({
                        StartDate: dateRange.start.format('DD-MM-YYYY'),
                        EndDate: dateRange.end.format('DD-MM-YYYY')
                    })
                }
            );

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`API Error ${response.status}: ${errorText}`);
            }

            const data = await response.json();

            if (!Array.isArray(data)) {
                throw new Error('Invalid data format received from API');
            }

            // Filter data by selected branch if not 'all'
            const filteredData = selectedBranch === 'all'
                ? data
                : data.filter(record => record.Branch === selectedBranch);

            setSickDays(filteredData);
        } catch (error) {
            console.error('Data Fetch Error:', error);
            setError(error.message);
            setSickDays([]);
        } finally {
            setLoading(false);
        }
    };

    // Then modify your existing useEffect to just call this function:
    useEffect(() => {
        fetchSickDays();
    }, [token, dateRange.start, dateRange.end, selectedBranch]);

    const handleAddSickDay = () => {
        setIsModalOpen(true);
    };


    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/staff', {
                    headers: { token: token }
                });
                if (!response.ok) throw new Error('Failed to fetch staff');
                const data = await response.json();
                setStaff(data);
            } catch (error) {
                console.error('Error fetching staff:', error);
            }
        };

        if (token) {
            fetchStaff();
        }
    }, [token]);


    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            localeText={{
                shortDayLabel: 'DD/MM/YYYY'
            }}
            adapterLocale="en-GB"
        >
            <Paper elevation={3} sx={{
                p: 3,
                borderRadius: 4,
                background: '#1A1A1A',
                minHeight: 400,
                color: '#FFFFFF'
            }}>
                <Box sx={{ mb: 4 }}>
                    <SickDaysOverview />
                    <Grid container justifyContent="space-between" alignItems="center" mb={2}>
                        <Grid item>
                            <Typography variant="h6" sx={{
                                color: '#90CAF9',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}>
                                <EventNote fontSize="medium" /> Sick Day Records
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={handleAddSickDay}
                                startIcon={<Add />}
                                sx={{
                                    color: '#90CAF9',
                                    borderColor: '#90CAF9',
                                    '&:hover': {
                                        borderColor: '#90CAF9',
                                        backgroundColor: 'rgba(144, 202, 249, 0.08)'
                                    }
                                }}
                            >
                                Add Sick Day
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                select
                                fullWidth
                                label="Branch"
                                value={selectedBranch}
                                onChange={(e) => setSelectedBranch(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        color: '#FFFFFF',
                                        backgroundColor: '#2D2D2D',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#404040'
                                        }
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#B0BEC5'
                                    }
                                }}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            style: {
                                                backgroundColor: '#2D2D2D'
                                            }
                                        }
                                    }
                                }}
                            >
                                <MenuItem value="all" style={{ color: '#FFFFFF' }}>
                                    All Branches
                                </MenuItem>
                                {allBranches.map((branch) => (
                                    <MenuItem
                                        key={branch.MamCode}
                                        value={branch.MamCode}
                                        style={{ color: '#FFFFFF' }}
                                    >
                                        {branch.Branch}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DatePicker
                                label="Start Date"
                                value={dateRange.start}
                                format="DD/MM/YYYY"
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    if (newValue) {
                                        const formattedDate = moment(newValue).format('DD/MM/YYYY');
                                        setDateRange(prev => ({
                                            ...prev,
                                            start: moment(formattedDate, 'DD/MM/YYYY')
                                        }));
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                color: '#FFFFFF',
                                                background: '#2D2D2D',
                                                '& fieldset': {
                                                    borderColor: '#404040'
                                                }
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#B0BEC5'
                                            },
                                            '& .MuiIconButton-root': {
                                                color: '#B0BEC5'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DatePicker
                                label="End Date"
                                value={dateRange.end}
                                format="DD/MM/YYYY"
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => {
                                    if (newValue) {
                                        const formattedDate = moment(newValue).format('DD/MM/YYYY');
                                        setDateRange(prev => ({
                                            ...prev,
                                            end: moment(formattedDate, 'DD/MM/YYYY')
                                        }));
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                color: '#FFFFFF',
                                                background: '#2D2D2D',
                                                '& fieldset': {
                                                    borderColor: '#404040'
                                                }
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#B0BEC5'
                                            },
                                            '& .MuiIconButton-root': {
                                                color: '#B0BEC5'
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: 6
                    }}>
                        <CircularProgress size={40} thickness={4} sx={{ color: '#90CAF9' }} />
                        <Typography variant="body2" sx={{ mt: 2, color: '#B0BEC5' }}>
                            Loading sick day records...
                        </Typography>
                    </Box>
                ) : error ? (
                    <Alert severity="error" sx={{
                        mb: 3,
                        bgcolor: '#D32F2F',
                        color: '#FFFFFF',
                        '& .MuiAlert-icon': { color: '#FFFFFF' }
                    }}>
                        Error: {error}
                    </Alert>
                ) : (
                    <TableContainer sx={{
                        border: '1px solid #404040',
                        borderRadius: 2,
                        overflow: 'hidden',
                        background: '#2D2D2D'
                    }}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead sx={{
                                background: '#121212',
                                '.MuiTableCell-root': {
                                    color: '#90CAF9',
                                    fontWeight: 600
                                }
                            }}>
                                <TableRow>
                                    <TableCell sx={{ width: '25%' }}>Branch</TableCell>
                                    <TableCell sx={{ width: '25%' }}>Staff Member</TableCell>
                                    <TableCell align="center">
                                        <EventAvailable sx={{ fontSize: 18, mr: 1, color: 'inherit' }} />
                                        Start Date
                                    </TableCell>
                                    <TableCell align="center">
                                        <EventAvailable sx={{ fontSize: 18, mr: 1, color: 'inherit' }} />
                                        End Date
                                    </TableCell>
                                    <TableCell align="center">Days</TableCell>
                                    <TableCell sx={{ width: '30%' }}>Notes</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {sickDays.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4, color: '#B0BEC5' }}>
                                            No sick days found in selected period
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    [...sickDays]
                                        .sort((a, b) => moment(a.StartDatetime).diff(moment(b.StartDatetime)))
                                        .map((record) => (
                                            <TableRow
                                                key={record.SickId}
                                                hover
                                                sx={{
                                                    '&:nth-of-type(even)': { background: '#373737' },
                                                    '&:hover': { background: '#404040' }
                                                }}
                                            >
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                        <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                                                            {`${record.Branch || 'Branch'}`}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                        <Avatar sx={{
                                                            bgcolor: '#CE93D8',
                                                            width: 32,
                                                            height: 32,
                                                            color: '#000000'
                                                        }}>
                                                            {record.FName?.[0]?.toUpperCase() || '?'}
                                                        </Avatar>
                                                        <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                                                            {`${record.FName || 'Unknown'} ${record.SName || 'Staff'}`}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Chip
                                                        label={moment(record.StartDatetime).format('DD MMM')}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            borderColor: '#90CAF9',
                                                            color: '#90CAF9'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Chip
                                                        label={moment(record.EndDatetime).format('DD MMM')}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            borderColor: '#90CAF9',
                                                            color: '#90CAF9'
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Chip
                                                        label={moment(record.endDate).diff(moment(record.startDate), 'days') + 1}
                                                        sx={{
                                                            background: '#90CAF9',
                                                            color: '#000000',
                                                            fontWeight: 600
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Tooltip
                                                        title={record.Reason || 'No notes'}
                                                        arrow
                                                        componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    bgcolor: '#2D2D2D',
                                                                    color: '#FFFFFF',
                                                                    border: '1px solid #404040',
                                                                    fontSize: '0.875rem'
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            noWrap
                                                            sx={{
                                                                color: record.Reason ? '#FFFFFF' : '#B0BEC5',
                                                                fontStyle: !record.Reason ? 'italic' : 'normal'
                                                            }}
                                                        >
                                                            {record.Reason || 'No notes provided'}
                                                        </Typography>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <AddSickDayModal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSubmit={() => {
                        setIsModalOpen(false);
                        // Re-fetch the sick days data after successful submission
                        fetchSickDays();
                    }}
                    token={token}
                    staffMembers={staff}
                />

            </Paper>
        </LocalizationProvider>
    );
};

export default SickDays;