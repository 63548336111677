import { create } from 'zustand';
import moment from 'moment-timezone';

const calculateStaffMetrics = (data, periodMonths) => {
    const { clockingRecords, sick } = data;
    const endDate = moment();
    const startDate = moment().subtract(periodMonths - 1, 'months').startOf('month');

    // Track metrics by staff member
    const staffAbsences = {};      // For counting separate absence periods
    const staffSickDays = {};      // For counting total sick days
    const staffInfractions = {};   // For counting lates/earlies

    // Process sick days
    sick.forEach(absence => {
        const staffId = absence.StaffID;
        const staffName = absence.StaffName;
        const start = moment(absence.StartDate);
        const end = moment(absence.EndDate);

        // Initialize staff records if needed
        if (!staffAbsences[staffId]) {
            staffAbsences[staffId] = {
                staffName,
                periods: [],
                totalDays: 0
            };
        }
        if (!staffSickDays[staffId]) {
            staffSickDays[staffId] = {
                staffName,
                totalDays: 0,
                periods: []
            };
        }

        // Add as an absence period
        staffAbsences[staffId].periods.push({
            start,
            end,
            type: 'Sick Leave',
            days: end.diff(start, 'days') + 1
        });

        // Add to sick days total
        staffSickDays[staffId].totalDays += end.diff(start, 'days') + 1;
        staffSickDays[staffId].periods.push({
            startDate: start.format('DD/MM/YYYY'),
            endDate: end.format('DD/MM/YYYY'),
            days: end.diff(start, 'days') + 1
        });
    });

    // Process missing shifts as absence periods
    // Group clock records by date and staff to identify missing shifts
    const staffShifts = {};
    clockingRecords.forEach(record => {
        const staffId = record.StaffID;
        const date = moment(record.ClockDate).format('YYYY-MM-DD');

        if (!staffShifts[staffId]) {
            staffShifts[staffId] = {};
        }
        if (!staffShifts[staffId][date]) {
            staffShifts[staffId][date] = [];
        }
        staffShifts[staffId][date].push(record);
    });

    // Add missing shifts to absence periods
    Object.entries(staffShifts).forEach(([staffId, dates]) => {
        Object.entries(dates).forEach(([date, records]) => {
            if (records.length === 0) {
                if (!staffAbsences[staffId]) {
                    staffAbsences[staffId] = {
                        staffName: records[0]?.StaffName,
                        periods: [],
                        totalDays: 0
                    };
                }

                staffAbsences[staffId].periods.push({
                    start: moment(date),
                    end: moment(date),
                    type: 'Missing Shift',
                    days: 1
                });
            }
        });
    });

    // Process late arrivals and early departures
    clockingRecords.forEach(record => {
        const staffId = record.StaffID;
        const staffName = record.StaffName;
        const date = moment(record.ClockDate);

        if (!staffInfractions[staffId]) {
            staffInfractions[staffId] = {
                staffName,
                count: 0,
                details: []
            };
        }

        // Only count late arrivals and early departures
        if (record.IsLate) {
            staffInfractions[staffId].count++;
            staffInfractions[staffId].details.push({
                date: date.format('DD/MM/YYYY'),
                type: 'Late arrival',
                time: record.ClockTime,
                minutes: record.MinutesLate
            });
        }
        if (record.IsEarlyLeave) {
            staffInfractions[staffId].count++;
            staffInfractions[staffId].details.push({
                date: date.format('DD/MM/YYYY'),
                type: 'Early departure',
                time: record.ClockTime,
                minutes: record.MinutesEarly
            });
        }
    });

    // Get thresholds based on period
    const thresholds = {
        3: { absencePeriods: 3, absenceDays: 5, infractions: 6 },
        6: { absencePeriods: 4, absenceDays: 8, infractions: 12 },
        12: { absencePeriods: 6, absenceDays: 10, infractions: 24 }
    }[periodMonths];

    // Find staff exceeding thresholds
    const flaggedStaff = {
        absencePeriods: [],
        absenceDays: [],
        infractions: []
    };

    // Check absence periods (sick days + missing shifts)
    Object.entries(staffAbsences).forEach(([staffId, data]) => {
        if (data.periods.length >= thresholds.absencePeriods) {
            flaggedStaff.absencePeriods.push({
                staffName: data.staffName,
                count: data.periods.length,
                details: data.periods.map(p => ({
                    startDate: p.start.format('DD/MM/YYYY'),
                    endDate: p.end.format('DD/MM/YYYY'),
                    type: p.type,
                    days: p.days
                }))
            });
        }
    });

    // Check sick days
    Object.entries(staffSickDays).forEach(([staffId, data]) => {
        if (data.totalDays >= thresholds.absenceDays) {
            flaggedStaff.absenceDays.push({
                staffName: data.staffName,
                totalDays: data.totalDays,
                details: data.periods
            });
        }
    });

    // Check infractions (lates/earlies only)
    Object.entries(staffInfractions).forEach(([staffId, data]) => {
        if (data.count >= thresholds.infractions) {
            flaggedStaff.infractions.push({
                staffName: data.staffName,
                count: data.count,
                details: data.details
            });
        }
    });

    return {
        flaggedStaff,
        thresholds,
        periodStart: startDate.format('DD/MM/YYYY'),
        periodEnd: endDate.format('DD/MM/YYYY')
    };
};

const useMetricsStore = create((set) => ({
    metrics: {
        threeMonth: null,
        sixMonth: null,
        twelveMonth: null
    },
    isLoading: false,
    error: null,

    fetchMetrics: async (token, selectedBranch, baseDate) => {
        set({ isLoading: true, error: null });
        try {
            // Calculate date ranges
            const endDate = moment(baseDate).endOf('month').format('YYYY-MM-DD');
            const threeMonthStart = moment(baseDate).subtract(2, 'months').startOf('month').format('YYYY-MM-DD');
            const sixMonthStart = moment(baseDate).subtract(5, 'months').startOf('month').format('YYYY-MM-DD');
            const twelveMonthStart = moment(baseDate).subtract(11, 'months').startOf('month').format('YYYY-MM-DD');

            // Fetch data for each period
            const [threeMonthData, sixMonthData, twelveMonthData] = await Promise.all([
                fetchPeriodData(token, selectedBranch, threeMonthStart, endDate),
                fetchPeriodData(token, selectedBranch, sixMonthStart, endDate),
                fetchPeriodData(token, selectedBranch, twelveMonthStart, endDate)
            ]);

            // Calculate metrics for each period
            const metrics = {
                threeMonth: calculateStaffMetrics(threeMonthData, 3),
                sixMonth: calculateStaffMetrics(sixMonthData, 6),
                twelveMonth: calculateStaffMetrics(twelveMonthData, 12)
            };

            set({ metrics, isLoading: false });
        } catch (error) {
            console.error('Error fetching metrics:', error);
            set({ error: error.message, isLoading: false });
        }
    }
}));

// Helper function to fetch data for a specific period
async function fetchPeriodData(token, selectedBranch, startDate, endDate) {
    // Fetch all required data in parallel
    const [clockingResponse, sickResponse] = await Promise.all([
        fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token
            },
            body: JSON.stringify({
                startDate,
                endDate,
                Branch: selectedBranch === 'ALL' ? null : selectedBranch
            })
        }),
        fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token
            },
            body: JSON.stringify({
                StartDate: startDate,
                EndDate: endDate,
                Branch: selectedBranch === 'ALL' ? null : selectedBranch
            })
        })
    ]);

    // Check responses
    if (!clockingResponse.ok || !sickResponse.ok) {
        throw new Error('Failed to fetch period data');
    }

    // Parse all responses
    const [clockingRecords, sick] = await Promise.all([
        clockingResponse.json(),
        sickResponse.json()
    ]);

    return {
        clockingRecords,
        sick
    };
}

export default useMetricsStore;