import React, { useContext, useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    createTheme,
    ThemeProvider,
    IconButton,
    TextField,
    Button,
    TablePagination,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { GlobalContext } from '../../context/GlobalContext';
// Create a theme instance.
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function Regions() {
    const { token } = useContext(GlobalContext)
    const [branches, setBranches] = useState([]);
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [editRowId, setEditRowId] = useState(null);
    const [deleteRowId, setDeleteRowId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentBranch, setCurrentBranch] = useState({ SalesTarget: '', MarginTarget: '' });
    const [currentRegion, setCurrentRegion] = useState({ RegionId: '', RegionName: '', RegionalManager: '', RMEmail: '' });
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [newBranch, setNewBranch] = useState({ Branch: '', SalesTarget: '', MarginTarget: '' });
    const [newRegion, setNewRegion] = useState({ RegionId: '', RegionName: '', RegionalManager: '', RMEmail: '' });
    const rowsPerPage = 5;
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');

    const [alignment, setAlignment] = useState('0');

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
    };


    // Function to handle the dialog open event
    const handleDialogOpen = (region) => {
        setCurrentRegion(region);
        setDialogOpen(true);
    };

    // Function to handle the dialog close event
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // Function to handle the dialog close event
    const handleAddBranchDialog = () => {
        setAddDialogOpen(true);
    };

    // Function to handle changes in Saturday and Sunday trade status
    const handleTradeChange = (tradeType, newValue) => {
        if (newValue !== null) {
            setCurrentBranch({ ...currentBranch, [tradeType]: newValue });
        }
    };

    // Filter branches based on search
    const filteredRegions = regions.filter(region =>
        region.RegionalManager.toLowerCase().includes(search.toLowerCase())
    );

    // Function to handle the save event
    const handleSave = async () => {
        try {
            console.log('Updating region with data:', {
                RegionId: currentRegion.RegionId,
                RegionName: currentRegion.RegionName,
                RegionalManager: currentRegion.RegionalManager,
                RMEmail: currentRegion.RMEmail
            });

            setLoading(true); // Show a loading indicator
            const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/updateRegion/${currentRegion._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Assuming you're using bearer token for authorization
                },
                body: JSON.stringify({
                    RegionId: currentRegion.RegionId,
                    RegionName: currentRegion.RegionName,
                    RegionalManager: currentRegion.RegionalManager,
                    RMEmail: currentRegion.RMEmail
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Assuming your API returns the updated region
            const updatedRegion = await response.json();

            // Update the local state with the updated region
            setRegions(regions.map(region => region._id === updatedRegion._id ? updatedRegion : region));

            // Close the dialog and stop loading
            setDialogOpen(false);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (branchId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this branch?");
        if (confirmDelete) {
            const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/deleteBranch/${branchId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                // Remove the deleted branch from the state
                const updatedBranches = branches.filter(branch => branch._id !== branchId);
                setBranches(updatedBranches);

                // Check if we need to adjust the current page
                if (page > 0 && updatedBranches.length <= page * rowsPerPage) {
                    setPage(page - 1);
                }
            } else {
                // Handle errors
                console.error('Failed to delete the branch', await response.text());
            }
        }
    };
    const handleAddBranch = async () => {
    }
    const handleAddRegion = async () => {
        const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/addRegion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                RegionId: newRegion.RegionId,
                RegionName: newRegion.RegionName,
                RegionalManager: newRegion.RegionalManager,
                RMEmail: newRegion.RMEmail
            })
        });

        if (response.ok) {
            const addedRegion = await response.json();
            setRegions([...regions, addedRegion]);
            setAddDialogOpen(false); // Close the dialog
            setNewRegion({ RegionId: '', RegionName: '', RegionalManager: '', RMEmail: '' }); // Reset form
        } else {
            // Handle errors
            console.error('Failed to add the branch', await response.text());
        }
    };


    useEffect(() => {

        async function fetchRegions() {
            try {
                const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/regions`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    console.error('HTTP error! status:', response.status);
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.info('Regions:', data);
                setRegions(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchRegions();
    }, []);


    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <TextField
                    label="Search Region"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />
                <div onClick={() => handleAddBranchDialog()} style={{ cursor: 'pointer', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                    <h4>ADD REGION</h4>
                    <IconButton>
                        <AddIcon />
                    </IconButton>
                </div>
            </div>
            <TableContainer style={{ height: '75vh', overflow: 'scroll' }} component={Paper}>
                <Table aria-label="regions table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Region No</TableCell>
                            <TableCell>Region Name</TableCell>
                            <TableCell align="right">Regional Manager</TableCell>
                            <TableCell align="right">RM Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRegions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((region) => (
                            <TableRow key={region._id}>
                                <TableCell component="th" scope="row">
                                    {region.RegionId}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {region.RegionName}
                                </TableCell>
                                <TableCell align="right">{region.RegionalManager}</TableCell>
                                <TableCell align="right">{region.RMEmail}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleDialogOpen(region)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(region._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={branches.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]} // Remove the option to change rows per page
            />


            {/* Dialog for editing branch targets */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Regions</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="regionId"
                        label="Region ID"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={currentRegion.RegionId || ''}
                        onChange={(e) => setCurrentRegion({ ...currentRegion, RegionId: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="regionName"
                        label="Region Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentRegion.RegionName || ''}
                        onChange={(e) => setCurrentRegion({ ...currentRegion, RegionName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="regionalManager"
                        label="Regional Manager"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={currentRegion.RegionalManager || ''}
                        onChange={(e) => setCurrentRegion({ ...currentRegion, RegionalManager: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="rmEmail"
                        label="RM Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={currentRegion.RMEmail || ''}
                        onChange={(e) => setCurrentRegion({ ...currentRegion, RMEmail: e.target.value })}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* dialog for adding branch */}
            <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
                <DialogTitle>Add New Region</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="regionId"
                        label="Region ID"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={newRegion.RegionId}
                        onChange={(e) => setNewRegion({ ...newRegion, RegionId: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="regionName"
                        label="Region Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newRegion.RegionName}
                        onChange={(e) => setNewRegion({ ...newRegion, RegionName: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="RegionalManager"
                        label="Regional Manager"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newRegion.RegionalManager}
                        onChange={(e) => setNewRegion({ ...newRegion, RegionalManager: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        id="RMEmail"
                        label="RM Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={newRegion.RMEmail}
                        onChange={(e) => setNewRegion({ ...newRegion, RMEmail: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleAddRegion}>Add</Button>
                </DialogActions>
            </Dialog>


        </ThemeProvider>
    );
}

export default Regions;
