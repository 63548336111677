import React, { useState, useMemo } from 'react'
import { Card, CardContent, Typography, Box, Button, Chip } from '@mui/material'
import moment from 'moment-timezone'

const AbsencesCard = ({ data }) => {
  const [selectedType, setSelectedType] = useState('HOLIDAYS')

  const handleTypeChange = (type) => {
    setSelectedType(type)
  }

  const formatDateRange = (startDate, endDate) => {
    const start = moment(startDate)
    const end = moment(endDate)

    if (start.isSame(end, 'day')) {
      return start.format('DD/MM/YYYY')
    } else {
      return `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`
    }
  }

  const groupedAbsences = useMemo(() => {
    let absences
    switch (selectedType) {
      case 'HOLIDAYS':
        absences = data.holidays || []
        break
      case 'SICK_DAYS':
        absences = data.sick || []
        break
      case 'CASUAL_WORKERS':
        absences = data.casual || []
        break
      default:
        absences = []
    }

    // Sort absences by start date
    const sortedAbsences = absences.sort((a, b) => {
      const dateA = moment(a.StartDatetime)
      const dateB = moment(b.StartDatetime)
      return dateA - dateB
    })

    // Group absences by employee and track all details
    const result = {}
    sortedAbsences.forEach((absence) => {
      const name = absence.StaffName || absence.Name
      const startDate = moment(absence.StartDatetime)
      const endDate = moment(absence.EndDatetime)

      if (!result[name]) {
        result[name] = []
      }

      result[name].push({
        startDate,
        endDate,
        status: absence.Status,
        approvedBy: absence.ApprovedBy,
        clockInTime: absence.ClockInTime,
        clockOutTime: absence.ClockOutTime,
        timeWorked: absence.TimeWorked
      })
    })

    return result
  }, [selectedType, data])

  const renderAbsences = () => {
    return Object.entries(groupedAbsences).map(([name, absences]) => (
      <Box key={name} sx={{ mb: 2, p: 1, backgroundColor: '#1E1E1E', borderRadius: 1 }}>
        <Typography variant="body1" color="white" sx={{ fontWeight: 'bold', mb: 1, textTransform: 'uppercase' }}>
          {name}
        </Typography>
        {absences.map((absence, index) => (
          <Box key={index} sx={{ mb: 2, borderLeft: '2px solid #333', pl: 2 }}>
            <Typography variant="body2" color="gray">
              {formatDateRange(absence.startDate, absence.endDate)}
            </Typography>
            {selectedType === 'CASUAL_WORKERS' && (
              <>
                <Typography variant="body2" color="gray" sx={{ mt: 0.5 }}>
                  Clocked: {absence.clockInTime} - {absence.clockOutTime}
                </Typography>
                <Typography variant="body2" color="gray" sx={{ mb: 1 }}>
                  Time Worked: {absence.timeWorked}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Chip
                    label={absence.status}
                    size="small"
                    color={absence.status === 'Approved' ? 'success' : 'warning'}
                  />
                  {absence.approvedBy && (
                    <Typography variant="caption" color="gray">
                      by {absence.approvedBy}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
        ))}
      </Box>
    ))
  }

  return (
    <Card sx={{ mt: 2, bgcolor: '#121212', maxHeight: '100%' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
          Details
        </Typography>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Button
            onClick={() => handleTypeChange('HOLIDAYS')}
            variant={selectedType === 'HOLIDAYS' ? 'contained' : 'outlined'}
            sx={{ mr: 1 }}
          >
            HOLIDAYS
          </Button>
          <Button
            onClick={() => handleTypeChange('SICK_DAYS')}
            variant={selectedType === 'SICK_DAYS' ? 'contained' : 'outlined'}
            sx={{ mr: 1 }}
          >
            SICK DAYS
          </Button>
          <Button
            onClick={() => handleTypeChange('CASUAL_WORKERS')}
            variant={selectedType === 'CASUAL_WORKERS' ? 'contained' : 'outlined'}
          >
            CASUAL WORKERS
          </Button>
        </Box>
        <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }}>{renderAbsences()}</Box>
      </CardContent>
    </Card>
  )
}

export default AbsencesCard
