import React, { useState, useEffect, useContext, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Switch, Typography, Button, CircularProgress, Fab, Menu, MenuItem, Checkbox, ListItemText, Divider, FormControl, InputLabel, Select } from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import GELeadData from './GELeadData';
import GELeadNewModal from './GELeadNewModal';
import CommentModalGE from './CommentModalGE';
import moment from 'moment-timezone';

const GELeadContainer = ({ geState, setGEState }) => {
    const { operatorData, username, token } = useContext(GlobalContext);
    const [geleads, setGELeads] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddGELeadModal, setShowAddGELeadModal] = useState(false);
    const [selectedGELead, setSelectedGELead] = useState(null);
    const [currentComments, setCurrentComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isPosting, setIsPosting] = useState(false);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [availableBranches, setAvailableBranches] = useState([]);

    useEffect(() => {
        if (geleads.length > 0) {
            const branches = [...new Set(geleads.map(lead => lead.Branch).filter(Boolean))];
            setAvailableBranches(branches);
        }
    }, [geleads]);

    const getFilteredLeads = useCallback(() => {
        return geleads.filter(lead => {
            const branchMatch = selectedBranches.length === 0 || selectedBranches.includes(lead.Branch);
            const statusMatch = selectedStatuses.length === 0 || selectedStatuses.includes(lead.Status);
            return branchMatch && statusMatch;
        });
    }, [geleads, selectedBranches, selectedStatuses]);

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleBranchFilterChange = (branch) => {
        setSelectedBranches(prev => {
            if (prev.includes(branch)) {
                return prev.filter(b => b !== branch);
            } else {
                return [...prev, branch];
            }
        });
    };

    const handleStatusFilterChange = (status) => {
        setSelectedStatuses(prev => {
            if (prev.includes(status)) {
                return prev.filter(s => s !== status);
            } else {
                return [...prev, status];
            }
        });
    };

    const clearFilters = () => {
        setSelectedBranches([]);
        setSelectedStatuses([]);
        handleFilterClose();
    };



    useEffect(() => {
        console.log("Fetching GE Leads...");
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let url = 'https://spar.connectplus.parts/api/salesassist/cplusassist/geleads';

            if (operatorData.Status === 'Sales') {
                url += `/${operatorData.Branch}`;
            } else if (operatorData.Status === 'rm') {
                url += `?regionalManager=${encodeURIComponent(operatorData.Name)}`;
            }

            console.log(`Fetching from URL: ${url}`);
            const response = await fetch(url, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const listLeads = await response.json();
            console.log("Received GE Leads:", listLeads);

            if (operatorData.Status === 'bm' || operatorData.Status === 'sales') {
                const filteredLeads = listLeads.filter(lead => lead.Branch === operatorData.Branch);
                setGELeads(filteredLeads);
            }
            else {
                setGELeads(listLeads);
            }

        } catch (error) {
            console.error("Failed to fetch GE Leads:", error);
        }
        setIsLoading(false);
    };

    const handleAddComment = async ({ comment, image }) => {
        if (!comment.trim() && !image) return;

        setIsPosting(true);
        const payload = {
            comment: comment,
            user: operatorData.Inits,
            dateTime: new Date(),
            image: image,  // Include the image in the payload
            pdf: null
        };

        try {
            const response = await fetch(
                `https://spar.connectplus.parts/api/salesassist/cplusassist/comms/${selectedGELead._id}/comments`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                }
            );

            if (response.ok) {
                // Transform the payload to match the expected UI structure
                const uiPayload = {
                    Comment: payload.comment,
                    User: payload.user,
                    DateTime: payload.dateTime,
                    Image: payload.image,  // Include the image in the UI payload
                    _id: Date.now()
                };

                setSelectedGELead(prev => ({
                    ...prev,
                    Comments: [...(prev.Comments || []), uiPayload]
                }));
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsPosting(false);
        }
    };

    const handleLeadSelect = (lead) => {
        setSelectedGELead(lead);
        // Reset the new comment field
        setNewComment('');
        // Set the comments from the selected lead
        if (lead && Array.isArray(lead.Comments)) {
            setCurrentComments(lead.Comments);
        } else {
            setCurrentComments([]);
        }
    };


    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 170px)' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="pb-[100px]" style={{ height: 'calc(100vh - 170px)', overflow: 'none', }}>
            {/* <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    {operatorData.Status !== 'sales' && (
                        <Grid item>
                            <Switch
                                checked={geState}
                                onChange={(e) => setGEState(e.target.checked)}
                                name="toggleGEState"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            <Typography style={{ color: 'white' }}>DASH</Typography>
                        </Grid>
                    )}

                    <Grid item>
                        <Button onClick={() => setShowAddGELeadModal(true)}>NEW LEAD</Button>
                    </Grid>
                </Grid>
            </div> */}

            <div style={{ display: 'flex', width: '100%', maxHeight: 'calc(100vh - 170px)', overflow: 'hidden', }}>
                <div style={{ width: selectedGELead ? '67%' : '100%', transition: 'width 0.3s', height: 'calc(100vh - 170px)', overflow: 'scroll' }}>
                    <GELeadData
                        geleads={getFilteredLeads()}  // Use the filtered leads here
                        setGELeads={setGELeads}
                        operatorData={operatorData}
                        token={token}
                        setSelectedGELead={handleLeadSelect}
                        selectedGELead={selectedGELead}
                    />
                </div>

                <CommentModalGE
                    selectedGELead={selectedGELead}
                    setSelectedGELead={setSelectedGELead}
                    addComment={handleAddComment}
                    isPosting={isPosting}
                    newComment={newComment}
                    setNewComment={setNewComment}
                    user={operatorData.Inits}  // Add this line
                />

            </div>

            <GELeadNewModal
                open={showAddGELeadModal}
                onClose={() => setShowAddGELeadModal(false)}
                operatorData={operatorData}
                token={token}
                geleads={geleads}
                setGELeads={setGELeads}
            />
            {(operatorData?.Status === 'admin' || operatorData?.Status === 'rm') && (
                <Fab
                    color="default"
                    aria-label="filter"
                    onClick={handleFilterClick}
                    style={{
                        position: 'fixed',
                        bottom: '140px',  // Position it above both buttons
                        right: '20px',
                        zIndex: 1000,
                        backgroundColor: (selectedBranches.length > 0 || selectedStatuses.length > 0) ? '#4caf50' : '#f5f5f5',
                        color: (selectedBranches.length > 0 || selectedStatuses.length > 0) ? 'white' : '#757575'
                    }}
                >
                    <FilterListIcon />
                </Fab>
            )}
            <Fab
                color="secondary"
                aria-label="refresh"
                onClick={() => fetchData()}
                disabled={isLoading}
                style={{
                    position: 'fixed',
                    bottom: '80px',
                    right: '20px',
                    zIndex: 1000,
                    backgroundColor: isLoading ? '#b39ddb' : '#9c27b0',
                    color: 'white'
                }}
            >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : <RefreshIcon />}
            </Fab>
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setShowAddGELeadModal(true)}
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 1000,
                    backgroundColor: '#1976d2', // Adjust to match your theme
                    color: 'white'
                }}
            >
                <AddIcon />
            </Fab>
            <Menu
                anchorEl={filterAnchorEl}
                open={Boolean(filterAnchorEl)}
                onClose={handleFilterClose}
                PaperProps={{
                    style: {
                        maxHeight: 300,
                        width: 250,
                    }
                }}
            >
                <Typography variant="subtitle1" style={{ padding: '8px 16px', fontWeight: 'bold' }}>
                    Filter By Branch
                </Typography>
                {availableBranches.map((branch) => (
                    <MenuItem key={branch} onClick={() => handleBranchFilterChange(branch)}>
                        <Checkbox checked={selectedBranches.includes(branch)} />
                        <ListItemText primary={branch} />
                    </MenuItem>
                ))}

                <Divider style={{ margin: '8px 0' }} />

                <Typography variant="subtitle1" style={{ padding: '8px 16px', fontWeight: 'bold' }}>
                    Filter By Status
                </Typography>
                {['Pending', 'In Progress', 'Finished', 'Archived'].map((status) => (
                    <MenuItem key={status} onClick={() => handleStatusFilterChange(status)}>
                        <Checkbox checked={selectedStatuses.includes(status)} />
                        <ListItemText primary={status} />
                    </MenuItem>
                ))}

                <Divider style={{ margin: '8px 0' }} />

                <MenuItem onClick={clearFilters}>
                    <Typography style={{ color: '#f44336' }}>Clear All Filters</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default GELeadContainer;