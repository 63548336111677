// import { SickDayEditModal } from '../pages/tma/SickDays'
// import { HolidayEditModal } from '../pages/tma/Holidays'
import StaffEditModal from '../modals/StaffEditModal'
import WarningModal from '../modals/WarningModal'
import { ShiftEditModal } from '../modals/ShiftEditModal'
import HolidayRequestModal from '../modals/HolidayRequestModal'
import HolidayReviewModal from '../modals/HolidayReviewModal'
import ShiftPatternModal from '../modals/ShiftPatternModal'
import AdminHolidayBookingModal from '../modals/AdminHolidayBookingModal'
import StaffAddModal from '../modals/StaffAddModal'
import CreateTicketModal from '../modals/CreateTicketModal'
import PromotionUploadModal from '../modals/PromotionUploadModal'
// import UpwModal from '../components/UpwModal'

const modals = {
  staffEditModal: StaffEditModal,
  staffAddModal: StaffAddModal,
  // sickDayEditModal: SickDayEditModal,
  // holidayEditModal: HolidayEditModal,
  warningModal: WarningModal,
  shiftEditModal: ShiftEditModal,
  holidayRequestModal: HolidayRequestModal,
  holidayReviewModal: HolidayReviewModal,
  shiftPatternModal: ShiftPatternModal,
  // upwModal: UpwModal,
  adminHolidayBookingModal: AdminHolidayBookingModal,
  createTicketModal: CreateTicketModal,
  promotionUploadModal: PromotionUploadModal
}

export default modals
