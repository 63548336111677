'use client'

import clsx from 'clsx'
import Button from '../Button/Button'
import s from './MultiButton.module.scss'

const MultiButton = ({ value, options, onChange }) => {
  return (
    <div className={s.container}>
      {
        options.map((o, ind) => (
          <Button
            key={ind}
            variant="rounded"
            onClick={() => onChange(o.value)}
            disabled={value === o.value}
            className={clsx(
              s.button,
              value === o.value && s.active
            )}
          >
            {o.label}
          </Button>
        ))
      }
    </div>
  )
}

export default MultiButton