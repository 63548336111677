import React, { useState } from 'react';
import { Pie, Bar } from 'react-chartjs-2';  // Import Bar along with Pie
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Chart, ArcElement, Tooltip, Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatCurrency } from '../utils';

Chart.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

const SalesPieChart = ({ salesData }) => {
  const [selectedOption, setSelectedOption] = useState('TotalGoods');
  const [chartType, setChartType] = useState('Pie');  // New state for chart type
  const data = salesData;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          padding: 10,
          boxWidth: 40,
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        color: '#fff',
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex] + '\n' + (selectedOption == 'DocumentCount' ? value : formatCurrency(value));
        },
        font: {
          weight: 'bold',
        },
        textAlign: 'center',
        anchor: 'center',
      },
    },
  };

  const calculateData = () => {
    const labels = data.map(d => d.Name || d.Inits);
    const values = data.map(d => {
      switch (selectedOption) {
        case 'TotalGoods':
          return d.TotalGoodsValue;
        case 'DocumentCount':
          return d.DocumentCount;
        case 'Credits':
          return Math.abs(d.Credits);
        case 'Margin':
          return d.MarginValue;
        case 'Nett':
          return d.TotalGoodsValue - Math.abs(d.Credits);
        default:
          return 0;
      }
    });

    return {
      labels,
      datasets: [{
        data: values,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      }],
    };
  };

  const renderChart = () => {
    const chartData = calculateData();
    if (chartType === 'Pie') {
      return <Pie data={chartData} options={options} />;
    } else if (chartType === 'Bar') {
      return <Bar data={chartData} options={options} />;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', color: 'white', justifyContent: 'space-around' }}>
      <div style={{ width: '30%' }}>
        <h3>Choose Type:</h3>
        <div>
          <input type="radio" value="TotalGoods" name="dataType" onChange={() => setSelectedOption('TotalGoods')} checked={selectedOption === 'TotalGoods'} /> Sales<br />
          <input type="radio" value="DocumentCount" name="dataType" onChange={() => setSelectedOption('DocumentCount')} checked={selectedOption === 'DocumentCount'} /> Invoices<br />
          <input type="radio" value="Credits" name="dataType" onChange={() => setSelectedOption('Credits')} checked={selectedOption === 'Credits'} /> Credits<br />
          <input type="radio" value="Margin" name="dataType" onChange={() => setSelectedOption('Margin')} checked={selectedOption === 'Margin'} /> Margin<br />
          <input type="radio" value="Nett" name="dataType" onChange={() => setSelectedOption('Nett')} checked={selectedOption === 'Nett'} /> Nett <br />
        </div>
        <h3>Chart Type:</h3>
        <div>
          <input type="radio" value="Pie" name="chartType" onChange={() => setChartType('Pie')} checked={chartType === 'Pie'} /> Pie<br />
          <input type="radio" value="Bar" name="chartType" onChange={() => setChartType('Bar')} checked={chartType === 'Bar'} /> Bar<br />
        </div>
      </div>
      <div style={{
        width: '70%',
        display: 'flex',
        height: '285px',
        paddingTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {renderChart()}
      </div>
    </div>
  );
};

export default SalesPieChart;
