import React, { useContext, useEffect, useState } from 'react'
import OverviewTab from '../tabs/OverviewTab'
import { GlobalContext } from '../../context/GlobalContext'
import moment from 'moment-timezone'
import { Box, CardContent, LinearProgress } from '@mui/material'
import SalesProgress from '../SalesProgress'
import QuoteConversionChart from '../QuoteConversionChart'
import styled from '@emotion/styled'
import Switch from '@mui/material/Switch'

// Styled Custom Switch
const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    'width': 42,
    'height': 26,
    'padding': 0,
    '& .MuiSwitch-switchBase': {
      'padding': 0,
      'margin': 2,
      'transitionDuration': '300ms',
      '&.Mui-checked': {
        'transform': 'translateX(16px)',
        'color': '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
)

function SalesShow() {
  const [progress, setProgress] = useState(100)
  const { operatorData, username, token, setOperatorData } = useContext(GlobalContext)
  const [aggregateData, setAggregateData] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(moment().toISOString())

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Aggregate Data
        if (operatorData.Inits) {
          const aggregateResponse = await fetch(
            `https://spar.connectplus.parts/api/salesassist/cplusassist/aggregateData/${operatorData.Inits}`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )
          if (!aggregateResponse.ok) {
            throw new Error(`HTTP error! status: ${aggregateResponse.status}`)
          }
          const aggData = await aggregateResponse.json()
          console.log('Aggregate Data:', aggData)
          setAggregateData(aggData)
        }
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchData()

    const fetchOpData = async () => {
      try {
        // Fetch Operator Data
        const operatorResponse = await fetch(
          `https://spar.connectplus.parts/api/salesassist/cplusassist/getByUsername/${username}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        if (!operatorResponse.ok) {
          throw new Error(`HTTP error! status: ${operatorResponse.status}`)
        }
        const operatorData = await operatorResponse.json()
        setOperatorData(operatorData)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchOpData()

    const interval = setInterval(() => {
      fetchData()
      fetchOpData()
      setLastUpdated(moment().toISOString())
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [username, token, operatorData?.Inits])

  // Function to format date: show only time if today, else show date and time
  const formatDateTime = (dateTime) => {
    const momentDate = moment(dateTime)
    if (!momentDate.isValid()) {
      console.error('Invalid date:', dateTime)
      return 'Invalid date'
    }
    return momentDate.isSame(moment(), 'day') ? momentDate.format('HH:mm') : momentDate.format('ddd DD/MM/YYYY HH:mm')
  }

  return (
    <div style={{ margin: 10 }}>
      <div
        style={{
          margin: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <img src="https://spar.connectplus.parts/api/public/images/Logo.png" alt="Logo" style={{ height: '65px' }} />
      </div>

      {aggregateData ? (
        <div style={{}}>
          <Box style={{ width: '100%' }}>
            <CardContent style={{ padding: 0 }}>
              <SalesProgress sales={aggregateData.GrossSales} target={operatorData.SalesTarget} />
              <br></br>
              <QuoteConversionChart
                quotesConverted={aggregateData?.QuotesConverted}
                quotesOutstanding={aggregateData?.QuotesOutstanding}
              />
            </CardContent>
          </Box>
          {/* <Box variant="outlined" style={{ marginBottom: 5 }}>
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  marginBottom: 15,
                  width: '100%',
                  flex: 1,
                  backgroundColor: '#21242c',
                  borderRadius: 15,
                  padding: 5,
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 14
                }}
              >
                📆 Last updated: {formatDateTime(lastUpdated)}
              </div>
              <div
                style={{
                  width: '100%',
                  flex: 1,
                  backgroundColor: '#21242c',
                  borderRadius: 15,
                  padding: 5,
                  textAlign: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 14
                }}
              >
                📆 Last Inv: {aggregateData && formatDateTime(aggregateData.LastSale.DateTime)} (£
                {aggregateData.LastSale.Goods ? aggregateData.LastSale.Goods.toFixed(2) : 0})
              </div>
            </CardContent>
          </Box> */}
        </div>
      ) : (
        <p style={{ color: 'white' }}>Loading sales or no data available yet for {operatorData?.Name}...</p>
      )}
      <LinearProgress variant="determinate" value={progress} />
    </div>
  )
}

export default SalesShow
