import React, { useState } from 'react';
import SalesForecast from './SalesForecast';
import SalesForecastChart from './SalesForecastChart';

const SalesForecastContainer = ({ currentSales, currentMargin, salesTarget, marginTarget, isToday }) => {
    const [viewMode, setViewMode] = useState('bar'); // 'bar' or 'chart'

    const styles = {
        container: {
            cursor: 'pointer',
            position: 'relative',
        }
    };

    const handleClick = () => {
        setViewMode(prevMode => prevMode === 'bar' ? 'chart' : 'bar');
    };

    return (
        // <div style={styles.container} onClick={handleClick}>
        <div style={styles.container} >

            {viewMode === 'bar' ? (
                <SalesForecast
                    currentSales={currentSales}
                    currentMargin={currentMargin}
                    salesTarget={salesTarget}
                    marginTarget={marginTarget}
                    isToday={isToday}
                />
            ) : (
                <SalesForecastChart
                    currentSales={currentSales}
                    currentMargin={currentMargin}
                    salesTarget={salesTarget}
                    marginTarget={marginTarget}
                />
            )}
        </div>
    );
};

export default SalesForecastContainer;