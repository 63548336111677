import React, { useContext, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ChevronLeft, ChevronRight } from 'lucide-react'

// Component imports
import BranchesTable from '../admin/BranchesTable'
import StaffTable from '../admin/StaffTable'
import About from './About'
import { GlobalContext } from '../../context/GlobalContext'
import Regions from '../admin/Regions'
import Messages from '../admin/Messages'
import AssignShifts from '../admin/AssignShifts'
import HolidayAllocation from '../admin/HolidayAllocation'
import ActiveStaff from '../admin/ActiveStaff'
import HolidayPlanner from '../admin/HolidayPlanner'
import HolidayLayout from '../admin/HolidayLayout'
import HolidayRequests from '../admin/HolidayRequests'
import MyHolidays from '../MyHolidays'
import Staff from '../newholidays/Staff'
import Calendar from '../newholidays/Calendar'
import Holidays from '../newholidays/Holidays'
import Shifts from '../newholidays/Shifts'
import SickDays from '../SickDays'
import Rotas from '../admin/Rotas'
import Promotions from '../newholidays/Promotions'

// Tab configurations for different user roles
const TAB_CONFIGS = {
  admin: [
    { label: 'Branch', component: <BranchesTable /> },
    { label: 'Staff', component: <Staff /> },
    { label: 'Calendar', component: <Calendar /> },
    { label: 'Holidays', component: <Holidays /> },
    { label: 'Shifts', component: <Shifts /> },
    { label: 'Rotas', component: <Rotas /> },
    { label: 'Regions', component: <Regions /> },
    { label: 'Messages', component: <Messages /> },
    { label: 'Sick Days', component: <SickDays /> },
    { label: 'Active Staff', component: <ActiveStaff /> },
    { label: 'Promotions', component: <Promotions /> },
    { label: 'About', component: <About /> }
  ],
  hr: [
    { label: 'Staff', component: <Staff /> },
    { label: 'Calendar', component: <Calendar /> },
    { label: 'Holidays', component: <Holidays /> },
    { label: 'Shifts', component: <Shifts /> },
    { label: 'Rotas', component: <Rotas /> },
    { label: 'Messages', component: <Messages /> },
    { label: 'Sick Days', component: <SickDays /> },
    { label: 'Active Staff', component: <ActiveStaff /> },
    { label: 'Promotions', component: <Promotions /> },
    { label: 'About', component: <About /> }
  ],
  hr: [
    { label: 'Staff', component: <Staff /> },
    { label: 'Calendar', component: <Calendar /> },
    { label: 'Holidays', component: <Holidays /> },
    { label: 'Shifts', component: <Shifts /> },
    { label: 'Rotas', component: <Rotas /> },
    { label: 'Messages', component: <Messages /> },
    { label: 'Sick Days', component: <SickDays /> },
    { label: 'Active Staff', component: <ActiveStaff /> },
    { label: 'About', component: <About /> }
  ],
  rm: [
    { label: 'Holidays', component: <Holidays /> },
    { label: 'Calendar', component: <Calendar /> },
    { label: 'Shifts', component: <Shifts /> },
    { label: 'About', component: <About /> }
  ],
  bm: [
    { label: 'Holidays', component: <Holidays /> },
    { label: 'Calendar', component: <Calendar /> },
    { label: 'About', component: <About /> }
  ],
  sales: [{ label: 'About', component: <About /> }]
}

const createAppTheme = (isCollapsed) =>
  createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            'color': '#fff',
            'minWidth': isCollapsed ? '40px' : '120px',
            'maxWidth': '120px',
            'padding': isCollapsed ? '6px' : '12px 16px',
            'transition': 'all 0.3s ease-in-out',
            'overflow': 'hidden',
            'whiteSpace': 'nowrap',
            'opacity': 1,
            '&.Mui-selected': {
              color: '#fff'
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          scrollButtons: {
            display: 'none' // Hide scroll buttons
          }
        }
      }
    },
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(',')
    }
  })

function SettingsTab() {
  const { token, operatorData } = useContext(GlobalContext)
  const [value, setValue] = useState(0)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const theme = createAppTheme(isCollapsed)
  const tabs = TAB_CONFIGS[operatorData?.Status] || [
    { label: 'My Holidays', component: (token) => <MyHolidays token={token} /> },
    { label: 'ABOUT', component: <About /> }
  ]

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          height: '80vh',
          width: '100%',
          color: '#fff'
        }}
      >
        <Box
          sx={{
            width: isCollapsed ? '50px' : '150px',
            transition: 'width 0.3s ease-in-out',
            borderRight: 1,
            borderColor: 'divider',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flex: 'none'
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              padding: '4px'
            }}
          >
            <MenuToggle isOpen={!isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)} />
          </Box>

          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={{
              '& .MuiTabs-indicator': {
                transition: 'all 0.3s ease-in-out'
              },
              '& .MuiTab-root': {
                transition: 'all 0.3s ease-in-out',
                opacity: 1
              }
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  transform: isCollapsed ? 'translateX(-80px)' : 'translateX(0)',
                  transition: 'transform 0.3s ease-in-out'
                }}
              />
            ))}
          </Tabs>
        </Box>

        <Box
          sx={{
            flex: 1,
            padding: '20px',
            overflowY: 'auto',
            position: 'relative'
          }}
        >
          {token &&
            tabs[value] &&
            (typeof tabs[value].component === 'function' ? tabs[value].component(token) : tabs[value].component)}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

const MenuToggle = ({ isOpen, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex justify-center items-center w-8 h-8 bg-gray-100 rounded-full shadow-sm transition-all duration-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
      aria-label={isOpen ? 'Close menu' : 'Open menu'}
    >
      {isOpen ? <ChevronLeft className="w-5 h-5 text-gray-600" /> : <ChevronRight className="w-5 h-5 text-gray-600" />}
    </button>
  )
}

export default SettingsTab
