import React, { useContext, useMemo } from 'react';
import { Card, Typography } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { GlobalContext } from '../../context/GlobalContext';

const MANUAL_CLOCK_ID = '33f9a591-466a-471d-9bf6-44d326d796a5';
const MACHINE_CLOCK_ID = 'de8c4712-f9c9-4a32-a129-854257541aa6';

const HRCharts = ({
    clockingRecords = [],
    holidayData = [],
    sickData = [],
    lateClocks,
    earlyClockOuts
}) => {
    const { analyzeClockingRecordsForMonth } = useContext(GlobalContext);

    // Process attendance data using the props passed down
    const attendanceStats = useMemo(() => {
        const total = clockingRecords.length;
        return {
            lates: lateClocks || 0,
            earlies: earlyClockOuts || 0,
            onTime: total - (lateClocks || 0) - (earlyClockOuts || 0)
        };
    }, [clockingRecords.length, lateClocks, earlyClockOuts]);

    // Attendance Distribution
    const attendanceData = [
        { name: 'On Time', value: attendanceStats.onTime, color: '#4CAF50' },
        { name: 'Late In', value: attendanceStats.lates, color: '#FFA726' },
        { name: 'Early Out', value: attendanceStats.earlies, color: '#EF5350' }
    ].filter(item => item.value > 0);

    // Status Distribution
    const workingDays = clockingRecords?.length || 0;
    const sickDays = sickData?.length || 0;
    const holidayDays = holidayData?.length || 0;

    const statusData = [
        { name: 'Working', value: workingDays, color: '#4CAF50' },
        { name: 'Sick', value: sickDays, color: '#EF5350' },
        { name: 'Holiday', value: holidayDays, color: '#42A5F5' }
    ].filter(item => item.value > 0);

    // Clock Type Distribution
    const clockTypeData = [
        {
            name: 'Machine Clocks',
            value: clockingRecords?.filter(record => record.AttendanceTypeID === MACHINE_CLOCK_ID).length || 0,
            color: '#2196F3'
        },
        {
            name: 'Manual Clocks',
            value: clockingRecords?.filter(record => record.AttendanceTypeID === MANUAL_CLOCK_ID).length || 0,
            color: '#FF9800'
        }
    ].filter(item => item.value > 0);

    const ChartCard = ({ title, data }) => (
        <Card sx={{ bgcolor: '#1E1E1E', p: 3, height: '400px' }}>
            <Typography variant="h6" color="white" gutterBottom sx={{ fontSize: '1rem' }}>
                {title}
            </Typography>
            <div style={{ width: '100%', height: '300px' }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            label={({ value }) => value}
                        >
                            {data.map((entry, index) => (
                                <Cell key={index} fill={entry.color} />
                            ))}
                        </Pie>
                        <Tooltip
                            contentStyle={{
                                backgroundColor: '#1E1E1E',
                                border: '1px solid #333',
                                borderRadius: '4px',
                                padding: '8px'
                            }}
                        />
                        <Legend
                            verticalAlign="bottom"
                            height={36}
                            formatter={(value) => (
                                <span style={{ color: '#fff' }}>{value}</span>
                            )}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </Card>
    );

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}>
            <ChartCard title="Attendance Distribution" data={attendanceData} />
            <ChartCard title="Status Distribution" data={statusData} />
            <ChartCard title="Clock Type Distribution" data={clockTypeData} />
        </div>
    );
};

export default HRCharts;