import React, { useContext } from 'react'
import BranchListTable from './BranchListTable'
import BranchListTableV2 from './BranchListTableV2'
import BranchDetails from './BranchDetails'
import BranchHolder from './BranchHolder'
import { GlobalContext } from '../../context/GlobalContext'

function BranchList({ drillDownBranch, setDrillDownBranch, progress, setLastUpdated, setProgress }) {
  const { operatorData, token, setToken, setIsLoggedIn } = useContext(GlobalContext)

  const showBranchListTableV2 = operatorData.Username === 'spartan_admin'

  return (
    !drillDownBranch ? (

      <BranchListTable
        drillDownBranch={drillDownBranch}
        setDrillDownBranch={setDrillDownBranch}
        progress={progress}
        setLastUpdated={setLastUpdated}
        setProgress={setProgress}
        operatorData={operatorData}
      />

    ) : (
      <BranchHolder
        drillDownBranch={drillDownBranch}
        setDrillDownBranch={setDrillDownBranch}
        progress={progress}
        setLastUpdated={setLastUpdated}
        setProgress={setProgress}
        operatorData={operatorData}
        token={token}
      />
    )
  )
}

export default BranchList
