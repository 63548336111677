import React, { useMemo } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import moment from 'moment-timezone'

const AttendanceLeagueTable = ({ clockingData, shifts, shiftAssignments, isLoading }) => {
  const branchScores = useMemo(() => {
    if (!clockingData?.length || !shifts?.length || !shiftAssignments?.length) return []

    const branchStats = {}

    clockingData.forEach((record) => {
      const branch = record.StaffBranch
      if (!branchStats[branch]) {
        branchStats[branch] = {
          totalShifts: 0,
          onTimeClocks: 0,
          totalHoursExpected: 0,
          totalHoursWorked: 0,
          missedShifts: 0
        }
      }

      const assignment = shiftAssignments.find((a) => a.staffId === record.StaffID)
      const dayOfWeek = moment(record.ClockInTime).isoWeekday()
      const shift = assignment ? shifts.find((s) => s._id === assignment.shifts[dayOfWeek]) : null

      if (shift) {
        branchStats[branch].totalShifts++
        const shiftStart = moment(`${record.ClockInTime.split('T')[0]}T${shift.Start}`)
        const shiftEnd = moment(`${record.ClockInTime.split('T')[0]}T${shift.Finish}`)
        if (shiftEnd.isBefore(shiftStart)) shiftEnd.add(1, 'day')

        const clockIn = moment(record.ClockInTime)
        const clockOut = record.ClockOutTime ? moment(record.ClockOutTime) : null

        // Check if clock-in is within grace period
        if (clockIn.isSameOrBefore(shiftStart.clone().add(15, 'minutes'))) {
          branchStats[branch].onTimeClocks++
        }

        // Calculate hours
        branchStats[branch].totalHoursExpected += shiftEnd.diff(shiftStart, 'hours', true)
        if (clockOut) {
          branchStats[branch].totalHoursWorked += clockOut.diff(clockIn, 'hours', true)
        }
      } else {
        branchStats[branch].missedShifts++
      }
    })

    // Calculate scores and create sorted array
    return Object.entries(branchStats)
      .map(([branch, stats]) => ({
        branch,
        score: (stats.onTimeClocks / stats.totalShifts) * 100,
        ...stats
      }))
      .sort((a, b) => b.score - a.score)
  }, [clockingData, shifts, shiftAssignments])

  if (isLoading) {
    return <div style={{ color: 'white' }}>Loading...</div>
  }

  return (
    <TableContainer style={{ height: 'calc(100vh - 350px)', overflow: 'scroll' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: 'white' }}>Branch</TableCell>
            <TableCell align="right" sx={{ color: 'white' }}>
              Score
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {branchScores.map((branch) => (
            <Tooltip
              key={branch.branch}
              title={
                <div>
                  <div>Total Shifts: {branch.totalShifts}</div>
                  <div>On-Time: {branch.onTimeClocks}</div>
                  <div>Expected Hours: {branch.totalHoursExpected.toFixed(1)}</div>
                  <div>Worked Hours: {branch.totalHoursWorked.toFixed(1)}</div>
                  <div>Missed Shifts: {branch.missedShifts}</div>
                </div>
              }
            >
              <TableRow hover>
                <TableCell sx={{ color: 'white' }}>{branch.branch}</TableCell>
                <TableCell align="right" sx={{ color: 'white' }}>
                  {branch.score.toFixed(1)}%
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AttendanceLeagueTable
