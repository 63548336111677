import React from 'react';
import { Tooltip } from '@mui/material';
import moment from 'moment';

const StatsTooltip = ({ details = [], children, type = 'infringement' }) => {
    if (!details || details.length === 0) return children;

    const tooltipContent = (
        <div style={{ maxHeight: '400px', overflow: 'auto' }}>
            {details.map((detail, index) => {
                if (type === 'infringement') {
                    return (
                        <div key={index} style={{ marginBottom: '4px', whiteSpace: 'nowrap' }}>
                            [{detail.branch || '?'}] {detail.name} ({moment(detail.date).format('DD/MM/YYYY')})
                        </div>
                    );
                }
                if (type === 'holiday' || type === 'sick') {
                    const startDate = moment(detail.StartDate).format('DD/MM/YYYY');
                    const endDate = moment(detail.EndDate).format('DD/MM/YYYY');
                    return (
                        <div key={index} style={{ marginBottom: '4px', whiteSpace: 'nowrap' }}>
                            [{detail.Branch || '?'}] {detail.StaffName} ({startDate} - {endDate})
                        </div>
                    );
                }
            })}
        </div>
    );

    return (
        <Tooltip
            title={tooltipContent}
            placement="bottom-start"
            arrow
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        bgcolor: '#1E1E1E',
                        color: 'white',
                        border: '1px solid #333',
                        maxWidth: 'none',
                        padding: '8px'
                    }
                }
            }}
        >
            <span>{children}</span>
        </Tooltip>
    );
};

export default StatsTooltip;