import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import moment from 'moment-timezone';

const ClocklogTable = ({ data = [], loading, onRowClick }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [collapsedDays, setCollapsedDays] = useState(() => {
        // Initialize with all dates collapsed
        return new Set(data.map(day => day.date));
    });
    const sortedData = [...data].sort((a, b) => moment(b.date).diff(moment(a.date)));

    const handleRowClick = (detail, day) => {
        setSelectedRow(`${detail.staffId}-${day.date}`);
        onRowClick(detail, day);
    };

    const toggleDayCollapse = (date) => {
        setCollapsedDays(prev => {
            const newSet = new Set(prev);
            if (newSet.has(date)) {
                newSet.delete(date);
            } else {
                newSet.add(date);
            }
            return newSet;
        });
    };

    return (
        <Box sx={{ overflow: 'auto', height: '100%', width: '100%', }}>
            {sortedData.map((day) => {
                const isCollapsed = collapsedDays.has(day.date);
                return (
                    <Paper
                        key={day.date}
                        elevation={3}
                        sx={{
                            mb: 2,
                            mx: 0,
                            bgcolor: '#1E1E1E',
                            borderRadius: 0,
                            overflow: 'hidden',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                        }}
                    >
                        <Box
                            onClick={() => toggleDayCollapse(day.date)}
                            sx={{
                                p: 3,
                                background: 'linear-gradient(90deg, #1A237E 0%, #0D47A1 100%)',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                                transition: 'background 0.2s ease',
                                '&:hover': {
                                    background: 'linear-gradient(90deg, #1A237E 0%, #1565C0 100%)',
                                }
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <IconButton
                                    size="small"
                                    sx={{ color: 'white', p: 0 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleDayCollapse(day.date);
                                    }}
                                >
                                    {isCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                                </IconButton>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: 'white',
                                        fontWeight: 600,
                                        textShadow: '0px 2px 4px rgba(0,0,0,0.3)',
                                        paddingLeft: 1,
                                        paddingRight: 1,
                                    }}
                                >
                                    {moment(day.date).format('DD MMMM YYYY - dddd')}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                paddingLeft: 1,
                                marginRight: 1,
                                gap: 3,
                                '& > div': {
                                    px: 3,
                                    py: 1,
                                    borderRadius: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                }
                            }}>
                                <Box sx={{ bgcolor: 'rgba(76, 175, 80, 0.2)' }}>
                                    <Typography sx={{ color: '#4CAF50', fontWeight: 600 }}>
                                        OK: {day.totalOK}
                                    </Typography>
                                </Box>
                                <Box sx={{ bgcolor: 'rgba(244, 67, 54, 0.2)' }}>
                                    <Typography sx={{ color: '#f44336', fontWeight: 600 }}>
                                        Infringements: {day.totalInfr}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{
                            maxHeight: isCollapsed ? 0 : '500px',
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease-in-out',
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: '#1E1E1E',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#333',
                                borderRadius: '4px',
                            }
                        }}>
                            {day.details?.map((detail, idx) => {
                                const rowId = `${detail.staffId}-${day.date}`;
                                const isSelected = selectedRow === rowId;
                                return (
                                    <Box
                                        key={`${detail.staffId}-${idx}`}
                                        onClick={() => handleRowClick(detail, day)}
                                        sx={{
                                            p: 3,
                                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                                            transition: 'all 0.2s ease',
                                            position: 'relative',
                                            '&:hover': {
                                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                cursor: 'pointer',
                                                transform: 'translateX(4px)'
                                            },
                                            ...(isSelected && {
                                                bgcolor: 'rgba(25, 118, 210, 0.2)',
                                                borderLeft: '4px solid #1976d2',
                                                '&:hover': {
                                                    bgcolor: 'rgba(25, 118, 210, 0.25)',
                                                    transform: 'translateX(4px)'
                                                }
                                            }),
                                            ...(detail.status === 'INFRINGEMENT' && {
                                                backgroundColor: isSelected
                                                    ? 'rgba(244, 67, 54, 0.2)'
                                                    : 'rgba(244, 67, 54, 0.1)',
                                                ...(isSelected && {
                                                    borderLeft: '4px solid #f44336'
                                                })
                                            })
                                        }}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mb: 2,
                                            alignItems: 'center',
                                            marginLeft: 1,
                                            marginRight: 1,
                                        }}>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: 'white',
                                                    fontWeight: isSelected ? 600 : 500
                                                }}
                                            >
                                                {detail.staffName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                            </Typography>

                                            <Typography sx={{
                                                color: 'rgba(255, 255, 255, 0.7)',
                                                fontFamily: 'monospace',
                                                fontSize: '0.9rem'
                                            }}>
                                                {detail.clockInTime} - {detail.clockOutTime || 'No clock out'}
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginLeft: 1,
                                            marginRight: 1,
                                        }}>
                                            <Typography
                                                sx={{
                                                    color: detail.status === 'OK' ? '#4CAF50' : '#f44336',
                                                    fontWeight: isSelected ? 600 : 500,
                                                    fontSize: '0.9rem'
                                                }}
                                            >
                                                {detail.reason || detail.status}
                                            </Typography>
                                            {detail.totalWorkedMinutes && (
                                                <Typography sx={{
                                                    color: 'rgba(255, 255, 255, 0.7)',
                                                    fontSize: '0.9rem'
                                                }}>
                                                    Time worked: {detail.totalWorkedMinutes}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Paper>
                );
            })}
        </Box>
    );
};

export default ClocklogTable;