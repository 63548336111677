import React from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Chip,
    Paper
} from '@mui/material';

const formatCurrency = (value) =>
    `£${(value || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const CustomerTable = ({ data, onViewComments }) => {
    if (!data) return null;

    return (
        <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto', backgroundColor: '#1a1a1a', borderRadius: 2 }}>
            <Table sx={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={headerStyle}></TableCell>
                        <TableCell sx={headerStyleCenter}>Code</TableCell>
                        <TableCell sx={headerStyle}>Name</TableCell>
                        <TableCell sx={headerStyleRight}>30d Spend</TableCell>
                        <TableCell sx={headerStyleRight}>Today</TableCell>
                        <TableCell sx={headerStyleRight}>Margin</TableCell>
                        <TableCell sx={headerStyleRight}>Credits</TableCell>
                        <TableCell sx={headerStyleRight}>Quotes Count</TableCell>
                        <TableCell sx={headerStyleRight}>Quotes Value</TableCell>
                        <TableCell sx={headerStyleCenter}>Comments</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((customer, index) => {
                        if (!customer) {
                            console.error('No customer data');
                            return null;
                        }

                        const hasSales = (customer.totalSales || 0) > 0;
                        const hasQuotes = (customer.quotesCount || 0) > 0 || (customer.quotesValue || 0) > 0;
                        const status = hasSales ? 'active' : hasQuotes ? 'pending' : 'inactive';
                        const rowKey = customer.customerCode || `row-${index}`;

                        return (
                            <TableRow
                                key={rowKey}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                    },
                                    position: 'relative',
                                    backgroundColor: status === 'active' ? 'rgba(76, 175, 80, 0.8)' :
                                        status === 'pending' ? 'rgba(255, 152, 0, 0.8)' :
                                            'rgba(244, 67, 54, 0.8)',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: '4px',
                                        backgroundColor: status === 'active' ? '#4caf50' :
                                            status === 'pending' ? '#ff9800' : '#f44336',
                                        opacity: 0.9
                                    }
                                }}
                            >
                                <TableCell sx={cellStyle}>
                                    <Chip
                                        label={customer.customerCode}
                                        size="small"
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                            color: '#fff',
                                            fontWeight: 500,
                                            minWidth: '80px'
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ ...cellStyle, maxWidth: '200px' }}>{customer.customerName}</TableCell>
                                <TableCell sx={cellStyleRight}>{formatCurrency(customer.thirtyDaySales)}</TableCell>
                                <TableCell sx={cellStyleRight}>{formatCurrency(customer.totalSales)}</TableCell>
                                <TableCell sx={cellStyleRight}>{formatCurrency(customer.totalMargin)}</TableCell>
                                <TableCell sx={cellStyleRight}>{formatCurrency(customer.totalCredits)}</TableCell>
                                <TableCell sx={cellStyleRight}>{customer.quotesCount || 0}</TableCell>
                                <TableCell sx={cellStyleRight}>{formatCurrency(customer.quotesValue)}</TableCell>
                                <TableCell sx={cellStyleCenter}>
                                    <Button
                                        onClick={() => onViewComments(customer)}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            minWidth: '32px',
                                            height: '24px',
                                            backgroundColor: (customer.comments?.length || 0) > 0 ?
                                                'rgba(76, 175, 80, 0.3)' : 'rgba(255, 255, 255, 0.08)',
                                            color: (customer.comments?.length || 0) > 0 ?
                                                '#fff' : '#666',
                                            '&:hover': {
                                                backgroundColor: (customer.comments?.length || 0) > 0 ?
                                                    'rgba(76, 175, 80, 0.4)' : 'rgba(255, 255, 255, 0.12)',
                                            },
                                            padding: '0px 8px',
                                        }}
                                    >
                                        {customer.comments?.length || 0}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const headerStyle = {
    backgroundColor: '#262626',
    color: 'rgba(255, 255, 255, 0.7)',
    fontWeight: 500,
    fontSize: '0.875rem',
    padding: '8px 16px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
};

const headerStyleRight = {
    ...headerStyle,
    textAlign: 'right'
};

const headerStyleCenter = {
    ...headerStyle,
    textAlign: 'center'
};

const cellStyle = {
    color: '#fff',
    padding: '8px 16px',
    fontSize: '0.875rem',
    textAlign: 'left',
    height: '48px'
};

const cellStyleRight = {
    ...cellStyle,
    textAlign: 'right'
};

const cellStyleCenter = {
    ...cellStyle,
    textAlign: 'center'
};
