import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import HourlySalesBar from '../HourlySalesBar'
import '../../App.css'
import SalesRank from '../SalesRank'
import SalesPieChart from '../SalesPieChart'
import { GlobalContext } from '../../context/GlobalContext'

function SalesTab({ setProgress, progress }) {
  const { operatorData, token } = useContext(GlobalContext)
  const [salesData, setSalesData] = useState(null)
  const [salesByHour, setSalesByHour] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(null)

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        // Fetch Quotes Data
        const salesResponse = await fetch(
          `https://spar.connectplus.parts/api/salesassist/cplusassist/salesToday/${operatorData.Branch}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        if (!salesResponse.ok) {
          throw new Error(`HTTP error! status: ${salesResponse.status}`)
        }

        const salesData = await salesResponse.json()
        console.log('Sales Data:', salesData)
        setSalesData(salesData)
      } catch (error) {
        console.error('Failed to fetch quotes data:', error)
      }
    }

    const fetchSalesByHour = async () => {
      try {
        // Fetch Quotes Data
        const byHourResponse = await fetch(
          `https://spar.connectplus.parts/api/salesassist/cplusassist/salesByHour/${operatorData.Inits}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        if (!byHourResponse.ok) {
          throw new Error(`HTTP error! status: ${byHourResponse.status}`)
        }

        const salesByHourData = await byHourResponse.json()
        console.log('Sales by Hour:', salesByHourData)
        setSalesByHour(salesByHourData)
      } catch (error) {
        console.error('Failed to fetch sales by hour data:', error)
      }
    }

    fetchSalesData()
    fetchSalesByHour()

    const interval = setInterval(() => {
      fetchSalesData()
      fetchSalesByHour()
      setLastUpdated(moment().format('ddd DD/MM/YYYY HH:mm'))
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [token])

  return (
    <div style={{ overflow: 'scroll' }}>
      <div>
        {salesByHour && (
          <div>
            <HourlySalesBar salesData={salesByHour} />
          </div>
        )}
      </div>
      {/* <div>
        {salesData && (
          <div style={{ width: '100%' }}>
            <SalesPieChart salesData={salesData.salesByInits} />
          </div>
        )}
      </div> */}
    </div>
  )
}

export default SalesTab
