import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import moment from 'moment-timezone'
import { throttle } from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete'
import HolidayIcon from '@mui/icons-material/BeachAccess'
import WorkOffIcon from '@mui/icons-material/WorkOff'
import EventIcon from '@mui/icons-material/Event'
import SickIcon from '@mui/icons-material/Sick'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import haxios from '../newholidays/lib/haxios'

const MonthYearPicker = ({
  isOpen,
  onClose,
  onConfirm,
  selectedMonth,
  selectedYear,
  setSelectedMonth,
  setSelectedYear
}) => {
  const months = moment.months()
  const years = [moment().year() - 1, moment().year(), moment().year() + 1]
  const [sickList, setSickList] = useState([])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Select Month and Year</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Month</InputLabel>
          <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
            {months.map((month, index) => (
              <MenuItem key={month} value={index}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Year</InputLabel>
          <Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          CLOSE
        </Button>
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function TandABranch({ operatorData, rmBranches, staffBranch, setStaffBranch, token, staffList }) {
  const [shiftAssignments, setShiftAssignments] = useState([])
  const [shifts, setShifts] = useState([])
  const [viewMode, setViewMode] = useState('Today')
  const [clockingInRecords, setClockingInRecords] = useState([])
  const [monthClockingRecords, setMonthClockingRecords] = useState([])
  const [fixing, setFixing] = useState(false)
  const [summaryCounts, setSummaryCounts] = useState({ goodRecords: 0, warningRecords: 0, badRecords: 0 })
  const [breaks, setBreaks] = useState({})
  const [viewType, setViewType] = useState('All')
  const [infringementCount, setInfringementCount] = useState(0)
  const [totalRecordsCount, setTotalRecordsCount] = useState(0)
  const [notDueForWorkRecords, setNotDueForWorkRecords] = useState([])
  const [holidayRecords, setHolidayRecords] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(moment().month())
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [isMonthPickerOpen, setIsMonthPickerOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sickList, setSickList] = useState([])
  const [acceptedInfringements, setAcceptedInfringements] = useState([])
  const [casualPopupOpen, setCasualPopupOpen] = useState(false)
  const [casualPopupData, setCasualPopupData] = useState({
    staffId: '',
    date: null,
    coveringFor: '',
    approvedBy: ''
  })

  const safeClockingInRecords = useMemo(() => {
    const records = viewMode === 'Month' ? monthClockingRecords : clockingInRecords
    return Array.isArray(records)
      ? records.filter((record) => record && record.StaffID) // Filter out null/undefined records and those without StaffID
      : []
  }, [viewMode, monthClockingRecords, clockingInRecords])

  const getEmployeeBreak = useCallback(
    (staffId, dayOfWeek) => {
      return breaks[staffId]?.[dayOfWeek] || 0
    },
    [breaks]
  )

  const calculateTotalHours = useCallback(
    (employee, date) => {
      const dayClockLogs = safeClockingInRecords.filter(
        (record) => record.StaffID === employee.StaffId && moment(record.ClockInTime).isSame(date, 'day')
      )
      let totalMinutes = 0

      dayClockLogs.forEach((log) => {
        const start = moment(log.ClockInTime)
        const end = log.ClockOutTime ? moment(log.ClockOutTime) : moment().endOf('day')
        totalMinutes += end.diff(start, 'minutes')
      })

      const breakMinutes = getEmployeeBreak(employee.StaffId, date.isoWeekday())
      totalMinutes -= breakMinutes

      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60
      return { hours, minutes, formatted: `${hours}h ${minutes}m` }
    },
    [safeClockingInRecords, getEmployeeBreak]
  )

  const handleOpenCasualPopup = (staffId, date) => {
    setCasualPopupData((prevData) => ({
      ...prevData,
      staffId,
      date,
      coveringFor: ''
      // Don't reset approvedBy here
    }))
    setCasualPopupOpen(true)
  }

  const handleConfirmCasual = async (updatedData) => {
    try {
      const { staffId, date, coveringFor, approvedBy } = updatedData
      const employee = staffList.find((s) => s.StaffId === staffId)
      const employeeClockLogs = safeClockingInRecords.filter(
        (record) => record.StaffID === staffId && moment(record.ClockInTime).isSame(date, 'day')
      )

      if (employeeClockLogs.length === 0) {
        alert('No clocking records found for this casual shift.')
        return
      }

      const clockId = employeeClockLogs[0].ClockID
      const minsOut = calculateTotalHours(employee, date).hours * 60 + calculateTotalHours(employee, date).minutes

      const newInfringement = {
        ClockId: clockId,
        StaffId: staffId,
        ApprovedBy: approvedBy,
        DateApproved: date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        ApprovedType: 'Casual',
        CreatedBy: operatorData.Username,
        MinsOut: Math.round(minsOut),
        CoveredFor: coveringFor,
        CompanyID: '00000000-0000-0000-0000-000000000000'
      }

      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newInfringement)
      })

      if (response.ok) {
        console.log('Casual shift accepted successfully')
        await fetchAcceptedInfringements()
        setCasualPopupOpen(false)
      } else {
        const errorText = await response.text()
        console.error('Failed to accept casual shift:', response.status, errorText)
        alert(`Failed to accept casual shift: ${response.status} ${errorText}`)
      }
    } catch (error) {
      console.error('Error accepting casual shift:', error)
      alert(`Error accepting casual shift: ${error.message}`)
    }
  }

  const CasualAcceptancePopup = ({ open, onClose, onConfirm, staffList, casualPopupData, setCasualPopupData }) => {
    const [localApprovedBy, setLocalApprovedBy] = useState(casualPopupData.approvedBy || '')

    const handleApprovedByChange = (e) => {
      setLocalApprovedBy(e.target.value)
    }

    const handleConfirm = () => {
      const updatedData = {
        ...casualPopupData,
        approvedBy: localApprovedBy
      }
      setCasualPopupData(updatedData)
      onConfirm(updatedData)
    }

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Accept Casual Shift</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Staff: {staffList.find((s) => s.StaffId === casualPopupData.staffId)?.FName}{' '}
            {staffList.find((s) => s.StaffId === casualPopupData.staffId)?.SName}
          </Typography>
          <Typography variant="body1">Date: {casualPopupData.date?.format('YYYY-MM-DD')}</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Covering For</InputLabel>
            <Select
              value={casualPopupData.coveringFor}
              onChange={(e) => setCasualPopupData({ ...casualPopupData, coveringFor: e.target.value })}
            >
              {staffList.map((staff) => (
                <MenuItem key={staff.StaffId} value={staff.StaffId}>
                  {staff.FName} {staff.SName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Approved By"
            value={localApprovedBy}
            onChange={handleApprovedByChange}
            inputProps={{ maxLength: 50 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" disabled={!casualPopupData.coveringFor || !localApprovedBy}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const scrollRef = useRef(null)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState({ logs: [], shift: null })
  const [temporaryLogs, setTemporaryLogs] = useState([])

  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
  }

  const fetchAcceptedInfringements = async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response.ok) {
        const data = await response.json()
        setAcceptedInfringements(data)
      } else {
        console.error('Failed to fetch accepted infringements:', response.statusText)
      }
    } catch (error) {
      console.error('Error fetching accepted infringements:', error)
    }
  }

  const getEmployeeShift = useCallback(
    (staffId, date) => {
      const assignment = shiftAssignments.find((a) => a.staffId === staffId)
      if (!assignment) return null
      const dayOfWeek = moment(date).isoWeekday()
      const shiftId = assignment.shifts[dayOfWeek]
      return shifts.find((s) => s._id === shiftId) || null
    },
    [shiftAssignments, shifts]
  )

  const handleDialogOpen = (employee, shift, logs) => {
    console.info('dialog for: ', employee, shift, logs)
    setDialogContent({ logs, shift, employee })
    setTemporaryLogs(logs.map((log) => ({ ...log })))
    setDialogOpen(true)
  }

  const isZerhoShift = useCallback((shift) => {
    return shift && shift.ShiftName === 'ZEROH'
  }, [])

  const handleDialogClose = () => {
    setDialogOpen(false)
    setDialogContent({ logs: [], shift: null })
  }

  const handleDialogConfirm = async () => {
    console.info('confirming dialog: ', temporaryLogs)
    if (temporaryLogs.length > 0) {
      // Normalize StaffId to StaffID
      const normalizedLogs = temporaryLogs.map((log) => {
        if ('StaffId' in log && !('StaffID' in log)) {
          return { ...log, StaffID: log.StaffId }
        }
        return log
      })
      console.info('confirming dialog: ', normalizedLogs)

      const updatedRecords = await Promise.all(normalizedLogs.map((log) => fixClockingRecord(log, dialogContent.shift)))

      // Update monthClockingRecords with the fixed records
      setMonthClockingRecords((prevRecords) => {
        const updatedMonthRecords = prevRecords.filter(
          (record) => !normalizedLogs.some((log) => log.ClockID === record.ClockID)
        )
        return [...updatedMonthRecords, ...updatedRecords]
      })
      await fetchSelectedMonthClockingRecords()
      handleDialogClose()
    }
  }

  const fixClockingRecord = async (log, shift) => {
    setFixing(true)
    try {
      const url = 'https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/'
      const modifiedDatetime = moment().format('YYYY-MM-DD HH:mm:ss')

      const updatedLog = {
        ClockId: log.ClockID,
        ClockInTime: log.ClockInTime,
        ClockOutTime: log.ClockOutTime,
        Branch: log.ClockBranch,
        StaffId: log.StaffID,
        AttendanceTypeID: '33f9a591-466a-471d-9bf6-44d326d796a5',
        ModifiedUserId: 'a12c937c-4428-4a82-8540-3e916bfe14e7',
        ModifiedDatetime: modifiedDatetime
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify(updatedLog)
      })

      if (response.ok) {
        console.log('Clocking record fixed successfully.')
        return updatedLog
      } else {
        console.error('Failed to fix the clocking record:', response.statusText)
        return null
      }
    } catch (error) {
      console.error('Error fixing the clocking record:', error)
      return null
    } finally {
      setFixing(false)
    }
  }

  const deleteClockingRecord = async (clockId) => {
    try {
      const response = await fetch(`https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/${clockId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        }
      })

      if (response.ok) {
        console.log('Clocking record deleted successfully.')
        // Update temporary logs state to reflect the deletion
        setTemporaryLogs((prevLogs) => prevLogs.filter((log) => log.ClockID !== clockId))
        // Update the monthClockingRecords state
        setMonthClockingRecords((prevRecords) => prevRecords.filter((record) => record.ClockID !== clockId))
        // Close the dialog after successful deletion
        handleDialogClose()
      } else {
        console.error('Failed to delete the clocking record:', response.statusText)
        alert('Failed to delete the clocking record. Please try again.')
      }
    } catch (error) {
      console.error('Error deleting the clocking record:', error)
      alert('An error occurred while deleting the clocking record. Please try again.')
    }
  }

  const renderProgressBar = (employee, date, shift) => {
    //console.info('rendering progress bar for: ', employee);
    const logsToUse = Array.isArray(viewMode === 'Month' ? monthClockingRecords : clockingInRecords)
      ? viewMode === 'Month'
        ? monthClockingRecords
        : clockingInRecords
      : []

    // Filter clocking records for the employee on the given date
    const employeeClockLogs = logsToUse
      .filter((record) => record.StaffID === employee.StaffId && moment(record.ClockInTime).isSame(date, 'day'))
      .filter((log) => log.ClockInTime)

    const barContainerStyle = {
      width: '100%',
      height: '30px',
      backgroundColor: '#e0e0e0',
      position: 'relative',
      marginBottom: '10px',
      marginTop: '20px'
    }

    const shiftStart = shift
      ? moment(date).set({
          hour: parseInt(shift.Start.split(':')[0]),
          minute: parseInt(shift.Start.split(':')[1])
        })
      : null
    const shiftEnd = shift
      ? moment(date).set({
          hour: parseInt(shift.Finish.split(':')[0]),
          minute: parseInt(shift.Finish.split(':')[1])
        })
      : null

    const shiftStartPercent = shiftStart ? ((shiftStart.hour() * 60 + shiftStart.minute()) / (24 * 60)) * 100 : 0
    const shiftEndPercent = shiftEnd ? ((shiftEnd.hour() * 60 + shiftEnd.minute()) / (24 * 60)) * 100 : 100

    const renderClockBar = (log, index) => {
      const start = moment(log.ClockInTime)
      const end = log.ClockOutTime ? moment(log.ClockOutTime) : moment().isSame(date, 'day') ? moment() : null
      const startPercent = ((start.hour() * 60 + start.minute()) / (24 * 60)) * 100
      const duration = end ? end.diff(start, 'minutes') : 0
      const widthPercent = (duration / (24 * 60)) * 100

      return (
        <React.Fragment key={index}>
          <div
            style={{
              position: 'absolute',
              left: `${startPercent}%`,
              width: `${widthPercent}%`,
              height: '100%',
              backgroundColor: 'rgba(0, 255, 0, 0.5)',
              zIndex: 3
            }}
          />
          <div
            style={{
              position: 'absolute',
              left: `${startPercent}%`,
              top: '-20px',
              fontSize: '10px',
              transform: 'translateX(-50%)',
              color: 'orange',
              whiteSpace: 'nowrap'
            }}
          >
            {start.format('HH:mm')}
          </div>
          {end && (
            <div
              style={{
                position: 'absolute',
                left: `${startPercent + widthPercent}%`,
                top: '-20px',
                fontSize: '10px',
                transform: 'translateX(-50%)',
                color: log.ClockOutTime ? 'orange' : 'green',
                whiteSpace: 'nowrap'
              }}
            >
              {log.ClockOutTime ? end.format('HH:mm') : ''}
            </div>
          )}
          {!log.ClockOutTime && moment().isSame(date, 'day') && (
            <div
              style={{
                position: 'absolute',
                left: `${startPercent + widthPercent}%`,
                height: '100%',
                borderLeft: '2px dashed blue',
                zIndex: 4
              }}
            />
          )}
        </React.Fragment>
      )
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={barContainerStyle}>
          {shift && (
            <div
              style={{
                position: 'absolute',
                left: `${shiftStartPercent}%`,
                width: `${shiftEndPercent - shiftStartPercent}%`,
                height: '100%',
                backgroundColor: 'rgba(0, 0, 255, 0.2)',
                border: '1px solid blue',
                zIndex: 1
              }}
            />
          )}
          {employeeClockLogs.map(renderClockBar)}
          {shift && (
            <>
              <div
                style={{
                  position: 'absolute',
                  left: `${shiftStartPercent}%`,
                  bottom: '-20px',
                  fontSize: '12px',
                  transform: 'translateX(-50%)',
                  color: 'gray',
                  whiteSpace: 'nowrap'
                }}
              >
                {shift.Start}
              </div>
              <div
                style={{
                  position: 'absolute',
                  left: `${shiftEndPercent}%`,
                  bottom: '-20px',
                  fontSize: '12px',
                  transform: 'translateX(-50%)',
                  color: 'gray',
                  whiteSpace: 'nowrap'
                }}
              >
                {shift.Finish}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    fetchShiftAssignments()
    fetchShifts()
    fetchBreaks()
    fetchAcceptedInfringements()
    if (viewMode === 'Month') {
      fetchSelectedMonthClockingRecords()
      fetchNotDueForWorkRecords()
      fetchHolidayRecords()
      fetchSickList()
    } else if (viewMode === 'Today') {
      fetchTodayClockingRecords()
    }
  }, [token, viewMode, selectedMonth, selectedYear, staffBranch])

  const fetchShiftAssignments = async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shiftassignments', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      setShiftAssignments(data)
    } catch (error) {
      console.error('Error fetching shift assignments:', error)
    }
  }

  const fetchShifts = async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shifts', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      setShifts(data)
    } catch (error) {
      console.error('Error fetching shifts:', error)
    }
  }

  const fetchBreaks = async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/breaks', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      const breakData = data.reduce((acc, breakTime) => {
        acc[breakTime.staffId] = breakTime.breaks
        return acc
      }, {})
      setBreaks(breakData)
    } catch (error) {
      console.error('Error fetching breaks:', error)
    }
  }

  const fetchMonthClockingRecords = async () => {
    try {
      const startDate = moment().startOf('month').format('YYYY-MM-DD')
      const endDate = moment().endOf('month').format('YYYY-MM-DD')
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({ startDate, endDate })
      })
      const data = await response.json()

      // Filter the data before setting the state
      const filteredData = data.filter((record) => record.CompanyID === '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b')
      setMonthClockingRecords(filteredData)
    } catch (error) {
      console.error('Error fetching month clocking records:', error)
    }
  }

  const fetchTodayClockingRecords = async () => {
    try {
      const startDate = moment().startOf('day').format('YYYY-MM-DD')
      const endDate = moment().endOf('day').format('YYYY-MM-DD')
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({ startDate, endDate })
      })
      const data = await response.json()

      // Filter the data before setting the state
      const filteredData = data.filter((record) => record.CompanyID === '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b')
      setClockingInRecords(filteredData)
    } catch (error) {
      console.error('Error fetching today clocking records:', error)
    }
  }

  const fetchNotDueForWorkRecords = async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/notdueforwork', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      setNotDueForWorkRecords(data)
    } catch (error) {
      console.error('Error fetching Not Due for Work records:', error)
    }
  }

  const fetchHolidayRecords = async () => {
    try {
      const startDate = moment([selectedYear, selectedMonth]).startOf('month').format('YYYY-MM-DD')
      const endDate = moment([selectedYear, selectedMonth]).endOf('month').format('YYYY-MM-DD')
      console.log('Fetching holidays for:', startDate, 'to', endDate, 'Branch', staffBranch.name)

      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holidays', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({ StartDate: startDate, EndDate: endDate, Branch: staffBranch.code })
      })

      if (response.ok) {
        const data = await response.json()
        console.log('Holiday API response:', data)
        setHolidayRecords(data)
        console.log('Holiday records updated:', data)
      } else {
        const errorText = await response.text()
        console.error('Failed to fetch holiday records:', response.status, errorText)
        alert(`Failed to fetch holiday records: ${response.status} ${errorText}`)
      }
    } catch (error) {
      console.error('Error fetching holiday records:', error)
      alert(`Error fetching holiday records: ${error.message}`)
    }
  }

  const addClockingRecord = async (staffId, date, shift) => {
    try {
      const currentTime = moment()
      const shiftStart = moment(date).set({
        hour: parseInt(shift.Start.split(':')[0]),
        minute: parseInt(shift.Start.split(':')[1])
      })
      const shiftEnd = moment(date).set({
        hour: parseInt(shift.Finish.split(':')[0]),
        minute: parseInt(shift.Finish.split(':')[1])
      })

      let clockInTime, clockOutTime

      if (date.isSame(currentTime, 'day')) {
        // If it's today
        clockInTime = currentTime.isAfter(shiftStart) ? shiftStart.format() : null
        clockOutTime = currentTime.isAfter(shiftEnd) ? shiftEnd.format() : null
      } else {
        // If it's a past day
        clockInTime = shiftStart.format()
        clockOutTime = shiftEnd.format()
      }

      if (!clockInTime) {
        alert('Cannot add clock-in time for future shifts.')
        return
      }

      const newRecord = {
        ClockID: '00000000-0000-0000-0000-000000000000',
        CompanyID: '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b', // You might need to replace this with the actual CompanyID
        Branch: staffBranch.name,
        ClockInTime: clockInTime,
        ClockOutTime: clockOutTime,
        StaffId: staffId,
        AttendanceTypeID: '33f9a591-466a-471d-9bf6-44d326d796a5'
      }

      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/clockin/manual', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify(newRecord)
      })

      if (response.ok) {
        console.log('Clocking record added successfully.')
        // Fetch updated records instead of manually adding to state
        await fetchSelectedMonthClockingRecords()
        //alert('Clocking record added successfully.');
      } else {
        const errorText = await response.text()
        console.error('Failed to add clocking record:', response.status, errorText)
        alert(`Failed to add clocking record: ${response.status} ${errorText}`)
      }
    } catch (error) {
      console.error('Error adding clocking record:', error)
      alert('An error occurred while adding the clocking record. Please try again.')
    }
  }

  const acceptInfringement = async (clockId, staffId, date, shift, employeeClockLogs, reason) => {
    try {
      let type = reason
      let minsOut

      if (employeeClockLogs.length === 0) {
        minsOut = calculateShiftDuration(shift)
      } else if (!employeeClockLogs[0].ClockOutTime) {
        alert('This record needs a clock out time.')
        return false
      } else {
        minsOut = calculateInfringementMinutes(employeeClockLogs[0], shift)
      }

      const newInfringement = {
        ClockId: clockId || '00000000-0000-0000-0000-000000000000',
        StaffId: staffId || '',
        ApprovedBy: operatorData.Username || '',
        DateApproved: date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        ApprovedType: type || '',
        CreatedBy: operatorData.Username || '',
        MinsOut: Math.round(minsOut) || 0,
        CoveredFor: 'N/A',
        CompanyID: '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b'
      }

      console.log('Sending infringement data:', JSON.stringify(newInfringement, null, 2))

      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newInfringement)
      })

      if (response.ok) {
        const acceptedInfringement = await response.json()
        console.log('Infringement accepted successfully:', acceptedInfringement)
        await fetchAcceptedInfringements()
        return true
      } else {
        const errorText = await response.text()
        console.error('Failed to accept infringement:', response.status, errorText)
        alert(`Failed to accept infringement: ${response.status} ${errorText}`)
        return false
      }
    } catch (error) {
      console.error('Error accepting infringement:', error)
      alert(`Error accepting infringement: ${error.message}`)
      return false
    }
  }

  const calculateShiftDuration = (shift) => {
    if (!shift) return 0
    const start = moment(shift.Start, 'HH:mm')
    const end = moment(shift.Finish, 'HH:mm')
    return end.diff(start, 'minutes')
  }

  const calculateInfringementMinutes = (clockLog, shift) => {
    if (!shift) return 0
    const shiftStart = moment(shift.Start, 'HH:mm')
    const shiftEnd = moment(shift.Finish, 'HH:mm')
    const clockIn = moment(clockLog.ClockInTime)
    const clockOut = moment(clockLog.ClockOutTime)

    let infringementMinutes = 0

    // Calculate minutes late at the start of the shift
    if (clockIn.isAfter(shiftStart.add(15, 'minutes'))) {
      infringementMinutes += clockIn.diff(shiftStart, 'minutes')
    }

    // Calculate minutes early at the end of the shift
    if (clockOut.isBefore(shiftEnd.subtract(15, 'minutes'))) {
      infringementMinutes += shiftEnd.diff(clockOut, 'minutes')
    }

    return infringementMinutes
  }

  const removeAcceptedInfringement = async (staffId, date) => {
    try {
      const formattedDate = date.format('YYYY-MM-DD')
      const infringementToRemove = acceptedInfringements.find(
        (inf) => inf.StaffId === staffId && moment(inf.DateApproved).isSame(formattedDate, 'day')
      )

      if (!infringementToRemove) {
        console.error('No accepted infringement found to remove')
        return
      }

      const response = await fetch(
        `https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements/${infringementToRemove._id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      if (response.ok) {
        console.log('Accepted infringement removed successfully')
        fetchAcceptedInfringements()
      } else {
        console.error('Failed to remove accepted infringement:', response.statusText)
      }
    } catch (error) {
      console.error('Error removing accepted infringement:', error)
    }
  }

  const fetchSickList = async () => {
    try {
      const startDate = moment([selectedYear, selectedMonth]).startOf('month').format('YYYY-MM-DD')
      const endDate = moment([selectedYear, selectedMonth]).endOf('month').format('YYYY-MM-DD')
      console.log(JSON.stringify({ StartDate: startDate, EndDate: endDate, Branch: staffBranch.code }))

      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({ StartDate: startDate, EndDate: endDate, Branch: staffBranch.code })
      })

      if (response.ok) {
        const data = await response.json()
        console.log('Sick days API response:', data)
        setSickList(data)
        console.log('Sick list updated:', data)
      } else {
        const errorText = await response.text()
        console.error('Failed to fetch sick days:', response.status, errorText)
        alert(`Failed to fetch sick days: ${response.status} ${errorText}`)
      }
    } catch (error) {
      console.error('Error fetching sick days:', error)
      alert(`Error fetching sick days: ${error.message}`)
    }
  }

  useEffect(() => {
    console.log('Holiday records updated:', holidayRecords)
  }, [holidayRecords])

  const markAsNotDueForWork = async (staffId, date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      console.log(`Attempting to mark/unmark not due for work for staff ${staffId} on ${formattedDate}`)

      const existingRecord = notDueForWorkRecords.find(
        (record) => record.staffId === staffId && moment(record.date).isSame(formattedDate, 'day')
      )

      const isHoliday = holidayRecords.some(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )
      const isSick = sickList.some(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )

      if (isHoliday) {
        alert('This day is already marked as a holiday. Please remove the holiday first.')
        return
      }
      if (isSick) {
        alert('This day is already marked as sick. Please remove the sick day first.')
        return
      }

      if (existingRecord) {
        // Remove the record
        const response = await fetch(
          `https://spar.connectplus.parts/api/salesassist/cplusassist/notdueforwork/${existingRecord._id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        if (response.ok) {
          setNotDueForWorkRecords((prevRecords) => prevRecords.filter((record) => record._id !== existingRecord._id))
          console.log('Not Due for Work record removed successfully.')
        } else {
          const errorMessage = await response.text()
          console.error('Failed to remove Not Due for Work record:', errorMessage)
          alert(`Failed to remove Not Due for Work record: ${errorMessage}`)
        }
      } else {
        // Add the record
        const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/notdueforwork', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ staffId, date })
        })

        if (response.ok) {
          const newRecord = await response.json()
          setNotDueForWorkRecords((prevRecords) => [...prevRecords, newRecord])
          console.log('Marked as Not Due for Work successfully.')
        } else {
          const errorMessage = await response.text()
          console.error('Failed to mark as Not Due for Work:', errorMessage)
          alert(`Failed to mark as Not Due for Work: ${errorMessage}`)
        }
      }
    } catch (error) {
      console.error('Error toggling Not Due for Work status:', error)
      alert(`An error occurred: ${error.message}`)
    }
  }

  const markAsHoliday = async (staffId, date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      console.log(`Attempting to mark/unmark holiday for staff ${staffId} on ${formattedDate}`)

      // Fetch the latest holiday records before proceeding
      await fetchHolidayRecords()

      // Ensure holidayRecords is an array
      if (!Array.isArray(holidayRecords)) {
        console.error('holidayRecords is not an array:', holidayRecords)
        alert('Error: Holiday records are not in the correct format. Please refresh the page.')
        return
      }

      // Log all holiday records for this staff member
      console.log(
        'All holiday records for this staff:',
        holidayRecords.filter((record) => record.StaffID === staffId)
      )

      const existingHolidayRecord = holidayRecords.find(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )

      console.log('Existing holiday record:', existingHolidayRecord)

      const isSick = sickList.some(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )
      const isNotDueForWork = notDueForWorkRecords.some(
        (record) => record.staffId === staffId && moment(record.date).format('YYYY-MM-DD') === formattedDate
      )

      if (isSick) {
        alert('This day is already marked as a sick day. Please remove the sick day first.')
        return
      }
      if (isNotDueForWork) {
        alert('This day is already marked as not due for work. Please remove the not due for work status first.')
        return
      }

      if (existingHolidayRecord) {
        console.log(`Attempting to delete holiday record with ID: ${existingHolidayRecord.HolidayID}`)
        const response = await fetch(
          `https://vision-web-api-test.azurewebsites.net/api/tma/holiday/${existingHolidayRecord.HolidayID}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Token': token
            }
          }
        )

        if (response.ok) {
          console.log('Holiday record deleted successfully.')
          setHolidayRecords((prevRecords) => {
            const updatedRecords = prevRecords.filter((record) => record.HolidayID !== existingHolidayRecord.HolidayID)
            console.log('Updated holiday records:', updatedRecords)
            return updatedRecords
          })
        } else {
          const errorText = await response.text()
          console.error('Failed to delete holiday record:', response.status, errorText)
          alert(`Failed to delete holiday record: ${response.status} ${errorText}`)
        }
      } else {
        console.log('No existing holiday record found. Attempting to add a new one.')

        const holidayId = generateUniqueId()
        const startDatetime = moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const endDatetime = moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const addedDatetime = moment().format('YYYY-MM-DD HH:mm:ss')

        await haxios.post('/salesassist/cplusassist/holidayrequests', {
          staffId: staffId,
          dtStart: moment(startDatetime).format('YYYY-MM-DD'),
          dtEnd: moment(endDatetime).format('YYYY-MM-DD'),
          reason: '',
          requestDate: moment(addedDatetime).format('YYYY-MM-DD'),
          decision: 'APPROVED',
          Notes: ''
        })

        const payload = {
          HolidayID: holidayId,
          StartDatetime: startDatetime,
          EndDatetime: endDatetime,
          StaffID: staffId,
          TotalDays: 1,
          Paid: true,
          HoursPerDay: 8,
          AddedUserID: '988cca25-74f8-4908-ab96-f2ac9be8d9b3',
          AddedDatetime: addedDatetime
        }

        console.log('Sending POST request with payload:', payload)

        const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holiday', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Token': token
          },
          body: JSON.stringify(payload)
        })

        if (response.ok) {
          console.log('Holiday marked successfully.')
          setHolidayRecords((prevHolidayRecords) => {
            const updatedRecords = [...prevHolidayRecords, payload]
            console.log('Updated holiday records:', updatedRecords)
            return updatedRecords
          })
        } else {
          const errorText = await response.text()
          console.error('Failed to mark holiday:', response.status, errorText)
          alert(`Failed to mark holiday: ${response.status} ${errorText}`)
        }
      }

      // Trigger a re-fetch of holiday records
      await fetchHolidayRecords()
    } catch (error) {
      console.error('Error in markAsHoliday function:', error)
      alert(`An error occurred: ${error.message}`)
    }
  }

  const markAsSick = async (staffId, date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      console.log(`Attempting to mark/unmark sick day for staff ${staffId} on ${formattedDate}`)

      // Fetch the latest sick records before proceeding
      await fetchSickList()

      // Ensure sickList is an array
      if (!Array.isArray(sickList)) {
        console.error('sickList is not an array:', sickList)
        alert('Error: Sick records are not in the correct format. Please refresh the page.')
        return
      }

      // Log all sick records for this staff member
      console.log(
        'All sick records for this staff:',
        sickList.filter((record) => record.StaffID === staffId)
      )

      const existingSickRecord = sickList.find(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )

      console.log('Existing sick record:', existingSickRecord)

      const isHoliday = holidayRecords.some(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )
      const isNotDueForWork = notDueForWorkRecords.some(
        (record) => record.staffId === staffId && moment(record.date).format('YYYY-MM-DD') === formattedDate
      )

      if (isHoliday) {
        alert('This day is already marked as a holiday. Please remove the holiday first.')
        return
      }
      if (isNotDueForWork) {
        alert('This day is already marked as not due for work. Please remove the not due for work status first.')
        return
      }

      if (existingSickRecord) {
        console.log(`Attempting to delete sick record with ID: ${existingSickRecord.SickID}`)
        const response = await fetch(
          `https://vision-web-api-test.azurewebsites.net/api/tma/sickday/${existingSickRecord.SickID}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Token': token
            }
          }
        )

        if (response.ok) {
          console.log('Sick record deleted successfully.')
          setSickList((prevRecords) => {
            const updatedRecords = prevRecords.filter((record) => record.SickID !== existingSickRecord.SickID)
            console.log('Updated sick records:', updatedRecords)
            return updatedRecords
          })
        } else {
          const errorText = await response.text()
          console.error('Failed to delete sick record:', response.status, errorText)
          alert(`Failed to delete sick record: ${response.status} ${errorText}`)
        }
      } else {
        console.log('No existing sick record found. Attempting to add a new one.')

        const sickId = generateUniqueId()
        const startDatetime = moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const endDatetime = moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const addedDatetime = moment().format('YYYY-MM-DD HH:mm:ss')
        const payload = {
          SickID: sickId,
          StartDatetime: startDatetime,
          EndDatetime: endDatetime,
          StaffID: staffId,
          TotalDays: 1,
          Paid: true,
          HoursPerDay: '8',
          AddedUserID: '988cca25-74f8-4908-ab96-f2ac9be8d9b3',
          AddedDatetime: addedDatetime,
          Reason: 'Sick Day'
        }

        console.log('Sending POST request with payload:', payload)

        const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickday', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Token': token
          },
          body: JSON.stringify(payload)
        })

        if (response.ok) {
          const newSickRecord = await response.json()
          console.log('Sick day marked successfully. New record:', newSickRecord)
          setSickList((prevSickList) => {
            const updatedRecords = [...prevSickList, newSickRecord]
            console.log('Updated sick records:', updatedRecords)
            return updatedRecords
          })
        } else {
          const errorText = await response.text()
          console.error('Failed to mark sick day:', response.status, errorText)
          alert(`Failed to mark sick day: ${response.status} ${errorText}`)
        }
      }

      // Trigger a re-fetch of sick records
      await fetchSickList()
    } catch (error) {
      console.error('Error in markAsSick function:', error)
      alert(`An error occurred: ${error.message}`)
    }
  }

  const fetchSelectedMonthClockingRecords = async () => {
    try {
      const startDate = moment([selectedYear, selectedMonth]).startOf('month').format('YYYY-MM-DD')
      const endDate = moment([selectedYear, selectedMonth]).endOf('month').format('YYYY-MM-DD')
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({ startDate, endDate, Branch: staffBranch.code })
      })
      const data = await response.json()

      // Filter the data to keep only records with the specified companyID
      const filteredData = data.filter((record) => record.CompanyID === '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b')
      console.info(
        'filtered',
        filteredData.filter((record) => record.FName === 'David' && record.SName === 'Thomas')
      )
      setMonthClockingRecords(filteredData)
      return filteredData // Return the filtered data
    } catch (error) {
      console.error('Error fetching selected month clocking records:', error)
      return [] // Return an empty array in case of error
    }
  }

  const handleMonthYearConfirm = async () => {
    setIsMonthPickerOpen(false)
    setIsLoading(true)
    await fetchSelectedMonthClockingRecords()
    setIsLoading(false)
  }

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode)
      if (newMode === 'Month') {
        setIsMonthPickerOpen(true)
      }
    }
  }

  const handleViewTypeChange = (event, newType) => {
    if (newType !== null) {
      setViewType(newType)
    }
  }

  const getStaffStats = () => {
    const totalStaff = staffList.length
    let clockedIn = 0
    let clockedOut = 0
    let notClocked = 0

    const today = moment().startOf('day')

    staffList.forEach((employee) => {
      const employeeClockLogs = clockingInRecords.filter(
        (record) => record.StaffID === employee.StaffId && moment(record.ClockInTime).isSame(today, 'day')
      )

      if (employeeClockLogs.length > 0) {
        const sortedLogs = employeeClockLogs.sort((a, b) => new Date(b.ClockInTime) - new Date(a.ClockInTime))
        const mostRecentRecord = sortedLogs[0]

        if (mostRecentRecord.ClockOutTime === null) {
          clockedIn++
        } else {
          clockedOut++
        }
      } else {
        notClocked++
      }
    })

    return { totalStaff, clockedIn, clockedOut, notClocked }
  }

  const renderHeader = () => {
    const stats = getStaffStats()
    return (
      <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: 2, marginBottom: 2, borderRadius: 1 }}>
        <Typography variant="h6" style={{ color: 'white' }}>
          Staff Summary
        </Typography>
        <Typography variant="body1" style={{ color: 'white' }}>
          Total Staff: {stats.totalStaff} | Clocked In: {stats.clockedIn} | Clocked Out: {stats.clockedOut} | Not
          Clocked: {stats.notClocked}
        </Typography>
      </Box>
    )
  }

  const DaySelector = ({ daysInMonth, onDayClick, currentViewDay }) => {
    return (
      <div
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
          borderBottom: '1px solid #ccc',
          overflowX: 'auto'
        }}
      >
        {[...Array(daysInMonth)].map((_, index) => {
          const day = index + 1
          const isCurrentDay = day === currentViewDay
          return (
            <Button
              key={index}
              onClick={() => onDayClick(day)}
              style={{
                margin: '1px',
                minWidth: '30px',
                padding: '2px 4px',
                fontSize: '0.8rem',
                backgroundColor: isCurrentDay ? '#e0e0e0' : 'transparent'
              }}
              variant={isCurrentDay ? 'contained' : 'outlined'}
            >
              {day}
            </Button>
          )
        })}
      </div>
    )
  }

  const TandAMonthView = React.memo(
    ({
      staffBranch,
      clockingInRecords,
      staffList,
      viewType,
      selectedMonth,
      selectedYear,
      holidayRecords,
      sickList,
      acceptedInfringements,
      calculateTotalHours,
      safeClockingInRecords,
      token,
      fetchAcceptedInfringements
    }) => {
      const scrollRef = useRef(null)
      const selectedDate = moment([selectedYear, selectedMonth])
      const firstDayOfMonth = selectedDate.clone().startOf('month')
      const lastDayOfMonth = selectedDate.clone().endOf('month')
      const daysToRender = lastDayOfMonth.diff(firstDayOfMonth, 'days') + 1
      const currentMonth = selectedDate.format('MMMM YYYY')
      const currentDate = moment()
      const dayRefs = useRef({})
      const [currentViewDay, setCurrentViewDay] = useState(1)
      const [infringementModalOpen, setInfringementModalOpen] = useState(false)
      const [infringementModalData, setInfringementModalData] = useState({
        clockId: null,
        staffId: '',
        date: null,
        shift: null,
        employeeClockLogs: [],
        reason: ''
      })

      const InfringementAcceptanceModal = ({ open, onClose, onConfirm, data, setData }) => {
        return (
          <Dialog open={open} onClose={onClose}>
            <DialogTitle>Accept Infringement</DialogTitle>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Reason</InputLabel>
                <Select value={data.reason} onChange={(e) => setData({ ...data, reason: e.target.value })}>
                  <MenuItem value="ABSENT">ABSENT</MenuItem>
                  <MenuItem value="NOT DUE FOR WORK">NOT DUE FOR WORK</MenuItem>
                  <MenuItem value="STARTED LATE">STARTED LATE</MenuItem>
                  <MenuItem value="FINISHED EARLY">FINISHED EARLY</MenuItem>
                  <MenuItem value="BEREAVEMENT">BEREAVEMENT</MenuItem>
                  <MenuItem value="SICK">SICK</MenuItem>
                  <MenuItem value="STARTED EARLY">STARTED EARLY</MenuItem>
                  <MenuItem value="FINISHED LATE">FINISHED LATE</MenuItem>
                  <MenuItem value="WORKED NOSHIFT">WORKED NOSHIFT</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button onClick={onConfirm} color="primary" disabled={!data.reason}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )
      }

      const addDefaultClockingRecord = async (employee, date, shift) => {
        if (!shift) return null

        console.info('adding: ', employee, date, shift)

        const shiftStart = moment(date).set({
          hour: parseInt(shift.Start.split(':')[0]),
          minute: parseInt(shift.Start.split(':')[1])
        })
        const shiftEnd = moment(date).set({
          hour: parseInt(shift.Finish.split(':')[0]),
          minute: parseInt(shift.Finish.split(':')[1])
        })
        //console.info(staffBranch)
        const newRecord = {
          CompanyID: '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b',
          Branch: staffBranch.code,
          ClockInTime: shiftStart.format(),
          ClockOutTime: shiftEnd.format(),
          StaffId: employee.StaffId,
          AttendanceTypeID: '33f9a591-466a-471d-9bf6-44d326d796a5'
        }

        try {
          //console.log('Sending POST request with payload:', JSON.stringify(newRecord, null, 2));
          const response = await fetch(
            'https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/clockin/manual',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Token': token
              },
              body: JSON.stringify(newRecord)
            }
          )

          if (response.ok) {
            //console.log('Default clocking record added successfully.');
            await fetchSelectedMonthClockingRecords()
            return newRecord
          } else {
            const errorText = await response.text()
            //console.error('Failed to add default clocking record:', response.status, errorText);
            alert(`Failed to add default clocking record: ${response.status} ${errorText}`)
            return null
          }
        } catch (error) {
          //console.error('Error adding default clocking record:', error);
          alert(`Error adding default clocking record: ${error.message}`)
          return null
        }
      }

      const handleDayClick = (day) => {
        const targetRef = dayRefs.current[day]
        if (targetRef) {
          targetRef.scrollIntoView({ behavior: 'smooth', block: 'start' })
          setTimeout(() => {
            setCurrentViewDay(day)
          }, 300) // Adjust delay as needed to match scroll duration
        }
      }

      useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = scrollRef.current.scrollTop
          const dayElements = Object.entries(dayRefs.current)

          for (let [day, ref] of dayElements) {
            const { offsetTop, offsetHeight } = ref
            if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
              setCurrentViewDay(parseInt(day))
              break
            }
          }
        }

        const scrollContainer = scrollRef.current
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll)
        }

        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScroll)
          }
        }
      }, [])

      const [summary, setSummary] = useState({ goodRecords: 0, warningRecords: 0, badRecords: 0 })

      useEffect(() => {
        const summaryCounts = { goodRecords: 0, warningRecords: 0, badRecords: 0 }
        let totalInfringements = 0

        safeClockingInRecords.forEach((record) => {
          const { StaffID, ClockInTime, ClockOutTime } = record
          const employee = staffList.find((emp) => emp.StaffId === StaffID)
          const date = moment(ClockInTime).startOf('day')
          const shift = getEmployeeShift(employee?.StaffId, date)
          const breakMinutes = getEmployeeBreak(employee?.StaffId, date.isoWeekday())

          if (!employee || !shift) return

          const start = moment(ClockInTime)
          const end = ClockOutTime ? moment(ClockOutTime) : null

          const shiftStart = moment(date).set({
            hour: parseInt(shift.Start.split(':')[0]),
            minute: parseInt(shift.Start.split(':')[1])
          })
          const shiftEnd = moment(date).set({
            hour: parseInt(shift.Finish.split(':')[0]),
            minute: parseInt(shift.Finish.split(':')[1])
          })

          const shiftStartDiff = start.diff(shiftStart, 'minutes')
          const shiftEndDiff = end ? end.diff(shiftEnd, 'minutes') : 0
          const isClockInWithinGrace = Math.abs(shiftStartDiff) <= 5
          const isClockOutWithinGrace = end ? Math.abs(shiftEndDiff) <= 5 : false
          const isShiftEnded = moment().isAfter(shiftEnd)

          if (isClockInWithinGrace && isClockOutWithinGrace) {
            summaryCounts.goodRecords += 1
          } else if (!end && isShiftEnded) {
            summaryCounts.warningRecords += 1
            totalInfringements++
          } else if (Math.abs(shiftStartDiff) > 5 || (end && Math.abs(shiftEndDiff) > 5)) {
            summaryCounts.badRecords += 1
            totalInfringements++
          } else {
            summaryCounts.warningRecords += 1
          }
        })

        setSummary(summaryCounts)
        setInfringementCount(totalInfringements)
        setTotalRecordsCount(safeClockingInRecords.length)
      }, [safeClockingInRecords, staffList])

      const handleScroll = useCallback(
        throttle(() => {
          if (scrollRef.current) {
            const scrollPosition = scrollRef.current.scrollTop
            const dayElements = Object.entries(dayRefs.current)
            const buffer = 5 // Small buffer to prevent flickering

            for (let [day, ref] of dayElements) {
              const { offsetTop, offsetHeight } = ref
              if (scrollPosition >= offsetTop - buffer && scrollPosition < offsetTop + offsetHeight - buffer) {
                setCurrentViewDay(parseInt(day))
                break
              }
            }
            sessionStorage.setItem('monthViewScrollPosition', scrollPosition)
          }
        }, 100),
        []
      )

      const timeLabels = useMemo(
        () => (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
            {[...Array(25)].map((_, i) => (
              <Typography key={i} variant="caption" style={{ flexBasis: '4%', textAlign: 'center', color: 'black' }}>
                {i.toString().padStart(2, '0')}:00
              </Typography>
            ))}
          </div>
        ),
        []
      )

      const handleDeleteCasual = async (staffId, date) => {
        try {
          const infringementToDelete = acceptedInfringements.find(
            (inf) => inf.StaffId === staffId && moment(inf.DateApproved).isSame(date, 'day')
          )

          if (!infringementToDelete) {
            console.error('No accepted infringement found to delete')
            return
          }

          const response = await fetch(
            `https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements/${infringementToDelete._id}`,
            {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )

          if (response.ok) {
            console.log('Casual acceptance deleted successfully')
            await fetchAcceptedInfringements()
          } else {
            console.error('Failed to delete casual acceptance:', response.statusText)
            alert('Failed to delete casual acceptance. Please try again.')
          }
        } catch (error) {
          console.error('Error deleting casual acceptance:', error)
          alert('An error occurred while deleting the casual acceptance. Please try again.')
        }
      }

      const calculateExpectedHours = useCallback((shift, breakMinutes) => {
        if (!shift) return { hours: 0, minutes: 0, formatted: '0h 0m' }

        const shiftStart = moment().set({
          hour: parseInt(shift.Start.split(':')[0]),
          minute: parseInt(shift.Start.split(':')[1])
        })
        const shiftEnd = moment().set({
          hour: parseInt(shift.Finish.split(':')[0]),
          minute: parseInt(shift.Finish.split(':')[1])
        })

        const totalMinutes = shiftEnd.diff(shiftStart, 'minutes') - breakMinutes
        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        return { hours, minutes, formatted: `${hours}h ${minutes}m` }
      }, [])

      const isWeekend = useCallback((date) => {
        const day = date.day()
        return day === 0 || day === 6
      }, [])

      let infringementCounter = 0
      const renderDay = useCallback(
        (date, index) => {
          const day = date.date()

          const weekend = isWeekend(date)

          return (
            <div ref={(el) => (dayRefs.current[day] = el)} key={index}>
              <Card key={index} style={{ margin: '16px 0', backgroundColor: weekend ? '#f0f0f0' : 'white' }}>
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" style={{ color: 'black' }}>
                      {date.format('dddd, MMMM D, YYYY')}
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: 'black' }}>
                      Total Hours: {calculateTotalHours({ StaffId: null }, date).formatted}
                    </Typography>
                  </div>
                  {timeLabels}
                  {staffList.map((employee) => {
                    const employeeClockLogs = safeClockingInRecords.filter(
                      (record) => record.StaffID === employee.StaffId && moment(record.ClockInTime).isSame(date, 'day')
                    )
                    const didClockIn = employeeClockLogs.length > 0
                    const totalHours = calculateTotalHours(employee, date)
                    const shift = getEmployeeShift(employee.StaffId, date)
                    const breakMinutes = getEmployeeBreak(employee.StaffId, date.isoWeekday())
                    const expectedHours = calculateExpectedHours(shift, breakMinutes)
                    const differenceMinutes =
                      totalHours.hours * 60 + totalHours.minutes - (expectedHours.hours * 60 + expectedHours.minutes)
                    const differenceFormatted = `${Math.floor(Math.abs(differenceMinutes) / 60)}h ${
                      Math.abs(differenceMinutes) % 60
                    }m`
                    const differenceSign = differenceMinutes >= 0 ? '+' : '-'

                    const notDueForWork = notDueForWorkRecords.some(
                      (record) => record.staffId === employee.StaffId && moment(record.date).isSame(date, 'day')
                    )

                    const onHoliday =
                      Array.isArray(holidayRecords) &&
                      holidayRecords.some((record) => {
                        const holidayStart = moment(record.StartDatetime).startOf('day')
                        const holidayEnd = moment(record.EndDatetime).endOf('day')
                        return (
                          record.StaffID === employee.StaffId &&
                          date.isSameOrAfter(holidayStart) &&
                          date.isSameOrBefore(holidayEnd)
                        )
                      })

                    const onSick =
                      Array.isArray(sickList) &&
                      sickList.some((record) => {
                        const sickStart = moment(record.StartDatetime).startOf('day')
                        const sickEnd = moment(record.EndDatetime).endOf('day')
                        return (
                          record.StaffID === employee.StaffId &&
                          date.isSameOrAfter(sickStart) &&
                          date.isSameOrBefore(sickEnd)
                        )
                      })

                    let statusEmoji = '❌'
                    let borderColor = 'red'

                    const isInfringementAccepted = acceptedInfringements.some(
                      (inf) => inf.StaffId === employee.StaffId && moment(inf.DateApproved).isSame(date, 'day')
                    )

                    if (isInfringementAccepted) {
                      statusEmoji = '✅'
                      borderColor = 'green'
                    } else if (employeeClockLogs.length > 0) {
                      const lastLog = employeeClockLogs[employeeClockLogs.length - 1]
                      const start = moment(lastLog.ClockInTime)
                      const end = lastLog.ClockOutTime ? moment(lastLog.ClockOutTime) : null

                      const shiftStart = shift
                        ? moment(date).set({
                            hour: parseInt(shift.Start.split(':')[0]),
                            minute: parseInt(shift.Start.split(':')[1])
                          })
                        : null
                      const shiftEnd = shift
                        ? moment(date).set({
                            hour: parseInt(shift.Finish.split(':')[0]),
                            minute: parseInt(shift.Finish.split(':')[1])
                          })
                        : null

                      const shiftStartDiff = shiftStart ? start.diff(shiftStart, 'minutes') : 0
                      const shiftEndDiff = end && shiftEnd ? end.diff(shiftEnd, 'minutes') : 0

                      const isClockInWithinGrace = shiftStart && Math.abs(shiftStartDiff) <= 5
                      const isClockOutWithinGrace = end && shiftEnd ? Math.abs(shiftEndDiff) <= 5 : false
                      const isShiftEnded = shiftEnd && moment().isAfter(shiftEnd)

                      if (isClockInWithinGrace && isClockOutWithinGrace) {
                        statusEmoji = '✅'
                        borderColor = 'green'
                      } else if (!end && isShiftEnded) {
                        statusEmoji = '❌'
                        borderColor = 'red'
                        infringementCounter++
                      } else if (Math.abs(shiftStartDiff) > 5 || (end && Math.abs(shiftEndDiff) > 5)) {
                        statusEmoji = '❌'
                        borderColor = 'red'
                        infringementCounter++
                      } else {
                        statusEmoji = '⚠️'
                        borderColor = 'orange'
                        infringementCounter++
                      }
                    } else if (shift && !notDueForWork && !onHoliday && !onSick) {
                      // New else case to catch missing clock logs
                      console.log(
                        `Infringement for ${employee.FName} ${employee.SName}: No clock logs but has a shift.`
                      )
                      statusEmoji = '❌'
                      borderColor = 'red'
                      infringementCounter++ // Increment on infringement
                    }

                    if (notDueForWork || onHoliday || onSick) {
                      borderColor = 'green'
                      statusEmoji = '✅'
                    }

                    if (viewType === 'Infringements' && statusEmoji === '✅') {
                      return null
                    }

                    return (
                      <div
                        key={employee.StaffId}
                        style={{
                          margin: '8px 0',
                          border: `2px solid ${borderColor}`,
                          padding: 10,
                          borderRadius: 15,
                          position: 'relative'
                        }}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ textAlign: 'left' }}>
                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                              {employee.FName} {employee.SName} {statusEmoji}
                              {notDueForWork && (
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    padding: 6,
                                    borderRadius: 15,
                                    backgroundColor: 'grey',
                                    marginLeft: '8px',
                                    color: 'orange'
                                  }}
                                >
                                  Not Due for Work
                                </span>
                              )}
                              {onHoliday && (
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    padding: 6,
                                    borderRadius: 15,
                                    backgroundColor: 'grey',
                                    marginLeft: '8px',
                                    color: 'yellow'
                                  }}
                                >
                                  On Holiday
                                </span>
                              )}
                              {onSick && (
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    padding: 6,
                                    borderRadius: 15,
                                    backgroundColor: 'grey',
                                    marginLeft: '8px',
                                    color: 'purple'
                                  }}
                                >
                                  Sick
                                </span>
                              )}
                              {isInfringementAccepted && (
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    padding: 6,
                                    borderRadius: 15,
                                    backgroundColor: 'grey',
                                    marginLeft: '8px',
                                    color: 'orange'
                                  }}
                                >
                                  Infringement Accepted
                                </span>
                              )}
                            </Typography>
                          </div>
                          <div style={{ textAlign: 'right' }}>
                            <Typography variant="body2" style={{ color: 'black' }}>
                              Total: {totalHours.formatted} | Expected: {expectedHours.formatted} | Difference:{' '}
                              {differenceSign}
                              {differenceFormatted}
                            </Typography>
                          </div>
                        </div>
                        {renderProgressBar(employee, date, shift)}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '4px'
                          }}
                        >
                          <div style={{ textAlign: 'left' }}>
                            <Typography variant="body2" style={{ color: 'black' }}>
                              Assigned Shift: {shift ? `${shift.Start} - ${shift.Finish}` : 'N/A'}
                            </Typography>
                            {employeeClockLogs.length > 0 && (
                              <Typography variant="body2" style={{ color: 'black' }}>
                                Actual Hours: {moment(employeeClockLogs[0].ClockInTime).format('HH:mm')} -{' '}
                                {employeeClockLogs[employeeClockLogs.length - 1].ClockOutTime
                                  ? moment(employeeClockLogs[employeeClockLogs.length - 1].ClockOutTime).format('HH:mm')
                                  : 'Not clocked out'}
                              </Typography>
                            )}
                            <Typography variant="body2" style={{ color: 'black' }}>
                              Break Time: {breakMinutes} minutes
                            </Typography>
                          </div>

                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {isZerhoShift(shift) && employeeClockLogs.length > 0 && (
                              <>
                                {isInfringementAccepted ? (
                                  <>
                                    <Typography variant="body2" style={{ color: 'black', marginRight: '10px' }}>
                                      {(() => {
                                        const acceptance = acceptedInfringements.find(
                                          (inf) =>
                                            inf.StaffId === employee.StaffId &&
                                            moment(inf.DateApproved).isSame(date, 'day')
                                        )
                                        if (acceptance) {
                                          const coveringFor = staffList.find((s) => s.StaffId === acceptance.CoveredFor)
                                          return (
                                            <>
                                              Covering for:{' '}
                                              {coveringFor ? `${coveringFor.FName} ${coveringFor.SName}` : 'N/A'}
                                              <br />
                                              Approved by: {acceptance.ApprovedBy || 'N/A'}
                                            </>
                                          )
                                        }
                                        return null
                                      })()}
                                    </Typography>
                                    <Button
                                      onClick={() => handleDeleteCasual(employee.StaffId, date)}
                                      variant="contained"
                                      color="secondary"
                                      size="small"
                                      style={{ marginRight: '5px' }}
                                    >
                                      Delete Casual
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    onClick={() => handleOpenCasualPopup(employee.StaffId, date)}
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    style={{ marginRight: '5px' }}
                                  >
                                    Accept Casual
                                  </Button>
                                )}
                              </>
                            )}

                            {(statusEmoji === '❌' || shift === null) && !isInfringementAccepted && (
                              <Button
                                onClick={() => {
                                  setInfringementModalData({
                                    clockId: employeeClockLogs.length > 0 ? employeeClockLogs[0].ClockID : null,
                                    staffId: employee.StaffId,
                                    date: date,
                                    shift: shift,
                                    employeeClockLogs: employeeClockLogs,
                                    reason: ''
                                  })
                                  setInfringementModalOpen(true)
                                }}
                                variant="contained"
                                size="small"
                                style={{ marginRight: '5px', backgroundColor: 'darkorange' }}
                              >
                                Accept Infringement
                              </Button>
                            )}

                            {isInfringementAccepted && (
                              <Button
                                onClick={() => removeAcceptedInfringement(employee.StaffId, date)}
                                variant="contained"
                                color="secondary"
                                size="small"
                                style={{ marginRight: '5px' }}
                              >
                                Remove Acceptance
                              </Button>
                            )}
                            {!isInfringementAccepted && (
                              <div style={{ textAlign: 'right', borderRadius: 15, backgroundColor: 'grey' }}>
                                {shift ? (
                                  employeeClockLogs.length > 0 ? (
                                    // There are clock logs, show "Fix Record" button
                                    <Button
                                      onClick={() => handleDialogOpen(employee, shift, employeeClockLogs)}
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                    >
                                      Fix Record
                                    </Button>
                                  ) : (
                                    <div style={{ display: 'flex', gap: '5px' }}>
                                      <Button
                                        onClick={async () => {
                                          const defaultRecord = await addDefaultClockingRecord(employee, date, shift)
                                          if (defaultRecord) {
                                            //handleDialogOpen(employee, shift, [defaultRecord]);
                                            alert('Clocking Record Added Successfully')
                                          } else {
                                            console.error('Failed to add default clocking record')
                                            // Optionally, you could show an alert here as well
                                          }
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                      >
                                        Add & Fix
                                      </Button>
                                      <IconButton
                                        onClick={() => markAsNotDueForWork(employee.StaffId, date.format('YYYY-MM-DD'))}
                                        color="secondary"
                                        aria-label="mark as not due for work"
                                      >
                                        <WorkOffIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => markAsHoliday(employee.StaffId, date.format('YYYY-MM-DD'))}
                                        sx={{ color: 'yellow' }}
                                        aria-label="mark as holiday"
                                      >
                                        <HolidayIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => markAsSick(employee.StaffId, date.format('YYYY-MM-DD'))}
                                        aria-label="mark as sick"
                                        sx={{ color: 'purple' }}
                                      >
                                        <SickIcon />
                                      </IconButton>
                                    </div>
                                  )
                                ) : (
                                  // No shift assigned, show other options
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <IconButton
                                      onClick={() => markAsNotDueForWork(employee.StaffId, date.format('YYYY-MM-DD'))}
                                      color="secondary"
                                      aria-label="mark as not due for work"
                                    >
                                      <WorkOffIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => markAsHoliday(employee.StaffId, date.format('YYYY-MM-DD'))}
                                      sx={{ color: 'yellow' }}
                                      aria-label="mark as holiday"
                                    >
                                      <HolidayIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => markAsSick(employee.StaffId, date.format('YYYY-MM-DD'))}
                                      aria-label="mark as sick"
                                      sx={{ color: 'purple' }}
                                    >
                                      <SickIcon />
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </CardContent>
              </Card>
            </div>
          )
        },
        [
          staffList,
          safeClockingInRecords,
          calculateTotalHours,
          isWeekend,
          renderProgressBar,
          timeLabels,
          calculateExpectedHours,
          getEmployeeShift,
          getEmployeeBreak,
          viewType,
          fixing,
          handleDialogOpen,
          notDueForWorkRecords,
          holidayRecords,
          sickList,
          markAsNotDueForWork,
          markAsHoliday,
          markAsSick,
          acceptInfringement,
          removeAcceptedInfringement,
          acceptedInfringements
        ]
      )

      useEffect(() => {
        setInfringementCount(infringementCounter) // Update state with the final infringement count
        console.log(`Total Infringement Count: ${infringementCounter}`)
      }, [infringementCounter])

      const monthContent = useMemo(
        () =>
          [...Array(daysToRender)]
            .map((_, i) => {
              const dayDate = firstDayOfMonth.clone().add(i, 'days')
              if (dayDate.isSameOrBefore(currentDate, 'day')) {
                return renderDay(dayDate, i)
              }
              return null
            })
            .filter(Boolean),
        [daysToRender, renderDay, firstDayOfMonth, currentDate]
      )

      return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <DaySelector daysInMonth={daysToRender} onDayClick={handleDayClick} currentViewDay={currentViewDay} />{' '}
          <div
            ref={scrollRef}
            onScroll={handleScroll}
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              maxHeight: 'calc(65vh - 60px)' // Adjust this value as needed
            }}
          >
            {monthContent}
          </div>
          <InfringementAcceptanceModal
            open={infringementModalOpen}
            onClose={() => setInfringementModalOpen(false)}
            onConfirm={() => {
              acceptInfringement(
                infringementModalData.clockId,
                infringementModalData.staffId,
                infringementModalData.date,
                infringementModalData.shift,
                infringementModalData.employeeClockLogs,
                infringementModalData.reason
              )
              setInfringementModalOpen(false)
            }}
            data={infringementModalData}
            setData={setInfringementModalData}
          />
        </div>
      )
    }
  )

  if (!staffBranch) {
    return (
      <div style={{ color: 'white', padding: '20px' }}>
        <Button
          onClick={() => setStaffBranch(null)}
          variant="contained"
          color="primary"
          style={{ marginBottom: '20px' }}
        >
          Back to Branches
        </Button>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>
          Select a branch to view
        </Typography>
      </div>
    )
  }

  return (
    <div style={{ color: 'white', padding: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
        <Button
          onClick={() => setStaffBranch(null)}
          variant="contained"
          color="primary"
          style={{ marginBottom: '20px' }}
        >
          Back to Branches
        </Button>
        <Typography variant="h4" style={{ marginBottom: '20px' }}>
          {staffBranch.name}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          {viewMode === 'Month' && (
            <Button
              variant="contained"
              onClick={() => setIsMonthPickerOpen(true)}
              style={{ marginLeft: '10px', marginRight: '10px' }}
            >
              {moment([selectedYear, selectedMonth]).format('MMMM YYYY')}
            </Button>
          )}
          <ToggleButtonGroup value={viewMode} exclusive onChange={handleViewModeChange} aria-label="view mode">
            <ToggleButton value="Today" aria-label="today view">
              Today
            </ToggleButton>
            <ToggleButton value="Month" aria-label="month view">
              Month
            </ToggleButton>
          </ToggleButtonGroup>

          {viewMode === 'Month' && (
            <>
              <ToggleButtonGroup
                value={viewType}
                exclusive
                onChange={handleViewTypeChange}
                aria-label="view type"
                style={{ marginLeft: '20px' }}
              >
                <ToggleButton value="All" aria-label="all records">
                  All
                </ToggleButton>
                <ToggleButton value="Infringements" aria-label="infringements">
                  Infringements
                </ToggleButton>
              </ToggleButtonGroup>
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 2, color: 'white' }}>
                <Typography variant="body1">
                  <span style={{ color: 'red', fontWeight: 'bold' }}>{infringementCount}</span> Infringements
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </div>

      {viewMode === 'Today' ? (
        <>
          {renderHeader()}
          <div
            style={{ overflow: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxHeight: '65vh' }}
          >
            {staffList.map((employee) => {
              const employeeClockLogs = clockingInRecords.filter((record) => record.StaffID === employee.StaffId)
              const shift = getEmployeeShift(employee.StaffId, moment())
              const breakMinutes = getEmployeeBreak(employee.StaffId, moment().isoWeekday())

              const currentTime = moment()
              const shiftStartTime = shift
                ? moment().set({
                    hour: parseInt(shift.Start.split(':')[0]),
                    minute: parseInt(shift.Start.split(':')[1])
                  })
                : null
              const shiftEndTime = shift
                ? moment().set({
                    hour: parseInt(shift.Finish.split(':')[0]),
                    minute: parseInt(shift.Finish.split(':')[1])
                  })
                : null

              let borderColor = 'grey'
              if (shift && currentTime.isAfter(shiftStartTime) && employeeClockLogs.length === 0) {
                borderColor = 'red'
              } else if (
                shift &&
                currentTime.isAfter(shiftEndTime) &&
                employeeClockLogs.length > 0 &&
                !employeeClockLogs[employeeClockLogs.length - 1].ClockOutTime
              ) {
                borderColor = 'purple'
              } else if (!shift && employeeClockLogs.length > 0) {
                borderColor = 'orange'
              }

              if (employeeClockLogs.length > 0) {
                const sortedLogs = employeeClockLogs.sort((a, b) => new Date(b.ClockInTime) - new Date(a.ClockInTime))
                const mostRecentLog = sortedLogs[0]

                const start = moment(mostRecentLog.ClockInTime)
                const end = mostRecentLog.ClockOutTime ? moment(mostRecentLog.ClockOutTime) : null

                const shiftStart = shift
                  ? moment().set({
                      hour: parseInt(shift.Start.split(':')[0]),
                      minute: parseInt(shift.Start.split(':')[1])
                    })
                  : null
                const shiftEnd = shift
                  ? moment().set({
                      hour: parseInt(shift.Finish.split(':')[0]),
                      minute: parseInt(shift.Finish.split(':')[1])
                    })
                  : null

                const shiftStartDiff = shiftStart ? start.diff(shiftStart, 'minutes') : 0
                const shiftEndDiff = end ? end.diff(shiftEnd, 'minutes') : 0

                if (Math.abs(shiftStartDiff) > 5 || (end && Math.abs(shiftEndDiff) > 5)) {
                  borderColor = 'red'
                }
              }

              return (
                <Card
                  key={employee.StaffId}
                  style={{ margin: '16px', width: '300px', border: `2px solid ${borderColor}` }}
                >
                  <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ textAlign: 'left' }}>
                        <Typography variant="h6">
                          {employee.FName} {employee.SName}
                        </Typography>
                        {shift ? (
                          <Typography variant="body2">
                            {shift.ShiftName} ({shift.Start} - {shift.Finish})
                          </Typography>
                        ) : (
                          <Typography variant="body2">No shift assigned for today</Typography>
                        )}
                        <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>
                          Break Time: {breakMinutes} minutes
                        </Typography>
                      </div>
                    </div>
                    {renderProgressBar(employee, moment(), shift)}
                  </CardContent>
                </Card>
              )
            })}
          </div>
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)' }}>
          <TandAMonthView
            key={monthClockingRecords.length}
            staffBranch={staffBranch}
            clockingInRecords={monthClockingRecords}
            staffList={staffList}
            viewType={viewType}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            holidayRecords={holidayRecords}
            sickList={sickList}
            acceptedInfringements={acceptedInfringements}
            calculateTotalHours={calculateTotalHours}
            safeClockingInRecords={safeClockingInRecords}
            token={token}
            fetchAcceptedInfringements={fetchAcceptedInfringements}
          />
        </div>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Fix Clocking Records</DialogTitle>
        <DialogContent>
          {dialogContent.employee && (
            <>
              <Typography>{`Editing clocking records for ${dialogContent.employee?.FName} ${dialogContent.employee?.SName}`}</Typography>
              <Typography sx={{ fontSize: 10 }}>{`Staff ID: ${dialogContent.employee?.StaffId}`}</Typography>
              <Typography>{`Expected Clock In: ${dialogContent.shift?.Start}`}</Typography>
              <Typography>{`Expected Clock Out: ${dialogContent.shift?.Finish}`}</Typography>
            </>
          )}
          {temporaryLogs.map((log, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <Typography variant="body2">{`Log ${index + 1}`}</Typography>
              <TextField
                label="Clock In Time"
                type="datetime-local"
                value={moment(log.ClockInTime).format('YYYY-MM-DDTHH:mm')}
                onChange={(e) => {
                  const updatedLogs = [...temporaryLogs]
                  updatedLogs[index].ClockInTime = moment(e.target.value).format()
                  setTemporaryLogs(updatedLogs)
                }}
                fullWidth
                margin="normal"
              />
              <Button
                onClick={() => {
                  const updatedLogs = [...temporaryLogs]
                  const clockInDate = moment(log.ClockInTime).format('YYYY-MM-DD')
                  updatedLogs[index].ClockInTime = moment(
                    `${clockInDate} ${dialogContent.shift?.Start}`,
                    'YYYY-MM-DD HH:mm'
                  ).format()
                  setTemporaryLogs(updatedLogs)
                }}
              >
                Set Shift Start
              </Button>
              <TextField
                label="Clock Out Time"
                type="datetime-local"
                value={log.ClockOutTime ? moment(log.ClockOutTime).format('YYYY-MM-DDTHH:mm') : ''}
                onChange={(e) => {
                  const updatedLogs = [...temporaryLogs]
                  updatedLogs[index].ClockOutTime = moment(e.target.value).format()
                  setTemporaryLogs(updatedLogs)
                }}
                fullWidth
                margin="normal"
              />
              <Button
                onClick={() => {
                  const updatedLogs = [...temporaryLogs]
                  const clockInDate = moment(log.ClockInTime).format('YYYY-MM-DD')
                  updatedLogs[index].ClockOutTime = moment(
                    `${clockInDate} ${dialogContent.shift?.Finish}`,
                    'YYYY-MM-DD HH:mm'
                  ).format()
                  setTemporaryLogs(updatedLogs)
                }}
              >
                Set Shift End
              </Button>
              <IconButton
                aria-label="delete"
                onClick={() => deleteClockingRecord(log.ClockID)}
                style={{ color: 'red' }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          {dialogContent.employee && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                markAsNotDueForWork(
                  dialogContent.employee.StaffId,
                  moment(dialogContent.logs[0].ClockInTime).format('YYYY-MM-DD')
                )
              }}
              fullWidth
              style={{ marginTop: '10px' }}
            >
              {notDueForWorkRecords.some(
                (record) =>
                  record.staffId === dialogContent.employee.StaffId &&
                  moment(record.date).isSame(moment(dialogContent.logs[0].ClockInTime), 'day')
              )
                ? 'Remove Not Due for Work'
                : 'Mark as Not Due for Work'}
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MonthYearPicker
        isOpen={isMonthPickerOpen}
        onClose={() => setIsMonthPickerOpen(false)}
        onConfirm={handleMonthYearConfirm}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setSelectedMonth={setSelectedMonth}
        setSelectedYear={setSelectedYear}
      />

      <CasualAcceptancePopup
        open={casualPopupOpen}
        onClose={() => setCasualPopupOpen(false)}
        onConfirm={handleConfirmCasual}
        staffList={staffList}
        casualPopupData={casualPopupData}
        setCasualPopupData={setCasualPopupData}
      />
    </div>
  )
}

export default TandABranch
