import { useEffect, useRef, useState } from 'react'
import { Card, Icon } from './gsys-ui'
import {
  ClipboardDocumentListIcon,
  DocumentDuplicateIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  UserIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
import { ActionIcon, Button, NativeSelect, TextInput } from '@mantine/core'
import BranchDropdown from './components/BranchDropdown'
import MultiButton from './gsys-ui/MultiButton/MultiButton'
import useApi from './hooks/useApi'
import s from './StaffTable.module.scss'
import clsx from 'clsx'
import useShiftCopyStore from './stores/useShiftCopyStore'
import { successToast } from './lib/helpers'
import { modals } from '@mantine/modals'
import haxios from './lib/haxios'
import useBranchStore from './stores/useBranchStore'
import { CircularProgress } from '@mui/material'
import VirtualizedScrollingContainer from './components/VirtualizedScrollingContainer'

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const Shifts = () => {
  const selectedBranch = useBranchStore((state) => state.selectedBranch)
  const selectedRegion = useBranchStore((state) => state.selectedRegion)
  const regions = useBranchStore((state) => state.regions)
  const [search, setSearch] = useState('')
  const [view, setView] = useState('staff')
  const [dayOfWeek, setDayOfWeek] = useState(1)
  const [position, setPosition] = useState('All positions')

  const {
    data: staff,
    isLoading,
    refetch
  } = useApi({
    url: '/salesassistnew/tma/shifts',
    enabled: !!selectedBranch
  })

  useEffect(() => {
    setPosition('All positions')
  }, [selectedBranch])

  if (isLoading)
    return (
      <div className="flex absolute inset-0 justify-center items-center">
        <CircularProgress />
      </div>
    )

  const branchStaff = staff.filter((el) => selectedBranch === 'ALL' || el.Branch === selectedBranch)
  const staffFiltered = staff.filter(
    (el) =>
      `${el.FName} ${el.SName} ${el.Branch}`.toLowerCase().includes(search.toLowerCase()) &&
      (selectedBranch === 'ALL' || el.Branch === selectedBranch) &&
      (position === 'All positions' || el.Position === position) &&
      (selectedRegion ? regions[selectedRegion].some((b) => b.MamCode === el.Branch) : true)
  )

  const staffFormatted = branchStaff.map((s) => ({ ...s, Position: s.Position.trim() }))
  const positionValues = [...new Set(staffFormatted.map((s) => s.Position))].sort().map((p) => ({ label: p, value: p }))
  console.log(staffFiltered)

  const staffSorted = staffFiltered.sort((a, b) => {
    const branchComparison = a.Branch.localeCompare(b.Branch)
    if (branchComparison !== 0) {
      return branchComparison
    }

    const positionComparison = a.Position.localeCompare(b.Position)
    if (positionComparison !== 0) {
      return positionComparison
    }

    const aName = `${a.FName} ${a.SName}`
    const bName = `${b.FName} ${b.SName}`
    return aName.localeCompare(bName)
  })

  return (
    <div className="absolute inset-0">
      <div className="flex flex-col h-full">
        <Header
          branch={selectedBranch}
          search={search}
          setSearch={setSearch}
          view={view}
          setView={setView}
          dayOfWeek={dayOfWeek}
          setDayOfWeek={setDayOfWeek}
          positionValues={positionValues}
          position={position}
          setPosition={setPosition}
        />
        <div className="overflow-y-scroll flex-1">
          {view === 'day' ? (
            <ByDay staff={staffSorted} dayOfWeek={dayOfWeek} refetch={refetch} />
          ) : (
            <ByStaff staff={staffSorted} refetch={refetch} />
          )}
        </div>
      </div>
    </div>
  )
}

const Header = ({
  branch,
  search,
  setSearch,
  view,
  setView,
  dayOfWeek,
  setDayOfWeek,
  positionValues,
  position,
  setPosition
}) => {
  const handleShiftPattern = () => {
    modals.openContextModal({
      modal: 'shiftPatternModal',
      title: 'Shift patterns',
      centered: true
    })
  }

  return (
    <div className="flex flex-none justify-between items-center p-2 border-b border-b-gray-700">
      <div className="flex items-center space-x-2">
        <MultiButton
          value={view}
          options={[
            { label: 'By staff', value: 'staff' },
            { label: 'By day', value: 'day' }
          ]}
          onChange={setView}
        />
        <Button onClick={handleShiftPattern}>Shift patterns</Button>
      </div>
      {view === 'day' && (
        <div>
          <MultiButton
            value={dayOfWeek}
            options={[
              { label: 'Mon', value: 1 },
              { label: 'Tues', value: 2 },
              { label: 'Weds', value: 3 },
              { label: 'Thurs', value: 4 },
              { label: 'Fri', value: 5 },
              { label: 'Sat', value: 6 },
              { label: 'Sun', value: 7 }
            ]}
            onChange={setDayOfWeek}
          />
        </div>
      )}
      <div className="flex items-center space-x-2">
        <NativeSelect
          value={position}
          onChange={(e) => setPosition(e.currentTarget.value)}
          data={[{ label: 'All positions', value: null }, ...positionValues]}
        />
        <BranchDropdown />
        <TextInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Type to search..."
          leftSection={<Icon Comp={MagnifyingGlassIcon} />}
        />
      </div>
    </div>
  )
}

const ByDay = ({ staff, dayOfWeek, refetch }) => {
  return (
    <div className="flex-1 h-full">
      <div className={clsx('overflow-y-scroll relative w-full h-full border-r border-r-gray-700')}>
        <div
          className="flex sticky top-0 z-50 font-semibold text-center border-b-2 border-b-gray-700"
          style={{ background: '#181A1F' }}
        >
          <div className="flex flex-1 items-center p-2 space-x-1 border-r-2 border-r-gray-700">
            <Icon Comp={UsersIcon} />
            <div>Staff</div>
          </div>

          <div className="flex flex-none border-r border-r-gray-700">
            {new Array(24).fill('ayylmao').map((_, ind) => {
              const is9t5 = ind >= 9 && ind <= 17

              return (
                <div
                  className={clsx(
                    'pt-0.5 w-[32px] border-r border-r-gray-700 z-10 relative inline-flex justify-center items-center',
                    s.dayCont,
                    s.highlightColOnly,
                    is9t5 && 'bg-gray-800'
                  )}
                >
                  <div className="leading-tight">{ind}</div>
                  <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
                </div>
              )
            })}
          </div>

          <div className="w-[500px] flex items-center justify-start p-2">
            <div>Assignment</div>
          </div>
        </div>
        <div className="z-40 select-none">
          {staff.map((row) => (
            <StaffRowByDay key={row.StaffId} staff={row} dayOfWeek={dayOfWeek} refetch={refetch} />
          ))}
        </div>
      </div>
    </div>
  )
}

const isTimeInRange = (start, end, targetHour) => {
  if (!start || !end) return { inRange: false, ratio: 1 }
  const [startHour, startMinute] = start.split(':').map((n) => parseInt(n))
  const [endHour, endMinute] = end.split(':').map((n) => parseInt(n))

  const inRange = startHour <= targetHour && endHour >= targetHour
  let ratio = 0
  let startEnd = null

  if (startHour === targetHour) {
    ratio = startMinute / 60
    startEnd = 'start'
  }

  if (endHour === targetHour) {
    ratio = endMinute / 60
    startEnd = 'end'
  }

  return { inRange, ratio, startEnd }
}

const StaffRowByDay = ({ staff, dayOfWeek, refetch }) => {
  const todaysShift = staff.Shifts?.shifts?.filter((el) => parseInt(el.day) === dayOfWeek).pop()?.shift

  return (
    <div className={clsx('flex h-full border-b border-b-gray-700', s.tRow)}>
      <div
        className={clsx('flex-1 px-2 py-1 border-r-2 border-r-gray-700  relative z-40', s.dayCont, s.highlightRowOnly)}
      >
        <div className="text-lg font-bold">
          {staff.FName} {staff.SName} <span className="text-sm font-normal">{staff.Position}</span>
        </div>
        <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
      </div>

      <div className="relative flex-none p-0 border-r border-r-gray-700">
        {new Array(24).fill('ayylmao').map((_, ind) => {
          const { inRange, ratio, startEnd } = isTimeInRange(todaysShift?.Start, todaysShift?.Finish, ind)

          return (
            <div
              key={ind}
              className={clsx('inline-block w-8 h-full border-r border-r-gray-700 relative cursor-pointer', s.dayCont)}
            >
              <div
                className={clsx(
                  'absolute inset-0 flex flex-col items-center justify-center overflow-hidden',
                  inRange && 'bg-blue-500'
                )}
              >
                {startEnd === 'start' && (
                  <div
                    className={clsx('absolute top-0 left-0 w-0 h-0 border-b-transparent border-r-transparent')}
                    style={{
                      borderWidth: `${41 * ratio}px`,
                      borderTopColor: '#1F1F1F',
                      borderLeftColor: '#1F1F1F'
                    }}
                  />
                )}
                {startEnd === 'end' && (
                  <div
                    className={clsx('absolute right-0 bottom-0 w-0 h-0 border-t-transparent border-l-transparent')}
                    style={{
                      borderWidth: `${41 * ratio}px`,
                      borderBottomColor: '#1F1F1F',
                      borderRightColor: '#1F1F1F'
                    }}
                  />
                )}
              </div>
              <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
            </div>
          )
        })}
      </div>

      <Assignment todaysShift={todaysShift} staff={staff} dayOfWeek={dayOfWeek} refetch={refetch} />
    </div>
  )
}

const StaffCard = ({ data: st, refetch }) => (
  <Card key={`card${st.StaffId}`} className="pb-2 h-[367px]">
    <div className={`relative w-full`}>
      <div className="flex sticky top-0 z-50 font-semibold text-center border-b-2 border-b-gray-700">
        <div className="flex flex-1 items-center p-2 space-x-1 border-r-2 border-r-gray-700">
          <div>
            {st.FName} {st.SName} <span className="text-sm font-normal">{st.Position}</span>
          </div>
        </div>
        <div className="flex-none border-r border-r-gray-700">
          {new Array(24).fill('ayylmao').map((_, ind) => {
            const is9t5 = ind >= 9 && ind <= 17
            return (
              <div
                key={ind}
                className={clsx(
                  'w-[32px] h-full border-r border-r-gray-700 z-10 relative inline-flex items-center justify-center',
                  st.dayCont,
                  st.highlightColOnly,
                  is9t5 && 'bg-gray-800 '
                )}
              >
                <div className="leading-tight">{ind}</div>
                <div className={clsx('absolute inset-0 pointer-events-none', st.highlightDiv)} />
              </div>
            )
          })}
        </div>
        <div className="w-[500px] flex items-center justify-between p-2">
          <div>Assignment</div>
          <div className="space-x-1">
            <CopyPasteButtons id={st.StaffId} name={`${st.FName} ${st.SName}`} refetch={refetch} />
          </div>
        </div>
      </div>
      <div className="z-40 select-none">
        {new Array(7).fill('ayylmao').map((_, ind) => (
          <StaffRowByStaff key={`${st.StaffId}${ind}`} staff={st} dayOfWeek={ind + 1} refetch={refetch} />
        ))}
      </div>
    </div>
  </Card>
)

const ByStaff = ({ staff, refetch }) => {
  return (
    <VirtualizedScrollingContainer
      data={staff}
      height={367}
      RenderElem={StaffCard}
      className="p-2"
      childProps={{ refetch }}
    />
  )
}

const CopyPasteButtons = ({ id, name, refetch }) => {
  const { copiedStaffId, setCopiedStaffId } = useShiftCopyStore()

  const handlePaste = () => {
    modals.openContextModal({
      modal: 'warningModal',
      withCloseButton: false,
      centered: true,
      innerProps: {
        message: `You are about to overwrite ${name}'s shifts with ${copiedStaffId.name}'s shifts.`,
        submit: async () => {
          setCopiedStaffId(null)
          await haxios.post(`/salesassistnew/tma/shifts/paste/${id}`, { id: copiedStaffId.id })
          await refetch()
          successToast(`Shift assignments for ${name} overwritten.`)
        }
      }
    })
  }

  const handleCopy = (id) => {
    setCopiedStaffId({ id, name })
    successToast(`Shift assignments for ${name} copied to clipboard.`)
  }

  return (
    <>
      <Button size="compact-sm" variant="subtle" onClick={() => handleCopy(id)}>
        Copy
      </Button>
      <Button
        size="compact-sm"
        variant="subtle"
        onClick={handlePaste}
        disabled={copiedStaffId === null || copiedStaffId.id === id}
      >
        Paste
      </Button>
    </>
  )
}

const StaffRowByStaff = ({ staff, dayOfWeek, refetch }) => {
  const todaysShift = staff.Shifts?.shifts?.filter((el) => parseInt(el.day) === dayOfWeek).pop()?.shift

  return (
    <div className={clsx('flex h-full [&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-gray-700', s.tRow)}>
      <div
        className={clsx(
          'flex-1 px-2 py-1 border-r-2 border-r-gray-700  relative z-40 flex items-center',
          s.dayCont,
          s.highlightRowOnly,
          (dayOfWeek === 6 || dayOfWeek === 7) && 'bg-gray-800'
        )}
      >
        <div className="text-lg font-bold">{daysOfWeek[dayOfWeek - 1]}</div>
        <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
      </div>

      <div className="relative flex-none p-0 border-r border-r-gray-700">
        {new Array(24).fill('ayylmao').map((_, ind) => {
          const { inRange, ratio, startEnd } = isTimeInRange(todaysShift?.Start, todaysShift?.Finish, ind)

          return (
            <div
              key={ind}
              className={clsx(
                'inline-block w-[32px] h-full border-r border-r-gray-700 relative cursor-pointer',
                s.dayCont
              )}
            >
              <div
                className={clsx(
                  'absolute inset-0 flex flex-col items-center justify-center overflow-hidden',
                  inRange && 'bg-blue-500'
                )}
              >
                {startEnd === 'start' && (
                  <div
                    className={clsx('absolute top-0 left-0 w-0 h-0 border-b-transparent border-r-transparent')}
                    style={{
                      borderWidth: `${41 * ratio}px`,
                      borderTopColor: '#1F1F1F',
                      borderLeftColor: '#1F1F1F'
                    }}
                  />
                )}
                {startEnd === 'end' && (
                  <div
                    className={clsx('absolute right-0 bottom-0 w-0 h-0 border-t-transparent border-l-transparent')}
                    style={{
                      borderWidth: `${41 * ratio}px`,
                      borderBottomColor: '#1F1F1F',
                      borderRightColor: '#1F1F1F'
                    }}
                  />
                )}
              </div>
              <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
            </div>
          )
        })}
      </div>

      <Assignment todaysShift={todaysShift} staff={staff} dayOfWeek={dayOfWeek} refetch={refetch} />
    </div>
  )
}

const Assignment = ({ todaysShift, staff, dayOfWeek, refetch }) => {
  const handleEdit = (shift, brk) => {
    modals.openContextModal({
      modal: 'shiftEditModal',
      title: `Edit ${staff.FName} ${staff.SName} - ${daysOfWeek[dayOfWeek - 1]}`,
      centered: true,
      innerProps: {
        defaultValues: {
          Shift: shift,
          Break: brk
        },
        id: staff.StaffId,
        day: dayOfWeek,
        refetch
      }
    })
  }

  return (
    <div className="w-[500px] p-2 flex items-center justify-between">
      <div className="inline-flex items-center">
        <div className="mr-1 font-bold">Shift:</div>
        {todaysShift ? (
          <div className="w-[120px]">
            {todaysShift.Start} - {todaysShift.Finish}
          </div>
        ) : (
          <div className="w-[120px]">—</div>
        )}
      </div>

      <div className="inline-flex items-center">
        <div className="mr-1 font-bold">Break:</div>
        <div className="w-[60px]">
          {staff?.Break?.breaks?.[dayOfWeek] ? `${staff.Break.breaks[dayOfWeek]} min` : '—'}
        </div>
      </div>

      {/* <div className="inline-flex items-center">
          <div className="mr-1 font-bold">Hours:</div>
          <div className="w-[60px]">{staff?.Break?.expectedHours ? `${staff.Break.expectedHours}` : '—'}</div>
        </div> */}

      <ActionIcon variant="default" onClick={() => handleEdit(todaysShift?._id, staff?.Break?.breaks?.[dayOfWeek])}>
        <Icon Comp={PencilIcon} />
      </ActionIcon>
    </div>
  )
}

export default Shifts
