import React from 'react';

const SalesForecast = ({
    currentSales,
    currentMargin,
    salesTarget,
    marginTarget,
    isToday
}) => {
    const styles = {
        container: {
            width: '100%',
            minWidth: '300px',
            backgroundColor: '#1f2937',
            padding: '16px',
            boxSizing: 'border-box',
            color: 'white',
        },
        trackContainer: { marginBottom: '16px' },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '4px',
        },
        titleGroup: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
        },
        title: {
            fontSize: '14px',
            fontWeight: 'bold',
        },
        dailyMetrics: {
            fontSize: '10px',
            color: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            gap: '12px',
        },
        status: {
            padding: '2px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
        },
        track: {
            height: '32px',
            display: 'flex',
            borderRadius: '6px',
            overflow: 'hidden',
            backgroundColor: '#374151',
            position: 'relative',
        },
        daySegment: {
            height: '100%',
            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
            position: 'relative',
        },
        valueContainer: {
            position: 'absolute',
            right: '8px',
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        currentValue: {
            position: 'absolute',
            left: '8px',
            fontSize: '12px',
            fontWeight: 'bold',
            color: 'white',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        projectedValue: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: 'white',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
            lineHeight: '1.2',
        },
        targetValue: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#f97316',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
            lineHeight: '1.2',
        },
        performance: {
            fontSize: '12px',
            marginRight: '8px',
        }
    };

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const currentDate = today.getDate();
    const lastDay = new Date(year, month + 1, 0).getDate();

    const getWorkingDaysElapsed = () => {
        let workingDays = 0;
        for (let i = 1; i < currentDate; i++) {
            const date = new Date(year, month, i);
            const day = date.getDay();
            if (day >= 1 && day <= 5) workingDays += 1;
            else if (day === 6) workingDays += 0.5;
        }
        return Math.max(workingDays, 1);
    };

    const getTotalWorkingDays = () => {
        let workingDays = 0;
        for (let i = 1; i <= lastDay; i++) {
            const date = new Date(year, month, i);
            const day = date.getDay();
            if (day >= 1 && day <= 5) workingDays += 1;
            else if (day === 6) workingDays += 0.5;
        }
        return workingDays;
    };

    const workingDaysElapsed = getWorkingDaysElapsed();
    const totalWorkingDays = getTotalWorkingDays();
    const workingDaysLeft = totalWorkingDays - workingDaysElapsed;

    console.info(`Current Sales: £${currentSales}, Current Margin: £${currentMargin}, Sales Target: £${salesTarget}, Margin Target: £${marginTarget}`);

    const daysArray = Array.from({ length: lastDay }, (_, i) => {
        const date = new Date(year, month, i + 1);
        const isSaturday = date.getDay() === 6;
        const isSunday = date.getDay() === 0;
        const isPast = i + 1 < currentDate;
        const isToday = i + 1 === currentDate;
        return {
            date,
            type: isSunday ? 'sunday' : isSaturday ? 'saturday' :
                isPast ? 'past' : isToday ? 'today' : 'future'
        };
    });

    const getTrackStatus = (current, target, isToday, label) => {
        if (isToday) {
            const ratio = current / target;
            const difference = target - current;
            return getRatioStatus(ratio, difference);
        }

        const dailyAverage = current / workingDaysElapsed;
        let ratio;
        let difference;

        if (label === 'MARGIN') {
            // For margin, target is already a percentage
            ratio = (dailyAverage / (currentSales / workingDaysElapsed)) / target;
            const projectedMargin = (currentMargin / currentSales);
            difference = currentSales * (target - projectedMargin);
        } else {
            // For sales, target is a daily value
            ratio = dailyAverage / target;
            const projectedTotal = dailyAverage * totalWorkingDays;
            const monthlyTarget = target * totalWorkingDays;
            difference = monthlyTarget - projectedTotal;
        }

        console.log({
            label,
            dailyAverage,
            target,
            ratio,
            difference
        });

        return getRatioStatus(ratio, difference);
    };

    const getRatioStatus = (ratio, difference) => {
        if (ratio >= 1.02) return {
            label: 'EXCELLENT',
            color: '#1eaa59',
            message: 'Keep it up!'
        };
        if (ratio >= 1) return {
            label: 'ON TRACK',
            color: '#f1c40f',
        };
        if (ratio >= 0.85) return {
            label: 'AT RISK',
            color: '#e67e22',
        };
        return {
            label: 'OFF TRACK',
            color: '#e84c3d',
        };
    };



    const formatCurrency = (value) => {
        return Math.round(value).toLocaleString('en-GB');
    };

    const getRequiredRate = (current, target) => {
        if (isToday) return 0;
        const remaining = (target * totalWorkingDays) - current;
        return workingDaysLeft > 0 ? remaining / workingDaysLeft : 0;
    };

    const dayWidth = 100 / lastDay;

    const renderTrack = (label, current, target, color) => {
        let projected, dailyMetric, monthlyTarget;

        if (isToday) {
            projected = current;
            dailyMetric = current;
            monthlyTarget = target;
        } else {
            dailyMetric = current / workingDaysElapsed;
            projected = dailyMetric * totalWorkingDays;
            monthlyTarget = target * totalWorkingDays;
        }

        const required = getRequiredRate(current, target);
        const status = getTrackStatus(current, target, isToday, label);
        const progressWidth = (current / (isToday ? target : monthlyTarget)) * 100;

        return (
            <div style={styles.trackContainer}>
                <div style={styles.header}>
                    <div style={styles.titleGroup}>
                        <span style={styles.title}>{label}</span>
                        <div style={styles.dailyMetrics}>
                            {isToday ? (
                                <span style={{ color: 'white', fontSize: 12 }}>
                                    Target: £{formatCurrency(target)}
                                </span>
                            ) : (
                                <>
                                    <span style={{ color: 'white', fontSize: 12 }}>
                                        Current avg: £{formatCurrency(dailyMetric)}/day
                                    </span>
                                    <span style={{ color: 'white', fontSize: 12 }}>
                                        Target: £{formatCurrency(target)}/day
                                    </span>
                                    {dailyMetric < target && (
                                        <span style={{ color: 'white', fontSize: 12 }}>
                                            Required: £{formatCurrency(required)}/day
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span style={{
                            ...styles.performance,
                            color: status.color
                        }}>
                            {status.message}
                        </span>
                        <span style={{
                            ...styles.status,
                            backgroundColor: status.color
                        }}>
                            {status.label}
                        </span>
                    </div>
                </div>
                <div style={styles.track}>
                    {!isToday && daysArray.map((day, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.daySegment,
                                width: `${dayWidth}%`,
                                backgroundColor: index < currentDate ? color : 'transparent',
                                opacity: index < currentDate ? 1 : 0.15
                            }}
                        />
                    ))}
                    {isToday && (
                        <div
                            style={{
                                ...styles.daySegment,
                                width: `${progressWidth}%`,
                                backgroundColor: color,
                                opacity: 1
                            }}
                        />
                    )}
                    <span style={styles.currentValue}>£{formatCurrency(current)}</span>
                    <div style={styles.valueContainer}>
                        <span style={styles.projectedValue}>£{formatCurrency(projected)}</span>
                        <span style={styles.targetValue}>£{formatCurrency(monthlyTarget)}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div style={styles.container}>
            {renderTrack('SALES', currentSales, salesTarget, '#3b82f6')}
            {renderTrack('MARGIN', currentMargin, marginTarget, '#8b5cf6')}
        </div>
    );
};

export default SalesForecast;