import React, { useContext, useState, useEffect } from 'react'
import { Modal, TextInput, Select, Group, Stack, Text, ActionIcon, Textarea, NativeSelect } from '@mantine/core'
import Button from '../components/Button'
import { useForm, yupResolver } from '@mantine/form'
import * as yup from 'yup'
import useApi from '../hooks/useApi'
import { GlobalContext } from '../../../context/GlobalContext'
import haxios from '../lib/haxios'
import { modals } from '@mantine/modals'
import useBranchStore from '../stores/useBranchStore'

const schema = yup.object().shape({
  Name: yup.string().required('Ticket name is required'),
  Message: yup.string().required('Message is required'),
  Branch: yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed')
    .required('This field is required')
})

const CreateTicketModal = ({ onClose = () => {}, innerProps }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { operatorData } = useContext(GlobalContext)
  const [error, setError] = useState(null)

  const form = useForm({
    initialValues: {
      CompanyId: 1, // spartan
      ProjectId: 1, // aa-sales-assist-spartan
      TypeId: 1, // 'query'
      Name: '',
      Description: '',
      Branch: operatorData.Status === 'bm' || operatorData.Status === 'sales' ? operatorData.Branch : ''
    },
    validate: yupResolver(schema)
  })

  const handleSubmit = async (values) => {
    setError(null)
    setIsLoading(true)

    try {
      const response = await haxios.post(
        'https://support.automm.co.uk/api/tickets',
        {
          ...values,
          Status: 'open'
        },
        { headers: { staff: operatorData.staffId, token: 'YXl5bG1hbw' } }
      )

      innerProps.onSubmit(response)
      modals.closeAll()
    } catch (err) {
      console.log(err)
      setError(err.response?.data?.error || 'An error occurred while creating the ticket')
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing="md">
        {operatorData.Status !== 'bm' && operatorData.Status !== 'sales' && (
          <BranchSelect
            label="Branch"
            name="Branch"
            placeholder="Select a branch"
            required
            error={form.errors.Name}
            {...form.getInputProps('Branch')}
          />
        )}
        <TextInput
          label="Ticket subject"
          placeholder="Enter ticket subject"
          required
          error={form.errors.Name}
          {...form.getInputProps('Name')}
        />

        <Textarea
          label="Message"
          placeholder="Enter your query"
          rows={5}
          required
          error={form.errors.Message}
          {...form.getInputProps('Message')}
        />

        {error && (
          <Text color="red" size="sm">
            {error}
          </Text>
        )}

        <div className="flex justify-end space-x-2">
          <Button variant="subtle" onClick={modals.closeAll}>
            Cancel
          </Button>
          <Button type="submit" loading={isLoading}>
            Create Ticket
          </Button>
        </div>
      </Stack>
    </form>
  )
}

const BranchSelect = (props) => {
  const branches = useBranchStore((state) => state.branches)
  const regions = useBranchStore((state) => state.regions)
  const { operatorData } = useContext(GlobalContext)

  const adminBranches = branches
    .map((b) => ({
      value: b.MamCode,
      label: `[${b.MamCode}] ${b.Branch}`
    }))
    .filter((b) => b.value !== 'ALL')

  const rmBranches = regions[operatorData.Name]
    ?.map((b) => ({
      value: b.MamCode,
      label: `[${b.MamCode}] ${b.Branch}`
    }))
    .filter((b) => b.value !== 'ALL') || [{ label: 'No assigned branches.', value: 'NONE' }]

  const options = [
    { value: '', label: 'Select a branch' },
    ...(operatorData.Status === 'admin' ? adminBranches : operatorData.Status === 'rm' ? rmBranches : [])
  ]

  return <NativeSelect {...props} data={options} />
}

export default CreateTicketModal
