import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
  Typography,
  IconButton,
  TablePagination,
  Button
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment-timezone'
import * as XLSX from 'xlsx'

const StyledTableCell = styled(TableCell)({
  fontSize: '0.7rem',
  padding: '4px 8px'
})

const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: 'unset'
  }
})

const StyledTableHead = styled(TableHead)({
  margin: 0,
  fontWeight: 'bold'
})

const formatElapsedTime = (minutes) => {
  const days = Math.floor(minutes / 1440)
  const hours = Math.floor((minutes % 1440) / 60)
  const mins = minutes % 60

  let formattedTime = ''
  if (days > 0) formattedTime += `${days}d `
  if (hours > 0) formattedTime += `${hours}h `
  if (mins > 0) formattedTime += `${mins}m`

  return formattedTime.trim() || '0m' // Return '0m' if all values are zero
}

const transformQuotesDetails = (quotesDetails) => {
  const grouped = quotesDetails.reduce((acc, item) => {
    if (!acc[item.Document]) {
      acc[item.Document] = {
        ...item,
        details: []
      }
    }
    acc[item.Document].details.push({
      SeqNo: item.SeqNo,
      Unit: item.Unit,
      Part: item.Part,
      Qty: item.Qty,
      BopDes: item.BopDes,
      Free: item.Free
    })
    return acc
  }, {})

  return Object.values(grouped)
}

const flattenQuotesDetails = (quotesDetails) => {
  return quotesDetails.flatMap((row) => {
    if (!row.details || row.details.length === 0) {
      return [
        {
          Document: row.Document,
          DateTime: moment(row.DateTime).format('DD/MM/YYYY HH:mm'),
          Acct: row.Acct,
          CustomerName: row.CustomerName,
          Goods: row.Goods,
          COrder: row.COrder,
          Age: formatElapsedTime(moment().diff(moment(row.DateTime), 'minutes')),
          SeqNo: '',
          Part: '',
          Unit: '',
          Qty: '',
          BopDes: '',
          Free: ''
        }
      ]
    }

    return row.details.map((detail) => ({
      Document: row.Document,
      DateTime: moment(row.DateTime).format('DD/MM/YYYY HH:mm'),
      Acct: row.Acct,
      CustomerName: row.CustomerName,
      Goods: row.Goods,
      COrder: row.COrder,
      Age: formatElapsedTime(moment().diff(moment(row.DateTime), 'minutes')),
      SeqNo: detail.SeqNo,
      Part: detail.Part,
      Unit: detail.Unit,
      Qty: detail.Qty,
      BopDes: detail.BopDes,
      Free: detail.Free
    }))
  })
}

const handleDownload = (quotesDetails) => {
  const flattenedData = flattenQuotesDetails(quotesDetails)
  const worksheet = XLSX.utils.json_to_sheet(flattenedData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotes')

  // Apply currency formatting to 'Goods' and 'Unit' columns
  const goodsCol = XLSX.utils.encode_col(5) // Column F
  const unitCol = XLSX.utils.encode_col(10) // Column K
  for (let i = 2; i <= flattenedData.length + 1; i++) {
    worksheet[`${goodsCol}${i}`].z = '£#,##0.00'
    worksheet[`${unitCol}${i}`].z = '£#,##0.00'
  }

  XLSX.writeFile(workbook, 'quotes_export.xlsx')
}

const Row = ({ row }) => {
  const [open, setOpen] = useState(false)
  const elapsed = moment().diff(moment(row.DateTime), 'minutes')
  const formattedElapsed = formatElapsedTime(elapsed)

  // Check if row.details is an array and sort it if it is
  const sortedDetails = Array.isArray(row.details) ? row.details.sort((a, b) => a.SeqNo - b.SeqNo) : []

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset', textAlign: 'center', fontFamily: 'Montserrat' } }}>
        <StyledTableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.Document}
        </StyledTableCell>
        <StyledTableCell align="right">{moment(row.DateTime).format('ddd DD/MM/YYYY HH:mm')}</StyledTableCell>
        <StyledTableCell align="right">{row.Acct}</StyledTableCell>
        <StyledTableCell align="right">{row.CustomerName}</StyledTableCell>
        <StyledTableCell align="right">£{row.Goods.toFixed(2)}</StyledTableCell>
        <StyledTableCell align="right">{row.COrder}</StyledTableCell>
        <StyledTableCell align="right">{formattedElapsed}</StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="details">
                <StyledTableHead>
                  <StyledTableRow
                    sx={{
                      '& > *': {
                        borderBottom: 'unset',
                        color: 'white',
                        backgroundColor: 'black',
                        textAlign: 'center',
                        fontFamily: 'Montserrat'
                      }
                    }}
                  >
                    <StyledTableCell style={{ color: 'white', fontFamily: 'Montserrat' }}>SeqNo</StyledTableCell>
                    <StyledTableCell style={{ color: 'white' }} align="right">
                      Part
                    </StyledTableCell>
                    <StyledTableCell style={{ color: 'white' }} align="right">
                      Unit
                    </StyledTableCell>
                    <StyledTableCell style={{ color: 'white' }} align="right">
                      Qty
                    </StyledTableCell>
                    <StyledTableCell style={{ color: 'white' }} align="right">
                      Desc
                    </StyledTableCell>
                    <StyledTableCell style={{ color: 'white' }} align="right">
                      Free
                    </StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <TableBody>
                  {sortedDetails.map((detailRow) => (
                    <StyledTableRow
                      sx={{ '& > *': { borderBottom: 'unset', textAlign: 'center' } }}
                      key={`${row.Document}-${detailRow.SeqNo}`}
                    >
                      <StyledTableCell component="th" scope="row">
                        {detailRow.SeqNo}
                      </StyledTableCell>
                      <StyledTableCell align="right">{detailRow.Part}</StyledTableCell>
                      <StyledTableCell align="right">£{detailRow.Unit.toFixed(2)}</StyledTableCell>
                      <StyledTableCell align="right">{detailRow.Qty}</StyledTableCell>
                      <StyledTableCell align="right">{detailRow.BopDes}</StyledTableCell>
                      <StyledTableCell align="right">{detailRow.Free || 0}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  )
}

const QuotesTable = ({ quotesDetails, filter, setFilter, handleFilterChange }) => {
  const transformedQuotesDetails = transformQuotesDetails(quotesDetails)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const filteredRows = transformedQuotesDetails.filter((row) => {
    const rowValues = Object.values(row).filter((value) => typeof value !== 'object')

    const rowMatches = rowValues.some((value) => {
      const valueString = value.toString().toLowerCase()
      const filterString = filter.toLowerCase()
      const isMatch = valueString.includes(filterString)
      return isMatch
    })

    return rowMatches
  })

  const paginatedRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDownload(transformedQuotesDetails)}
        style={{ marginBottom: '20px' }}
      >
        Download as Excel
      </Button>
      <TableContainer sx={{ '& > *': { width: '100%', height: 'auto', textAlign: 'center' } }} component={Paper}>
        <Table aria-label="collapsible table">
          <StyledTableHead>
            <StyledTableRow sx={{ '& > *': { textAlign: 'center' } }}>
              <TableCell />
              <StyledTableCell style={{ fontWeight: 'bold', fontFamily: 'Montserrat' }}>Document</StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', fontFamily: 'Montserrat' }} align="right">
                Date
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', fontFamily: 'Montserrat' }} align="right">
                Acct
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', fontFamily: 'Montserrat' }} align="right">
                Name
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', fontFamily: 'Montserrat' }} align="right">
                Value
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', fontFamily: 'Montserrat' }} align="right">
                Order No
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 'bold', fontFamily: 'Montserrat' }} align="right">
                Age
              </StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <Row key={row.Document} row={row} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={transformedQuotesDetails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

export default QuotesTable
