import React, { useContext, useEffect, useState } from 'react'
import { Card, Typography, Grid } from '@mui/material'
import { GlobalContext } from '../../context/GlobalContext'
import moment from 'moment-timezone'

function HeaderStats({
  data,
  clockingRecords,
  staffList,
  shiftAssignments,
  shifts,
  selectedMonth,
  selectedYear,
  analysisData
}) {
  //const { analyzeClockingRecordsForMonth } = useContext(GlobalContext);

  const holidays = data.holidays || []
  const sickDays = data.sick || []
  const casualWorkers = data.casual || []

  let totalCorrect = 0
  let totalCasual = 0
  let totalInfringements = 0

  for (const day of analysisData) {
    totalCorrect += day.totalOK || 0
    totalCasual += day.totalCasualWorkers || 0
    totalInfringements += day.totalInfringements || 0
  }

  return (
    <Card sx={{ padding: 2, marginTop: 2 }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h6" color="primary">
            Correct Clock-Ins
          </Typography>
          <Typography variant="body1">{totalCorrect}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="primary">
            Infringements
          </Typography>
          <Typography variant="body1">{totalInfringements}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="primary">
            Holidays
          </Typography>
          <Typography variant="body1">{holidays.length}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="primary">
            Sick Days
          </Typography>
          <Typography variant="body1">{sickDays.length}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="primary">
            Casual Workers
          </Typography>
          <Typography variant="body1">{totalCasual}</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default HeaderStats
