import { useContext } from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import useBranchStore from '../stores/useBranchStore'
import { branchMamToName } from '../lib/helpers'
import useApi from '../hooks/useApi'
import { Card } from '../gsys-ui'

const PromotionsDisplay = () => {
  const { operatorData } = useContext(GlobalContext)
  const branchName = branchMamToName(operatorData.Branch)

  const { data: promotions } = useApi({
    url: '/promotions',
    initial: [],
    refetch: 60 * 1000
  })

  const promotionsFiltered = promotions.filter((p) =>
    p.Branches.includes(operatorData.Branch)
  )

  return (
    <div>
      <div className="text-2xl font-semibold text-white">
        Promotions for {branchName}
      </div>
      <div>
        {promotionsFiltered.map((p) => (
          <div key={p._id} className="inline-block p-2 cursor-pointer">
            <a href={p.FilePath} target="_blank">
              <Card className="!w-auto">
                <div className="mb-1 text-sm font-semibold truncate">
                  {p.Title}
                </div>
                <img src={p.ThumbnailPath} className="w-[120px]" />
              </Card>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PromotionsDisplay
