import React from "react";
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
} from "@mui/material";

const CustomerDetails = ({ metrics }) => {
    if (!metrics) return null;

    // Calculate percentages
    const total = metrics.total || 0;
    const redPercent = (metrics.redCount / total * 100) || 0;
    const orangePercent = (metrics.orangeCount / total * 100) || 0;
    const greenPercent = (metrics.greenCount / total * 100) || 0;

    const PieChart = () => {
        // Calculate the SVG arc parameters
        const generateArc = (startPercent, endPercent, color) => {
            const start = (startPercent / 100) * Math.PI * 2 - Math.PI / 2;
            const end = (endPercent / 100) * Math.PI * 2 - Math.PI / 2;
            const largeArcFlag = endPercent - startPercent > 50 ? 1 : 0;

            const startX = Math.cos(start) * 60 + 75;  // Reduced radius and viewBox
            const startY = Math.sin(start) * 60 + 75;
            const endX = Math.cos(end) * 60 + 75;
            const endY = Math.sin(end) * 60 + 75;

            return `M 75 75 L ${startX} ${startY} A 60 60 0 ${largeArcFlag} 1 ${endX} ${endY} Z`;
        };

        let currentPercent = 0;
        const paths = [];

        if (redPercent > 0) {
            paths.push(<path key="red" d={generateArc(currentPercent, currentPercent + redPercent, '#ef4444')} fill="#ef4444" />);
            currentPercent += redPercent;
        }
        if (orangePercent > 0) {
            paths.push(<path key="orange" d={generateArc(currentPercent, currentPercent + orangePercent, '#f97316')} fill="#f97316" />);
            currentPercent += orangePercent;
        }
        if (greenPercent > 0) {
            paths.push(<path key="green" d={generateArc(currentPercent, currentPercent + greenPercent, '#22c55e')} fill="#22c55e" />);
        }

        return (
            <svg width="150" height="150" viewBox="0 0 150 150">
                {paths}
            </svg>
        );
    };

    return (
        <Box sx={{ padding: 2, height: '100vh', overflow: 'auto', bgcolor: '#1a1a1a', color: 'white', marginLeft: 5 }}>
            <h2 style={{ color: 'white', marginTop: 5, marginBottom: 5 }}>LINKED CUSTOMER STATS</h2>
            <Card sx={{ mb: 2, bgcolor: '#262626', color: 'white' }}>
                <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                                {metrics.total} Linked
                            </Typography>

                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Typography variant="h4" sx={{ color: '#ef4444', fontWeight: 'bold' }}>
                                        {metrics.redCount}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#ef4444' }}>
                                        Not Active
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h4" sx={{ color: '#f97316', fontWeight: 'bold' }}>
                                        {metrics.orangeCount}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#f97316' }}>
                                        Quoted
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h4" sx={{ color: '#22c55e', fontWeight: 'bold' }}>
                                        {metrics.greenCount}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#22c55e' }}>
                                        With Sales
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <PieChart />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid container spacing={2}>
                <Grid item xs={6} style={{ height: 100 }}>
                    <Card sx={{ bgcolor: '#22c55e', height: 120 }}>
                        <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                            <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold', mb: 1 }}>
                                Active Customers
                            </Typography>
                            <Box sx={{ p: 1.5, borderRadius: 1, mb: 1 }}>
                                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                    Sales: £{parseFloat(metrics.greenTotalSpend || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Typography>
                            </Box>
                            <Box sx={{ p: 1.5, borderRadius: 1 }}>
                                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                    Quotes: £{parseFloat(metrics.greenQuotesValue || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6} style={{ height: 100 }}>
                    <Card sx={{ bgcolor: '#f97316', height: 120 }}>
                        <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                            <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold', mb: 1 }}>
                                Quote-Only Customers
                            </Typography>
                            <Box sx={{ p: 1.5, borderRadius: 1 }}>
                                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                    Quotes: £{parseFloat(metrics.orangeQuotesValue || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomerDetails;