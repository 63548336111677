import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material'
import './App.css'
import moment from 'moment-timezone'
import AdminOverview from './components/admin/AdminOverview'
import BranchList from './components/admin/BranchList'
import { GlobalContext } from './context/GlobalContext'
import GETab from './components/tabs/GETab'
import StockCheck from './components/admin/StockCheck'
import NewsFlash from './components/NewsFlash'
import TandA from './components/admin/TandA'
import SettingsTab from './components/tabs/SettingsTab'
import NewMyHolidays from './components/newholidays/MyHolidays'
import Support from './components/newholidays/Support'
import CustomerTab from './components/tabs/CustomerTab'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  if (value !== index) {
    return null // Render nothing if this tab is not active
  }

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      {...other}
    >
      <Box sx={{ p: 0 }}>{children}</Box>
    </div>
  )
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function BMPanel() {
  const { username, token, operatorData, setOperatorData, isToday, setIsToday, setToken, setIsLoggedIn } =
    useContext(GlobalContext)
  const [tabValue, setTabValue] = useState(0)
  const [progress, setProgress] = useState(100)
  const [lastUpdated, setLastUpdated] = useState(null)
  const [drillDownBranch, setDrillDownBranch] = useState(null)
  const [rmBranches, setRmBranches] = useState([])
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [quitOpen, setQuitOpen] = useState(false)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const memoizedGETab = useMemo(() => <GETab />, [])

  const handleSwitchChange = (event) => {
    setIsToday(event.target.checked)
  }

  const handleLogout = () => {
    setToken(null)
    setIsLoggedIn(false)
    setLogoutOpen(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Operator Data
        const operatorResponse = await fetch(
          `https://spar.connectplus.parts/api/salesassist/cplusassist/getByUsername/${username}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        if (!operatorResponse.ok) {
          throw new Error(`HTTP error! status: ${operatorResponse.status}`)
        }
        const operatorData = await operatorResponse.json()
        setOperatorData(operatorData)

        // Fetch Branch Data
        const branchesResponse = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (!branchesResponse.ok) {
          throw new Error(`HTTP error! status: ${branchesResponse.status}`)
        }
        const branchesData = await branchesResponse.json()
        console.log('Branches Data:', branchesData)

        const filteredBranches = branchesData.filter((branch) => branch.Region === operatorData.Name)
        console.log('Filtered Branches:', filteredBranches)
        setRmBranches(filteredBranches)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchData()
    const interval = setInterval(() => {
      fetchData()
      setLastUpdated(moment().format('ddd DD/MM/YYYY HH:mm'))
    }, 60 * 1000) // 60 seconds divided by 100

    return () => clearInterval(interval)
  }, [username, token])

  return (
    <Container
      style={{
        height: 'calc(100vh - 100px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        backgroundColor: '#181a1f'
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          height: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="https://spar.connectplus.parts/api/public/images/Logo.png" alt="Logo" style={{ height: '65px' }} />
          <Switch checked={isToday} onChange={handleSwitchChange} color="primary" />
          <h3 style={{ color: 'white' }}>{isToday ? 'TODAY' : 'MONTH'}</h3>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-around' }}>
          <h5 style={{ color: 'white', cursor: 'pointer' }} onClick={() => setLogoutOpen(true)}>
            LOGOUT
          </h5>
        </Box>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
            <Tab style={{ color: 'white', fontSize: 12 }} label="Overview" {...a11yProps(0)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Customers" {...a11yProps(1)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Branches" {...a11yProps(2)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="T&A" {...a11yProps(3)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="My holidays" {...a11yProps(4)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="GE Lead" {...a11yProps(5)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Settings" {...a11yProps(6)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Support" {...a11yProps(7)} />
          </Tabs>
          <div style={{ textAlign: 'right' }}></div>
        </div>
      </Box>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0
        }}
        value={tabValue}
        index={0}
      >
        {operatorData && (
          <AdminOverview
            rmBranches={rmBranches}
            operatorData={operatorData}
            drillDownBranch={drillDownBranch}
            setDrillDownBranch={setDrillDownBranch}
            progress={progress}
            setProgress={setProgress}
          />
        )}
      </TabPanel>
      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0
        }}
        value={tabValue}
        index={1}
      >
        {operatorData && (
          <CustomerTab
            rmBranches={rmBranches}
            operatorData={operatorData}
            drillDownBranch={drillDownBranch}
            setDrillDownBranch={setDrillDownBranch}
            progress={progress}
            setProgress={setProgress}
          />
        )}
      </TabPanel>

      <TabPanel style={{ margin: '0px !important', maxHeight: '100vh', overflow: 'scroll' }} value={tabValue} index={2}>
        {operatorData && (
          <BranchList
            operatorData={operatorData}
            token={token}
            drillDownBranch={drillDownBranch}
            setDrillDownBranch={setDrillDownBranch}
            progress={progress}
            setProgress={setProgress}
            setLastUpdated={setLastUpdated}
          />
        )}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={3}
      >
        {operatorData && (
          <TandA rmBranches={rmBranches} operatorData={operatorData} progress={progress} setProgress={setProgress} />
        )}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={4}
      >
        {operatorData && <NewMyHolidays />}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={5}
      >
        {operatorData && memoizedGETab}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0
        }}
        value={tabValue}
        index={6}
      >
        {operatorData && <SettingsTab progress={progress} setProgress={setProgress} />}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll',
          position: 'relative'
        }}
        value={tabValue}
        index={7}
      >
        <div className="absolute inset-0">{operatorData && <Support />}</div>
      </TabPanel>

      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          height: 'auto',
          position: 'absolute',
          bottom: 0,
          left: 0,
          justifyContent: 'center'
        }}
      >
        {operatorData ? (
          <div
            style={{
              width: '90%',
              marginLeft: 5,
              marginRight: 5
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexGrow: 1,
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#181a1f'
              }}
            >
              <span style={{ fontSize: 40, marginRight: 20 }}>👤</span>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flex: 1
                }}
              >
                <Typography style={{ color: 'white' }}>{operatorData.Name}</Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 10
                  }}
                >
                  <Typography style={{ color: 'white' }}>({operatorData.Inits})</Typography>
                  <Typography style={{ color: 'white' }}>{operatorData.Branch}</Typography>
                </div>
              </div>
              <div style={{ flex: 2, paddingLeft: 20, minWidth: '75%' }}>
                <NewsFlash
                  containerStyle={{ width: '100%' }}
                  setLastUpdated={setLastUpdated}
                  progress={progress}
                  setProgress={setProgress}
                />
              </div>
            </div>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        ) : (
          <Typography>Loading operator data...</Typography>
        )}
      </Box>

      <Dialog
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">{'Are you sure you want to logout?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Clicking OK will log you out of the application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default BMPanel
