import { useContext, useState } from 'react'

import {
  ChatBubbleLeftRightIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PencilIcon,
  TrashIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import { Button, Checkbox, Select, Textarea, TextInput } from '@mantine/core'
import moment from 'moment-timezone'
import clsx from 'clsx'
import TicketChip from './components/TicketChip'
import StatusChip from './components/StatusChip'
import IconButton from './components/IconButton'
import useApi from './hooks/useApi'
import { modals } from '@mantine/modals'
import TypeChip from './components/TypeChip'
import axios from './lib/haxios'
import Time from './components/Timeago'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { GlobalContext } from '../../context/GlobalContext'
import haxios from './lib/haxios'
import BranchDropdown from './components/BranchDropdown'
import { branchMamToName } from './lib/helpers'
import useBranchStore from './stores/useBranchStore'

const SupportTest = () => {
  const { operatorData } = useContext(GlobalContext)
  console.log(operatorData)

  const { data } = useApi({
    url: 'https://api.aa-vision.com/api/authenticate/expired'
  })

  const { data: tickets, refetch: refetchTickets } = useApi({
    url: `https://support.automm.co.uk/api/tickets?projectId=1`,
    initial: [],
    refetch: 5 * 1000,
    headers: {
      staff: operatorData.staffId,
      token: 'YXl5bG1hbw'
    }
  })

  return (
    <div>
      <div>{JSON.stringify(data)}</div>
      <div>{JSON.stringify(tickets)}</div>
    </div>
  )
}

const Support = () => {
  const { operatorData } = useContext(GlobalContext)
  const [statusFilter, setStatusFilter] = useState('active')
  const [searchValue, setSearchValue] = useState('')
  const [selectedTicketNo, setSelectedTicketNo] = useState(null)
  const selectedBranch = useBranchStore((state) => state.selectedBranch)
  const selectedRegion = useBranchStore((state) => state.selectedRegion)
  const regions = useBranchStore((state) => state.regions)

  const { data: tickets, refetch: refetchTickets } = useApi({
    url: `https://support.automm.co.uk/api/tickets?projectId=1`,
    initial: [],
    refetch: 5 * 1000,
    headers: {
      staff: operatorData.staffId,
      token: 'YXl5bG1hbw'
    }
  })

  if (!tickets) return null

  const selectedTicket = tickets.find((t) => t.TicketNumber === selectedTicketNo)

  const filteredTickets = tickets.filter((t) => {
    const regionMatch = selectedRegion
      ? regions?.[selectedRegion]
        ? regions[selectedRegion].some((b) => b.MamCode === t.Branch)
        : false
      : true
    const isBranch = selectedBranch === 'ALL' || t.Branch === selectedBranch
    const isInStatus =
      statusFilter === 'none' ||
      (statusFilter === 'active' ? t.Status === 'open' || t.Status === 'pending' : t.Status === statusFilter)
    const isInSearch = `${t.TicketNumber + 1000} ${t.Name} ${t.Description}`
      .toLowerCase()
      .includes(searchValue.toLowerCase())
    return isInSearch && isInStatus && regionMatch && isBranch
  })

  const openCreateTicketModal = () => {
    modals.openContextModal({
      modal: 'createTicketModal',
      title: 'Add a ticket',
      centered: true,
      innerProps: {
        onSubmit: async (data) => {
          await refetchTickets()
          setSelectedTicketNo(data.TicketNumber)
        }
      }
    })
  }

  const handleSearchEnter = (e) => {
    if (filteredTickets.length === 0) return

    if (e.key === 'Enter') {
      setSelectedTicketNo(filteredTickets[0].TicketNumber)
    }
  }

  return (
    <div className="absolute inset-0 text-white">
      <nav className="absolute left-0 top-0 bottom-0 w-[400px] h-full border-r-2 border-r-gray-700  z-10">
        <div className="flex flex-col p-2 space-y-2 h-full">
          <div className="flex flex-none items-center space-x-2">
            <Button onClick={openCreateTicketModal} className="flex-none">
              New ticket
            </Button>

            <TextInput
              placeholder="Type to search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyUp={handleSearchEnter}
              className="flex-1"
            />
          </div>
          <div className="flex !mb-4 space-x-2 flex-none">
            <div className="flex-none">
              <BranchDropdown />
            </div>
            <div className="flex-1">
              <Select
                placeholder="Status"
                data={[
                  { value: 'none', label: 'All' },
                  { value: 'active', label: 'Active' },
                  { value: 'on_hold', label: 'On hold' },
                  { value: 'closed', label: 'Closed' }
                ]}
                value={statusFilter}
                onChange={(value) => setStatusFilter(value)}
              />
            </div>
          </div>

          <div className="overflow-scroll flex-1 p-1 space-y-2">
            {filteredTickets.map((t, ind) => (
              <TicketLink
                key={ind}
                ticket={t}
                active={t.TicketNumber === selectedTicket?.TicketNumber}
                setSelectedTicketNo={setSelectedTicketNo}
              />
            ))}
          </div>
        </div>
      </nav>

      {selectedTicket ? (
        <SelectedTicket ticketNo={selectedTicket?.TicketNumber} refetch={refetchTickets} operatorData={operatorData} />
      ) : null}
    </div>
  )
}

const SelectedTicket = ({ ticketNo, refetch, operatorData }) => {
  const { data: ticket, refetch: refetchTicket } = useApi({
    url: `https://support.automm.co.uk/api/tickets/${ticketNo}`,
    refetch: 5 * 1000,
    headers: {
      staff: operatorData.staffId,
      token: 'YXl5bG1hbw'
    }
  })

  if (!ticket) return null

  return (
    <>
      <div className="absolute top-0 left-[400px] right-[0px]">
        <TicketTitle ticket={ticket} />
      </div>

      <main
        key={ticket.TicketNo}
        className="absolute top-[105px] left-[400px] right-[0px] bottom-0 pl-[56px] pr-8 pb-[310px] pt-4 overflow-scroll no-scrollbar flex flex-col-reverse space-y-6 space-y-reverse"
        style={{ scrollTop: '1000vh' }}
      >
        {[...ticket.Messages].reverse().map((m) => {
          if (m.IsStatusChange) return <Status key={m.MessageId} message={m} />
          if (m.IsPriorityChange) return <Priority key={m.MessageId} message={m} />
          if (m.Content) {
            if (m.IsSilent) {
              return null
            }
            return <Message key={m.MessageId} message={m} />
          }
          return null
        })}
      </main>

      <div className="absolute bottom-0 right-[0px] left-[400px] border-t-2 border-t-gray-700 bg-gray-900 z-10">
        <MessageInput ticket={ticket} refetch={refetchTicket} operatorData={operatorData} />
      </div>
    </>
  )
}

const TicketLink = ({ ticket, active, setSelectedTicketNo }) => {
  return (
    <div onClick={() => setSelectedTicketNo(ticket.TicketNumber)}>
      <div
        className={clsx(
          'px-2 py-2 w-full bg-stone-800 rounded transition-colors cursor-pointer ',
          active && 'outline outline-2 outline-blue-500',
          !active && 'hover:bg-stone-700'
        )}
      >
        <div className="flex items-center mb-1 space-x-2 w-full">
          <ChatBubbleLeftRightIcon className="size-[24px] text-gray-200 flex-none" />
          {ticket.Priority === 'high' && <ExclamationTriangleIcon className="size-[24px] text-red-500 flex-none" />}
          <div className="flex-1 font-semibold truncate">{ticket.Name}</div>
        </div>
        <div className="flex items-center mb-1 space-x-2 w-full">
          <div className="flex-1 text-sm truncate">
            {branchMamToName(ticket.Branch)} - {ticket.SupportUserName}
          </div>
        </div>
        <div className="flex justify-between items-end">
          <div className="space-x-1">
            <TicketChip num={ticket.TicketNumber} />
            <TypeChip label={ticket.TicketTypeName} color={ticket.TicketTypeColor} />
            <StatusChip status={ticket.Status} />
          </div>
          <div className="text-xs text-gray-200">{moment(ticket.UpdatedAt).format('DD/MM/YYYY HH:mm')}</div>
        </div>
      </div>
    </div>
  )
}

const TicketTitle = ({ ticket }) => {
  return (
    <div className="flex items-center p-6 space-x-4 border-b border-b-gray-700">
      <div className="p-2 rounded bg-stone-800">
        <ChatBubbleLeftRightIcon className="size-[40px] text-gray-200 flex-none" />
      </div>
      <div>
        <div className="flex items-center mb-1 space-x-2">
          {ticket.Priority === 'high' && <ExclamationTriangleIcon className="size-[28px] text-red-500 flex-none" />}
          <div className="flex-none">
            <TicketChip num={ticket.TicketNumber} />
          </div>
          <div className="flex-none">
            <TypeChip label={ticket.TicketTypeName} color={ticket.TicketTypeColor} />
          </div>
          <div className="flex-none">
            <StatusChip status={ticket.Status} />
          </div>
          <div className="flex-1 text-lg font-semibold truncate">{ticket.Name}</div>
        </div>
        <div className="text-sm text-gray-200">
          {branchMamToName(ticket.Branch)} - {ticket.SupportUserName} -{' '}
          {moment(ticket.CreatedAt).format('DD/MM/YYYY HH:mm')}
        </div>
      </div>
    </div>
  )
}

const Message = ({ message }) => {
  return (
    <div className="p-4 bg-transparent rounded border border-gray-700 messageBox">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-baseline space-x-3">
          <div className="font-semibold">
            {!!message.StaffId && <span className="px-1 py-0.5 mr-2 text-gray-800 bg-gray-100 rounded">AA</span>}
            {message.StaffName}{' '}
          </div>
          <div className="text-sm text-gray-500">
            sent <Time date={message.CreatedAt} />
          </div>
        </div>

        {/* <div className="flex space-x-1">
          <IconButton Icon={PencilIcon} />
          <IconButton Icon={TrashIcon} />
        </div> */}
      </div>

      <div>{message.Content}</div>
    </div>
  )
}

const Status = ({ message }) => {
  return (
    <div className="p-1.5 statusBox">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="mr-3 font-semibold">{message.StaffName}</div>
          <div className="mr-2">set Status to</div>
          <StatusChip status={message.NewStatus} />
          <div className="ml-4 text-sm text-gray-500">
            <Time date={message.CreatedAt} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Priority = ({ message }) => {
  return (
    <div className="p-1.5 statusBox">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="mr-3 font-semibold">{message.StaffName}</div>
          <div className="mr-2">
            set Priority to{' '}
            <span className="font-semibold">{message.NewPriority === 'medium' ? 'Standard' : 'Critical'}</span>
          </div>
          <div className="ml-2 text-sm text-gray-500">
            <Time date={message.CreatedAt} />
          </div>
        </div>
      </div>
    </div>
  )
}

const MessageInput = ({ ticket, refetch, operatorData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')

  const handleSendMessage = async () => {
    setIsLoading(true)

    await haxios.post(
      `https://support.automm.co.uk/api/tickets/${ticket.TicketNumber}/messages`,
      {
        Content: message,
        IsSilent: false
      },
      {
        headers: {
          staff: operatorData.staffId,
          token: 'YXl5bG1hbw'
        }
      }
    )

    await refetch()
    setMessage('')
    setIsLoading(false)
  }

  return (
    <div>
      <div className="flex justify-between items-center p-1 border-b border-b-gray-700">
        <div>
          <div className="font-semibold">{operatorData.Name}</div>
        </div>
        {/* <div>
          <IconButton Icon={PaperClipIcon} />
        </div> */}
      </div>

      <div className="px-2">
        <Textarea
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          minRows={5}
          maxRows={8}
          autosize
          variant="unstyled"
          className="flex-1"
          styles={{
            input: {
              transition: 'height 200ms ease',
              fontSize: '14px',
              lineHeight: '1.5'
            }
          }}
        />
      </div>

      <div className="flex justify-end items-center p-2 space-x-6 border-t border-t-gray-700">
        <Button
          rightSection={<PaperAirplaneIcon className="w-5 h-5" />}
          disabled={message.trim().length === 0}
          onClick={handleSendMessage}
          loading={isLoading}
        >
          Send
        </Button>
      </div>
    </div>
  )
}

export default Support
