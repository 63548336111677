import { create } from 'zustand';

const MANUAL_CLOCK_ID = '33f9a591-466a-471d-9bf6-44d326d796a5';
const MACHINE_CLOCK_ID = 'de8c4712-f9c9-4a32-a129-854257541aa6';

const useClockStatsStore = create((set) => ({
    machineClocks: 0,
    manualClocks: 0,
    isLoading: false,
    setLoading: (loading) => set({ isLoading: loading }),
    updateClockStats: (clockingRecords) => {
        if (!Array.isArray(clockingRecords)) {
            set({ machineClocks: 0, manualClocks: 0, isLoading: false });
            return;
        }

        const counts = clockingRecords.reduce((acc, record) => {
            const attendanceType = record.AttendanceTypeID;

            if (attendanceType === MANUAL_CLOCK_ID) {
                acc.manual++;
            } else if (attendanceType === MACHINE_CLOCK_ID) {
                acc.machine++;
            }
            return acc;
        }, { manual: 0, machine: 0 });

        set({
            machineClocks: counts.machine,
            manualClocks: counts.manual,
            isLoading: false
        });
    },
}));

export default useClockStatsStore;