import React from 'react';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import '../App.css';


const QuoteConversionChart = ({ quotesConverted, quotesOutstanding }) => {
  const progress = Math.min((quotesConverted / quotesOutstanding) * 100, 100);
  const textColor = 'white'

  const getColorBasedOnProgress = (progress) => {
    if (progress >= 80) {
      return 'green';
    } else if (progress >= 60) {
      return 'orange';
    } else if (progress >= 30) {
      return 'pink';
    } else if (progress >= 10) {
      return 'red';
    }
    return 'grey'; // Default color for progress less than 10%
  };

  const barColor = getColorBasedOnProgress(progress);



  return (
    <div style={{
      backgroundColor: '#21242c',
      borderRadius: 15,
      height: 140,
      padding: 10,
    }}>
      <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 24 }}>QUOTES</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ color: textColor, display: 'flex', flexDirection: 'row', flex: 0.6, justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            ✅ <strong style={{ margin: 10 }}>Invoiced: </strong>
            <h2
              style={{
                color: 'white',
                margin: 0,
                backgroundColor: 'darkgreen',
                borderRadius: '50%',
                height: 30,
                width: 30,
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 20
              }}>{quotesConverted}</h2>
          </div>
        </Typography>
      </div>
      <Box sx={{ position: 'relative', width: '100%', mr: 1, height: 20, marginTop: 1, marginBottom: 2 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: '100%',
            borderRadius: 5,
            '& .MuiLinearProgress-bar': {
              backgroundColor: barColor, // Apply dynamic color
            }
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            left: progress > 10 ? `${progress}%` : '10px', // Adjust this for label positioning
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white' // Adjust text color for visibility
          }}
        >
          <Typography style={{ color: 'white', fontWeight: 'bold' }} variant="body2" >{progress ? `${Math.round(progress)}%` : '0%'}</Typography>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>

          <strong style={{ color: textColor, }}>{progress ? progress.toFixed(2) : 0}%</strong>
          <Typography style={{ color: textColor, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            ⚠️ <strong style={{ margin: 10 }}>Quoted: </strong><h2
              style={{
                color: 'white',
                margin: 0,
                backgroundColor: 'darkorange',
                borderRadius: '50%',
                height: 30,
                width: 30,
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 20
              }}>{quotesOutstanding}</h2>
          </Typography>
        </div>
      </Box>
    </div>
  );
};

export default QuoteConversionChart;
