import React, { useContext, useState } from 'react'
import { Card, CardContent, Typography, Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import BMInfringementHandler from './BMInfringementHandler'
import SupervisorInfringementHandler from './SupervisorInfringementHandler'
import { GlobalContext } from '../../context/GlobalContext'

const InfringementHandler = ({
    selectedDetail,
    setHighlightedRecord,
    onAcceptInfringement,
    onClose,
    refreshClockingRecords,
    fetchSelectedMonthClockingRecords,
    staffBranch,
    fetchAcceptedInfringements,
    refreshAnalysisData,
    markAsHoliday,
    markAsSick,
    staffList
}) => {
    const [viewMode, setViewMode] = useState('bm')
    const {
        token,
        operatorData
    } = useContext(GlobalContext)
    // Check if user has supervisor permissions
    const hasSupervisorAccess = operatorData?.Status === 'rm' || operatorData?.Status === 'admin'

    // Handle view mode change
    const handleViewChange = (event, newViewMode) => {
        if (newViewMode !== null) {
            setViewMode(newViewMode)
        }
    }

    return (
        <Card sx={{ mt: 2, p: 2 }}>
            {hasSupervisorAccess && (
                <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <ToggleButtonGroup
                        value={viewMode}
                        exclusive
                        onChange={handleViewChange}
                        size="small"
                    >
                        <ToggleButton value="bm">
                            BM View
                        </ToggleButton>
                        <ToggleButton value="supervisor">
                            RM View
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )}

            {viewMode === 'bm' ? (
                <BMInfringementHandler
                    selectedDetail={selectedDetail}
                    setHighlightedRecord={setHighlightedRecord}
                    onAcceptInfringement={onAcceptInfringement}
                    onClose={onClose}
                    refreshClockingRecords={refreshClockingRecords}
                />
            ) : (
                <SupervisorInfringementHandler
                    selectedDetail={selectedDetail}
                    setHighlightedRecord={setHighlightedRecord}
                    onClose={onClose}
                    refreshClockingRecords={refreshClockingRecords}
                    token={token}
                    fetchSelectedMonthClockingRecords={fetchSelectedMonthClockingRecords}
                    staffBranch={staffBranch}
                    fetchAcceptedInfringements={fetchAcceptedInfringements}
                    refreshAnalysisData={refreshAnalysisData}
                    markAsHoliday={markAsHoliday}
                    markAsSick={markAsSick}
                    staffList={staffList}
                />
            )}
        </Card>
    )
}

export default InfringementHandler