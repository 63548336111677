import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, Text } from '@mantine/core'
import { useForm, Controller } from 'react-hook-form'
import { TextInput, FileInput } from 'react-hook-form-mantine'
import { DateTimePicker } from '@mantine/dates'
import yup from '../lib/yup'
import { Checkbox } from '@mantine/core'
import useBranchStore from '../stores/useBranchStore'
import haxios from '../lib/haxios'

const validation = yup.object().shape({
  title: yup
    .string()
    .required('Title is required')
    .min(3, 'Title must be at least 3 characters'),
  expiryTime: yup
    .date()
    .required('Expiry time is required')
    .test('future', 'Expiry time must be in the future', (value) => {
      if (!value) return true
      return value > new Date()
    }),
  pdf: yup
    .mixed()
    .required('PDF is required')
    .test(
      'fileType',
      'File must be a PDF',
      (value) => value && value.type === 'application/pdf'
    ),
  branches: yup
    .array()
    .of(yup.string())
    .min(1, 'Select at least one branch')
    .required('Select at least one branch')
})

const PromotionUploadModal = ({ context, id, innerProps }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [pdfUrl, setPdfUrl] = useState(null)
  const regions = useBranchStore((state) => state.regions)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    //resolver: yupResolver(validation),
    defaultValues: innerProps?.defaultValues || {
      title: '',
      expiryTime: null,
      pdf: null,
      branches: []
    }
  })

  const handleFileSelect = (file) => {
    setValue('pdf', file, { shouldValidate: true })
    if (file) {
      const url = URL.createObjectURL(file)
      setPdfUrl(url)
    } else {
      setPdfUrl(null)
    }
  }

  console.log('Form values before submit:', control._formValues)

  const onSubmit = async (data) => {
    console.log('onSubmit called with data:', data)
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('pdf', data.pdf)
      formData.append('title', data.title)
      formData.append('expiryTime', data.expiryTime.toISOString())
      formData.append('branches', JSON.stringify(data.branches))

      const response = await (innerProps?.defaultValues?.id
        ? haxios.put(`/promotions/${innerProps?.defaultValues?.id}`, {
            title: data.title,
            expiryTime: data.expiryTime.toISOString(),
            branches: JSON.stringify(data.branches)
          })
        : haxios.post('/promotions/upload', formData))

      if (innerProps?.refetch) {
        await innerProps.refetch()
      }

      context.closeModal(id)
    } catch (error) {
      console.error('Error uploading promotion:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form
      onSubmit={(e) => {
        console.log('Form submit event triggered')
        e.preventDefault()
        return handleSubmit(onSubmit)(e)
      }}
    >
      <Grid>
        {/* Left Side - Form */}
        <Grid.Col span={7}>
          <Grid>
            <Grid.Col span={4}>
              <TextInput
                label="Promotion title"
                name="title"
                control={control}
                error={errors.title?.message}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Controller
                name="expiryTime"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    label="Expiry time"
                    minDate={new Date()}
                    value={field.value}
                    onChange={(date) => {
                      field.onChange(date)
                      setValue('expiryTime', date, { shouldValidate: true })
                    }}
                    error={errors.expiryTime?.message}
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              {!innerProps?.defaultValues?.pdf && (
                <FileInput
                  label="Select PDF"
                  name="pdf"
                  control={control}
                  accept="application/pdf"
                  onChange={handleFileSelect}
                  placeholder="Select PDF file"
                  error={errors.pdf?.message}
                />
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <div className="flex items-center space-x-2">
                <div className="font-semibold">Select branches</div>
              </div>
              {errors.branches && (
                <Text color="red" size="sm" mt={2}>
                  {errors.branches.message}
                </Text>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <div className="h-[365px] overflow-y-scroll">
                {Object.keys(regions).map((region) => {
                  const regionBranches = regions[region]
                  const regionMamCodes = regionBranches.map((b) => b.MamCode)
                  const regionIsSelected = (field) =>
                    regionBranches.every((branch) =>
                      field.value.includes(branch.MamCode)
                    )

                  return (
                    <div key={region}>
                      <Controller
                        name="branches"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Checkbox
                              label={`Region ${region}`}
                              checked={regionIsSelected(field)}
                              onChange={(e) => {
                                let newBranches = [...field.value]
                                if (e.currentTarget.checked) {
                                  regionMamCodes.forEach((mamCode) => {
                                    if (!newBranches.includes(mamCode)) {
                                      newBranches.push(mamCode)
                                    }
                                  })
                                } else {
                                  newBranches = newBranches.filter(
                                    (b) => !regionMamCodes.includes(b)
                                  )
                                }
                                field.onChange(newBranches)
                              }}
                            />
                            <div className="relative pl-6">
                              <div className="absolute w-[1px] bg-gray-700 left-[9px] top-1 bottom-1" />
                              {regionBranches.map((branch) => (
                                <div key={branch.MamCode} className="py-1">
                                  <Checkbox
                                    label={`[${branch.MamCode}] ${branch.Branch}`}
                                    checked={field.value.includes(
                                      branch.MamCode
                                    )}
                                    onChange={(e) => {
                                      const updatedBranches = e.currentTarget
                                        .checked
                                        ? [...field.value, branch.MamCode]
                                        : field.value.filter(
                                            (b) => b !== branch.MamCode
                                          )
                                      field.onChange(updatedBranches)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  )
                })}
              </div>
            </Grid.Col>

            <Grid.Col span={12}>
              <div className="flex space-x-4">
                <Button
                  variant="outline"
                  onClick={() => context.closeModal(id)}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button type="submit" loading={isLoading}>
                  {innerProps?.defaultValues?.id ? 'Save' : 'Upload'}
                </Button>
              </div>
            </Grid.Col>
          </Grid>
        </Grid.Col>

        {/* Right Side - Preview */}
        <Grid.Col span={5}>
          <div className="overflow-hidden w-full h-full rounded-md border border-neutral-800">
            {pdfUrl || innerProps?.defaultValues?.pdf ? (
              <iframe
                src={pdfUrl || innerProps?.defaultValues?.pdf}
                className="w-full h-full"
                title="PDF Preview"
              />
            ) : (
              <div className="flex justify-center items-center w-full h-full bg-neutral-700">
                <Text color="dimmed">No PDF selected</Text>
              </div>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </form>
  )
}

export default PromotionUploadModal
