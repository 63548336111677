import React, { useMemo } from 'react';

const SalesForecastChart = ({ currentSales, currentMargin, salesTarget, marginTarget }) => {
    const styles = {
        container: {
            width: '100%',
            minWidth: '300px',
            backgroundColor: '#1f2937',
            padding: '16px',
            boxSizing: 'border-box',
            color: 'white',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '12px',
        },
        chartContainer: {
            height: '200px',
            position: 'relative',
            marginTop: '24px',
        },
        chart: {
            height: '100%',
            width: '100%',
        },
        targetContainer: {
            position: 'absolute',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        targetLabel: {
            backgroundColor: '#1f2937',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        },
        dot: {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
        },
        value: {
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            backgroundColor: '#1f2937',
        },
        status: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        },
        statusPill: {
            padding: '2px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
        },
        metric: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        },
        metricLabel: {
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
        }
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            maximumFractionDigits: 0,
        }).format(value);
    };

    const calculations = useMemo(() => {
        const today = new Date();
        const currentDate = today.getDate();
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        const progressPercent = (currentDate / lastDay) * 100;

        const isWeekend = (date) => {
            const day = date.getDay();
            return day === 0 || day === 6;
        };

        let workingDaysGone = 0;
        let workingDaysTotal = 0;

        for (let i = 1; i <= lastDay; i++) {
            const date = new Date(today.getFullYear(), today.getMonth(), i);
            if (!isWeekend(date)) {
                workingDaysTotal++;
                if (i < currentDate) workingDaysGone++;
            }
        }

        const workingDaysLeft = workingDaysTotal - workingDaysGone;

        const dailyRateSales = currentSales / workingDaysGone;
        const projectedSales = currentSales + (dailyRateSales * workingDaysLeft);

        const dailyRateMargin = currentMargin / workingDaysGone;
        const projectedMargin = currentMargin + (dailyRateMargin * workingDaysLeft);

        const monthlyTargetSales = salesTarget * workingDaysTotal;
        const monthlyTargetMargin = marginTarget * workingDaysTotal;

        const maxValue = Math.max(projectedSales, monthlyTargetSales, projectedMargin, monthlyTargetMargin) * 1.1;

        const getTrackStatus = (projected, target) => {
            const percentage = (projected / target) * 100;
            const diff = target - projected;
            const diffPercent = ((target - projected) / target) * 100;

            if (percentage >= 100) return {
                label: 'EXCELLENT',
                color: '#22c55e',
                diff,
                diffPercent,
                message: 'Keep it up!'
            };
            if (percentage >= 98) return {
                label: 'ON TRACK',
                color: '#22c55e',
                diff,
                diffPercent,
                // message: `${formatCurrency(diff)} to target`
            };
            if (percentage >= 90) return {
                label: 'AT RISK',
                color: '#eab308',
                diff,
                diffPercent,
                // message: `${formatCurrency(diff)} to target`
            };
            return {
                label: 'OFF TRACK',
                color: '#ef4444',
                diff,
                diffPercent,
                // message: `${formatCurrency(diff)} to target`
            };
        };

        const getYPosition = (value) => {
            return 100 - ((value / maxValue) * 100);
        };

        const createPath = (currentValue, projectedValue) => {
            const current = getYPosition(currentValue);
            const projected = getYPosition(projectedValue);

            return {
                current: `M 0 100 L ${progressPercent} ${current}`,
                projected: `M ${progressPercent} ${current} L 100 ${projected}`
            };
        };

        const salesPath = createPath(currentSales, projectedSales);
        const marginPath = createPath(currentMargin, projectedMargin);

        const salesStatus = getTrackStatus(projectedSales, monthlyTargetSales);
        const marginStatus = getTrackStatus(projectedMargin, monthlyTargetMargin);

        return {
            progressPercent,
            projectedSales,
            projectedMargin,
            getYPosition,
            salesPath,
            marginPath,
            salesStatus,
            marginStatus,
            monthlyTargetSales,
            monthlyTargetMargin
        };
    }, [currentSales, currentMargin, salesTarget, marginTarget]);

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <div style={styles.metric}>
                    <div style={styles.metricLabel}>
                        <div style={{ ...styles.dot, backgroundColor: calculations.salesStatus.color }} />
                        <span>SALES</span>
                    </div>
                    <div style={styles.status}>
                        <span style={{ color: calculations.salesStatus.color }}>
                            {calculations.salesStatus.message}
                        </span>
                        <span style={{
                            ...styles.statusPill,
                            backgroundColor: calculations.salesStatus.color
                        }}>
                            {calculations.salesStatus.label}
                        </span>
                    </div>
                </div>
            </div>

            <div style={styles.header}>
                <div style={styles.metric}>
                    <div style={styles.metricLabel}>
                        <div style={{ ...styles.dot, backgroundColor: calculations.marginStatus.color }} />
                        <span>MARGIN</span>
                    </div>
                    <div style={styles.status}>
                        <span style={{ color: calculations.marginStatus.color }}>
                            {calculations.marginStatus.message}
                        </span>
                        <span style={{
                            ...styles.statusPill,
                            backgroundColor: calculations.marginStatus.color
                        }}>
                            {calculations.marginStatus.label}
                        </span>
                    </div>
                </div>
            </div>

            <div style={styles.chartContainer}>
                <svg style={styles.chart} viewBox="0 0 100 100" preserveAspectRatio="none">
                    {/* Target lines */}
                    <line
                        x1="0"
                        y1={calculations.getYPosition(calculations.monthlyTargetSales)}
                        x2="100"
                        y2={calculations.getYPosition(calculations.monthlyTargetSales)}
                        stroke={calculations.salesStatus.color}
                        strokeWidth="1"
                        strokeDasharray="4"
                        opacity="0.3"
                    />
                    <line
                        x1="0"
                        y1={calculations.getYPosition(calculations.monthlyTargetMargin)}
                        x2="100"
                        y2={calculations.getYPosition(calculations.monthlyTargetMargin)}
                        stroke={calculations.marginStatus.color}
                        strokeWidth="1"
                        strokeDasharray="4"
                        opacity="0.3"
                    />

                    {/* Sales line */}
                    <path
                        d={calculations.salesPath.current}
                        stroke={calculations.salesStatus.color}
                        strokeWidth="2"
                        fill="none"
                    />
                    <path
                        d={calculations.salesPath.projected}
                        stroke={calculations.salesStatus.color}
                        strokeWidth="2"
                        strokeDasharray="4"
                        fill="none"
                        opacity="0.5"
                    />

                    {/* Margin line */}
                    <path
                        d={calculations.marginPath.current}
                        stroke={calculations.marginStatus.color}
                        strokeWidth="2"
                        fill="none"
                    />
                    <path
                        d={calculations.marginPath.projected}
                        stroke={calculations.marginStatus.color}
                        strokeWidth="2"
                        strokeDasharray="4"
                        fill="none"
                        opacity="0.5"
                    />

                    {/* Current value markers */}
                    <circle
                        cx={calculations.progressPercent}
                        cy={calculations.getYPosition(currentSales)}
                        r="4"
                        fill={calculations.salesStatus.color}
                    />
                    <circle
                        cx={calculations.progressPercent}
                        cy={calculations.getYPosition(currentMargin)}
                        r="4"
                        fill={calculations.marginStatus.color}
                    />
                </svg>

                {/* Current values with backgrounds */}
                <div style={{
                    ...styles.value,
                    position: 'absolute',
                    left: `${calculations.progressPercent}%`,
                    top: `${calculations.getYPosition(currentSales)}%`,
                    transform: 'translate(-50%, -150%)',
                    color: calculations.salesStatus.color,
                }}>
                    {formatCurrency(currentSales)}
                </div>
                <div style={{
                    ...styles.value,
                    position: 'absolute',
                    left: `${calculations.progressPercent}%`,
                    top: `${calculations.getYPosition(currentMargin)}%`,
                    transform: 'translate(-50%, -150%)',
                    color: calculations.marginStatus.color,
                }}>
                    {formatCurrency(currentMargin)}
                </div>

                {/* Target labels */}
                <div style={{
                    ...styles.targetContainer,
                    top: `${calculations.getYPosition(calculations.monthlyTargetSales)}%`
                }}>
                    <div style={{ ...styles.targetLabel, color: calculations.salesStatus.color }}>
                        <div style={{ ...styles.dot, backgroundColor: calculations.salesStatus.color }} />
                        Sales Target
                    </div>
                    <div style={{ ...styles.value, color: calculations.salesStatus.color }}>
                        {formatCurrency(calculations.monthlyTargetSales)}
                    </div>
                </div>

                <div style={{
                    ...styles.targetContainer,
                    top: `${calculations.getYPosition(calculations.monthlyTargetMargin)}%`
                }}>
                    <div style={{ ...styles.targetLabel, color: calculations.marginStatus.color }}>
                        <div style={{ ...styles.dot, backgroundColor: calculations.marginStatus.color }} />
                        Margin Target
                    </div>
                    <div style={{ ...styles.value, color: calculations.marginStatus.color }}>
                        {formatCurrency(calculations.monthlyTargetMargin)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesForecastChart;