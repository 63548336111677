import { create } from 'zustand'

const useBranchStore = create((set) => ({
  branches: null,
  regions: null,
  setBranches: (branches) => {
    branches = [{ MamCode: 'ALL', Branch: 'All branches' }, ...branches]
    if (branches.length === 0) return
    const firstBranch = branches[0]

    return set({
      branches,
      selectedBranch: firstBranch.MamCode
    })
  },
  setRegions: (regions) => set({ regions }),
  selectedBranch: null,
  selectedRegion: null,
  setSelectedBranch: (selectedBranch) => set({ selectedBranch, selectedRegion: null }),
  setSelectedRegion: (selectedRegion) => set({ selectedBranch: 'ALL', selectedRegion })
}))

export const branchStore = useBranchStore
export default useBranchStore
