import React, { useRef, useEffect } from 'react';
import { MessageSquare } from 'lucide-react';

const formatMessageTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const formatDateSeparator = (dateTime) => {
    const date = new Date(dateTime);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
    }
    return date.toLocaleDateString([], { weekday: 'long', month: 'long', day: 'numeric' });
};

const DateSeparator = ({ date }) => (
    <div className="flex items-center justify-center my-4">
        <div className="bg-gray-700 text-gray-300 text-xs px-3 py-1 rounded-full">
            {formatDateSeparator(date)}
        </div>
    </div>
);

const MessageGroup = ({ messages, isCurrentUser }) => (
    <div className={`flex flex-col gap-1 mb-3 ${isCurrentUser ? 'items-end' : 'items-start'}`}>
        <div className="text-xs text-gray-400 px-2 mb-0.5">{messages[0].User}</div>
        {messages.map((message, idx) => (
            <div
                key={idx}
                className={`flex flex-col ${isCurrentUser ? 'items-end' : 'items-start'}`}
            >
                <div
                    className={`px-3 py-1.5 rounded-lg max-w-fit break-words text-sm
            ${isCurrentUser ?
                            'bg-blue-600 text-white' :
                            'bg-gray-700 text-white'
                        }`}
                >
                    <div>{message.Comment}</div>
                    {message.Image && (
                        <img
                            src={message.Image}
                            alt="Attachment"
                            className="mt-2 rounded-lg max-w-full"
                        />
                    )}
                </div>
                {idx === messages.length - 1 && (
                    <div className="text-xs text-gray-400 mt-0.5 px-2">
                        {formatMessageTime(message.DateTime)}
                    </div>
                )}
            </div>
        ))}
    </div>
);

export const CommentModal = ({
    open,
    onClose,
    customer,
    newComment,
    setNewComment,
    newImage,
    setNewImage,
    onAddComment,
    isPosting,
    currentUser
}) => {
    const commentBoxRef = useRef(null);

    useEffect(() => {
        if (open && commentBoxRef.current) {
            const scrollElement = commentBoxRef.current;
            scrollElement.scrollTop = scrollElement.scrollHeight;
        }
    }, [open, customer?.comments.length]);

    const handlePaste = (e) => {
        const file = e.clipboardData?.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setNewImage(reader.result);
            reader.readAsDataURL(file);
        }
    };

    // Group messages by date and user
    const groupMessages = (comments) => {
        if (!comments?.length) return [];

        const groups = [];
        let currentGroup = [comments[0]];
        let currentDate = new Date(comments[0].DateTime).toDateString();

        for (let i = 1; i < comments.length; i++) {
            const comment = comments[i];
            const commentDate = new Date(comment.DateTime).toDateString();
            const prevComment = comments[i - 1];
            const timeDiff = new Date(comment.DateTime) - new Date(prevComment.DateTime);
            const isNewDay = commentDate !== currentDate;
            const isSameUser = comment.User === prevComment.User;
            const isWithinTimeWindow = timeDiff < 5 * 60 * 1000; // 5 minutes

            if (isNewDay) {
                groups.push({ type: 'date', date: comment.DateTime });
                groups.push({ type: 'messages', messages: [comment] });
                currentGroup = [comment];
                currentDate = commentDate;
            } else if (isSameUser && isWithinTimeWindow) {
                currentGroup.push(comment);
            } else {
                groups.push({ type: 'messages', messages: currentGroup });
                currentGroup = [comment];
            }
        }

        if (currentGroup.length > 0) {
            groups.push({ type: 'messages', messages: currentGroup });
        }

        return groups;
    };

    return (
        <div className={`fixed inset-0 bg-black/50 flex items-center justify-center ${open ? '' : 'hidden'}`}>
            <div className="bg-[#1a1d24] w-[90%] max-w-2xl rounded-lg shadow-xl flex flex-col max-h-[90vh]">
                {/* Header */}
                <div className="p-4 border-b border-gray-800 flex items-center gap-3">
                    <div>
                        <div className="text-base font-medium text-white">
                            {customer?.customerName}
                        </div>
                        <div className="text-sm text-gray-400">
                            {customer?.customerCode}
                        </div>
                    </div>
                </div>

                {/* Comment Area */}
                <div
                    ref={commentBoxRef}
                    className="flex-1 overflow-y-auto p-4 space-y-2 min-h-[300px] max-h-[60vh]"
                >
                    {groupMessages(customer?.comments).map((group, idx) => (
                        group.type === 'date' ? (
                            <DateSeparator key={`date-${idx}`} date={group.date} />
                        ) : (
                            <MessageGroup
                                key={`group-${idx}`}
                                messages={group.messages}
                                isCurrentUser={group.messages[0].User === currentUser}
                            />
                        )
                    ))}
                </div>

                {/* Input Area */}
                <div className="p-4 border-t border-gray-800">
                    {newImage && (
                        <div className="relative inline-block mb-3">
                            <img
                                src={newImage}
                                alt="Preview"
                                className="h-20 rounded-lg"
                            />
                            <button
                                onClick={() => setNewImage('')}
                                className="absolute -top-2 -right-2 bg-red-500 text-white w-6 h-6 rounded-full flex items-center justify-center hover:bg-red-600"
                            >
                                ×
                            </button>
                        </div>
                    )}

                    <div className="flex gap-2">
                        <input
                            type="text"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            onPaste={handlePaste}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    onAddComment();
                                }
                            }}
                            placeholder="Type a message..."
                            className="flex-1 bg-[#131619] text-white text-sm rounded-md px-4 py-2 focus:outline-none"
                        />
                        <button
                            onClick={onAddComment}
                            disabled={isPosting || !newComment.trim()}
                            className="px-6 py-2 bg-blue-600 text-white text-sm rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700"
                        >
                            {isPosting ? (
                                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                            ) : (
                                'Send'
                            )}
                        </button>
                    </div>
                </div>

                {/* Footer */}
                <div className="px-4 pb-4">
                    <button
                        onClick={onClose}
                        className="w-full py-2 text-gray-300 hover:text-white text-sm border border-gray-800 rounded-md hover:bg-gray-800"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};