export const formatCurrency = (amount) => {
    return Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
};


export const getColorBasedOnProgress = (progress) => {
    if (progress >= 100) {
        return 'green';
    } else if (progress >= 60) {
        return 'orange';
    } else if (progress >= 30) {
        return 'pink';
    } else if (progress >= 10) {
        return 'red';
    }
    return 'grey'; // Default color for progress less than 10%
};

export const countWorkingDaysUntil = (year, month, day) => {
    let workingDays = 0;
    for (let d = 1; d <= day; d++) {
        const weekday = new Date(year, month - 1, d).getDay();
        if (weekday >= 1 && weekday <= 5) { // Check if it's a weekday (Monday to Friday)
            workingDays += 1;
        } else if (weekday === 6) { // Check if it's Saturday
            workingDays += 0.5;
        }
    }
    console.info('Working Days until:', workingDays);
    return workingDays;
};

export const countWorkingDaysLeft = (year, month, day) => {
    const lastDayOfMonth = new Date(year, month, 0).getDate(); // Gets the last day of the given month
    let workingDaysLeft = 0;
    for (let d = day; d <= lastDayOfMonth; d++) {
        const weekday = new Date(year, month - 1, d).getDay();
        if (weekday >= 1 && weekday <= 5) { // Check if it's a weekday (Monday to Friday)
            workingDaysLeft += 1;
        } else if (weekday === 6) { // Check if it's Saturday
            workingDaysLeft += 0.5;
        }
    }
    console.info('Working Days left:', workingDaysLeft);
    return workingDaysLeft - 1;
};

export const getOperatorData = async (token, username) => {
    try {
        // Fetch Operator Data
        const operatorResponse = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/getByUsername/${username}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!operatorResponse.ok) {
            throw new Error(`HTTP error! status: ${operatorResponse.status}`);
        }
        const operatorData = await operatorResponse.json();
        //setOperatorData(operatorData);
        return operatorData;
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }
}