import React, { useState, useCallback, useMemo } from 'react';
import CustAnaly from '../CustAnaly';
import CustomerDetails from '../CustomerDetails';

function CustomerTab() {
    const [metrics, setMetrics] = useState(null);

    // Memoize calculateMetrics function
    const calculateMetrics = useMemo(() => (data) => {
        // Categorize customers
        const greenCustomers = data.filter(customer => customer.totalSales > 0);
        const orangeCustomers = data.filter(customer => customer.totalSales === 0 && customer.quotesCount > 0);
        const redCustomers = data.filter(customer => customer.totalSales === 0 && customer.quotesCount === 0);

        return {
            total: data.length,
            redCount: redCustomers.length,
            orangeCount: orangeCustomers.length,
            greenCount: greenCustomers.length,
            greenTotalSpend: greenCustomers.reduce((sum, customer) => sum + customer.totalSales, 0),
            greenQuotesValue: greenCustomers.reduce((sum, customer) => sum + customer.quotesValue, 0),
            orangeQuotesValue: orangeCustomers.reduce((sum, customer) => sum + customer.quotesValue, 0)
        };
    }, []); // Empty dependency array since it doesn't depend on any props or state

    // Memoize the onDataChange callback
    const handleDataChange = useCallback((data) => {
        setMetrics(calculateMetrics(data));
    }, [calculateMetrics]); // Include calculateMetrics in dependencies

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: 'auto', overflow: 'none' }}>
            <div style={{ flex: 2, overflow: 'scroll' }}>
                <CustAnaly onDataChange={handleDataChange} />
            </div>
            <div style={{ flex: 1, overflow: 'scroll', height: '100vh' }}>
                <CustomerDetails metrics={metrics} />
            </div>
        </div>
    );
}

export default CustomerTab;