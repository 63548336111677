import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Box, Grid, Select, MenuItem, Alert, Button, Typography } from '@mui/material'
import HeaderStats from './HeaderStats'
import AbsencesCard from './AbsencesCard'
import InfringementAnalysis from './InfringementAnalysis'
import ClockingDetails from './ClockingDetails'
import moment from 'moment-timezone'
import axios from 'axios'
import { debounce } from 'lodash'
import { GlobalContext } from '../../context/GlobalContext'
import haxios from '../newholidays/lib/haxios'
import InfringementHandler from './InfringementHandler'

// Define the fetchShiftAssignments and fetchShifts functions
const fetchShiftAssignments = async (token) => {
  try {
    const response = await axios.get('https://spar.connectplus.parts/api/salesassist/cplusassist/shiftassignments', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error('Failed to fetch shift assignments', error)
    throw error
  }
}

const fetchShifts = async (token) => {
  try {
    const response = await axios.get('https://spar.connectplus.parts/api/salesassist/cplusassist/shifts', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching shifts:', error)
    throw error
  }
}

const processCasualWorkers = (analysisData) => {
  const casualWorkers = []

  analysisData.forEach((day) => {
    day.details.forEach((detail) => {
      // Only include ZEROH shifts where someone actually clocked in
      if (detail.shift?.ShiftName === 'ZEROH' && detail.clockInTime !== 'N/A') {
        casualWorkers.push({
          StaffName: detail.staffName,
          StartDatetime: moment(day.date).startOf('day').toISOString(),
          EndDatetime: moment(day.date).endOf('day').toISOString(),
          Status: detail.hasAcceptedInfringement ? 'Approved' : 'Pending Approval',
          ApprovedBy: detail.acceptedInfringementApprovedBy || null,
          ClockInTime: detail.clockInTime,
          ClockOutTime: detail.clockOutTime,
          TimeWorked: detail.totalWorkedMinutes
        })
      }
    })
  })

  return casualWorkers
}

const fetchStaff = async (token, staffBranch) => {
  try {
    const response = await axios.get('https://vision-web-api-test.azurewebsites.net/api/tma/staff', {
      headers: { Token: token }
    })

    // Clear large response data we don't need
    const filteredData = response.data
      .filter((record) => record.Branch === staffBranch.code)
      .map(({ StaffId, FName, SName, Branch }) => ({
        StaffId,
        FName,
        SName,
        Branch
      }))

    return filteredData
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled')
      return []
    }
    console.error('Error fetching staff:', error)
    throw error
  }
}

const filterOutFutureDates = (records) => {
  //console.info('future: ', records)
  const today = moment().endOf('day')
  return records.filter((record) => {
    const recordDate = moment(record.ClockInTime || record.date || record.Date || record.ShiftDate)
    return recordDate.isSameOrBefore(today)
  })
}

const calculateMinsOut = (detail) => {
  if (!detail.shift) return 0;
  const shiftStart = moment(`${detail.date} ${detail.shift.Start}`, 'YYYY-MM-DD HH:mm');
  const shiftEnd = moment(`${detail.date} ${detail.shift.Finish}`, 'YYYY-MM-DD HH:mm');
  return shiftEnd.diff(shiftStart, 'minutes');
};




const fetchClockingRecords = async (branch, staffId, date, token) => {
  try {
    const response = await axios.post(
      'https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs',
      {
        staffId,
        date,
        Branch: branch.code
      },
      {
        headers: { Token: token }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error fetching clocking records:', error)
    return []
  }
}

function TandABranch_({ staffBranch, setStaffBranch }) {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState({
    clockingRecords: [],
    holidays: [],
    sick: [],
    casual: []
  })
  const [staffList, setStaffList] = useState([])
  const [shiftAssignments, setShiftAssignments] = useState([])
  const [shifts, setShifts] = useState([])
  const [analysisData, setAnalysisData] = useState([])
  const [selectedDetail, setSelectedDetail] = useState(null)
  const [highlightedRecord, setHighlightedRecord] = useState(null)
  const {
    analyzeClockingRecordsForMonth,
    acceptedInfringements,
    setAcceptedInfringements,
    fetchAcceptedInfringements,
    token,
    operatorData
  } = useContext(GlobalContext)
  //const token = localStorage.getItem('token');

  const handleMonthChange = (e) => setSelectedMonth(e.target.value)
  const handleYearChange = (e) => setSelectedYear(e.target.value)

  const handleAcceptInfringement = async (payload) => {
    try {
      // Format the date from the selected detail
      const infringementDate = moment(selectedDetail.date).format('YYYY-MM-DD');
      // Calculate minutes out from shift details
      const minsOut = calculateMinsOut(selectedDetail);
      // Build the payload exactly as required by the new schema
      const acceptedPayload = {
        ClockId: payload.clockId,
        StaffId: selectedDetail.staffId,
        //ApprovedBy: operatorData.username || 'Manager',
        DateApproved: `${infringementDate}T00:00:00.000Z`,
        ApprovedType: payload.reason,
        InfringementType: payload.infringementType,
        Business: payload.businessReason,
        BusinessReason: payload.businessReason ? payload.reason : '',
        HRReason: !payload.businessReason ? payload.reason : '',
        CreatedBy: operatorData.Inits,
        MinsOut: minsOut,
        CoveredFor: 'N/A'
      };

      const response = await fetch(
        'https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(acceptedPayload)
        }
      );

      if (response.ok) {
        await refreshAnalysisData();
        setSelectedDetail(null);
      } else {
        const errorText = await response.text();
        alert(`Failed to accept infringement: ${errorText}`);
      }
    } catch (error) {
      console.error('Error accepting infringement:', error);
      alert(`Error accepting infringement: ${error.message}`);
    }
  };


  const handleRowClick = (detail, day) => {
    console.log('Row Clicked: detail', detail) // Log detail object for debugging
    console.log('Day Object:', day) // Log day object for debugging

    let recordId = detail.clockingId

    // Ensure we use a valid date (prefer day.date if detail.date is missing or undefined)
    const validDate = detail.date || day.date

    if (!recordId && detail.staffId && validDate) {
      recordId = `${detail.staffId}-${moment(validDate).format('YYYY-MM-DD')}`
    }

    // Log identifier generation
    console.log('Generated recordId:', recordId)

    setSelectedDetail({
      ...detail,
      date: day.date
    })

    setHighlightedRecord(recordId) // Ensure recordId is set correctly
  }

  const handleCloseClockingDetails = () => {
    setSelectedDetail(null)
    setHighlightedRecord(null)
  }

  const fetchSelectedMonthClockingRecords = async () => {
    try {
      const startDate = moment([selectedYear, selectedMonth - 1])
        .startOf('month')
        .format('YYYY-MM-DD')
      const endDate = moment([selectedYear, selectedMonth - 1])
        .endOf('month')
        .format('YYYY-MM-DD')
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({ startDate, endDate, Branch: staffBranch.code })
      })
      const data = await response.json()

      const filteredData = data.filter((record) => record.CompanyID === '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b')
      //setData(prevData => ({ ...prevData, clockingRecords: filteredData }));
      return filteredData
    } catch (error) {
      console.error('Error fetching selected month clocking records:', error)
      return []
    }
  }

  const refreshAnalysisData = async (keepSelection = true) => {
    try {
      const startDate = moment([selectedYear, selectedMonth - 1])
        .startOf('month')
        .format('YYYY-MM-DD')
      const endDate = moment([selectedYear, selectedMonth - 1])
        .endOf('month')
        .format('YYYY-MM-DD')

      // Parallel fetch all data
      const [filteredClockingRecords, holidays, sick, newAcceptedInfringements] = await Promise.all([
        fetchSelectedMonthClockingRecords(),
        fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holidays', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Token': token
          },
          body: JSON.stringify({ StartDate: startDate, EndDate: endDate, Branch: staffBranch.code })
        }).then((res) => res.json()),
        fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Token': token
          },
          body: JSON.stringify({ StartDate: startDate, EndDate: endDate, Branch: staffBranch.code })
        }).then((res) => res.json()),
        fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then((res) => res.json())
      ])

      const analysis = analyzeClockingRecordsForMonth(
        filteredClockingRecords,
        staffList,
        shiftAssignments,
        shifts,
        holidays,
        sick,
        selectedMonth,
        selectedYear,
        newAcceptedInfringements
      )

      const filteredAnalysis = filterOutFutureDates(analysis)

      // Update data without clearing selection
      setData((prevData) => ({
        ...prevData,
        clockingRecords: filteredClockingRecords,
        holidays,
        sick
      }))

      setAnalysisData(filteredAnalysis)

      // Only clear selection if keepSelection is false
      if (!keepSelection) {
        setSelectedDetail(null)
        setHighlightedRecord(null)
      }
    } catch (error) {
      console.error('Error in refreshAnalysisData:', error)
    }
  }

  const markAsHoliday = async (staffId, date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      console.log(`Attempting to mark/unmark holiday for staff ${staffId} on ${formattedDate}`)

      // First, check if there's an existing holiday record for this date
      const existingHolidayRecord = data.holidays.find(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )

      if (existingHolidayRecord) {
        // If record exists, delete it
        const response = await fetch(
          `https://vision-web-api-test.azurewebsites.net/api/tma/holiday/${existingHolidayRecord.HolidayID}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Token': token
            }
          }
        )

        if (response.ok) {
          console.log('Holiday removed successfully.')
          await refreshAnalysisData()
        } else {
          const errorText = await response.text()
          console.error('Failed to remove holiday:', response.status, errorText)
          alert(`Failed to remove holiday: ${response.status} ${errorText}`)
        }
      } else {
        // If no record exists, add one
        const holidayId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
        const startDatetime = moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const endDatetime = moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const addedDatetime = moment().format('YYYY-MM-DD HH:mm:ss')

        await haxios.post('/salesassist/cplusassist/holidayrequests', {
          staffId: staffId,
          dtStart: moment(startDatetime).format('YYYY-MM-DD'),
          dtEnd: moment(endDatetime).format('YYYY-MM-DD'),
          reason: '',
          requestDate: moment(addedDatetime).format('YYYY-MM-DD'),
          decision: 'APPROVED',
          Notes: ''
        })

        const payload = {
          HolidayID: holidayId,
          StartDatetime: startDatetime,
          EndDatetime: endDatetime,
          StaffID: staffId,
          TotalDays: 1,
          Paid: true,
          HoursPerDay: 8,
          AddedUserID: '988cca25-74f8-4908-ab96-f2ac9be8d9b3',
          AddedDatetime: addedDatetime
        }

        const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holiday', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Token': token
          },
          body: JSON.stringify(payload)
        })

        if (response.ok) {
          console.log('Holiday added successfully.')
          await refreshAnalysisData()
        } else {
          const errorText = await response.text()
          console.error('Failed to add holiday:', response.status, errorText)
          alert(`Failed to add holiday: ${response.status} ${errorText}`)
        }
      }
    } catch (error) {
      console.error('Error in markAsHoliday function:', error)
      alert(`An error occurred: ${error.message}`)
    }
  }

  const markAsSick = async (staffId, date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      console.log(`Attempting to mark/unmark sick day for staff ${staffId} on ${formattedDate}`)

      // First, check if there's an existing sick record for this date
      const existingSickRecord = data.sick.find(
        (record) => record.StaffID === staffId && moment(record.StartDatetime).format('YYYY-MM-DD') === formattedDate
      )

      if (existingSickRecord) {
        // If record exists, delete it
        const response = await fetch(
          `https://vision-web-api-test.azurewebsites.net/api/tma/sickday/${existingSickRecord.SickID}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Token': token
            }
          }
        )

        if (response.ok) {
          console.log('Sick day removed successfully.')
          await refreshAnalysisData()
        } else {
          const errorText = await response.text()
          console.error('Failed to remove sick day:', response.status, errorText)
          alert(`Failed to remove sick day: ${response.status} ${errorText}`)
        }
      } else {
        // If no record exists, add one
        const sickId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
        const startDatetime = moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const endDatetime = moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
        const addedDatetime = moment().format('YYYY-MM-DD HH:mm:ss')

        const payload = {
          SickID: sickId,
          StartDatetime: startDatetime,
          EndDatetime: endDatetime,
          StaffID: staffId,
          TotalDays: 1,
          Paid: true,
          HoursPerDay: '8',
          AddedUserID: '988cca25-74f8-4908-ab96-f2ac9be8d9b3',
          AddedDatetime: addedDatetime,
          Reason: 'Sick Day'
        }

        const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickday', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Token': token
          },
          body: JSON.stringify(payload)
        })

        if (response.ok) {
          console.log('Sick day added successfully.')
          await refreshAnalysisData()
        } else {
          const errorText = await response.text()
          console.error('Failed to add sick day:', response.status, errorText)
          alert(`Failed to add sick day: ${response.status} ${errorText}`)
        }
      }
    } catch (error) {
      console.error('Error in markAsSick function:', error)
      alert(`An error occurred: ${error.message}`)
    }
  }

  // Remove acceptedInfringements from the dependency array
  useEffect(() => {
    const fetchData = async () => {
      setError(null)

      try {
        const startDate = moment([selectedYear, selectedMonth - 1])
          .startOf('month')
          .format('YYYY-MM-DD')
        const endDate = moment([selectedYear, selectedMonth - 1])
          .endOf('month')
          .format('YYYY-MM-DD')

        // Fetch staff first since it's causing the issue
        const staffData = await fetchStaff(token, staffBranch)
        setStaffList(staffData)

        // Then fetch the rest
        const [clockingRecords, holidays, sick, shiftAssignments, shifts, newAcceptedInfringements] = await Promise.all(
          [
            fetchSelectedMonthClockingRecords(),
            fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holidays', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Token': token },
              body: JSON.stringify({ StartDate: startDate, EndDate: endDate, Branch: staffBranch.code })
            }).then((res) => res.json()),
            fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Token': token },
              body: JSON.stringify({ StartDate: startDate, EndDate: endDate, Branch: staffBranch.code })
            }).then((res) => res.json()),
            fetchShiftAssignments(token),
            fetchShifts(token),
            fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
              headers: { Authorization: `Bearer ${token}` }
            }).then((res) => res.json())
          ]
        )

        const filteredClockingRecords = filterOutFutureDates(clockingRecords)
        const filteredHolidays = filterOutFutureDates(holidays)
        const filteredSick = filterOutFutureDates(sick)

        const analysis = analyzeClockingRecordsForMonth(
          filteredClockingRecords,
          staffData, // Use the already fetched staff data
          shiftAssignments,
          shifts,
          filteredHolidays,
          filteredSick,
          selectedMonth,
          selectedYear,
          newAcceptedInfringements
        )

        const filteredAnalysis = filterOutFutureDates(analysis)
        const casualWorkers = processCasualWorkers(filteredAnalysis)

        //console.log('setData')
        setData({
          clockingRecords: filteredClockingRecords,
          holidays: filteredHolidays,
          sick: filteredSick,
          casual: casualWorkers
        })

        setShiftAssignments(shiftAssignments)
        setShifts(shifts)
        setAcceptedInfringements(newAcceptedInfringements)
        setAnalysisData(filteredAnalysis)

        await new Promise((res) => setTimeout(res, 20000))
      } catch (error) {
        console.error('Failed to fetch data:', error)
        setError('Failed to load data. Please refresh the page and try again.')
      }
    }
    fetchData()

    // Clear any existing timers when component updates
    return () => {
      console.log('tandabranch_ unmount')
    }
  }, [selectedMonth, selectedYear, staffBranch?.code, token])

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2} alignItems="center" alignContent={'center'}>
        <Grid item>
          <Select value={selectedMonth} onChange={handleMonthChange} sx={{ marginRight: 2 }}>
            {Array.from({ length: 12 }).map((_, idx) => (
              <MenuItem key={idx} value={idx + 1}>
                {new Date(0, idx).toLocaleString('default', { month: 'long' })}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Select value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 5 }).map((_, idx) => (
              <MenuItem key={idx} value={new Date().getFullYear() - 2 + idx}>
                {new Date().getFullYear() - 2 + idx}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs>
          <HeaderStats
            data={data}
            staffList={staffList}
            shiftAssignments={shiftAssignments}
            shifts={shifts}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            analysisData={analysisData}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ color: 'white', textAlign: 'center', fontWeight: 'bold', fontSize: 25 }}>
            {staffBranch.code}
          </Typography>
          <Button
            onClick={() => setStaffBranch(null)}
            variant="contained"
            color="primary"
            style={{ marginBottom: '20px' }}
          >
            Back to Branches
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: 2,
          height: 'calc(100vh - 200px)', // Adjust based on your header height
          overflow: 'hidden' // Prevent container scrolling
        }}
      >
        <Grid
          item
          xs={8}
          sx={{
            height: '100%', // Make grid item full height
            overflow: 'hidden' // Prevent item scrolling
          }}
        >

          <InfringementAnalysis
            analysisData={analysisData}
            fetchClockingRecords={handleRowClick}
            handleRowClick={handleRowClick}
            setSelectedDetail={setSelectedDetail}
            selectedDetail={selectedDetail}
            fetchAcceptedInfringements={fetchAcceptedInfringements}
            highlightedRecord={highlightedRecord}
            acceptedInfringements={acceptedInfringements}
            data={data}
          />
        </Grid>
        <Grid item xs={4}>
          {!selectedDetail ? (
            // When no detail is selected, show the stats component.
            <AbsencesCard data={data} />
          ) : (
            // When an infringement is selected, show the fixing record component.
            operatorData.Status === 'admin' ? (
              <InfringementHandler
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                onClose={handleCloseClockingDetails}
                fetchAcceptedInfringements={fetchAcceptedInfringements}
                setHighlightedRecord={setHighlightedRecord}
                token={token}
                staffBranch={staffBranch}
                staffList={staffList}
                onAcceptInfringement={handleAcceptInfringement}  // our new handler
              />
            ) : (
              <ClockingDetails
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                onClose={handleCloseClockingDetails}
                fetchAcceptedInfringements={fetchAcceptedInfringements}
                token={token}
                staffBranch={staffBranch}
                staffList={staffList}
              />
            )
          )}

        </Grid>
      </Grid>
    </Box>
  )
}

export default TandABranch_
