import { create } from 'zustand'

const useShiftCopyStore = create((set) => ({
  copiedStaffId: null,
  setCopiedStaffId: (id) =>
    set((state) => ({
      copiedStaffId: id
    }))
}))

export default useShiftCopyStore
