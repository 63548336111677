import { createContext, useEffect, useState, useCallback, useMemo } from 'react'
import axios from 'axios'
import { getOperatorData } from '../utils'
import moment from 'moment-timezone'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [windowToggle, setWindowToggle] = useState(true)
  const [token, setToken] = useState(null)
  const [operatorData, setOperatorData] = useState(null)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [uinfo, setUinfo] = useState(null)
  //const [username, setUsername] = useState("spartan_admin");
  //const [password, setPassword] = useState("htyYTL8*&h");
  //const [username, setUsername] = useState("david.t@spartanmotorfactors.co.uk"); // rm
  //const [password, setPassword] = useState("DavidT123@");
  //const [username, setUsername] = useState("sbray@spartanmotorfactors.co.uk"); // admin
  //const [password, setPassword] = useState("Spartan123@");
  //const [username, setUsername] = useState("terry.s@spartanmotorfactors.co.uk"); // bm
  //const [password, setPassword] = useState("TerryS1234@");
  //const [username, setUsername] = useState("churrel@spartanmotorfactors.co.uk"); // sales
  //const [password, setPassword] = useState("ChrisH10767@");

  const [isToday, setIsToday] = useState(true)
  const [breaks, setBreaks] = useState({})
  const [acceptedInfringements, setAcceptedInfringements] = useState([])

  const loadingMessages = [
    'Sharpening spears... Your data will be ready soon!',
    'Marching to victory... Please stand by!',
    'Stoking the fires of war... Data deployment imminent!',
    'Standing at Thermopylae... Defending your data integrity!',
    'Consulting the Oracle... Data predictions incoming!',
    'Sharpening swords and strategies... Hang tight!',
    'The Oracle is consulting... Future sales will be revealed!',
    'Warming up the arena... Spartans never rush, but they deliver!',
    'The ships are crossing the Aegean... Sales figures inbound!',
    'Setting up camp... Your Spartan insights will be fortified!',
    'Raising the banners... Preparing to unleash the figures!',
    'Tuning the battle horns... Sales signals are being processed!',
    'Lighting the torches... Spartan scouts are gathering data!',
    'Sales scouts have spotted movement—stand firm, data is near!',
    'A Spartan never waits for long... Battle stats incoming!',
    'The gods favour the brave... Your numbers are on their way.',
    'Shields are polished—only the sharpest insights ahead.',
    'No retreat, no surrender—sales figures are forging ahead.',
    'On the road to Sparta—your data is marching near.',
    'The temple doors are opening... Insights from the gods arrive.'
  ]

  const refreshToken = useCallback(async () => {
    if (!username || !password) return

    try {
      const authResponse = await axios.post('https://vision-web-api-test.azurewebsites.net/api/authenticate', {
        Username: username,
        Password: password
      })

      if (authResponse.data) {
        setToken(authResponse.data.token)
      }
    } catch (error) {
      // Handle error silently
    }
  }, [username, password])

  const getUser = useCallback(async () => {
    if (!username || !password) return

    try {
      const authResponse = await axios.get('https://vision-web-api-test.azurewebsites.net/api/user', {
        headers: { Token: token }
      })

      if (authResponse.data) {
        setUinfo(authResponse.data)
      }
    } catch (error) {
      // Handle error silently
    }
  }, [username, password])

  const fetchBreaks = useCallback(async (token) => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/breaks', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      const breakData = data.reduce((acc, breakTime) => {
        acc[breakTime.staffId] = breakTime.breaks
        return acc
      }, {})
      setBreaks(breakData)
    } catch (error) {
      console.error('Error fetching breaks:', error)
    }
  }, [])

  const fetchAcceptedInfringements = useCallback(async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response.ok) {
        const data = await response.json()
        setAcceptedInfringements(data)
      } else {
        console.error('Failed to fetch accepted infringements:', response.statusText)
      }
    } catch (error) {
      console.error('Error fetching accepted infringements:', error)
    }
  }, [token])

  useEffect(() => {
    if (token && username && password) {
      const interval = setInterval(refreshToken, 300000)
      return () => clearInterval(interval)
    }
  }, [token, username, password, refreshToken])

  useEffect(() => {
    getUser() // This will trigger getUser whenever token changes
  }, [getUser, token])

  useEffect(() => {
    async function fetchData() {
      if (token && username) {
        const data = await getOperatorData(token, username)
        setOperatorData(data)
        fetchBreaks(token)
        fetchAcceptedInfringements()
      }
    }

    fetchData()
  }, [token, username, fetchBreaks, fetchAcceptedInfringements])

  const checkInfringement = useCallback((clockingRecord, shift, breakMinutes, holidays, sickDays, staffId, date) => {
    let status = 'OK'
    let reason = ''

    // Check for holidays and sick days first
    const isHoliday = holidays.some(
      (holiday) =>
        staffId === holiday.StaffID &&
        moment(date).isBetween(
          moment(holiday.StartDatetime).startOf('day'),
          moment(holiday.EndDatetime).endOf('day'),
          'day',
          '[]'
        )
    )

    const isSickDay = sickDays.some(
      (sick) =>
        staffId === sick.StaffID &&
        moment(date).isBetween(
          moment(sick.StartDatetime).startOf('day'),
          moment(sick.EndDatetime).endOf('day'),
          'day',
          '[]'
        )
    )

    // Return early if it's a holiday or sick day
    if (isHoliday) {
      return { status: 'OK', reason: 'On holiday', totalWorkedMinutes: 0 }
    }

    if (isSickDay) {
      return { status: 'OK', reason: 'Sick day', totalWorkedMinutes: 0 }
    }

    // Check if the shift is a casual (ZEROH) shift
    const isZerhoShift = shift?.ShiftName === 'ZEROH'

    //isZerhoShift && console.log('ZEROH shift:', shift, staffId);

    // Handle no shift scenarios
    if (!shift) {
      if (!clockingRecord) {
        return { status: 'OK', reason: 'No shift and no clocking', totalWorkedMinutes: 0 }
      } else {
        return { status: 'INFRINGEMENT', reason: 'Clocking in without a shift', totalWorkedMinutes: 0 }
      }
    }

    // Handle casual shift (ZEROH) with clocking - needs acceptance
    if (isZerhoShift && clockingRecord) {
      return { status: 'OK', reason: 'Casual shift (ZERHO) with clocking - needs acceptance', totalWorkedMinutes: 0 }
    }

    // Handle casual shift (ZERHO) with no clocking
    if (isZerhoShift && !clockingRecord) {
      return { status: 'OK', reason: 'Casual shift (ZERHO) with no clocking', totalWorkedMinutes: 0 }
    }

    // Handle missing clocking record for non-casual shifts
    if (!clockingRecord) {
      return { status: 'INFRINGEMENT', reason: 'Missing clocking record', totalWorkedMinutes: 0 }
    }

    // Calculate shift times
    const shiftDate = moment(clockingRecord.ClockInTime).format('YYYY-MM-DD')
    const shiftStart = moment(`${shiftDate} ${shift.Start}`, 'YYYY-MM-DD HH:mm')
    const shiftEnd = moment(`${shiftDate} ${shift.Finish}`, 'YYYY-MM-DD HH:mm')

    // Handle overnight shifts
    if (shiftEnd.isBefore(shiftStart)) {
      shiftEnd.add(1, 'day')
    }

    // Calculate expected work duration
    const expectedWorkMinutes = shiftEnd.diff(shiftStart, 'minutes') - breakMinutes - 10 // 10 minute grace period
    const clockInTime = moment(clockingRecord.ClockInTime)
    const clockOutTime = moment(clockingRecord.ClockOutTime)
    let totalWorkedMinutes = 0

    // Check for late clock-in and early clock-out for regular shifts
    if (!isZerhoShift) {
      if (clockInTime.isAfter(shiftStart.clone().add(15, 'minutes'))) {
        status = 'INFRINGEMENT'
        reason += 'Late clock-in. '
      }

      if (clockOutTime.isBefore(shiftEnd.clone().subtract(15, 'minutes'))) {
        status = 'INFRINGEMENT'
        reason += 'Early clock-out. '
      }
    }

    // Calculate total worked minutes
    if (clockOutTime.isValid() && clockInTime.isValid()) {
      totalWorkedMinutes = clockOutTime.diff(clockInTime, 'minutes') - breakMinutes

      // Check for insufficient work time
      if (totalWorkedMinutes < expectedWorkMinutes && !isZerhoShift) {
        status = 'INFRINGEMENT'
        reason += 'Insufficient work time. '
      }
    } else {
      totalWorkedMinutes = 0
      if (!isZerhoShift) {
        status = 'INFRINGEMENT'
        reason += 'Invalid clock times. '
      }
    }

    // Handle excessive hours (optional, add if needed)
    const maxWorkMinutes = 720 // 12 hours
    if (totalWorkedMinutes > maxWorkMinutes) {
      status = 'INFRINGEMENT'
      reason += 'Excessive hours worked. '
    }

    return {
      status,
      reason: reason.trim(),
      totalWorkedMinutes,
      expectedWorkMinutes,
      shiftStartTime: shiftStart.format('HH:mm'),
      shiftEndTime: shiftEnd.format('HH:mm'),
      breakMinutes
    }
  }, [])

  const debugAnalyzeWrapper = (...args) => {
    console.log('Analyze function input:', {
      clockingRecords: args[0]?.length,
      staffList: args[1]?.length,
      shiftAssignments: args[2]?.length,
      shifts: args[3]?.length,
      holidays: args[4]?.length,
      sickDays: args[5]?.length,
      month: args[6],
      year: args[7],
      acceptedInfringements: args[8]?.length
    });

    const result = analyzeClockingRecordsForMonth(...args);

    console.log('Analyze function output:', {
      resultLength: result?.length,
      sampleDay: result?.[0]
    });

    return result;
  };

  const analyzeClockingRecordsForMonth = useCallback(
    (clockingRecords, staffList, shiftAssignments, shifts, holidays, sickDays, month, year, acceptedInfringements) => {
      const results = []
      const startOfMonth = moment([year, month - 1]).startOf('month')
      const endOfMonth = moment([year, month - 1]).endOf('month')

      for (let day = startOfMonth.clone(); day.isSameOrBefore(endOfMonth); day.add(1, 'days')) {
        const currentDate = day.format('YYYY-MM-DD')
        const dayResult = {
          date: currentDate,
          totalOK: 0,
          totalInfringements: 0,
          totalAcceptedInfringements: 0,
          totalCasualWorkers: 0,
          details: []
        }

        staffList.forEach((staff) => {
          const staffId = staff.StaffId
          const shiftAssignment = shiftAssignments.find((a) => a.staffId === staffId)
          const shift = shiftAssignment ? shifts.find((s) => s._id === shiftAssignment.shifts[day.isoWeekday()]) : null

          // Get ALL clocking records for this staff member on this day
          const staffClockingRecords = clockingRecords.filter(
            (record) => record.StaffID === staffId && moment(record.ClockInTime).isSame(day, 'day')
          )

          let clockInTime = 'N/A'
          let clockOutTime = 'N/A'
          let totalWorkedMinutes = 0
          let approvedBy = ''
          let clockID = ''
          let date = null
          let status = 'OK'
          let reason = ''

          // Use the first record for initial display, but keep all records
          if (staffClockingRecords.length > 0) {
            const mainRecord = staffClockingRecords[0]
            const clockInMoment = moment(mainRecord.ClockInTime)
            const clockOutMoment = mainRecord.ClockOutTime ? moment(mainRecord.ClockOutTime) : null
            clockID = mainRecord.ClockID
            date = clockInMoment

            clockInTime = clockInMoment.isValid() ? clockInMoment.format('HH:mm') : 'N/A'
            clockOutTime = clockOutMoment && clockOutMoment.isValid() ? clockOutMoment.format('HH:mm') : 'N/A'

            // Only calculate worked minutes if both clock in and out times exist
            if (clockInMoment.isValid() && clockOutMoment && clockOutMoment.isValid()) {
              totalWorkedMinutes = clockOutMoment.diff(clockInMoment, 'minutes')
            }
          }

          let acceptedInfringement = clockID
            ? acceptedInfringements.find((inf) => inf.ClockId === clockID)
            : acceptedInfringements.find(
              (inf) =>
                inf.StaffId === staffId &&
                moment(inf.DateApproved).format('YYYY-MM-DD') === currentDate &&
                (inf.ClockId === '00000000-0000-0000-0000-000000000000' || !inf.ClockId)
            )

          const formattedTimeWorked =
            totalWorkedMinutes > 0 ? `${Math.floor(totalWorkedMinutes / 60)}h ${totalWorkedMinutes % 60}m` : 'N/A'

          if (acceptedInfringement) {
            status = 'OK'
            reason = acceptedInfringement.ApprovedType || 'Accepted Infringement'
            approvedBy = acceptedInfringement.ApprovedBy
            dayResult.totalAcceptedInfringements++
          } else {
            const result = checkInfringement(
              staffClockingRecords[0], // Pass the first record for basic status check
              shift,
              0, // breakMinutes
              holidays,
              sickDays,
              staffId,
              currentDate
            )
            status = result.status
            reason = result.reason

            if (status === 'OK') {
              dayResult.totalOK++
            } else if (status === 'INFRINGEMENT') {
              dayResult.totalInfringements++
            }
          }

          // Check if this is a casual worker who has clocked in
          if (shift?.ShiftName === 'ZEROH' && clockInTime !== 'N/A') {
            dayResult.totalCasualWorkers++
          }

          // Enhanced detail object including all clocking records
          dayResult.details.push({
            staffId,
            staffName: `${staff.FName} ${staff.SName}`,
            status,
            reason,
            clockInTime,
            clockOutTime,
            approvedBy,
            totalWorkedMinutes: formattedTimeWorked,
            shift,
            clockingId: clockID,
            date,
            hasAcceptedInfringement: !!acceptedInfringement,
            acceptedInfringementType: acceptedInfringement?.ApprovedType || null,
            acceptedInfringementApprovedBy: acceptedInfringement?.ApprovedBy || null,
            acceptedInfringementId: acceptedInfringement?._id || null,
            clockingRecords: staffClockingRecords, // Add all clocking records
            baseRecord: staffClockingRecords[0] || null // Add the main record used for initial analysis
          })
        })

        results.push(dayResult)
      }

      return results
    },
    [checkInfringement]
  )

  const contextValue = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      token,
      setToken,
      operatorData,
      setOperatorData,
      username,
      setUsername,
      password,
      setPassword,
      isToday,
      uinfo,
      setIsToday,
      windowToggle,
      setWindowToggle,
      refreshToken,
      loadingMessages,
      analyzeClockingRecordsForMonth,
      acceptedInfringements,
      setAcceptedInfringements,
      fetchAcceptedInfringements
    }),
    [
      isLoggedIn,
      token,
      operatorData,
      username,
      password,
      isToday,
      windowToggle,
      refreshToken,
      analyzeClockingRecordsForMonth,
      acceptedInfringements,
      fetchAcceptedInfringements
    ]
  )

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>
}

export default GlobalProvider
