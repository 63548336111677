import { useState, useEffect } from 'react';

export const useFetchCustomerData = (token, repcode) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchTodayData = async () => {
        setIsLoading(true);
        try {
            const url = `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByCustomerToday?rep=${repcode}`;
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Failed to fetch data');
            const fetchedData = await response.json();


            const processedData = fetchedData
            // Log processed data
            console.log('Processed data:', processedData);

            setData(processedData);
            setError(null);
        } catch (error) {
            console.error('Error fetching today data:', error);
            setError('Failed to load data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTodayData();
    }, [token, repcode]);

    return { data, setData, isLoading, error, setError, fetchTodayData };
};