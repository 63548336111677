import React, { useState, useContext, useEffect, useMemo } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import { Box, Snackbar, Alert, CircularProgress } from '@mui/material'
import { useFetchCustomerData } from '../hooks/useFetchCustomerData'
import { useCustomerFilters } from '../hooks/useCustomerFilters'
import { useOperators } from '../hooks/useOperators'
import { SearchBar } from './SearchBar'
import { CustomerTable } from './CustomerTable'
import { CommentModal } from './CommentModal'

function CustAnaly({ onDataChange = () => { } }) {
  const { token, operatorData } = useContext(GlobalContext)

  // State management
  const [viewingComments, setViewingComments] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [newComment, setNewComment] = useState('')
  const [newImage, setNewImage] = useState('')
  const [isPosting, setIsPosting] = useState(false)
  const [selectedRep, setSelectedRep] = useState(operatorData.Inits)

  // Helper functions
  const hasManagementAccess = (status) => ['admin', 'bm', 'rm'].includes(status?.toLowerCase())
  const isRegionalManager = (status) => status?.toLowerCase() === 'rm'

  // Initial operator state - empty for managers, current user for non-managers
  const [availableOperators, setAvailableOperators] = useState(() =>
    hasManagementAccess(operatorData.Status) ? [] : [{
      Inits: operatorData.Inits,
      Name: operatorData.Name || operatorData.Inits,
      Branch: operatorData.Branch
    }]
  )

  // Branch configuration for operator fetching
  const branchConfig = useMemo(() => {
    if (!hasManagementAccess(operatorData.Status)) return null;

    if (isRegionalManager(operatorData.Status)) {
      return {
        status: 'rm',
        rmName: operatorData.Name
      };
    }
    return operatorData.Branch;
  }, [operatorData.Status, operatorData.Name, operatorData.Branch]);

  // Operator data fetching
  const { operators, isLoading: isLoadingOperators } = useOperators(token, branchConfig)

  // Update available operators when operators list changes
  useEffect(() => {
    if (hasManagementAccess(operatorData.Status) && operators?.length > 0) {
      // Filter, format, and sort operators
      const filteredOperators = operators
        .filter(op => ['sales', 'bm'].includes(op.Status?.toLowerCase()))
        .map(op => ({
          ...op,
          Name: op.Name?.toUpperCase() || op.Inits?.toUpperCase(),
          Inits: op.Inits?.toUpperCase()
        }))
        .sort((a, b) => {
          // First sort by Branch
          const branchCompare = (a.Branch || '').localeCompare(b.Branch || '');
          if (branchCompare !== 0) return branchCompare;

          // Then sort by first name
          const aFirstName = a.Name?.split(' ')[0] || '';
          const bFirstName = b.Name?.split(' ')[0] || '';
          return aFirstName.localeCompare(bFirstName);
        });

      setAvailableOperators(filteredOperators);
    }
  }, [operators, operatorData.Status])

  // Customer data fetching
  const {
    data,
    setData,
    isLoading,
    error,
    setError,
    fetchTodayData
  } = useFetchCustomerData(token, selectedRep)

  const {
    filteredData,
    searchTerm,
    setSearchTerm,
    activeColorFilters,
    setActiveColorFilters
  } = useCustomerFilters(data)

  useEffect(() => {
    if (data) {
      onDataChange(data)
    }
  }, [data, onDataChange])

  // Event handlers
  const handleViewComments = (customer) => {
    setSelectedCustomer({
      ...customer,
      comments: [...(customer.comments || [])].sort((a, b) =>
        new Date(a.DateTime) - new Date(b.DateTime)
      )
    })
    setViewingComments(true)
  }

  const handleCloseComments = () => {
    setViewingComments(false)
    setSelectedCustomer(null)
    setNewComment('')
    setNewImage('')
  }

  const handleToggleColor = (color) => {
    setActiveColorFilters((prev) => ({
      ...prev,
      [color]: !prev[color]
    }))
  }

  const handleAddComment = async () => {
    if (!newComment.trim()) return

    setIsPosting(true)
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/addCustomerComment', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          keyCode: selectedCustomer.customerCode,
          comment: newComment,
          user: operatorData.Inits,
          image: newImage
        })
      })

      if (!response.ok) {
        throw new Error((await response.text()) || 'Failed to add comment')
      }

      const newCommentObj = {
        Comment: newComment,
        DateTime: new Date().toISOString(),
        User: operatorData.Inits,
        Image: newImage
      }

      setSelectedCustomer((prev) => ({
        ...prev,
        comments: [...(prev.comments || []), newCommentObj].sort((a, b) =>
          new Date(a.DateTime) - new Date(b.DateTime)
        )
      }))

      setData((prevData) =>
        prevData.map((customer) =>
          customer.customerCode === selectedCustomer.customerCode
            ? {
              ...customer,
              comments: [...(customer.comments || []), newCommentObj].sort(
                (a, b) => new Date(a.DateTime) - new Date(b.DateTime)
              )
            }
            : customer
        )
      )

      setNewComment('')
      setNewImage('')
      fetchTodayData()
    } catch (error) {
      console.error(`Failed to add comment: ${error.message}`)
      setError('Failed to send comment. Please try again.')
    } finally {
      setIsPosting(false)
    }
  }

  return (
    <Box sx={{ p: 2.5, color: '#ffffff', position: 'relative', height: '100vh' }}>
      {(isLoading || isLoadingOperators) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            borderRadius: 1
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        activeColorFilters={activeColorFilters}
        onToggleColor={handleToggleColor}
        showRepSelect={hasManagementAccess(operatorData.Status)}
        selectedRep={selectedRep}
        onRepChange={setSelectedRep}
        operators={availableOperators}
        isLoadingOperators={isLoadingOperators}
      />

      <CustomerTable
        data={filteredData}
        onViewComments={handleViewComments}
      />

      <CommentModal
        open={viewingComments}
        onClose={handleCloseComments}
        customer={selectedCustomer}
        newComment={newComment}
        setNewComment={setNewComment}
        newImage={newImage}
        setNewImage={setNewImage}
        onAddComment={handleAddComment}
        isPosting={isPosting}
        currentUser={operatorData.Inits}
      />
    </Box>
  )
}

export default CustAnaly