import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

const GELeadData = ({
    geleads = [],
    setGELeads,
    operatorData = {},
    token,
    setSelectedGELead,
    selectedGELead
}) => {
    console.log("GE Leads Data:", geleads);

    const handleStatusChange = async (event, id) => {
        const newStatus = event.target.value;
        const updatedData = { _id: id, Status: newStatus };

        try {
            const response = await fetch(
                'https://spar.connectplus.parts/api/salesassist/cplusassist/geleads',
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updatedData)
                }
            );

            if (response.ok) {
                setGELeads((prev) =>
                    prev.map((gelead) =>
                        gelead?._id === id ? { ...gelead, Status: newStatus } : gelead
                    )
                );
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleDeleteGELead = async (id) => {
        if (window.confirm('Are you sure you want to delete this GELead?')) {
            try {
                const response = await fetch(
                    `https://spar.connectplus.parts/api/salesassist/cplusassist/geleads/${id}`,
                    { method: 'DELETE', headers: { Authorization: `Bearer ${token}` } }
                );

                if (response.ok) {
                    setGELeads((prev) => prev.filter((gelead) => gelead?._id !== id));
                    setSelectedGELead(null); // Add this line to close the comments modal
                }
            } catch (error) {
                console.error('Error deleting GELead:', error);
            }
        }
    };

    return (
        <div component={Paper} style={{ height: 'calc(100vh - 170px)', }}>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Acct</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>Telephone</TableCell>
                        <TableCell>GE PartNo</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Comments</TableCell>
                        {operatorData?.Status === 'admin' && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(geleads) && geleads.length > 0 ? (
                        geleads
                            .filter(gelead => gelead && typeof gelead === "object")
                            .map((gelead) => (
                                <TableRow
                                    key={gelead?._id || Math.random()}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setSelectedGELead(gelead)}
                                >
                                    <TableCell>{gelead?.Acct || '-'}</TableCell>
                                    <TableCell>{gelead?.Name || '-'}</TableCell>
                                    <TableCell>{gelead?.Branch || '-'}</TableCell>
                                    <TableCell>{gelead?.Tel || '-'}</TableCell>
                                    <TableCell>{gelead?.GEPartNo || '-'}</TableCell>
                                    <TableCell>
                                        {gelead?.DateTime ? moment(gelead.DateTime).format('DD/MM/YY') : '-'}
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            value={gelead?.Status || 'Pending'}
                                            onChange={(event) => handleStatusChange(event, gelead?._id)}
                                            size="small"
                                        >
                                            <MenuItem value="Pending">Pending</MenuItem>
                                            <MenuItem value="In Progress">In Progress</MenuItem>
                                            <MenuItem value="Finished">Finished</MenuItem>
                                            <MenuItem
                                                value="Archived"
                                                disabled={operatorData?.Status === 'sales'}
                                            >
                                                Archived
                                            </MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>{gelead?.Value || '-'}</TableCell>
                                    <TableCell>
                                        {Array.isArray(gelead?.Comments) ? gelead.Comments.length : 0}
                                    </TableCell>

                                    {operatorData?.Status === 'admin' && (
                                        <TableCell>
                                            <Button
                                                onClick={() => handleDeleteGELead(gelead?._id)}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={10} align="center">
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

GELeadData.propTypes = {
    geleads: PropTypes.array,
    setGELeads: PropTypes.func.isRequired,
    operatorData: PropTypes.shape({
        Status: PropTypes.string
    }),
    token: PropTypes.string,
    setSelectedGELead: PropTypes.func.isRequired,
    selectedGELead: PropTypes.object
};


GELeadData.defaultProps = {
    geleads: [],
    operatorData: {},
    token: ''
};

export default GELeadData;