import { useEffect, useState } from 'react'
import haxios from '../lib/haxios'

// url: url to get, haxios rules, /whatever without domain fetches from apir, full url for elsewhere
// initial: initial data value for before fetch has completed
// method: GET or POST
// data: data for POST body or GET query string
// refetch: interval for automatic refetching in ms
// headers: optional headers to include in request
// useCache: if true, shows cached data while fetching fresh data. if false, shows loading state
const cache = new Map()

const getCacheKey = (url, method, body, headers) => {
  return `${method}:${url}:${JSON.stringify(body)}:${JSON.stringify(headers)}`
}

const useApi = ({
  url,
  initial = null,
  method = 'get',
  data: body = {},
  refetch = null,
  headers = {},
  useCache = false
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState(() => {
    if (!useCache) return initial
    const cached = cache.get(getCacheKey(url, method, body, headers))
    return cached || initial
  })

  const loadData = async () => {
    setIsFetching(true)

    if (useCache) {
      const cacheKey = getCacheKey(url, method, body, headers)
      const cached = cache.get(cacheKey)
      if (cached) {
        setData(cached)
        setIsLoading(false)
      }
    }

    let res

    try {
      if (method.toLowerCase() === 'get') {
        res = await haxios.get(url, {
          params: body,
          headers
        })
      } else if (method.toLowerCase() === 'post') {
        res = await haxios.post(url, body, { headers })
      }
    } catch (e) {
      console.log(e)
      setIsError(true)
      setIsFetching(false)
      setIsLoading(false)
      return
    }

    cache.set(getCacheKey(url, method, body, headers), res)
    setData(res)
    setIsError(false)
    setIsFetching(false)
    setIsLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [JSON.stringify(body), JSON.stringify(headers), url, method])

  useEffect(() => {
    if (!refetch) return
    const interval = setInterval(loadData, refetch)
    return () => clearInterval(interval)
  }, [refetch, JSON.stringify(body), JSON.stringify(headers), url, method])

  return {
    isLoading,
    isFetching,
    isError,
    data,
    refetch: loadData
  }
}

export default useApi
