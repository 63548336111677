import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

const SickDaysLeagueTable = ({ sickDays, isLoading }) => {
    const branchScores = useMemo(() => {
        if (!sickDays?.length) return [];

        const branchStats = {};

        sickDays.forEach(record => {
            const branch = record.Branch;
            if (!branchStats[branch]) {
                branchStats[branch] = {
                    totalDays: 0,
                    totalStaffSick: new Set(),
                    totalHours: 0,
                    records: []
                };
            }

            branchStats[branch].totalDays += record.TotalDays;
            branchStats[branch].totalStaffSick.add(record.StaffID);
            branchStats[branch].totalHours += (record.TotalDays * parseFloat(record.HoursPerDay));
            branchStats[branch].records.push(record);
        });

        return Object.entries(branchStats)
            .map(([branch, stats]) => ({
                branch,
                totalDays: stats.totalDays,
                uniqueStaff: stats.totalStaffSick.size,
                totalHours: stats.totalHours,
                records: stats.records,
                score: (stats.totalStaffSick.size * stats.totalDays)
            }))
            .sort((a, b) => a.totalDays - b.totalDays);
    }, [sickDays]);

    if (isLoading) return <div style={{ color: 'white' }}>Loading...</div>;

    return (
        <TableContainer style={{ height: 'calc(100vh - 350px)', overflow: 'scroll' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }}>Branch</TableCell>
                        <TableCell align="right" sx={{ color: 'white' }}>Days Lost</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {branchScores.map((branch) => (
                        <Tooltip
                            key={branch.branch}
                            title={
                                <div>
                                    <div>Total Staff Sick: {branch.uniqueStaff}</div>
                                    <div>Total Days: {branch.totalDays}</div>
                                    <div>Total Hours Lost: {branch.totalHours}</div>
                                </div>
                            }
                        >
                            <TableRow hover>
                                <TableCell sx={{ color: 'white' }}>{branch.branch}</TableCell>
                                <TableCell align="right" sx={{ color: 'white' }}>
                                    {branch.totalDays}
                                </TableCell>
                            </TableRow>
                        </Tooltip>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SickDaysLeagueTable;
