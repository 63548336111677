import React from 'react';
import {
    Box,
    Typography,
    IconButton,
    Card,
    CardContent,
    Divider
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import moment from 'moment-timezone';

const ClocklogDetails = ({ detail, onClose }) => {
    if (!detail) return null;

    // Combine the date with the time strings for accurate parsing
    const date = moment(detail.date).format('YYYY-MM-DD');
    const clockInTime = moment(`${date} ${detail.clockInTime}`, 'YYYY-MM-DD HH:mm');
    const clockOutTime = detail.clockOutTime ?
        moment(`${date} ${detail.clockOutTime}`, 'YYYY-MM-DD HH:mm') : null;
    const duration = clockOutTime && clockInTime.isValid() && clockOutTime.isValid() ?
        moment.duration(clockOutTime.diff(clockInTime)) : null;

    return (
        <Box sx={{ height: '100%', bgcolor: 'background.paper' }}>
            {/* Header */}
            <Box sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: 1,
                borderColor: 'divider',
                height: 40
            }}>
                <Typography variant="h6">Clocking Details</Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* Content */}
            <Box sx={{ p: 2, overflow: 'auto', height: 'calc(100% - 64px)' }}>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography>
                            {detail.staffName.split(' ').map(word =>
                                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                            ).join(' ')}
                        </Typography>
                    </CardContent>
                </Card>

                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Clocking Times
                        </Typography>
                        <Typography>
                            Clock In: {clockInTime.isValid() ? clockInTime.format('HH:mm') : 'Invalid time'}
                        </Typography>
                        <Typography>
                            Clock Out: {clockOutTime?.isValid() ? clockOutTime.format('HH:mm') : 'Not clocked out'}
                        </Typography>
                        {duration && (
                            <Typography>
                                Duration: {Math.floor(duration.asHours())}h {duration.minutes()}m
                            </Typography>
                        )}
                    </CardContent>
                </Card>

                {detail.shift && (
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Shift Information
                            </Typography>
                            <Typography>Start: {detail.shift.Start}</Typography>
                            <Typography>End: {detail.shift.Finish}</Typography>
                            {detail.status === 'INFRINGEMENT' && (
                                <Typography color="error" sx={{ mt: 1 }}>
                                    {detail.reason}
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                )}

                {detail.acceptedInfringement && (
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Infringement Details
                            </Typography>
                            <Typography>Type: {detail.acceptedInfringement.ApprovedType}</Typography>
                            <Typography>Approved By: {detail.acceptedInfringement.ApprovedBy}</Typography>
                            <Typography>
                                Date: {moment(detail.acceptedInfringement.DateApproved).format('DD/MM/YYYY')}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </Box>
    );
};

export default ClocklogDetails;
