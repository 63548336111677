import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, Typography, Grid, Box, Button } from '@mui/material'
import { BeachAccess, Sick, CheckCircle, Warning } from '@mui/icons-material'
import moment from 'moment-timezone'

const DayNavigation = ({ days, currentDay, onDayClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: 100
      }}
    >
      {days.map((day, index) => (
        <Button
          key={`day-${day}-${index}`}
          onClick={() => onDayClick(day)}
          sx={{
            'minWidth': '20px',
            'height': '40px',
            'margin': '0 2px',
            'backgroundColor': currentDay === day ? '#1976d2' : 'transparent',
            'color': currentDay === day ? 'white' : '#888',
            '&:hover': {
              backgroundColor: currentDay === day ? '#1565c0' : 'rgba(255, 255, 255, 0.08)'
            }
          }}
        >
          {day}
        </Button>
      ))}
    </Box>
  )
}

const InfringementAnalysis = ({
  handleRowClick,
  analysisData,
  selectedBranch,
  fetchClockingRecords,
  setSelectedDetail,
  selectedDetail,
  fetchAcceptedInfringements,
  highlightedRecord,
  acceptedInfringements
}) => {
  const [currentDay, setCurrentDay] = useState(null)
  const [showOnlyInfringements, setShowOnlyInfringements] = useState(false)
  const dayRefs = useRef({})
  const containerRef = useRef(null)

  const getRecordIdentifier = (detail, dayDate) => {
    // If clockingId exists, use it as primary identifier
    if (detail.clockingId) {
      return detail.clockingId
    }

    // Ensure we have a valid date - use either detail's date or day's date
    const date = detail.date || dayDate
    const formattedDate = moment(date).format('YYYY-MM-DD')

    // If we have staffId and date, create a composite key
    if (detail.staffId && date) {
      return `${detail.staffId}-${formattedDate}`
    }

    // If we have staffName and date as fallback
    if (detail.staffName && date) {
      return `${detail.staffName.replace(/\s+/g, '-')}-${formattedDate}`
    }

    // Last resort - create a unique identifier using available data
    return `record-${detail.staffId || detail.staffName || 'unknown'}-${formattedDate}-${Math.random()
      .toString(36)
      .substr(2, 9)}`
  }

  const shouldHighlight = (detail, dayDate) => {
    if (!highlightedRecord) return false

    const recordId = getRecordIdentifier(detail, dayDate)
    const isMatch = String(recordId) === String(highlightedRecord)

    //console.log(`Comparing recordId: ${recordId} with highlightedRecord: ${highlightedRecord}. Match: ${isMatch}`);

    return isMatch
  }

  useEffect(() => {
    const handleScroll = () => {
      let closestDay = null
      let closestDistance = Infinity

      Object.values(dayRefs.current).forEach((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect()
          const viewportMiddle = window.innerHeight / 2

          if (rect.top <= viewportMiddle && rect.bottom > viewportMiddle) {
            const distanceFromMiddle = Math.abs(rect.top - viewportMiddle)

            if (distanceFromMiddle < closestDistance) {
              closestDistance = distanceFromMiddle
              closestDay = parseInt(ref.getAttribute('data-day'))
            }
          }
        }
      })

      if (closestDay !== null && closestDay !== currentDay) {
        setCurrentDay(closestDay)
      }
    }

    const container = containerRef.current

    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [analysisData, currentDay])

  useEffect(() => {
    if (highlightedRecord) {
      const highlightedElement = document.getElementById(`record-${highlightedRecord}`)
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [highlightedRecord])

  const handleDayClick = (day) => {
    dayRefs.current[day]?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleToggleInfringements = () => {
    setShowOnlyInfringements((prev) => !prev)
  }

  const filteredAnalysisData = analysisData.map((day) => {
    const relevantDetails = showOnlyInfringements
      ? day.details.filter((detail) => detail.status === 'INFRINGEMENT')
      : day.details

    return {
      ...day,
      details: relevantDetails
    }
  })

  const days = filteredAnalysisData.map((day) => moment(day.date).date())

  const getIconForStatus = (detail) => {
    if (detail.reason === 'On holiday') {
      return <BeachAccess sx={{ color: 'green', fontSize: 30 }} />
    } else if (detail.reason === 'Sick' || detail.reason === 'Sick day') {
      return <Sick sx={{ color: 'green', fontSize: 30 }} />
    } else if (
      detail.status === 'OK' &&
      (detail.reason === 'No shift scheduled' || detail.reason === 'Casual shift (ZEROH) with no clocking')
    ) {
      return <CheckCircle sx={{ color: 'green', fontSize: 30 }} />
    } else if (detail.status === 'INFRINGEMENT') {
      return <Warning sx={{ color: 'red', fontSize: 30 }} />
    }
    return null
  }

  if (!filteredAnalysisData || !Array.isArray(filteredAnalysisData) || filteredAnalysisData.length === 0) {
    return (
      <Card sx={{ mt: 2, p: 2, bgcolor: '#121212' }}>
        <Typography color="white">
          {filteredAnalysisData ? 'No analysis data available.' : 'Loading analysis data...'}
        </Typography>
      </Card>
    )
  }

  return (
    <Card
      sx={{
        mt: 2,
        maxHeight: 'calc(100vh - 330px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#121212'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          mb: 3,
          mt: 2,
          ml: 2,
          mr: 2
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
          Daily Infringement Analysis
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleToggleInfringements}
          sx={{ backgroundColor: showOnlyInfringements ? '#FF5252' : '#1976d2' }}
        >
          {showOnlyInfringements ? 'Show All' : 'Show Only Infringements'}
        </Button>
      </Box>
      <DayNavigation days={days} currentDay={currentDay} onDayClick={handleDayClick} />

      <CardContent ref={containerRef} sx={{ overflow: 'auto', flexGrow: 1 }}>
        {filteredAnalysisData.map((day, dayIndex) => {
          const okCount = day.details ? day.details.filter((detail) => detail.status === 'OK').length : 0
          const infringementCount = day.details
            ? day.details.filter((detail) => detail.status === 'INFRINGEMENT').length
            : 0

          return (
            <Box
              key={`day-${day.date}-${dayIndex}`}
              ref={(el) => (dayRefs.current[moment(day.date).date()] = el)}
              data-day={moment(day.date).date()}
              sx={{ position: 'relative', zIndex: filteredAnalysisData.length - dayIndex, mb: 4 }}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: -20,
                  backgroundColor: 'black',
                  zIndex: 1,
                  py: 2,
                  mb: 2,
                  height: 50,
                  paddingLeft: 2,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Box sx={{ display: 'flex', marginLeft: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'white',
                      fontWeight: 'bold'
                    }}
                  >
                    {moment(day.date).format('D MMMM')} - {moment(day.date).format('dddd')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', marginRight: 2 }}>
                  <Typography component="span" sx={{ ml: 2, fontSize: '1em', color: '#4CAF50' }}>
                    OK: {okCount}
                  </Typography>
                  <Typography component="span" sx={{ ml: 2, fontSize: '1em', color: '#FF5252' }}>
                    Infringements: {infringementCount}
                  </Typography>
                </Box>
              </Box>

              {day.details && day.details.length > 0 ? (
                day.details.map((detail, detailIndex) => {
                  const recordId = getRecordIdentifier(detail, day.date)
                  const isHighlighted = shouldHighlight(detail, day.date)

                  return (
                    <Box
                      id={`record-${recordId}`}
                      key={`detail-${recordId}-${detailIndex}`}
                      onClick={() => {
                        if (detail.status !== 'OK') {
                          handleRowClick(detail, day)
                        }
                      }}
                      sx={{
                        mb: 2,
                        p: 2,
                        border: isHighlighted ? '2px solid #FFD700' : '1px solid #333',
                        borderRadius: 2,
                        bgcolor: isHighlighted ? 'rgba(255, 215, 0, 0.1)' : '#1E1E1E',
                        transition: 'all 0.3s ease',
                        boxShadow: isHighlighted ? '0 0 10px #FFD700' : 'none',
                        cursor: detail.status !== 'OK' ? 'pointer' : 'default',
                        opacity: detail.status === 'OK' ? 0.7 : 1
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 2,
                          p: 1,
                          cursor: 'pointer',
                          backgroundColor: 'yellow',
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: 20
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold', ml: 2, textTransform: 'uppercase' }}>
                          {detail.staffName}
                        </Typography>
                        {getIconForStatus(detail)}
                      </Box>
                      <Grid container spacing={2} sx={{ ml: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2" color="white">
                            <strong>SHIFT:</strong>{' '}
                            {detail.shift ? `${detail.shift.Start} - ${detail.shift.Finish}` : 'No shift'}
                          </Typography>
                          <Typography variant="body2" color="white">
                            <strong>BREAK:</strong> {detail.breakMinutes} minutes
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body2" color="white">
                            <strong>CLOCKED IN:</strong> {detail.clockInTime ? detail.clockInTime : 'N/A'}
                          </Typography>
                          <Typography variant="body2" color="white">
                            <strong>CLOCKED OUT:</strong> {detail.clockOutTime ? detail.clockOutTime : 'N/A'}
                          </Typography>
                          <Typography variant="body2" color="white">
                            <strong>TIME WORKED:</strong> {detail.totalWorkedMinutes || 'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}
                      >
                        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="body2" color={detail.status === 'OK' ? '#4CAF50' : '#FF5252'}>
                            <strong>STATUS:</strong> {detail.status}
                          </Typography>
                          <Typography variant="body2" color="white" sx={{ mt: 0.5 }}>
                            <strong>REASON:</strong> {detail.reason || 'N/A'}{' '}
                            {detail.approvedBy && (
                              <>
                                <strong>APPROVED BY:</strong> {detail.approvedBy || 'N/A'}
                              </>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            onClick={() =>
                              handleRowClick(
                                {
                                  ...detail,
                                  acceptedInfringements // Add this to the detail object
                                },
                                day
                              )
                            }
                            variant="contained"
                            color={detail.status !== 'OK' ? 'secondary' : 'primary'}
                            sx={{ ml: 2 }}
                          >
                            {detail.status !== 'OK' ? 'FIX RECORD' : 'EDIT RECORD'}
                          </Button>
                        </Grid>
                      </Grid>
                      {isHighlighted && (
                        <Typography sx={{ color: '#FFD700', fontWeight: 'bold', mt: 1 }}>Currently Editing</Typography>
                      )}
                    </Box>
                  )
                })
              ) : (
                <Typography color="white">No details available for this day.</Typography>
              )}
            </Box>
          )
        })}
      </CardContent>
    </Card>
  )
}

export default InfringementAnalysis
