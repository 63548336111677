import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
  Container,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'
import './App.css'
import OverviewTab from './components/tabs/OverviewTab'
import QuotesTab from './components/tabs/QuotesTab'
import moment from 'moment-timezone'
import SalesTab from './components/tabs/SalesTab'
import PerformanceTab from './components/tabs/PerformanceTab'
import { GlobalContext } from './context/GlobalContext'
import GELeads from './components/GELeads'
import GETab from './components/tabs/GETab'
import SettingsTab from './components/tabs/SettingsTab'
import NewMyHolidays from './components/newholidays/MyHolidays'
import Support from './components/newholidays/Support'
import CustomerTab from './components/tabs/CustomerTab'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function Overview() {
  const { operatorData, setToken, setIsLoggedIn } = useContext(GlobalContext)
  const [tabValue, setTabValue] = useState(0)
  const [progress, setProgress] = useState(100)
  const [lastUpdated, setLastUpdated] = useState(null)
  const [logoutOpen, setLogoutOpen] = useState(false)

  const handleLogout = () => {
    setToken(null)
    setIsLoggedIn(false)
    setLogoutOpen(false)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const memoizedGETab = useMemo(() => <GETab />, [])


  return (
    <Container
      style={{
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        maxHeight: '90vh',
        overflow: 'scroll',
        backgroundColor: '#181a1f',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          height: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="https://spar.connectplus.parts/api/public/images/Logo.png" alt="Logo" style={{ height: '65px' }} />
          <h1 style={{ color: 'white' }}>({operatorData && operatorData.Branch})</h1>
        </Box>
        <Box>
          <h5 style={{ color: 'white', cursor: 'pointer' }} onClick={() => setLogoutOpen(true)}>
            LOGOUT
          </h5>
          {/* <h3 style={{ color: 'white' }}>QUIT</h3> */}
        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
            <Tab style={{ color: 'white' }} label="Overview" {...a11yProps(0)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Customers" {...a11yProps(1)} />

            {/* <Tab style={{ color: 'white' }} label="Performance" {...a11yProps(1)} /> */}
            {/* <Tab style={{ color: 'white' }} label="Sales" {...a11yProps(1)} /> */}
            <Tab style={{ color: 'white' }} label="Quotes" {...a11yProps(2)} />
            <Tab style={{ color: 'white' }} label="GE Lead" {...a11yProps(3)} />
            <Tab style={{ color: 'white' }} label="My holidays" {...a11yProps(4)} />
            {/* <Tab style={{ color: 'white' }} label="Deliveries" {...a11yProps(4)} />
             */}
            <Tab style={{ color: 'white' }} label="Support" {...a11yProps(5)} />
          </Tabs>
          <div style={{ textAlign: 'right' }}></div>
        </div>
      </Box>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0
        }}
        value={tabValue}
        index={0}
      >
        {operatorData && <OverviewTab progress={progress} setProgress={setProgress} />}
      </TabPanel>
      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0
        }}
        value={tabValue}
        index={1}
      >
        {operatorData && (
          <CustomerTab
            operatorData={operatorData}
            progress={progress}
            setProgress={setProgress}
          />
        )}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0
        }}
        value={tabValue}
        index={2}
      >
        {operatorData && <QuotesTab progress={progress} setProgress={setProgress} />}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={3}
      >
        {operatorData && memoizedGETab}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={4}
      >
        {operatorData && <NewMyHolidays />}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={5}
      >
        <div className="absolute inset-0 mb-[85px] mt-[92px] border-t border-gray-700 border-b">
          {operatorData && <Support />}
        </div>
      </TabPanel>

      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          height: 'auto',
          position: 'absolute',
          bottom: 0,
          left: 0,
          justifyContent: 'center'
        }}
      >
        {operatorData ? (
          <div
            style={{
              width: '90%',
              marginLeft: 5,
              marginRight: 5
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexGrow: 1,
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 40, marginRight: 20 }}>👤</span>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                <Typography style={{ color: 'white' }}>{operatorData.Name}</Typography>
                <Typography style={{ color: 'white' }}>({operatorData.Inits})</Typography>
                <Typography style={{ color: 'white' }}>{operatorData.Branch}</Typography>
              </div>
              {/* <Typography> {operatorData.Position}</Typography> */}
              <div style={{ flex: 1 }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', color: 'white', textAlign: 'right' }}>
                  {moment().format('ddd DD/MM/YYYY')}
                </Typography>
              </div>
            </div>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        ) : (
          <Typography>Loading operator data...</Typography>
        )}
      </Box>
      <Dialog
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">{'Are you sure you want to logout?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Clicking OK will log you out of the application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Overview
