import { useContext, useEffect, useState } from 'react'
import { Select } from '@mantine/core'
import useBranchStore from '../stores/useBranchStore'
import { GlobalContext } from '../../../context/GlobalContext'

const BranchDropdown = () => {
  const { operatorData } = useContext(GlobalContext)
  const branches = useBranchStore((state) => state.branches)
  const regions = useBranchStore((state) => state.regions)
  const selectedRegion = useBranchStore((state) => state.selectedRegion)
  const selectedBranch = useBranchStore((state) => state.selectedBranch)
  const setSelectedBranch = useBranchStore((state) => state.setSelectedBranch)
  const setSelectedRegion = useBranchStore((state) => state.setSelectedRegion)

  useEffect(() => {
    if (operatorData.Status === 'bm' || operatorData.Status === 'sales') setSelectedBranch(operatorData.Branch)

    if (operatorData.Status === 'rm') {
      if (!regions[operatorData.Name]) {
        setSelectedBranch('NONE')
      } else {
        setSelectedRegion(operatorData.Name)
      }
    }
  }, [])

  if (!branches) return <Select value="loading" data={[{ value: 'loading', label: 'Loading...' }]} disabled />

  if (operatorData.Status === 'admin' || operatorData.Status === 'hr') {
    const adminBranches = Object.keys(regions)
      .sort()
      .reduce(
        (acc, cur) => {
          acc.push({ label: cur, value: `AYYLMAO,${cur}` })
          const regionBranches = regions[cur].sort((a, b) => a.Branch.localeCompare(b.Branch))

          for (const b of regionBranches) {
            acc.push({ label: '\xa0\xa0\xa0\xa0\xa0' + `[${b.MamCode}] ${b.Branch}`, value: b.MamCode })
          }

          return acc
        },
        [{ label: '[ALL] All branches', value: 'ALL' }]
      )

    return (
      <>
        <Select
          value={selectedRegion ? `AYYLMAO,${selectedRegion}` : selectedBranch}
          onChange={(newVal) => {
            const split = newVal.split(',')

            if (split[0] === 'AYYLMAO') {
              setSelectedRegion(split[1])
            } else {
              setSelectedBranch(newVal)
            }
          }}
          data={adminBranches}
          searchable
          nothingFound="No branches found"
          maxDropdownHeight={280}
          clearable={false}
          allowDeselect={false}
        />
      </>
    )
  } else if (operatorData.Status === 'rm') {
    const rmBranches = regions[operatorData.Name]?.map((b) => ({
      value: b.MamCode,
      label: `[${b.MamCode}] ${b.Branch}`
    })) || [{ label: 'No assigned branches.', value: 'NONE' }]

    return (
      <>
        <Select
          value={selectedRegion ? `AYYLMAO,${selectedRegion}` : selectedBranch}
          onChange={(newVal) => {
            console.log('newVal', newVal)
            const split = newVal.split(',')

            if (split[0] === 'AYYLMAO') {
              setSelectedRegion(split[1])
            } else {
              setSelectedBranch(newVal)
            }
          }}
          data={
            rmBranches.length > 1
              ? [{ label: '[ALL] All branches', value: `AYYLMAO,${operatorData.Name}` }, ...rmBranches]
              : rmBranches
          }
          searchable
          nothingFound="No branches found"
          maxDropdownHeight={280}
          clearable={false}
          allowDeselect={false}
        />
      </>
    )
  } else if (operatorData.Status === 'bm' || operatorData.Status === 'sales') {
    return (
      <div className="px-3 py-1.5 text-sm font-semibold rounded border bg-neutral-800 border-neutral-600 text-neutral-200">
        [{selectedBranch}] {branches.filter((b) => b.MamCode === selectedBranch).pop().Branch}
      </div>
    )
  } else {
    return <div>bad status {JSON.stringify(operatorData.Status)}</div>
  }

  return (
    <>
      <Select
        value={selectedBranch}
        onChange={setSelectedBranch}
        data={branches.map((b) => ({
          value: b.MamCode,
          label: `[${b.MamCode}] ${b.Branch}`
        }))}
        searchable
        nothingFound="No branches found"
        maxDropdownHeight={280}
        clearable={false}
        allowDeselect={false}
      />
    </>
  )
}

export default BranchDropdown
