import { useEffect, useMemo, useState } from 'react'
import { Icon } from './gsys-ui'
import useApi from './hooks/useApi'
import moment from 'moment-timezone'
import { MagnifyingGlassIcon, UsersIcon } from '@heroicons/react/24/outline'
import { TextInput, Button, NativeSelect } from '@mantine/core'
import clsx from 'clsx'
import s from './StaffTable.module.scss'
import BranchDropdown from './components/BranchDropdown'
import useBranchStore from './stores/useBranchStore'
import { CircularProgress } from '@mui/material'
import checkInfringement, { calculateStatus, checkTimingIssues } from './lib/checkInfringement'
import haxios from './lib/haxios'

const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

const Calendar = () => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedPosition, setSelectedPosition] = useState('ALL')
  const [selectedCell, setSelectedCell] = useState([null, null])
  const selectedBranch = useBranchStore((state) => state.selectedBranch)
  const selectedRegion = useBranchStore((state) => state.selectedRegion)
  const regions = useBranchStore((state) => state.regions)
  const [selectedMonth, setSelectedMonth] = useState(moment().month())
  const [selectedYear, setSelectedYear] = useState(moment().year())

  const { data, isLoading, refetch } = useApi({
    url: '/salesassistnew/tma/calendar',
    enabled: !!selectedBranch,
    data: { branch: selectedBranch, month: selectedMonth, year: selectedYear }
  })

  const displayDateStart = useMemo(
    () => moment().date(1).month(selectedMonth).year(selectedYear).startOf('month'),
    [data]
  )
  const displayDateEnd = moment(displayDateStart).endOf('month')
  const dayCount = displayDateEnd.diff(displayDateStart, 'days') + 1

  useEffect(() => {
    setSelectedCell([null, null])
  }, [selectedBranch])

  useEffect(() => {
    setSelectedPosition('ALL')
  }, [data])

  if (isLoading) {
    return (
      <div className="flex absolute inset-0 justify-center items-center">
        <CircularProgress />
      </div>
    )
  }

  const allPositions = [...new Set(data.map((s) => s.Position.trim()))].sort().map((p) => ({ label: p, value: p }))

  const handleSetMonth = (val) => {
    setSelectedMonth(val)
    setSelectedCell([null, null])
  }

  const handleSetYear = (val) => {
    setSelectedYear(val)
    setSelectedCell([null, null])
  }

  const handleSetSearch = (val) => {
    setSearchValue(val)
    setSelectedCell([null, null])
  }

  const dataFiltered = data.filter((el) => {
    const regionMatch = selectedRegion ? regions[selectedRegion].some((b) => b.MamCode === el.Branch) : true
    const nameMatch = `${el.FName} ${el.SName} ${el.Branch}`.toLowerCase().includes(searchValue.toLowerCase())
    const positionMatch = selectedPosition === 'ALL' || el.Position.trim() === selectedPosition
    return nameMatch && positionMatch && regionMatch
  })

  const dataSorted = dataFiltered.sort((a, b) => {
    const branchComparison = a.Branch.localeCompare(b.Branch)
    if (branchComparison !== 0) {
      return branchComparison
    }

    const positionComparison = a.Position.localeCompare(b.Position)
    if (positionComparison !== 0) {
      return positionComparison
    }

    const aName = `${a.FName} ${a.SName}`
    const bName = `${b.FName} ${b.SName}`
    return aName.localeCompare(bName)
  })

  return (
    <div className="absolute inset-0">
      <div class="flex flex-col h-full">
        <div className="flex flex-none justify-between items-center p-2 text-white border-b border-b-gray-700">
          <div className="flex items-center space-x-2 text-xl font-semibold">Calendar</div>
          <div className="flex items-center space-x-2">
            <BranchDropdown />
            <NativeSelect
              value={selectedPosition}
              onChange={(e) => setSelectedPosition(e.currentTarget.value)}
              data={[{ label: 'All positions', value: 'ALL' }, ...allPositions]}
            />
            <NativeSelect
              value={selectedMonth}
              onChange={(e) => handleSetMonth(e.currentTarget.value)}
              data={[
                { value: 0, label: 'January' },
                { value: 1, label: 'February' },
                { value: 2, label: 'March' },
                { value: 3, label: 'April' },
                { value: 4, label: 'May' },
                { value: 5, label: 'June' },
                { value: 6, label: 'July' },
                { value: 7, label: 'August' },
                { value: 8, label: 'September' },
                { value: 9, label: 'October' },
                { value: 10, label: 'November' },
                { value: 11, label: 'December' }
              ]}
            />
            <NativeSelect
              value={selectedYear}
              onChange={(e) => handleSetYear(e.currentTarget.value)}
              data={Array.from({ length: moment().year() - 2024 + 1 }, (_, i) => 2024 + i).map((num) => ({
                value: num,
                label: num
              }))}
            />
            <TextInput
              value={searchValue}
              onChange={(e) => handleSetSearch(e.target.value)}
              placeholder="Type to search..."
              leftSection={<Icon Comp={MagnifyingGlassIcon} />}
            />
          </div>
        </div>

        <div class="flex overflow-y-hidden flex-1">
          <div className="flex-1 h-full">
            <div
              className={clsx(
                'overflow-y-scroll border-r border-r-gray-700 w-full h-full relative',
                s.tableColumnHover
              )}
            >
              <div
                className="flex sticky top-0 z-50 font-semibold text-center border-b-2 border-b-gray-700"
                style={{ background: '#181A1F' }}
              >
                <div className="flex flex-1 items-center px-2 space-x-1 border-r-2 border-r-gray-700">
                  <div>Staff</div>
                </div>
                <div className="flex flex-none">
                  {new Array(dayCount).fill('ayylmao').map((_, ind) => {
                    const thisDate = moment(displayDateStart).date(ind + 1)
                    const isToday = moment().isSame(thisDate, 'day')
                    const dayOfWeek = daysOfWeek[thisDate.isoWeekday() - 1]
                    const isWeekend = dayOfWeek === 'S'

                    return (
                      <div
                        key={ind}
                        data-col-index={ind + 1}
                        class={clsx(
                          'pt-0.5 w-8 border-r border-r-gray-700 z-10 relative',
                          s.dayCont,
                          s.highlightColOnly,
                          isWeekend && 'bg-gray-700 '
                        )}
                      >
                        <div class="leading-tight">{ind + 1}</div>
                        <div class="text-sm font-normal leading-tight">{dayOfWeek}</div>
                        <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
                        <div
                          className={clsx(
                            'absolute top-[1px] right-[1px] bottom-[1px] left-[1px] pointer-events-none',
                            isToday && 'outline outline-2 outline-yellow-400 z-50'
                          )}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="z-40 select-none">
                {dataSorted.map((row) => (
                  <StaffRow
                    key={row.StaffId}
                    staff={row}
                    dayCount={dayCount}
                    startDate={displayDateStart}
                    selectedDay={selectedCell[0] === row.StaffId ? selectedCell[1] : null}
                    setSelectedDay={(ind) => setSelectedCell([row.StaffId, ind])}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="flex flex-col h-full w-[330px]">
            <div className="flex-1 p-2 space-y-2 text-right">
              {selectedCell[0] && (
                <Sidebar
                  staff={data.filter((s) => s.StaffId === selectedCell[0]).pop()}
                  selectedDay={moment(displayDateStart).date(selectedCell[1])}
                  handleClose={() => setSelectedCell([null, null])}
                />
              )}
            </div>

            <div className="flex-none p-2 border-t-2 border-t-gray-700">
              <div className="font-semibold">Calendar legend</div>
              <div className="flex">
                <div className="flex-1">
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-1 w-5 h-5 text-sm bg-green-600 border-2 border-green-700">
                      W
                    </div>
                    <div>Shift worked</div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-1 w-5 h-5 text-sm bg-gray-600 border-2 border-gray-500">
                      D
                    </div>
                    <div>Due for shift</div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-1 w-5 h-5 text-sm bg-red-600 border-2 border-red-700">
                      A
                    </div>
                    <div>Absence</div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-1 w-5 h-5 text-sm bg-purple-600 border-2 border-purple-700">
                      S
                    </div>
                    <div>Sick leave</div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-1 w-5 h-5 text-sm bg-blue-500 border-2 border-blue-600">
                      H
                    </div>
                    <div>Holiday</div>
                  </div>
                </div>

                <div className="flex-1">
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-1 w-5 h-5 text-sm bg-gray-700 border-2 border-gray-800"></div>
                    <div>Not due for shift</div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex justify-center items-center mr-1 w-5 h-5 text-sm bg-transparent border-2 border-gray-800"></div>
                    <div>Shifts unassigned</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StaffRow = ({ staff, dayCount, startDate, selectedDay, setSelectedDay }) => {
  return (
    <div class={clsx('flex h-full', s.tRow)}>
      <div
        class={clsx(
          'flex-1 px-2 py-1 border-r-2 border-b border-r-gray-700 border-b-gray-700 h-[63px] relative z-40',
          s.dayCont,
          s.highlightRowOnly
        )}
      >
        <div class="text-lg font-bold">
          {staff.FName} {staff.SName}
        </div>
        <div class="">
          {staff.Branch} | {staff.Position}
        </div>
        <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
        <div
          className={clsx(
            'absolute top-[2px] right-[1px] bottom-[1px] left-[2px] pointer-events-none',
            !!selectedDay && ' outline outline-2 outline-blue-500 z-50'
          )}
        />
      </div>

      <div className=" flex-none p-0  h-[63px] relative">
        {new Array(dayCount).fill('ayylmao').map((_, ind) => {
          const thisDate = moment(startDate).date(ind + 1)
          const { status, clockStatus, isAm, isPm, timingIssues, infringement, isZerohShift } = calculateStatus(
            staff,
            thisDate
          )

          return (
            <div
              key={ind}
              data-col-index={ind + 1}
              className={clsx(
                'inline-block w-8 h-full border-r border-r-gray-700 border-b border-b-gray-700 relative cursor-pointer',
                s.dayCont
              )}
              onClick={() => setSelectedDay(ind + 1)}
            >
              <div
                className={clsx(
                  'absolute inset-0 flex flex-col items-center justify-center overflow-hidden',
                  status === 'due' && 'bg-gray-600',
                  status === 'noshift' && 'bg-gray-800',
                  status === 'clocked' && 'bg-green-600',
                  status === 'absent' && 'bg-red-600',
                  status === 'sick' && 'bg-purple-600',
                  status === 'hol' && 'bg-blue-500'
                )}
              >
                {isAm && (
                  <div
                    className={clsx(
                      'absolute right-0 bottom-0 w-0 h-0 border-[15px] border-t-transparent border-l-transparent',
                      clockStatus === 'due' && 'border-b-gray-600 border-r-gray-600',
                      clockStatus === 'clocked' && 'border-b-green-600 border-r-green-600',
                      clockStatus === 'absent' && 'border-b-red-600 border-r-red-600',
                      (clockStatus === 'noshift' || !clockStatus) && 'border-b-gray-800 border-r-gray-800'
                    )}
                  />
                )}
                {isPm && (
                  <div
                    className={clsx(
                      'absolute top-0 left-0 w-0 h-0 border-[15px]  border-b-transparent border-r-transparent',
                      clockStatus === 'due' && 'border-t-gray-600 border-l-gray-600',
                      clockStatus === 'clocked' && 'border-t-green-600 border-l-green-600',
                      clockStatus === 'absent' && 'border-t-red-600 border-l-red-600',
                      (clockStatus === 'noshift' || !clockStatus) && 'border-t-gray-800 border-l-gray-800'
                    )}
                  />
                )}
                <div>
                  {status === 'due' && 'D'}
                  {status === 'noshift' && ''}
                  {status === 'clocked' && 'W'}
                  {status === 'absent' && 'A'}
                  {status === 'sick' && 'S'}
                  {status === 'hol' && 'H'}
                </div>
                {timingIssues.isLate && (
                  <div className="absolute bottom-0 left-[2px] text-red-500 text-xs font-bold">L</div>
                )}
                {timingIssues.isEarlyOut && (
                  <div className="absolute bottom-0 right-[2px] text-red-500 text-xs font-bold">O</div>
                )}
                {isZerohShift && (
                  <div className="absolute bottom-0 left-[50%] translate-x-[-50%]  text-yellow-600 text-xs font-bold">
                    Z
                  </div>
                )}
              </div>
              <div className={clsx('absolute inset-0 pointer-events-none', s.highlightDiv)} />
              <div
                className={clsx(
                  'absolute top-[1px] right-[1px] bottom-[1px] left-[1px] pointer-events-none',
                  selectedDay - 1 === ind && ' outline outline-2 outline-blue-500 z-50'
                )}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Sidebar = ({ staff, selectedDay, handleClose }) => {
  const { status, clock, clockStatus, isAm, isPm, timingIssues, infringement, todaysShift } = calculateStatus(
    staff,
    selectedDay
  )

  return (
    <>
      <Button onClick={handleClose}>Close</Button>

      <div className="text-right">
        <div className="text-lg font-semibold">
          {staff.FName} {staff.SName}
        </div>
        <div>
          {staff.Branch} | {staff.Position}
        </div>
      </div>

      <div className="text-right">
        <div className="font-semibold">Date</div>
        <div>{selectedDay.format('dddd, MMMM Do YYYY')}</div>
      </div>

      <div className="text-right">
        <div className="font-semibold">Status</div>
        <div className="flex justify-end items-center space-x-2">
          <div
            className={clsx(
              'w-8 h-8 rounded-md flex items-center justify-center',
              status === 'due' && 'bg-gray-600',
              status === 'noshift' && 'bg-gray-800',
              status === 'clocked' && 'bg-green-600',
              status === 'absent' && 'bg-red-600',
              status === 'sick' && 'bg-purple-600',
              status === 'hol' && 'bg-blue-500'
            )}
          >
            {status === 'due' && 'D'}
            {status === 'noshift' && 'NS'}
            {status === 'clocked' && 'W'}
            {status === 'absent' && 'A'}
            {status === 'sick' && 'S'}
            {status === 'hol' && 'H'}
          </div>
          <div className="">
            {status === 'due' && 'Due'}
            {status === 'noshift' && 'No Shift'}
            {status === 'clocked' && 'Worked'}
            {status === 'absent' && 'Absent'}
            {status === 'sick' && 'Sick'}
            {status === 'hol' && 'Holiday'}
          </div>
        </div>
      </div>

      <div className="text-right">
        <div className="font-semibold">Scheduled shift</div>
        {todaysShift ? (
          <>
            <div className="">{todaysShift.ShiftName}</div>
            <div className="">
              {todaysShift.Start} - {todaysShift.Finish}
            </div>
          </>
        ) : (
          <div>No shift scheduled.</div>
        )}
      </div>

      <div className="text-right">
        <div className="font-semibold">Additional details</div>
        {clock?.ClockInTime && (
          <div>
            Clock: {moment(clock.ClockInTime).format('HH:mm')} -{' '}
            {clock.ClockOutTime && moment(clock.ClockOutTime).format('HH:mm')}{' '}
          </div>
        )}

        <div>Status: {infringement.status}</div>
        {infringement.reason && <div>Reason: {infringement.reason}</div>}
        {infringement.totalWorkedMinutes ? <div>Worked minutes: {infringement.totalWorkedMinutes}</div> : null}
        {infringement.expectedWorkMinutes ? <div>Expected minutes: {infringement.expectedWorkMinutes}</div> : null}
      </div>
    </>
  )
}

export default Calendar
