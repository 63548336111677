import React, { useContext, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  createTheme,
  ThemeProvider,
  IconButton,
  TextField,
  Button,
  TablePagination,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { GlobalContext } from '../../context/GlobalContext';

// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function BranchesTable() {
  const { token } = useContext(GlobalContext);
  const [branches, setBranches] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  const [editRowId, setEditRowId] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentBranch, setCurrentBranch] = useState({ SalesTarget: '', MarginTarget: '', Region: '' });

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [newBranch, setNewBranch] = useState({ Branch: '', SalesTarget: '', MarginTarget: '', Region: '' });

  const rowsPerPage = 500;
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');

  const [alignment, setAlignment] = useState('0');
  console.info(token);

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Log to see if function is being called
    console.log('Upload attempt with file:', file.name);

    const fileType = file.name.split('.').pop().toLowerCase();
    if (!['csv', 'xlsx'].includes(fileType)) {
      setUploadError('Please upload only CSV or Excel files');
      return;
    }

    setUploadLoading(true);
    setUploadError(null);
    setUploadResult(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/updateTargets', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      // Log response status
      console.log('Upload response status:', response.status);

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const result = await response.json();
      console.log('Upload result:', result);
      setUploadResult(result);

      // Refresh the branches data
      const branchesResponse = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const branchesData = await branchesResponse.json();
      setBranches(branchesData);

    } catch (error) {
      console.error('Upload error:', error);
      setUploadError(error.message);
    } finally {
      setUploadLoading(false);
      // Reset the file input
      event.target.value = '';
    }
  };

  // Function to handle the dialog open event
  const handleDialogOpen = (branch) => {
    setCurrentBranch(branch);
    setDialogOpen(true);
  };

  // Function to handle the dialog close event
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Function to handle the dialog close event
  const handleAddBranchDialog = () => {
    setAddDialogOpen(true);
  };

  // Function to handle changes in Saturday and Sunday trade status
  const handleTradeChange = (tradeType, newValue) => {
    if (newValue !== null) {
      setCurrentBranch({ ...currentBranch, [tradeType]: newValue });
    }
  };

  // Filter branches based on search
  const filteredBranches = branches.filter(branch =>
    branch.Branch.toLowerCase().includes(search.toLowerCase())
  );

  // Function to handle the save event
  const handleSave = async () => {
    try {
      console.log('Updating branch with data:', {
        SalesTarget: currentBranch.SalesTarget,
        MarginTarget: currentBranch.MarginTarget,
        SaturdayTrade: currentBranch.SaturdayTrade,
        SundayTrade: currentBranch.SundayTrade,
        Region: currentBranch.Region,
        PCode: currentBranch.PCode
      });

      setLoading(true); // Show a loading indicator
      const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/updateBranch/${currentBranch._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          SalesTarget: currentBranch.SalesTarget,
          MarginTarget: currentBranch.MarginTarget,
          SaturdayTrade: currentBranch.SaturdayTrade,
          SundayTrade: currentBranch.SundayTrade,
          Region: currentBranch.Region,
          PCode: currentBranch.PCode
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Assuming your API returns the updated branch
      const updatedBranch = await response.json();

      // Update the local state with the updated branch
      setBranches(branches.map(branch => branch._id === updatedBranch._id ? updatedBranch : branch));

      // Close the dialog and stop loading
      setDialogOpen(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (branchId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this branch?");
    if (confirmDelete) {
      const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/deleteBranch/${branchId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        // Remove the deleted branch from the state
        const updatedBranches = branches.filter(branch => branch._id !== branchId);
        setBranches(updatedBranches);

        // Check if we need to adjust the current page
        if (page > 0 && updatedBranches.length <= page * rowsPerPage) {
          setPage(page - 1);
        }
      } else {
        // Handle errors
        console.error('Failed to delete the branch', await response.text());
      }
    }
  };

  const handleAddBranch = async () => {
    const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/addBranch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        Branch: newBranch.Branch,
        SalesTarget: newBranch.SalesTarget,
        MarginTarget: newBranch.MarginTarget
      })
    });

    if (response.ok) {
      const addedBranch = await response.json();
      setBranches([...branches, addedBranch]);
      setAddDialogOpen(false); // Close the dialog
      setNewBranch({ Branch: '', SalesTarget: '', MarginTarget: '' }); // Reset form
    } else {
      // Handle errors
      console.error('Failed to add the branch', await response.text());
    }
  };

  useEffect(() => {
    async function fetchBranches() {
      try {
        const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/branches`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          console.error('HTTP error! status:', response.status);
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.info('Branches:', data);
        setBranches(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
    async function fetchRegions() {
      try {
        const response = await fetch(`https://spar.connectplus.parts/api/salesassist/cplusassist/regions`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          console.error('HTTP error! status:', response.status);
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.info('Regions:', data);
        setRegions(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchBranches();
    fetchRegions();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
        <TextField
          label="Search Branch"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginBottom: '0px' }}
        />
        <div style={{ cursor: 'pointer', alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <input
            key={uploadLoading ? 'uploading' : 'ready'}
            type="file"
            accept=".csv,.xlsx"
            style={{ display: 'none' }}
            id="file-upload"
            onChange={handleFileUpload}
          />

          <label htmlFor="file-upload">
            <Button
              component="span"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              disabled={uploadLoading}
              style={{ marginRight: '8px' }}
            >
              {uploadLoading ? 'Uploading...' : 'Upload Targets'}
            </Button>
          </label>
          {uploadLoading && <Typography>Processing...</Typography>}
          {uploadError && (
            <Typography color="error" style={{ marginLeft: '8px' }}>
              Error: {uploadError}
            </Typography>
          )}
        </div>
      </div>

      <TableContainer style={{ height: '70vh', overflow: 'scroll' }} component={Paper}>
        <Table aria-label="branches table">
          <TableHead>
            <TableRow>
              <TableCell>Region</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>PCode</TableCell>
              <TableCell align="right">Sales Target (£)</TableCell>
              <TableCell align="right">Margin Target (%)</TableCell>
              <TableCell align="center">Saturday</TableCell>
              <TableCell align="center">Sunday</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBranches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((branch) => (
              <TableRow key={branch._id}>
                <TableCell component="th" scope="row">
                  {branch.Region}
                </TableCell>
                <TableCell component="th" scope="row">
                  {branch.Branch}
                </TableCell>
                <TableCell component="th" scope="row">
                  {branch.PCode}
                </TableCell>
                <TableCell align="right">{branch.SalesTarget}</TableCell>
                <TableCell align="right">{(branch.MarginTarget * 100).toFixed(2)}</TableCell>
                <TableCell align="right">
                  <ToggleButtonGroup
                    value={branch.SaturdayTrade.toString()}
                    exclusive
                    onChange={handleChange}
                    aria-label="trade status"
                  >
                    <ToggleButton value="0" aria-label="closed">
                      C
                    </ToggleButton>
                    <ToggleButton value="0.5" aria-label="half-day">
                      1/2
                    </ToggleButton>
                    <ToggleButton value="1" aria-label="full-day">
                      1/1
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
                <TableCell align="right">
                  <ToggleButtonGroup
                    value={branch.SundayTrade.toString()}
                    exclusive
                    onChange={handleChange}
                    aria-label="trade status"
                  >
                    <ToggleButton value="0" aria-label="closed">
                      C
                    </ToggleButton>
                    <ToggleButton value="0.5" aria-label="half-day">
                      1/2
                    </ToggleButton>
                    <ToggleButton value="1" aria-label="full-day">
                      1/1
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleDialogOpen(branch)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(branch._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={branches.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]} // Remove the option to change rows per page
      />

      {/* Dialog for editing branch targets */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Branch Targets</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="dense"
            id="region"
            label="Region"
            value={currentBranch.Region || ''}
            onChange={(e) => setCurrentBranch({ ...currentBranch, Region: e.target.value })}
            fullWidth
            variant="outlined"
          >
            {regions.map((region) => (
              <MenuItem key={region.RegionId} value={region.RegionName}>
                {region.RegionName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            id="pCode"
            label="Postcode"
            type="text"
            fullWidth
            variant="outlined"
            value={currentBranch.PCode || ''}
            onChange={(e) => setCurrentBranch({ ...currentBranch, PCode: e.target.value })}
          />

          <TextField
            autoFocus
            margin="dense"
            id="salesTarget"
            label="Daily Sales Target (£)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentBranch.SalesTarget || ''}
            onChange={(e) => setCurrentBranch({ ...currentBranch, SalesTarget: e.target.value })}
          />
          <TextField
            margin="dense"
            id="marginTarget"
            label="Daily Margin Target (%)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentBranch.MarginTarget || ''}
            onChange={(e) => setCurrentBranch({ ...currentBranch, MarginTarget: e.target.value })}
          />

          <Typography style={{ marginTop: '10px' }}>Saturday Trade:</Typography>
          <ToggleButtonGroup
            value={currentBranch.SaturdayTrade?.toString() || '0'}
            exclusive
            onChange={(e, newAlignment) => setCurrentBranch({ ...currentBranch, SaturdayTrade: newAlignment })}
            aria-label="saturday trade status"
            fullWidth
          >
            <ToggleButton value="0" aria-label="closed">C</ToggleButton>
            <ToggleButton value="0.5" aria-label="half-day">1/2</ToggleButton>
            <ToggleButton value="1" aria-label="full-day">1/1</ToggleButton>
          </ToggleButtonGroup>

          <Typography style={{ marginTop: '10px' }}>Sunday Trade:</Typography>
          <ToggleButtonGroup
            value={currentBranch.SundayTrade?.toString() || '0'}
            exclusive
            onChange={(e, newAlignment) => setCurrentBranch({ ...currentBranch, SundayTrade: newAlignment })}
            aria-label="sunday trade status"
            fullWidth
          >
            <ToggleButton value="0" aria-label="closed">C</ToggleButton>
            <ToggleButton value="0.5" aria-label="half-day">1/2</ToggleButton>
            <ToggleButton value="1" aria-label="full-day">1/1</ToggleButton>
          </ToggleButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for adding branch */}
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add New Branch</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="branch"
            label="Branch Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newBranch.Branch}
            onChange={(e) => setNewBranch({ ...newBranch, Branch: e.target.value })}
          />
          <TextField
            margin="dense"
            id="salesTarget"
            label="Daily Sales Target (£)"
            type="number"
            fullWidth
            variant="outlined"
            value={newBranch.SalesTarget}
            onChange={(e) => setNewBranch({ ...newBranch, SalesTarget: e.target.value })}
          />
          <TextField
            margin="dense"
            id="marginTarget"
            label="Daily Margin Target (% - decimal value e.g. 0.5 for 50%)"
            type="number"
            fullWidth
            variant="outlined"
            value={newBranch.MarginTarget}
            onChange={(e) => setNewBranch({ ...newBranch, MarginTarget: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddBranch}>Add</Button>
        </DialogActions>
      </Dialog>

      {/* Upload Results Dialog */}
      {uploadResult && (
        <Dialog
          open={!!uploadResult}
          onClose={() => setUploadResult(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Upload Results</DialogTitle>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Summary
            </Typography>
            <Typography variant="body1" gutterBottom>
              Total rows processed: {uploadResult.summary.total}
            </Typography>
            <Typography variant="body1" gutterBottom color="success.main">
              Successfully updated: {uploadResult.summary.updated} branches
            </Typography>

            {uploadResult.notFound.length > 0 && (
              <>
                <Typography variant="h6" style={{ marginTop: '16px' }} color="warning.main">
                  Branches Not Found ({uploadResult.notFound.length})
                </Typography>
                <ul>
                  {uploadResult.notFound.map((item, index) => (
                    <li key={index}>
                      <Typography color="warning.main">
                        Row {item.row}: Branch code "{item.mamCode}" not found in database
                      </Typography>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {uploadResult.errors.length > 0 && (
              <>
                <Typography variant="h6" style={{ marginTop: '16px' }} color="error">
                  Errors ({uploadResult.errors.length})
                </Typography>
                <ul>
                  {uploadResult.errors.map((error, index) => (
                    <li key={index}>
                      <Typography color="error">
                        Row {error.row}: {error.mamCode} - {error.reason}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </>
            )}

            {uploadResult.skipped.length > 0 && (
              <>
                <Typography variant="h6" style={{ marginTop: '16px' }} color="info">
                  Skipped Rows ({uploadResult.skipped.length})
                </Typography>
                <ul>
                  {uploadResult.skipped.map((item, index) => (
                    <li key={index}>
                      <Typography color="info.main">
                        Row {item.row}: {item.reason}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUploadResult(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

    </ThemeProvider>
  );
}

export default BranchesTable;