import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Select, MenuItem, FormControl, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

export const SearchBar = ({
    searchTerm,
    setSearchTerm,
    activeColorFilters,
    onToggleColor,
    showRepSelect = false,
    selectedRep,
    onRepChange,
    operators = [],
    isLoadingOperators = false
}) => {
    const [filterText, setFilterText] = useState('');

    // Set initial selected rep when operators load
    useEffect(() => {
        if (operators.length > 0 && !selectedRep) {
            onRepChange(operators[0].Inits);
        }
    }, [operators, selectedRep, onRepChange]);

    // Filter operators based on search term
    const filteredOperators = operators.filter(op =>
        op.Name?.toLowerCase().includes(filterText.toLowerCase()) ||
        op.Inits?.toLowerCase().includes(filterText.toLowerCase()) ||
        op.Branch?.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <Box className="flex gap-2 mb-4" style={{ height: 'auto' }}>
            {showRepSelect && (
                <FormControl
                    size="small"
                    sx={{
                        minWidth: 200,
                        backgroundColor: '#262626',
                        '& .MuiOutlinedInput-root': {
                            color: '#fff',
                            '& fieldset': {
                                borderColor: '#444',
                            },
                            '&:hover fieldset': {
                                borderColor: '#666',
                            },
                        }
                    }}
                >
                    <Select
                        value={selectedRep}
                        onChange={(e) => onRepChange(e.target.value)}
                        disabled={isLoadingOperators}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: 400,
                                    backgroundColor: '#262626',
                                    color: '#fff',
                                    '& .MuiMenuItem-root': {
                                        padding: '8px 16px'
                                    }
                                }
                            }
                        }}
                        onClose={() => setFilterText('')}
                    >
                        <MenuItem sx={{ p: 0, position: 'sticky', top: 0, bgcolor: '#262626', zIndex: 1 }}>
                            <TextField
                                size="small"
                                fullWidth
                                placeholder="Filter operators..."
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                                onKeyDown={(e) => e.stopPropagation()}
                                sx={{
                                    bgcolor: '#333',
                                    '& .MuiOutlinedInput-root': {
                                        color: '#fff',
                                        '& fieldset': {
                                            borderColor: '#444',
                                        }
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: '#666' }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MenuItem>
                        {filteredOperators.map((op) => (
                            <MenuItem
                                key={op.Inits}
                                value={op.Inits}
                                sx={{
                                    fontSize: '0.875rem',
                                    '& .branch': {
                                        color: 'rgba(255,255,255,0.7)',
                                        fontSize: '0.75rem'
                                    }
                                }}
                            >
                                <div>
                                    <span>{op.Name}</span>
                                    <span style={{ color: 'rgba(255,255,255,0.7)', marginLeft: '4px' }}>
                                        ({op.Inits})
                                    </span>
                                    <div className="branch">{op.Branch}</div>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <TextField
                placeholder="Search by code or name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="flex-1"
                size="small"
                variant="outlined"
                sx={{
                    backgroundColor: '#262626',
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        '& fieldset': {
                            borderColor: '#444',
                        },
                        '&:hover fieldset': {
                            borderColor: '#666',
                        },
                    }
                }}
            />
            {['red', 'orange', 'green'].map(color => (
                <Button
                    key={color}
                    onClick={() => onToggleColor(color)}
                    variant={activeColorFilters[color] ? 'contained' : 'outlined'}
                    sx={{
                        backgroundColor: activeColorFilters[color] ? color : 'transparent',
                        borderColor: color,
                        color: activeColorFilters[color] ? '#fff' : color,
                        '&:hover': {
                            backgroundColor: activeColorFilters[color] ? color : 'rgba(255,255,255,0.1)',
                            borderColor: color,
                        }
                    }}
                >
                    {color}
                </Button>
            ))}
        </Box>
    );
};