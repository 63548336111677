import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { GlobalContext } from '../../context/GlobalContext';

Modal.setAppElement('#root'); // Set the app element for accessibility

const StockDetails = ({ branch, onClose, stockChecks, updateStockChecks }) => {
    const [hoveredMonth, setHoveredMonth] = useState(null);
    const [hoveredMonthBox, setHoveredMonthBox] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('productGroups');
    const [productGroups, setProductGroups] = useState([]);
    const [rangeCodes, setRangeCodes] = useState([]);
    const { token, operatorData } = useContext(GlobalContext);
    const [draggedItem, setDraggedItem] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [pendingGroups, setPendingGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [groupProgressForMonth, setGroupProgressForMonth] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`https://spar.connectplus.parts/api/salesassist/cplusassist/stockmaster/${branch.toUpperCase()}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
                console.info('url', `https://spar.connectplus.parts/api/salesassist/cplusassist/stockmaster/${branch.toUpperCase()}`);
                console.info('Stock checks:', response.data);
                updateStockChecks(response.data);
                setPendingGroups([]);
                setGroupProgressForMonth({});
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    updateStockChecks([]); // Update state with empty data on 404
                    setPendingGroups([]);
                    setGroupProgressForMonth({});
                } else {
                    console.error('Error fetching stock checks:', error);
                }
            } finally {
                setIsLoading(false);
            }

            await fetchProductGroups();
            await fetchRangeCodes();
        };

        fetchData();
    }, [branch]);

    const fetchProductGroups = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get('https://spar.connectplus.parts/api/salesassist/cplusassist/productgroups', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setProductGroups(response.data);
        } catch (error) {
            console.error('Error fetching product groups:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRangeCodes = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get('https://spar.connectplus.parts/api/salesassist/cplusassist/rangecodes', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setRangeCodes(response.data);
        } catch (error) {
            console.error('Error fetching range codes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const getGroupsForMonth = (month, stockChecksData = stockChecks) => {
        const monthIndex = months.indexOf(month);
        const groupProgress = stockChecksData
            .filter(check => check.Date && new Date(check.Date).getMonth() === monthIndex)
            .reduce((groups, check) => {
                if (check.Group && check.Products) {
                    if (!groups[check.Group]) {
                        groups[check.Group] = {
                            total: 0,
                            checked: 0,
                        };
                    }
                    groups[check.Group].total += check.Products.length;
                    groups[check.Group].checked += check.Products.filter(product => product.StockDetails).length;
                }
                return groups;
            }, {});

        return groupProgress;
    };

    const updateGroupProgressForMonth = (month, progress) => {
        setGroupProgressForMonth(prevProgress => ({
            ...prevProgress,
            [month]: progress,
        }));
    };

    const handleMonthBoxMouseEnter = (month) => {
        setHoveredMonthBox(month);
    };

    const handleMonthBoxMouseLeave = () => {
        setHoveredMonthBox(null);
    };

    const openModal = (month) => {
        const monthIndex = months.indexOf(month);
        const monthGroups = stockChecks.filter(check => new Date(check.Date).getMonth() === monthIndex);
        setPendingGroups(monthGroups);
        setHoveredMonth(month);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        const newGroupProgress = getGroupsForMonth(hoveredMonth, stockChecks);
        updateGroupProgressForMonth(hoveredMonth, newGroupProgress);
        updateStockChecks(stockChecks);
        setIsModalOpen(false);

        // Notify parent to keep in month-by-month view
        onClose(false);
    };

    const handleDragStart = (item) => {
        setDraggedItem(item);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        if (draggedItem && hoveredMonth && !isLoading) {
            setIsLoading(true);
            try {
                const date = new Date(new Date().getFullYear(), months.indexOf(hoveredMonth), 1);
                const groupType = activeTab === 'productGroups' ? 'PG' : 'RANGE';

                const response = await axios.post('https://spar.connectplus.parts/api/salesassist/cplusassist/stockmaster', {
                    Date: date,
                    Branch: branch.toUpperCase(),
                    Group: draggedItem.KeyCode,
                    GroupType: groupType,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });

                if (response.status === 201) {
                    const updatedStockChecks = [...stockChecks, response.data];
                    updateStockChecks(updatedStockChecks);
                    setPendingGroups([...pendingGroups, response.data]);

                    const newGroupProgress = getGroupsForMonth(hoveredMonth, updatedStockChecks);
                    updateGroupProgressForMonth(hoveredMonth, newGroupProgress);
                }
            } catch (error) {
                console.error('Error adding stock data:', error);
            } finally {
                setIsLoading(false);
            }
            setDraggedItem(null);
        }
    };

    const handlePendingDragStart = (group) => {
        setDraggedItem(group);
    };

    const handlePendingDragOver = (event) => {
        event.preventDefault();
    };

    const handlePendingDrop = async (event) => {
        event.preventDefault();
        console.log('Pending Drop: ', draggedItem);
        if (draggedItem && draggedItem.Group && draggedItem.Branch && draggedItem.GroupType && draggedItem.Date && !isLoading) {
            setIsLoading(true);
            try {
                const response = await axios.delete('https://spar.connectplus.parts/api/salesassist/cplusassist/stockmaster', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    data: {
                        Date: new Date(draggedItem.Date),
                        Branch: draggedItem.Branch,
                        Group: draggedItem.Group,
                        GroupType: draggedItem.GroupType,
                    },
                });

                if (response.status === 200) {
                    const updatedStockChecks = stockChecks.filter(check => check._id !== draggedItem._id);
                    updateStockChecks(updatedStockChecks);
                    setPendingGroups(pendingGroups.filter(group => group._id !== draggedItem._id));

                    const newGroupProgress = getGroupsForMonth(hoveredMonth, updatedStockChecks);
                    updateGroupProgressForMonth(hoveredMonth, newGroupProgress);
                }
            } catch (error) {
                console.error('Error removing stock data:', error);
            } finally {
                setIsLoading(false);
            }
            setDraggedItem(null);
        }
    };

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    };

    const renderTabContent = () => {
        const items = activeTab === 'productGroups' ? productGroups : rangeCodes;
        const filteredItems = items.filter(item =>
            (item.a1.toLowerCase().includes(filterText.toLowerCase()) ||
                item.KeyCode.toString().includes(filterText)) &&
            !item.a1.toLowerCase().includes('block import') &&
            !item.a1.toLowerCase().includes('error')
        );

        return (
            <CodeList
                onDragOver={handlePendingDragOver}
                onDrop={handlePendingDrop}
            >
                <FilterInput
                    type="text"
                    placeholder="Filter..."
                    value={filterText}
                    onChange={handleFilterChange}
                />
                {filteredItems.map(item => {
                    const isHighlighted = pendingGroups.some(group => group.Group === item.KeyCode);

                    return (
                        <StyledCodeItem
                            key={item.KeyCode}
                            draggable={!isLoading}
                            onDragStart={() => handleDragStart(item)}
                            isLoading={isLoading}
                            ishighlighted={isHighlighted.toString()} // Use lowercase for custom attribute
                        >
                            <strong>{item.KeyCode}</strong> - {item.a1}
                        </StyledCodeItem>
                    );
                })}
            </CodeList>
        );
    };

    // Define the handleClose function here
    const handleClose = (refresh = false) => {
        onClose(refresh);
    };

    return (
        <Wrapper>
            <HeaderWrapper>
                <HeaderText>{branch}</HeaderText>
                <HeaderText>
                    {isLoading && (
                        <>
                            FETCHING STOCK DATA{' '}
                            <LoadingText>
                                <LoadingAnimation />
                            </LoadingText>
                        </>
                    )}
                </HeaderText>
                <HeaderText onClick={() => handleClose(true)}>{'<BACK'}</HeaderText>
            </HeaderWrapper>
            <MonthsWrapper>
                {months.map(month => {
                    const groupProgress = groupProgressForMonth[month] || getGroupsForMonth(month);
                    return (
                        <MonthBox
                            key={month}
                            isLoading={isLoading}
                            isHovered={hoveredMonthBox === month}
                            onMouseEnter={() => handleMonthBoxMouseEnter(month)}
                            onMouseLeave={handleMonthBoxMouseLeave}
                            onClick={() => openModal(month)}
                        >
                            <div className="month-box-content">
                                <MonthTitle>{month}</MonthTitle>
                                {Object.keys(groupProgress).map(group => (
                                    <GroupProgress key={group}>
                                        <GroupName>{group}</GroupName>
                                        <ProgressBar>
                                            <ProgressFill
                                                width={`${(groupProgress[group].checked / groupProgress[group].total) * 100}%`}
                                                total={groupProgress[group].total}
                                            />
                                        </ProgressBar>
                                        <ProgressText>
                                            {groupProgress[group].checked}/{groupProgress[group].total}
                                        </ProgressText>
                                    </GroupProgress>
                                ))}
                            </div>
                        </MonthBox>
                    );
                })}
            </MonthsWrapper>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Stock Details Modal"
                style={{
                    content: {
                        width: '90%',
                        height: '90%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'rgb(24, 26, 31)',
                        overflow: 'hidden',
                    },
                }}
            >
                {isLoading && (
                    <LoadingOverlay>
                        <LoadingText>Loading...</LoadingText>
                    </LoadingOverlay>
                )}

                <ModalHeader>
                    <ModalTitle>{hoveredMonth}</ModalTitle>
                    <CloseButton onClick={closeModal}>Close</CloseButton>
                </ModalHeader>
                <ModalContent>
                    <ProgressSection />
                    <PendingList
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <PendingTitle>Pending</PendingTitle>
                        {pendingGroups.map(group => (
                            <PendingItem
                                key={group._id}
                                draggable={!isLoading}
                                onDragStart={() => handlePendingDragStart(group)}
                                isLoading={isLoading}
                            >
                                {group.Group}
                            </PendingItem>
                        ))}
                    </PendingList>
                    <ContentSection>
                        <ContentTitle>Content</ContentTitle>
                        <TabButtons>
                            <TabButton
                                onClick={() => setActiveTab('productGroups')}
                                isActive={activeTab === 'productGroups'}
                            >
                                Product Groups
                            </TabButton>
                            <TabButton
                                onClick={() => setActiveTab('rangeCodes')}
                                isActive={activeTab === 'rangeCodes'}
                            >
                                Range Codes
                            </TabButton>
                        </TabButtons>
                        <ContentWrapper>
                            {renderTabContent()}
                        </ContentWrapper>
                    </ContentSection>
                </ModalContent>
            </Modal>
        </Wrapper>
    );
};

export default StockDetails;

/* Styled Components */
const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const HeaderText = styled.h2`
    cursor: pointer;
    color: white;
`;

const LoadingText = styled.span`
    font-size: 20px;
`;

const LoadingAnimation = styled.span`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    width: 80%;
    height: 2px;
    background-color: white;
    transform-origin: center;
    animation: ${loadingAnimation} 1s linear infinite;
  }

  &::after {
    width: 0;
    height: 0;
    border: none;
  }
`;

const MonthsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 10px;
    flex: 1;
`;

const MonthBox = styled.div`
    border: 1px solid #ccc;
    padding: 10px;
    position: relative;
    overflow: hidden;
    opacity: ${props => props.isLoading ? 0.5 : 1};
    pointer-events: ${props => props.isLoading ? 'none' : 'auto'};
    cursor: pointer;

    &:after {
        content: '+';
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        background-color: white;
        color: black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: ${props => props.isHovered ? 1 : 0};
        transition: opacity 0.3s ease;
    }

    .month-box-content {
        max-height: 200px;
        overflow-y: auto;
    }
`;

const MonthTitle = styled.h3`
    text-align: center;
    color: black;
    margin: 3px;
    background-color: yellow;
    border-radius: 15px;
`;

const GroupProgress = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 100%;
`;

const GroupName = styled.div`
    color: white;
    flex-basis: 30%;
`;

const ProgressBar = styled.div`
    width: 40%;
    height: 10px;
    background-color: #ccc;
`;

const ProgressFill = styled.div`
    width: ${props => props.width};
    height: 100%;
    background-color: ${props => props.total === 0 ? 'red' : 'green'};
`;

const ProgressText = styled.div`
    color: white;
    flex-basis: 30%;
    text-align: right;
`;

const CodeList = styled.div`
    flex: 1;
    overflow-y: auto;
    background-color: white;
`;

const FilterInput = styled.input`
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
`;

const StyledCodeItem = styled.div`
    cursor: ${props => props.isLoading ? 'not-allowed' : 'grab'};
    background-color: ${props => props.ishighlighted === 'true' ? 'lightblue' : 'white'};
    opacity: ${props => props.isLoading ? 0.5 : 1};
`;

const LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ModalTitle = styled.h3`
    color: white;
`;

const CloseButton = styled.button`
    cursor: pointer;
`;

const ModalContent = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

const ProgressSection = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const PendingList = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const PendingTitle = styled.h3`
    color: white;
`;

const PendingItem = styled.div`
    color: white;
    cursor: ${props => props.isLoading ? 'not-allowed' : 'grab'};
    opacity: ${props => props.isLoading ? 0.5 : 1};
`;

const ContentSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    overflow: hidden;
`;

const ContentTitle = styled.h3`
    color: white;
`;

const TabButtons = styled.div`
    display: flex;
`;

const TabButton = styled.button`
    background-color: ${props => props.isActive ? 'lightblue' : 'white'};
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    margin: 5px;
`;

const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;
