import React, { useEffect, useContext } from 'react';
import { Box, Grid } from '@mui/material';
import HRCharts from './HRCharts';
import ExtendedMetrics from './ExtendedMetrics';
import useMetricsStore from './stores/useMetricsStore';
import { GlobalContext } from '../../context/GlobalContext';

const HROverviewDashboard = ({
    selectedBranch,
    selectedMonth,
    selectedYear,
    clockingRecords,
    holidayData = [],
    sickData = [],
    lateClocksCount,
    earlyClockOutsCount,
}) => {
    const { token } = useContext(GlobalContext);
    const fetchMetrics = useMetricsStore(state => state.fetchMetrics);

    // Fetch extended metrics when component mounts or when selection changes
    useEffect(() => {
        if (token && selectedBranch) {
            const baseDate = new Date(selectedYear, selectedMonth);
            fetchMetrics(token, selectedBranch, baseDate);
        }
    }, [token, selectedBranch, selectedMonth, selectedYear, fetchMetrics]);

    return (
        <Box sx={{ p: 3, bgcolor: '#121212', height: 'calc(100vh - 170px)', overflowY: 'scroll' }}>
            <Grid container spacing={3}>
                {/* First row - Charts Section */}
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <HRCharts
                                clockingRecords={clockingRecords}
                                holidayData={holidayData}
                                sickData={sickData}
                                lateClocks={lateClocksCount}
                                earlyClockOuts={earlyClockOutsCount}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Second row - Extended Metrics */}
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <ExtendedMetrics periodType="three" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ExtendedMetrics periodType="six" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ExtendedMetrics periodType="twelve" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HROverviewDashboard;