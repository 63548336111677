import { useContext, useEffect, useState } from 'react'
import { Card, Icon, Table } from './gsys-ui'
import haxios from './lib/haxios'
import useApi from './hooks/useApi'
import moment from 'moment-timezone'
import { PencilIcon, TrashIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useForm } from 'react-hook-form'
import {
  Checkbox,
  NativeSelect,
  TextInput,
  Button,
  ActionIcon,
  Textarea,
  Group,
  Radio,
  Chip,
  Alert,
  CopyButton
} from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { modals } from '@mantine/modals'
import { errorToast, successToast } from './lib/helpers'
import { GiPalmTree } from 'react-icons/gi'
import { GlobalContext } from '../../context/GlobalContext'
import { ClockIcon } from 'lucide-react'
import { CircularProgress } from '@mui/material'

const Holidays = () => {
  const [selectedYear, setSelectedYear] = useState(moment.utc().year())
  const { operatorData: uinfo } = useContext(GlobalContext)

  const {
    data: holidayRequests,
    isLoading,
    refetch: refetchMongo
  } = useApi({
    url: `/salesassistnew/holidayrequests/${uinfo.staffId}`,
    data: {
      dtStart: moment.utc().year(selectedYear).month(0).startOf('month').format('YYYY-MM-DD'),
      dtEnd: moment.utc().year(selectedYear).month(11).endOf('month').format('YYYY-MM-DD')
    }
  })

  const { data: ustafferator, isLoading: isLoadingUstaff } = useApi({
    url: `/salesassistnew/ustafferators/${uinfo.staffId}`
  })

  const {
    data: bookedHolidays,
    isLoading: isLoadingBookedHolidays,
    refetch: refetchMatt
  } = useApi({
    url: 'https://api.aa-vision.com/api/tma/holidays',
    method: 'POST',
    data: {
      StartDate: moment.utc().year(selectedYear).month(0).startOf('month').format('YYYY-MM-DD'),
      EndDate: moment.utc().year(selectedYear).month(11).endOf('month').format('YYYY-MM-DD'),
      StaffID: uinfo.staffId
    }
  })

  const refetch = async () => {
    await refetchMatt()
    await refetchMongo()
  }

  if (isLoading || isLoadingUstaff || isLoadingBookedHolidays) {
    return (
      <div className="flex absolute inset-0 justify-center items-center">
        <CircularProgress />
      </div>
    )
  }

  let problems = []

  for (const mattHol of bookedHolidays) {
    const req = holidayRequests
      .filter((el) => {
        return (
          moment.utc(el.dtStart).isSame(moment.utc(mattHol.StartDatetime)) &&
          moment.utc(el.dtEnd).isSame(moment.utc(mattHol.EndDatetime))
        )
      })
      .pop()

    if (!req) {
      problems.push(mattHol.HolidayID)
    }
  }

  const dataWithMattIds = holidayRequests.map((req) => {
    if (req.decision !== 'APPROVED') return { ...req, MattHolId: null }

    const mattHol = bookedHolidays
      .filter(
        (el) =>
          moment.utc(el.StartDatetime).isSame(moment.utc(req.dtStart)) &&
          moment.utc(el.EndDatetime).isSame(moment.utc(req.dtEnd))
      )
      .pop()

    if (!mattHol) {
      problems.push(req._id)
      return { ...req, MattHolId: null }
    }

    console.log(mattHol)
    return { ...req, MattHolId: mattHol.HolidayID }
  })

  const dataFiltered = dataWithMattIds.filter((req) => {
    const requestDate = moment.utc(req.dtStart)
    return req.staffId === uinfo.staffId && requestDate.year() === selectedYear
  })

  const allocation = ustafferator.HolidayAllocation
  const [totalDaysBooked, pendingDaysBooked] = dataFiltered.reduce(
    (acc, cur) => {
      const start = moment.utc(cur.dtStart)
      const end = moment.utc(cur.dtEnd)
      const isStartAtNoon = start.hours() === 12 && start.minutes() === 0
      const isEndAtNoon = end.hours() === 12 && end.minutes() === 0
      console.log(start, end, isStartAtNoon, isEndAtNoon)
      let amountToAdd = 0

      if (isStartAtNoon || isEndAtNoon) {
        amountToAdd = 0.5
      } else {
        amountToAdd = end.diff(start, 'days') + 1
      }

      if (cur.decision === 'PENDING') {
        acc[1] += amountToAdd
      }

      if (cur.decision !== 'REJECTED') {
        acc[0] += amountToAdd
      }

      return acc
    },
    [0, 0]
  )

  const dataSorted = dataFiltered.sort((a, b) => moment.utc(a.dtStart).isAfter(b.dtStart))

  const handleNewRequest = () => {
    modals.openContextModal({
      modal: 'holidayRequestModal',
      title: 'New holiday request',
      centered: true,
      innerProps: {
        staffId: uinfo.staffId,
        totalBooked: totalDaysBooked,
        allocation,
        refetch
      }
    })
  }

  const totalRequests = dataSorted.length
  const rejectedRequests = dataSorted.filter((request) => request.decision === 'REJECTED').length
  const approvedRequests = dataSorted.filter((request) => request.decision === 'APPROVED').length
  const pendingRequests = dataSorted.filter((request) => request.decision === 'PENDING').length

  return (
    <>
      <div className="flex justify-between items-center p-4 text-white">
        <div className="flex items-center space-x-2 text-xl font-semibold">
          <div>My holidays</div>
        </div>
        <div className="flex items-center space-x-2 font-normal">
          <div className="text-lg font-semibold">
            {totalDaysBooked}/{allocation} days booked
          </div>
          <div className="text-sm">
            ({allocation - totalDaysBooked} remaining, {pendingDaysBooked} pending)
          </div>
        </div>
        <div className="flex items-center space-x-4 font-normal">
          <div>
            <span>Total: </span>
            {totalRequests}
          </div>
          <div>
            <span>Rejected: </span>
            {rejectedRequests}
          </div>
          <div>
            <span>Approved: </span>
            {approvedRequests}
          </div>
          <div>
            <span>Pending: </span>
            {pendingRequests}
          </div>
        </div>
        <div className="flex items-center space-x-2 font-normal">
          <NativeSelect
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.currentTarget.value))}
            data={Array.from({ length: moment.utc().year() - 2024 + 1 }, (_, i) => 2024 + i).map((num) => ({
              value: num,
              label: num
            }))}
          />
          <Button onClick={handleNewRequest} disabled={totalDaysBooked === allocation}>
            New request
          </Button>
        </div>
      </div>
      {!!problems.length && (
        <div className="py-3">
          <Alert color="red">
            <div className="flex">
              <div className="flex flex-1 items-center">
                There has been a problem with your holiday bookings. Please contact support, quoting the following ID
                number(s): {problems.join(', ')}
              </div>
              <div className="flex-none">
                <CopyButton
                  value={`There has been a problem with your holiday bookings. Please contact support, quoting the following ID number(s): ${problems.join(
                    ', '
                  )}`}
                >
                  {({ copied, copy }) => (
                    <Button size="xs" color={copied ? 'teal' : 'blue'} onClick={copy}>
                      {copied ? 'Copied' : 'Copy'}
                    </Button>
                  )}
                </CopyButton>
              </div>
            </div>
          </Alert>
        </div>
      )}
      <Card>
        <Table
          data={dataSorted}
          columns={[
            {
              header: 'Start date',
              id: 'start',
              cell: ({ row }) => moment.utc(row.original.dtStart).format('DD/MM/YYYY')
            },
            {
              header: 'End date',
              id: 'end',
              cell: ({ row }) => moment.utc(row.original.dtEnd).format('DD/MM/YYYY')
            },
            {
              header: 'Days',
              id: 'days',
              cell: ({ row }) => {
                const start = moment.utc(row.original.dtStart)
                const end = moment.utc(row.original.dtEnd)
                let diff = end.diff(start, 'days') + 1

                if (start.hours() === 12 && start.minutes() === 0) {
                  return '0.5 (PM only)'
                }

                if (end.hours() === 12 && end.minutes() === 0) {
                  return '0.5 (AM only)'
                }

                return diff
              }
            },
            {
              header: 'Reason',
              accessorKey: 'reason'
            },
            {
              header: 'Status',
              id: 'status',
              cell: ({ row }) =>
                row.original.decision === 'APPROVED' ? (
                  <Chip color="green" checked={true} size="md">
                    Approved
                  </Chip>
                ) : row.original.decision === 'REJECTED' ? (
                  <Chip color="red" checked={true} icon={<Icon Comp={XMarkIcon} size={4} />} size="md">
                    Rejected
                  </Chip>
                ) : (
                  <Chip color="orange" checked={true} icon={<Icon Comp={ClockIcon} size={4} />} size="md">
                    Pending
                  </Chip>
                )
            },
            {
              header: 'Notes',
              accessorKey: 'Notes'
            },
            {
              header: '',
              id: 'edit',
              width: 1,
              cell: ({ row }) => (
                <Actions
                  row={row.original}
                  refetch={refetch}
                  uinfo={uinfo}
                  totalDaysBooked={totalDaysBooked}
                  allocation={allocation}
                />
              )
            }
          ]}
        />
      </Card>
    </>
  )
}

const Actions = ({ row, refetch, uinfo, totalDaysBooked, allocation }) => {
  const handleDelete = (id) => {
    modals.openContextModal({
      modal: 'warningModal',
      withCloseButton: false,
      centered: true,
      innerProps: {
        message: `You are about to delete your holiday request for ${moment
          .utc(row.dtStart)
          .format('DD/MM/YYYY')} to ${moment.utc(row.dtEnd).format('DD/MM/YYYY')}.`,
        submit: async () => {
          await haxios.delete(`/salesassist/cplusassist/holidayrequests/${row._id}`)

          if (row.decision === 'APPROVED') {
            try {
              await haxios.delete(`https://api.aa-vision.com/api/tma/holiday/${row.MattHolId}`)
            } catch (e) {
              console.log(e)
            }
          }

          await refetch()
          successToast(`Holiday request deleted.`)
        }
      }
    })
  }

  const handleEditRequest = () => {
    modals.openContextModal({
      modal: 'holidayRequestModal',
      title: 'Edit holiday request',
      centered: true,
      innerProps: {
        staffId: uinfo.staffId,
        refetch,
        editing: true,
        reqId: row._id,
        totalBooked: totalDaysBooked,
        allocation,
        defaultValues: {
          startDate: moment.utc(row.dtStart).toDate(),
          endDate: moment.utc(row.dtEnd).toDate(),
          reason: row.reason
        }
      }
    })
  }

  return (
    <div className="flex space-x-1">
      {row.decision && (
        <>
          <ActionIcon variant="transparent" color="rgba(255, 255, 255, 1)" onClick={handleEditRequest}>
            <Icon Comp={PencilIcon} size={4.5} />
          </ActionIcon>
          <ActionIcon variant="transparent" color="rgba(255, 255, 255, 1)" onClick={handleDelete}>
            <Icon Comp={TrashIcon} size={4.5} />
          </ActionIcon>
        </>
      )}
    </div>
  )
}

export default Holidays
