import React, { useContext, useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { Card, CardContent, Typography, Button, CircularProgress, Tooltip, TextField, IconButton } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import SickIcon from '@mui/icons-material/Sick'
import PersonIcon from '@mui/icons-material/Person'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import ZeroIcon from '@mui/icons-material/AssignmentInd' // Use an appropriate icon here
import moment from 'moment-timezone'
import TandABranch_ from './TandABranch_'
import { debounce, isEqual } from 'lodash'
import AttendanceAnalysis from './AttendanceAnalysis'

const MemoizedTandABranch = React.memo(TandABranch_)

function TandA({ rmBranches, setStaffBranch, progress, setProgress }) {
  const {
    token: initialToken,
    setToken,
    tokenExpiration,
    setTokenExpiration,
    operatorData,
    token
  } = useContext(GlobalContext)
  const [clockingInRecords, setClockinginRecords] = useState([])
  const [staffList, setStaffList] = useState([])
  const [holidayList, setHolidayList] = useState([])
  const [sickList, setSickList] = useState([])
  const [shifts, setShifts] = useState([])
  const [shiftAssignments, setShiftAssignments] = useState([])
  const [allBranches, setAllBranches] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [cameraView, setCameraView] = useState({})
  const [tailscaleStatus, setTailscaleStatus] = useState({})
  const [initialized, setInitialized] = useState(false)
  const [view, setView] = useState('main') // 'main' or 'attendance'

  const tokenRef = useRef(initialToken)

  const fetchWithTokenRefresh = async (url, options) => {
    let response = await fetch(url, options)

    if (response.status === 401) {
      // Token is invalid, fetch a new one
      const newToken = await fetchToken()
      if (!newToken) {
        throw new Error('Failed to refresh token')
      }

      // Update the Authorization header with the new token
      options.headers.Token = newToken

      // Retry the request with the new token
      response = await fetch(url, options)
    }

    return response
  }

  const fetchToken = async () => {
    try {
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': process.env.REACT_APP_TOKEN_USER
        },
        body: JSON.stringify({
          Username: process.env.REACT_APP_TOKEN_USER,
          Password: process.env.REACT_APP_TOKEN_PASS
        })
      })

      const data = await response.json()
      tokenRef.current = data.token
      setToken(data.token)
      setTokenExpiration(new Date().getTime() + 15 * 60 * 1000)
      return data.token
    } catch (error) {
      console.error('Error fetching token:', error)
      return null
    }
  }

  const checkAndRefreshToken = useCallback(async () => {
    const currentTime = new Date().getTime()
    if (!tokenRef.current || currentTime >= tokenExpiration) {
      return await fetchToken()
    }
    return tokenRef.current
  }, [tokenExpiration])

  const fetchBranches = useCallback(async () => {
    const token = await checkAndRefreshToken()
    if (!token) return
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.ok) {
        const data = await response.json()
        if (!isEqual(data, allBranches)) {
          setAllBranches(data)
        }
      } else {
        console.error('Error fetching branches:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error fetching branches:', error)
    }
  }, [checkAndRefreshToken, allBranches])

  const fetchClockinginRecords = useCallback(async () => {
    console.info('fetching clocking in records')
    const token = await checkAndRefreshToken()
    if (!token) return
    setIsLoading(true)
    try {
      const response = await fetchWithTokenRefresh('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({
          startDate: moment().startOf('day').toISOString(),
          endDate: moment().endOf('day').toISOString()
        })
      })

      if (response.ok) {
        const data = await response.json()
        const newRecords = Array.isArray(data) ? data : []
        const filteredByCompany = newRecords.filter(
          (record) => record.CompanyID === '01bc4014-0f4d-4585-be6d-dbe6caeb5d2b'
        )
        const filteredBranches =
          operatorData.Status === 'bm'
            ? filteredByCompany.filter((branch) => branch.StaffBranch === operatorData.Branch)
            : filteredByCompany
        if (!isEqual(filteredBranches, clockingInRecords)) {
          setClockinginRecords(filteredBranches)
        }
      } else {
        console.error('Error fetching clocking in records:', response.status, response.statusText)
        setClockinginRecords([])
      }
    } catch (error) {
      console.error('Error fetching clocking in records:', error)
      setClockinginRecords([])
    } finally {
      setIsLoading(false)
    }
  }, [checkAndRefreshToken, clockingInRecords, selectedBranch, operatorData])

  const fetchStaffList = useCallback(async () => {
    const token = await checkAndRefreshToken()
    if (!token) return
    setIsLoading(true)
    try {
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/staff', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        }
      })

      if (response.ok) {
        const data = await response.json()
        if (!isEqual(data, staffList)) {
          setStaffList(data)
        }
      } else {
        console.error('Error fetching staff list:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error fetching staff list:', error)
    } finally {
      setIsLoading(false)
    }
  }, [checkAndRefreshToken, staffList, selectedBranch])

  const fetchHolidayList = useCallback(async () => {
    const token = await checkAndRefreshToken()
    if (!token) return
    setIsLoading(true)
    try {
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holidays', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({
          StartDate: moment().startOf('day').format('YYYY-MM-DD'),
          EndDate: moment().endOf('day').format('YYYY-MM-DD')
        })
      })

      if (response.ok) {
        const data = await response.json()
        if (!isEqual(data, holidayList)) {
          setHolidayList(data)
        }
      } else {
        console.error('Error fetching holiday list:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error fetching holiday list:', error)
    } finally {
      setIsLoading(false)
    }
  }, [checkAndRefreshToken, holidayList, selectedBranch])

  const fetchSickList = useCallback(async () => {
    const token = await checkAndRefreshToken()
    if (!token) return
    setIsLoading(true)
    try {
      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': token
        },
        body: JSON.stringify({
          StartDate: moment().startOf('day').format('YYYY-MM-DD'),
          EndDate: moment().endOf('day').format('YYYY-MM-DD')
        })
      })

      if (response.ok) {
        const data = await response.json()
        if (!isEqual(data, sickList)) {
          setSickList(data)
        }
      } else {
        console.error('Error fetching sick day list:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error fetching sick day list:', error)
    } finally {
      setIsLoading(false)
    }
  }, [checkAndRefreshToken, sickList, selectedBranch])

  const fetchShifts = useCallback(async () => {
    const token = await checkAndRefreshToken()
    if (!token) return
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shifts', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      if (!isEqual(data, shifts)) {
        setShifts(data)
      }
    } catch (error) {
      console.error('Error fetching shifts:', error)
    }
  }, [checkAndRefreshToken, shifts, selectedBranch])

  const fetchShiftAssignments = useCallback(async () => {
    const token = await checkAndRefreshToken()
    if (!token) return
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shiftassignments', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()
      if (!isEqual(data, shiftAssignments)) {
        setShiftAssignments(data)
      }
    } catch (error) {
      console.error('Error fetching shift assignments:', error)
    }
  }, [checkAndRefreshToken, shiftAssignments, selectedBranch])

  const fetchTailscaleStatus = useCallback(async () => {
    try {
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/tailscale/status', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.ok) {
        const devices = await response.json()
        const newStatus = devices
          .filter((device) => device.name.startsWith('spar-tma-'))
          .reduce((acc, device) => {
            const name = device.name.replace('spar-tma-', '').split('.')[0].toLowerCase()
            acc[name] = {
              isConnected: new Date() - new Date(device.lastSeen) < 15 * 60 * 1000,
              lastSeen: device.lastSeen,
              ip: device.addresses[0]
            }
            return acc
          }, {})

        if (!isEqual(newStatus, tailscaleStatus)) {
          setTailscaleStatus(newStatus)
          if (selectedBranch && selectedBranch.code === 'COLCHESTER') {
            //window.electron.saveJson('tailscaleStatus.json', newStatus);
          }
        }
      } else {
        console.error('Error fetching Tailscale status:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error fetching Tailscale status:', error)
    }
  }, [tailscaleStatus, selectedBranch])

  useEffect(() => {
    const initializeData = async () => {
      if (!initialized) {
        setIsLoading(true)
        await checkAndRefreshToken()
        await fetchBranches()
        await fetchClockinginRecords()
        await fetchStaffList()
        await fetchHolidayList()
        await fetchSickList()
        await fetchShifts()
        await fetchShiftAssignments()
        //await fetchTailscaleStatus();
        setInitialized(true)
        setIsLoading(false)
      }
    }

    initializeData()
  }, [])

  const handleRefresh = useCallback(async () => {
    setIsLoading(true)
    await checkAndRefreshToken()
    await fetchClockinginRecords()
    await fetchStaffList()
    await fetchHolidayList()
    await fetchSickList()
    await fetchShifts()
    await fetchShiftAssignments()
    //await fetchTailscaleStatus();
    setIsLoading(false)
  }, [
    checkAndRefreshToken,
    fetchClockinginRecords,
    fetchStaffList,
    fetchHolidayList,
    fetchSickList,
    fetchShifts,
    fetchShiftAssignments,
    fetchTailscaleStatus
  ])

  const debouncedHandleRefresh = useMemo(() => debounce(handleRefresh, 1000), [handleRefresh])

  const openAttendance = () => {
    setView('attendance')
  }

  const closeAttendance = () => {
    setView('main')
  }

  const handleBranchClick = useCallback((branchCode, branchName) => {
    setSelectedBranch({ code: branchCode, name: branchName })
  }, [])

  const debouncedSetFilterText = useMemo(() => debounce(setFilterText, 300), [])

  const filteredClockingInRecords = useMemo(
    () => (selectedBranch ? clockingInRecords.filter((record) => record.ClockBranch === selectedBranch.code) : []),
    [clockingInRecords, selectedBranch]
  )

  const filteredStaffList = useMemo(
    () => (selectedBranch ? staffList.filter((staff) => staff.Branch === selectedBranch.code) : []),
    [staffList, selectedBranch]
  )

  const getShiftForToday = useCallback(
    (shiftAssignment) => {
      const today = moment().isoWeekday()
      return shifts.find((shift) => shift._id === shiftAssignment.shifts[today.toString()])
    },
    [shifts]
  )

  const getZeroShiftStaffCount = useCallback(
    (branchCode) => {
      const today = moment().startOf('day')
      const branchStaff = staffList.filter((staff) => staff.Branch === branchCode)

      const zeroShiftClockedInStaff = branchStaff.filter((staff) => {
        const shiftAssignment = shiftAssignments.find((assignment) => assignment.staffId === staff.StaffId)
        if (!shiftAssignment) return false
        const shiftForToday = getShiftForToday(shiftAssignment)
        if (!(shiftForToday && shiftForToday.ShiftName === 'ZEROH')) return false

        // Check if staff has clocked in today, regardless of clock out status
        return clockingInRecords.some(
          (record) =>
            record.StaffID === staff.StaffId &&
            record.ClockBranch === branchCode &&
            moment(record.ClockInTime).isSame(today, 'day')
        )
      })

      return zeroShiftClockedInStaff.length
    },
    [staffList, shiftAssignments, clockingInRecords, getShiftForToday]
  )

  const getZeroShiftClockedInStaff = useCallback(
    (branchCode) => {
      const today = moment().startOf('day')
      const branchStaff = staffList.filter((staff) => staff.Branch === branchCode)

      return branchStaff
        .filter((staff) => {
          const shiftAssignment = shiftAssignments.find((assignment) => assignment.staffId === staff.StaffId)
          if (!shiftAssignment) return false
          const shiftForToday = getShiftForToday(shiftAssignment)
          if (!(shiftForToday && shiftForToday.ShiftName === 'ZEROH')) return false

          // Check if staff has clocked in today, regardless of clock out status
          return clockingInRecords.some(
            (record) =>
              record.StaffID === staff.StaffId &&
              record.ClockBranch === branchCode &&
              moment(record.ClockInTime).isSame(today, 'day')
          )
        })
        .map((staff) => `${staff.FName} ${staff.SName}`)
    },
    [staffList, shiftAssignments, clockingInRecords, getShiftForToday]
  )

  const calculateAbsentStaff = (branchStaff, branchRecords, shiftAssignments, holidayList = [], sickList = []) => {
    const now = moment()
    const today = now.clone().startOf('day')

    return branchStaff.filter((staff) => {
      const shiftAssignment = shiftAssignments.find((assignment) => assignment.staffId === staff.StaffId)
      if (!shiftAssignment) return false
      const shiftForToday = getShiftForToday(shiftAssignment)
      if (!shiftForToday || shiftForToday.ShiftName === 'ZEROH') return false

      const shiftStartTime = today.clone().add(moment.duration(shiftForToday.Start))

      // Check if the shift has started
      if (now.isBefore(shiftStartTime)) return false

      // Check if the staff member has clocked in today
      const hasClockInToday = branchRecords.some(
        (record) => record.StaffID === staff.StaffId && moment(record.ClockInTime).isSame(today, 'day')
      )

      // Check if the staff member is on holiday
      const isOnHoliday = holidayList.some((holiday) => {
        const isMatchingStaff = holiday.StaffID === staff.StaffId
        const isWithinHolidayPeriod =
          moment(holiday.StartDatetime).isSameOrBefore(now) && moment(holiday.EndDatetime).isSameOrAfter(now)

        // Logging StaffIds
        // console.log(`holiday.StaffId: ${holiday.StaffID}, staff.StaffId: ${staff.StaffId}`);

        return isMatchingStaff && isWithinHolidayPeriod
      })

      // Check if the staff member is on sick leave
      const isOnSickLeave = sickList.some(
        (sick) =>
          sick.StaffID === staff.StaffId &&
          moment(sick.StartDatetime).isSameOrBefore(now) &&
          moment(sick.EndDatetime).isSameOrAfter(now)
      )

      // Staff is absent if their shift has started, they haven't clocked in,
      // and they're not on holiday or sick leave
      return !hasClockInToday && !isOnHoliday && !isOnSickLeave
    })
  }

  const getBranchDetails = useCallback(
    (branches, staffList, clockingInRecords, holidayList, sickList, shiftAssignments) => {
      const branchDetails = {}

      branches.forEach((branch) => {
        const branchStaff = staffList.filter((staff) => staff.Branch === branch.MamCode)
        const branchRecords = clockingInRecords.filter((record) => record.ClockBranch === branch.MamCode)
        const staffRecords = branchRecords.reduce((acc, record) => {
          if (!acc[record.StaffID]) {
            acc[record.StaffID] = []
          }
          acc[record.StaffID].push(record)
          return acc
        }, {})

        const onSiteStaff = []
        const offSiteStaff = []
        const today = moment().startOf('day')

        Object.entries(staffRecords).forEach(([staffID, records]) => {
          const sortedStaffRecords = records.sort((a, b) => new Date(b.ClockInTime) - new Date(a.ClockInTime))
          const mostRecentRecord = sortedStaffRecords[0]

          if (moment(mostRecentRecord.ClockInTime).isSame(today, 'day')) {
            if (mostRecentRecord.ClockOutTime === null) {
              onSiteStaff.push(mostRecentRecord)
            } else {
              if (moment(mostRecentRecord.ClockOutTime).isSame(today, 'day')) {
                offSiteStaff.push(mostRecentRecord)
              }
            }
          }
        })

        const absentStaff = calculateAbsentStaff(branchStaff, branchRecords, shiftAssignments, holidayList, sickList)

        const holidayStaffMembers = holidayList.filter(
          (holiday) =>
            holiday.Branch === branch.MamCode &&
            moment(holiday.StartDatetime).isSameOrBefore(moment().endOf('day')) &&
            moment(holiday.EndDatetime).isSameOrAfter(moment().startOf('day'))
        )

        const sickStaffMembers = sickList.filter(
          (sick) =>
            sick.Branch === branch.MamCode &&
            moment(sick.StartDatetime).isSameOrBefore(moment().endOf('day')) &&
            moment(sick.EndDatetime).isSameOrAfter(moment().startOf('day'))
        )

        const branchWithShift = branchStaff.filter((staff) => {
          const shiftAssignment = shiftAssignments.find((assignment) => assignment.staffId === staff.StaffId)
          if (!shiftAssignment) return false
          const shiftForToday = getShiftForToday(shiftAssignment)
          return shiftForToday && shiftForToday.ShiftName !== 'ZEROH'
        })

        const zeroShiftStaffCount = getZeroShiftStaffCount(branch.MamCode)

        branchDetails[branch.Branch] = {
          onSite: onSiteStaff.map((record) => `${record.FName} ${record.SName}`),
          offSite: offSiteStaff.map((record) => `${record.FName} ${record.SName}`),
          absent: absentStaff.map((staff) => `${staff.FName} ${staff.SName}`),
          holiday: holidayStaffMembers.map((holiday) => holiday.StaffName),
          sick: sickStaffMembers.map((sick) => sick.StaffName),
          zeroShift: getZeroShiftClockedInStaff(branch.MamCode),
          withShift: branchWithShift.map((staff) => `${staff.FName} ${staff.SName}`)
        }
      })

      return branchDetails
    },
    [calculateAbsentStaff, getShiftForToday, getZeroShiftStaffCount, getZeroShiftClockedInStaff]
  )

  const sortedBranches = useMemo(() => {
    // Start with all branches
    let branches = allBranches

    // Filter for BM if applicable
    if (operatorData.Status === 'bm') {
      branches = branches.filter((branch) => branch.MamCode === operatorData.Branch)
    }

    // Filter for RM branches if applicable
    if (rmBranches && rmBranches.length > 0) {
      const rmBranchCodes = new Set(rmBranches.map((branch) => branch.MamCode))
      branches = branches.filter((branch) => rmBranchCodes.has(branch.MamCode))
    }

    // Sort the filtered branches
    return branches.sort((a, b) => a.Branch.localeCompare(b.Branch))
  }, [allBranches, rmBranches, operatorData.Status, operatorData.Branch])

  const branchStats = useMemo(() => {
    const today = moment().startOf('day')
    const filteredBranches = sortedBranches.filter((branch) =>
      branch.Branch.toLowerCase().includes(filterText.toLowerCase())
    )

    const branchesWithActivity = new Set()
    const branchesWithoutActivity = new Set()
    let totalOnSite = 0
    let totalOffSite = 0
    let totalAbsent = 0
    let totalHoliday = 0
    let totalSick = 0
    let totalWithShift = 0
    let totalZeroShift = 0

    filteredBranches.forEach((branch) => {
      const branchRecords = clockingInRecords.filter((record) => record.ClockBranch === branch.MamCode)
      const branchStaff = staffList.filter((staff) => staff.Branch === branch.MamCode)

      const onSiteStaff = branchRecords.filter(
        (record) => moment(record.ClockInTime).isSame(today, 'day') && record.ClockOutTime === null
      )

      const offSiteStaff = branchRecords.filter(
        (record) =>
          moment(record.ClockInTime).isSame(today, 'day') &&
          record.ClockOutTime !== null &&
          moment(record.ClockOutTime).isSame(today, 'day')
      )

      const absentStaff = calculateAbsentStaff(branchStaff, branchRecords, shiftAssignments, holidayList, sickList)

      const holidayStaffMembers = holidayList.filter(
        (holiday) =>
          holiday.Branch === branch.MamCode &&
          moment(holiday.StartDatetime).isSameOrBefore(moment().endOf('day')) &&
          moment(holiday.EndDatetime).isSameOrAfter(moment().startOf('day'))
      )

      const sickStaffMembers = sickList.filter(
        (sick) =>
          sick.Branch === branch.MamCode &&
          moment(sick.StartDatetime).isSameOrBefore(moment().endOf('day')) &&
          moment(sick.EndDatetime).isSameOrAfter(moment().startOf('day'))
      )

      const branchWithShift = branchStaff.filter((staff) => {
        const shiftAssignment = shiftAssignments.find((assignment) => assignment.staffId === staff.StaffId)
        if (!shiftAssignment) return false
        const shiftForToday = getShiftForToday(shiftAssignment)
        return shiftForToday && shiftForToday.ShiftName !== 'ZEROH'
      })

      const zeroShiftStaffCount = getZeroShiftStaffCount(branch.MamCode)

      if (
        onSiteStaff.length > 0 ||
        offSiteStaff.length > 0 ||
        holidayStaffMembers.length > 0 ||
        sickStaffMembers.length > 0 ||
        zeroShiftStaffCount > 0
      ) {
        branchesWithActivity.add(branch.Branch)
      } else {
        branchesWithoutActivity.add(branch.Branch)
      }

      totalOnSite += onSiteStaff.length
      totalOffSite += offSiteStaff.length
      totalAbsent += absentStaff.length
      totalHoliday += holidayStaffMembers.length
      totalSick += sickStaffMembers.length
      totalWithShift += branchWithShift.length
      totalZeroShift += zeroShiftStaffCount
    })

    const branchDetails = getBranchDetails(
      filteredBranches,
      staffList,
      clockingInRecords,
      holidayList,
      sickList,
      shiftAssignments
    )

    return {
      branchesWithActivity,
      branchesWithoutActivity,
      withActivity: branchesWithActivity.size,
      withoutActivity: branchesWithoutActivity.size,
      totalOnSite,
      totalOffSite,
      totalAbsent,
      totalHoliday,
      totalSick,
      totalClockedIn: totalOnSite + totalOffSite,
      totalWithShift,
      totalZeroShift,
      branchDetails: branchDetails || {},
      totalBranches: filteredBranches.length
    }
  }, [
    sortedBranches,
    filterText,
    clockingInRecords,
    staffList,
    holidayList,
    sickList,
    shiftAssignments,
    getShiftForToday,
    getZeroShiftStaffCount,
    calculateAbsentStaff,
    getBranchDetails
  ])

  const handleCameraClick = (branchCode, e) => {
    e.stopPropagation()
    setCameraView((prev) => ({
      ...prev,
      [branchCode]: !prev[branchCode]
    }))
  }

  const filterEmptyBranches = (branchDetails, category) => {
    return Object.entries(branchDetails)
      .filter(([_, details]) => details[category] && details[category].length > 0)
      .map(([branch, details]) => `${branch}: ${details[category].join(', ')}`)
      .join('\n')
  }

  return (
    <div>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {view === 'attendance' ? (
        <AttendanceAnalysis onClose={closeAttendance} />
      ) : selectedBranch ? (
        <MemoizedTandABranch
          staffBranch={selectedBranch}
          setStaffBranch={setSelectedBranch}
          token={tokenRef.current}
          clockingInRecords={filteredClockingInRecords}
          staffList={filteredStaffList}
          operatorData={operatorData}
        />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginBottom: '16px'
            }}
          >
            <div
              style={{
                flex: 1.5,
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                label="Filter branches"
                variant="outlined"
                onChange={(e) => debouncedSetFilterText(e.target.value)}
                style={{ marginRight: '16px' }}
              />
              <Button variant="contained" onClick={debouncedHandleRefresh}>
                Refresh
              </Button>
              <Button variant="contained" onClick={openAttendance}>
                Attendance Analysis
              </Button>
            </div>
            <div style={{ flex: 2, display: 'flex', alignItems: 'end', marginBottom: '8px', flexDirection: 'column' }}>
              <Typography variant="body1" style={{ color: 'white' }}>
                Branches (
                <Tooltip
                  title={sortedBranches
                    .filter((branch) => branch.Branch.toLowerCase().includes(filterText.toLowerCase()))
                    .map((branch) => branch.Branch)
                    .join(', ')}
                  arrow
                >
                  <span style={{ fontWeight: 'bold', color: 'dodgerblue', cursor: 'help' }}>
                    {branchStats.totalBranches}
                  </span>
                </Tooltip>
                ) with activity today:
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'onSite')} arrow>
                  <span style={{ fontWeight: 'bold', color: 'green', cursor: 'help' }}>{branchStats.withActivity}</span>
                </Tooltip>
                | Branches without activity:
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'absent')} arrow>
                  <span style={{ fontWeight: 'bold', color: 'darkred', cursor: 'help' }}>
                    {branchStats.withoutActivity}
                  </span>
                </Tooltip>
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'withShift')} arrow>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PersonIcon style={{ color: 'dodgerblue' }} />
                    <Typography variant="body2" style={{ marginLeft: '4px', color: 'white', marginRight: '16px' }}>
                      {branchStats.totalWithShift}
                    </Typography>
                  </div>
                </Tooltip>
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'absent')} arrow>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DoNotDisturbIcon style={{ color: 'darkred' }} />
                    <Typography variant="body2" style={{ marginLeft: '4px', color: 'white', marginRight: '16px' }}>
                      {branchStats.totalAbsent}
                    </Typography>
                  </div>
                </Tooltip>
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'onSite')} arrow>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PersonAddIcon style={{ color: 'green' }} />
                    <Typography variant="body2" style={{ marginLeft: '4px', color: 'white', marginRight: '16px' }}>
                      {branchStats.totalOnSite}
                    </Typography>
                  </div>
                </Tooltip>
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'offSite')} arrow>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PersonRemoveIcon style={{ color: 'darkorange' }} />
                    <Typography variant="body2" style={{ marginLeft: '4px', color: 'white', marginRight: '16px' }}>
                      {branchStats.totalOffSite}
                    </Typography>
                  </div>
                </Tooltip>
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'holiday')} arrow>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BeachAccessIcon style={{ color: 'yellow' }} />
                    <Typography variant="body2" style={{ marginLeft: '4px', color: 'white', marginRight: '16px' }}>
                      {branchStats.totalHoliday}
                    </Typography>
                  </div>
                </Tooltip>
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'sick')} arrow>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SickIcon style={{ color: 'purple' }} />
                    <Typography variant="body2" style={{ marginLeft: '4px', color: 'white', marginRight: '16px' }}>
                      {branchStats.totalSick}
                    </Typography>
                  </div>
                </Tooltip>
                <Tooltip title={filterEmptyBranches(branchStats.branchDetails, 'zeroShift')} arrow>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ZeroIcon style={{ color: 'red' }} />
                    <Typography variant="body2" style={{ marginLeft: '4px', color: 'white' }}>
                      {branchStats.totalZeroShift}
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            style={{
              maxHeight: '80vh',
              overflow: 'scroll',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {sortedBranches
              .filter((branch) => branch.Branch.toLowerCase().includes(filterText.toLowerCase()))
              .map((branch) => {
                const branchRecords = clockingInRecords.filter((record) => record.ClockBranch === branch.MamCode)
                const staffRecords = branchRecords.reduce((acc, record) => {
                  if (!acc[record.StaffID]) {
                    acc[record.StaffID] = []
                  }
                  acc[record.StaffID].push(record)
                  return acc
                }, {})
                const onSiteStaff = []
                const offSiteStaff = []
                const today = moment().startOf('day')

                Object.entries(staffRecords).forEach(([staffID, records]) => {
                  const sortedStaffRecords = records.sort((a, b) => new Date(b.ClockInTime) - new Date(a.ClockInTime))
                  const mostRecentRecord = sortedStaffRecords[0]

                  if (moment(mostRecentRecord.ClockInTime).isSame(today, 'day')) {
                    if (mostRecentRecord.ClockOutTime === null) {
                      onSiteStaff.push(mostRecentRecord)
                    } else {
                      if (moment(mostRecentRecord.ClockOutTime).isSame(today, 'day')) {
                        offSiteStaff.push(mostRecentRecord)
                      }
                    }
                  }
                })

                const branchStaff = staffList.filter((staff) => staff.Branch === branch.MamCode)
                const absentStaff = calculateAbsentStaff(
                  branchStaff,
                  branchRecords,
                  shiftAssignments,
                  holidayList,
                  sickList
                )

                const holidayStaffMembers = holidayList.filter(
                  (holiday) =>
                    holiday.Branch === branch.MamCode &&
                    moment(holiday.StartDatetime).isSameOrBefore(moment().endOf('day')) &&
                    moment(holiday.EndDatetime).isSameOrAfter(moment().startOf('day'))
                )

                const sickStaffMembers = sickList.filter(
                  (sick) =>
                    sick.Branch === branch.MamCode &&
                    moment(sick.StartDatetime).isSameOrBefore(moment().endOf('day')) &&
                    moment(sick.EndDatetime).isSameOrAfter(moment().startOf('day'))
                )

                const branchWithShift = branchStaff.filter((staff) => {
                  const shiftAssignment = shiftAssignments.find((assignment) => assignment.staffId === staff.StaffId)
                  if (!shiftAssignment) return false
                  const shiftForToday = getShiftForToday(shiftAssignment)
                  return shiftForToday && shiftForToday.ShiftName !== 'ZEROH'
                })

                // Use the getZeroShiftClockedInStaff function to get the zero shift staff count
                const zeroShiftClockedInStaff = getZeroShiftClockedInStaff(branch.MamCode)

                const branchStatus = tailscaleStatus[branch.MamCode.toLowerCase()]
                const isConnected = branchStatus ? branchStatus.isConnected : false
                const lastSeen = branchStatus ? moment(branchStatus.lastSeen).fromNow() : 'Unknown'

                return (
                  <Card
                    key={branch.MamCode}
                    style={{ margin: '16px', width: '300px', cursor: 'pointer' }}
                    onClick={() => handleBranchClick(branch.MamCode, branch.Branch)}
                  >
                    <CardContent>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <Typography variant="h5" component="div">
                            {branch.Branch}
                          </Typography>
                        </div>
                        {(operatorData.Name === 'Paul Chandler' ||
                          operatorData.Name === 'Luke Bettles' ||
                          operatorData.Name === 'Sue Bray') && (
                          <IconButton onClick={(e) => handleCameraClick(branch.MamCode, e)} aria-label="camera">
                            <CameraAltIcon />
                          </IconButton>
                        )}
                      </div>
                      <Typography variant="body2">Region: {branch.Region}</Typography>
                      <div style={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0' }}>
                        <Tooltip title={`Total: ${branchWithShift.length} with shift assigned`} arrow>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PersonIcon style={{ color: 'dodgerblue' }} />
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                              {branchWithShift.length}
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            absentStaff.length > 0
                              ? `ABSENT: ${absentStaff.map((staff) => `${staff.FName} ${staff.SName}`).join(', ')}`
                              : 'No absent staff'
                          }
                          arrow
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DoNotDisturbIcon style={{ color: 'darkred' }} />
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                              {absentStaff.length}
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            onSiteStaff.length > 0
                              ? `ON-SITE: ${onSiteStaff.map((record) => `${record.FName} ${record.SName}`).join(', ')}`
                              : 'No staff clocked in'
                          }
                          arrow
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PersonAddIcon style={{ color: 'green' }} />
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                              {onSiteStaff.length}
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            offSiteStaff.length > 0
                              ? `OFF-SITE: ${offSiteStaff
                                  .map((record) => `${record.FName} ${record.SName}`)
                                  .join(', ')}`
                              : 'No staff clocked out'
                          }
                          arrow
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PersonRemoveIcon style={{ color: 'darkorange' }} />
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                              {offSiteStaff.length}
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            holidayStaffMembers.length > 0
                              ? `HOLIDAY: ${holidayStaffMembers.map((holiday) => holiday.StaffName).join(', ')}`
                              : 'No staff on holiday'
                          }
                          arrow
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BeachAccessIcon style={{ color: 'yellow' }} />
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                              {holidayStaffMembers.length}
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            sickStaffMembers.length > 0
                              ? `SICK: ${sickStaffMembers.map((sick) => sick.StaffName).join(', ')}`
                              : 'No staff on sick leave'
                          }
                          arrow
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SickIcon style={{ color: 'purple' }} />
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                              {sickStaffMembers.length}
                            </Typography>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            zeroShiftClockedInStaff.length > 0
                              ? `ZERO: ${zeroShiftClockedInStaff.length} staff clocked in`
                              : "No staff with 'ZERO' shift clocked in"
                          }
                          arrow
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ZeroIcon style={{ color: 'red' }} />
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                              {zeroShiftClockedInStaff.length}
                            </Typography>
                          </div>
                        </Tooltip>
                      </div>
                      {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2">
                          Status: <span style={{ color: isConnected ? 'green' : 'red' }}>{isConnected ? 'Connected' : 'Disconnected'}</span>
                        </Typography>
                        <span style={{ fontSize: '0.75em', color: '#888' }}>({lastSeen})</span>
                      </div> */}
                      {cameraView[branch.MamCode] && (
                        <div style={{ marginTop: '10px' }}>
                          <img
                            src={`http://spar.connectplus.parts:3000/camera/${branchStatus.ip}/?action=stream`}
                            alt="Live Camera Feed"
                            style={{ width: '100%' }}
                            onError={() => setCameraView((prev) => ({ ...prev, [branch.MamCode]: false }))}
                          />
                        </div>
                      )}
                    </CardContent>
                  </Card>
                )
              })}
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(TandA)
