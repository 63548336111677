import React, { useState, useEffect, useContext, useMemo } from 'react'
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material'
import './App.css'
import moment from 'moment-timezone'
import AdminOverview from './components/admin/AdminOverview'
import BranchList from './components/admin/BranchList'
import { GlobalContext } from './context/GlobalContext'
import GETab from './components/tabs/GETab'
import StockCheck from './components/admin/StockCheck'
import NewsFlash from './components/NewsFlash'
import TandA from './components/admin/TandA'
import TandABranch from './components/admin/TandABranch'
import TandABranch_ from './components/admin/TandABranch'
import RegionsTab from './components/tabs/RegionsTab'
import SettingsTab from './components/tabs/SettingsTab'
import Sophos from './components/admin/Sophos'
import NewMyHolidays from './components/newholidays/MyHolidays'
import Support from './components/newholidays/Support'
import CustomerTab from './components/tabs/CustomerTab'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  if (value !== index) {
    return null // Render nothing if this tab is not active
  }

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
      {...other}
    >
      <Box sx={{ p: 0 }}>{children}</Box>
    </div>
  )
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function AdminPanel() {
  const { username, token, operatorData, setOperatorData, isToday, setIsToday, setToken, setIsLoggedIn } =
    useContext(GlobalContext)
  const [tabValue, setTabValue] = useState(0)
  const [progress, setProgress] = useState(100)
  const [lastUpdated, setLastUpdated] = useState(null)
  const [drillDownBranch, setDrillDownBranch] = useState(null)
  const [staffBranch, setStaffBranch] = useState(null)
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [quitOpen, setQuitOpen] = useState(false)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const memoizedGETab = useMemo(() => <GETab />, [])


  const handleSwitchChange = (event) => {
    setIsToday(event.target.checked)
  }

  const handleLogout = () => {
    setToken(null)
    setIsLoggedIn(false)
    setLogoutOpen(false)
  }

  const fetchData = async () => {
    try {
      // Fetch Operator Data
      const operatorResponse = await fetch(
        `https://spar.connectplus.parts/api/salesassist/cplusassist/getByUsername/${username}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      if (!operatorResponse.ok) {
        throw new Error(`HTTP error! status: ${operatorResponse.status}`)
      }
      const operatorData = await operatorResponse.json()
      console.info('got the data in admin panel')
      setOperatorData(operatorData)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [username, token])

  return (
    <Container
      style={{
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%',
        margin: 0,
        padding: '20px 20px 0 20px'
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          height: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="https://spar.connectplus.parts/api/public/images/Logo.png" alt="Logo" style={{ height: '65px' }} />
          <Switch checked={isToday} onChange={handleSwitchChange} color="primary" />
          <h3 style={{ color: 'white' }}>{isToday ? 'TODAY' : 'MONTH'}</h3>
          {/* <h4 style={{ color: 'white' }}>{operatorData.Username}</h4> */}
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-around' }}>
          <h5 style={{ color: 'white', cursor: 'pointer' }} onClick={() => setLogoutOpen(true)}>
            LOGOUT
          </h5>
        </Box>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
            <Tab style={{ color: 'white', fontSize: 12 }} label="Overview" {...a11yProps(0)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Customers" {...a11yProps(1)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Branches" {...a11yProps(2)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Regions" {...a11yProps(3)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="GE Lead" {...a11yProps(4)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="T&A" {...a11yProps(5)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="My holidays" {...a11yProps(6)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Settings" {...a11yProps(7)} />
            <Tab style={{ color: 'white', fontSize: 12 }} label="Support" {...a11yProps(8)} />
          </Tabs>
          <div style={{ textAlign: 'right' }}></div>
        </div>
      </Box>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={0}
      >
        {operatorData && (
          <AdminOverview
            operatorData={operatorData}
            drillDownBranch={drillDownBranch}
            setDrillDownBranch={setDrillDownBranch}
            progress={progress}
            setProgress={setProgress}
          />
        )}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={1}
      >
        {operatorData && (
          <CustomerTab
            operatorData={operatorData}
            token={token}
            drillDownBranch={drillDownBranch}
            setDrillDownBranch={setDrillDownBranch}
            progress={progress}
            setProgress={setProgress}
            setLastUpdated={setLastUpdated}
          />
        )}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={2}
      >
        {operatorData && (
          <BranchList
            operatorData={operatorData}
            token={token}
            drillDownBranch={drillDownBranch}
            setDrillDownBranch={setDrillDownBranch}
            progress={progress}
            setProgress={setProgress}
            setLastUpdated={setLastUpdated}
          />
        )}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={3}
      >
        {operatorData && <RegionsTab progress={progress} setProgress={setProgress} />}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={4}
      >
        {operatorData && memoizedGETab}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={5}
      >
        {operatorData &&
          (staffBranch ? (
            <TandABranch_ operatorData={operatorData} staffBranch={staffBranch} setStaffBranch={setStaffBranch} />
          ) : (
            <TandA operatorData={operatorData} staffBranch={staffBranch} setStaffBranch={setStaffBranch} />
          ))}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={6}
      >
        {operatorData && <NewMyHolidays />}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll'
        }}
        value={tabValue}
        index={7}
      >
        {operatorData && <SettingsTab />}
      </TabPanel>

      <TabPanel
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 0,
          marginBottom: 100,
          margin: 0,
          overflow: 'scroll',
          position: 'relative'
        }}
        value={tabValue}
        index={8}
      >
        <div className="absolute inset-0 mb-[85px]">{operatorData && <Support />}</div>
      </TabPanel>

      <Box
        sx={{
          mb: 2,
          width: '100%',
          display: 'flex',
          height: 'auto',
          position: 'absolute',
          bottom: 0,
          left: 0,
          justifyContent: 'center',
          backgroundColor: '#181a1f',
          zIndex: 100
        }}
      >
        {operatorData ? (
          <div
            style={{
              width: '90%',
              marginLeft: 5,
              marginRight: 5
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexGrow: 1,
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span style={{ fontSize: 40, marginRight: 20 }}>👤</span>
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flex: 1
                }}
              >
                <Typography style={{ color: 'white' }}>{operatorData.Name}</Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 10
                  }}
                >
                  <Typography style={{ color: 'white' }}>({operatorData.Inits})</Typography>
                  <Typography style={{ color: 'white' }}>{operatorData.Branch}</Typography>
                </div>
              </div>
              <div style={{ flex: 2, paddingLeft: 20, minWidth: '75%' }}>
                <NewsFlash
                  containerStyle={{ width: '100%' }}
                  setLastUpdated={setLastUpdated}
                  progress={progress}
                  setProgress={setProgress}
                />
              </div>
            </div>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        ) : (
          <Typography>Loading operator data...</Typography>
        )}
      </Box>

      <Dialog
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">{'Are you sure you want to logout?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Clicking OK will log you out of the application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Sophos /> */}
    </Container>
  )
}

export default AdminPanel
