import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { GlobalContext } from '../context/GlobalContext'
import colosseumImage from '../assets/Colosseum.png'
import haxios, { getApir, getCinfo, setToken } from './newholidays/lib/haxios'
import useBranchStore from './newholidays/stores/useBranchStore'
import Button from './newholidays/components/Button'
import { Checkbox, MantineProvider, TextInput } from '@mantine/core'

const setupHaxios = async (token, username, password) => {
  setToken(token)
  await getCinfo()
  await getApir()
}

const LoginForm = ({ mode }) => {
  const { setToken, isLoggedIn, setIsLoggedIn, username, setUsername, password, setPassword } =
    useContext(GlobalContext)
  const setBranches = useBranchStore((state) => state.setBranches)
  const setRegions = useBranchStore((state) => state.setRegions)
  const [rememberMe, setRememberMe] = useState(false)
  const [loginIsLoading, setLoginIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const tryLogin = async (event) => {
    event.preventDefault()
    setLoginIsLoading(true)
    setError(null)
    try {
      const authResponse = await axios.post('https://vision-web-api-test.azurewebsites.net/api/authenticate', {
        Username: username,
        Password: password
      })

      if (authResponse.data) {
        setToken(authResponse.data.token)
        await setupHaxios(authResponse.data.token)
        const branchData = await haxios.get('/salesassistnew/branches')
        setBranches(branchData.branches)
        setRegions(branchData.regions)
        setIsLoggedIn(true)

        if (rememberMe) {
          localStorage.setItem('username', username)
          localStorage.setItem('password', password)
        } else {
          localStorage.removeItem('username')
          localStorage.removeItem('password')
        }
      }
    } catch (error) {
      setError(error.message)
      console.error('Error during login:', JSON.stringify(Object.keys(error)))
    }

    setLoginIsLoading(false)
  }

  useEffect(() => {
    const savedUsername = localStorage.getItem('username')
    const savedPassword = localStorage.getItem('password')

    if (savedUsername && savedPassword) {
      setUsername(savedUsername)
      setPassword(savedPassword)
      setRememberMe(true)
    }
  }, [])

  return (
    <div
      className="flex flex-col justify-between h-full text-white"
      style={{
        backgroundImage: `url(${colosseumImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="p-4 text-2xl font-bold tracking-wider text-right bg-black/50">WELCOME TO THE COLOSSEUM</div>
      <div className="flex justify-between items-center p-4 bg-black/50">
        <div className="flex items-center space-x-4">
          <TextInput
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-[350px]"
            placeholder="Username"
            size="lg"
          />
          <TextInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-[350px]"
            type="password"
            placeholder="Password"
            size="lg"
          />
        </div>
        <div className="text-lg font-semibold text-red-500">
          {error && (
            <div className="text-lg font-semibold text-red">
              {error === 'Request failed with status code 406' ? 'Incorrect credentials' : error}
            </div>
          )}
        </div>
        <div className="flex items-center space-x-4">
          <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.currentTarget.checked)} label="Remember me" />
          <Button size="md" loading={loginIsLoading} onClick={tryLogin}>
            Login
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
