import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import QuotesTable from '../QuotesTable'
import { GlobalContext } from '../../context/GlobalContext'

function QuotesTab({ setProgress, progress }) {
  const { operatorData, token } = useContext(GlobalContext)
  const [quotesData, setQuotesData] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(null)
  const [filter, setFilter] = useState('')

  const handleFilterChange = (event) => {
    setFilter(event.target.value)
  }

  useEffect(() => {
    const fetchQuotesData = async () => {
      try {
        // Fetch Quotes Data
        const quotesResponse = await fetch(
          `https://spar.connectplus.parts/api/salesassist/cplusassist/quotesData/${operatorData.Branch}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        if (!quotesResponse.ok) {
          throw new Error(`HTTP error! status: ${quotesResponse.status}`)
        }

        const quotesData = await quotesResponse.json()
        console.log('Quotes Data:', quotesData)
        setQuotesData(quotesData)
      } catch (error) {
        console.error('Failed to fetch quotes data:', error)
      }
    }

    fetchQuotesData()

    const interval = setInterval(() => {
      fetchQuotesData()
      setLastUpdated(moment().format('ddd DD/MM/YYYY HH:mm'))
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [token])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '75vh',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}
    >
      <div
        style={{
          marginBottom: 20,
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <h2 style={{ margin: 0, color: 'white' }}>Quotes - {operatorData.Branch}</h2>
        <input
          type="text"
          placeholder="Filter..."
          value={filter}
          onChange={handleFilterChange}
          style={{ marginLeft: 'auto' }}
        />
      </div>
      {quotesData && quotesData.OutstandingQuotesDetails ? (
        <QuotesTable
          handleFilterChange={handleFilterChange}
          filter={filter}
          setFilter={setFilter}
          quotesDetails={quotesData.OutstandingQuotesDetails}
        />
      ) : (
        <p style={{ color: 'white' }}>Loading quotes data or no data available...</p>
      )}
    </div>
  )
}

export default QuotesTab
