import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Tooltip
} from '@mui/material';
import moment from 'moment';
import { GlobalContext } from '../context/GlobalContext';
import { groupBy } from 'lodash';

const SickDaysOverview = () => {
    const { token } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [monthlyData, setMonthlyData] = useState([]);

    useEffect(() => {
        const fetchYearData = async () => {
            try {
                setLoading(true);
                setError('');

                if (!token) throw new Error('Authentication token not found');

                // Set date range for last 12 months
                const endDate = moment().endOf('month');
                const startDate = moment().subtract(11, 'months').startOf('month');

                const response = await fetch(
                    'https://vision-web-api-test.azurewebsites.net/api/tma/sickdays',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': token
                        },
                        body: JSON.stringify({
                            StartDate: startDate.format('DD-MM-YYYY'),
                            EndDate: endDate.format('DD-MM-YYYY')
                        })
                    }
                );

                if (!response.ok) {
                    throw new Error(`API Error ${response.status}`);
                }

                const data = await response.json();

                // Create lookup of all months
                const monthLookup = {};
                for (let i = 0; i < 12; i++) {
                    const monthDate = moment().subtract(i, 'months');
                    const monthKey = monthDate.format('YYYY-MM');
                    monthLookup[monthKey] = {
                        monthKey,
                        label: monthDate.format('MMM'),
                        totalDays: 0,
                        branchSummary: []
                    };
                }

                // Process the data
                data.forEach(record => {
                    const startDate = moment(record.StartDatetime);
                    const monthKey = startDate.format('YYYY-MM');

                    if (monthLookup[monthKey]) {
                        monthLookup[monthKey].totalDays += record.TotalDays;

                        // Track branch data
                        const existingBranch = monthLookup[monthKey].branchSummary.find(
                            b => b.branch === record.Branch
                        );

                        if (existingBranch) {
                            existingBranch.count++;
                        } else {
                            monthLookup[monthKey].branchSummary.push({
                                branch: record.Branch,
                                count: 1
                            });
                        }
                    }
                });

                // Convert to array and sort by date
                const processedData = Object.values(monthLookup)
                    .sort((a, b) => moment(a.monthKey).diff(moment(b.monthKey)));

                setMonthlyData(processedData);
            } catch (error) {
                console.error('Data Fetch Error:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchYearData();
    }, [token]);

    if (loading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px'
            }}>
                <CircularProgress size={24} sx={{ color: '#90CAF9' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mb: 3 }}>
                Error: {error}
            </Alert>
        );
    }

    return (
        <Box sx={{
            width: '100%',
            p: 3,
            backgroundColor: '#1A1A1A',
            borderRadius: 2,
            mb: 4
        }}>
            <Typography variant="h6" sx={{ mb: 3, color: '#90CAF9' }}>
                12 Month Sick Days Overview
            </Typography>

            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(12, minmax(60px, 1fr))',
                gap: 2,
                width: '100%',
                maxWidth: '900px',
                margin: '0 auto'
            }}>
                {monthlyData.map((month) => (
                    <Tooltip
                        key={month.monthKey}
                        title={
                            month.branchSummary.length > 0 ? (
                                <Box sx={{ p: 1 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
                                        {month.label} Sick Days by Branch:
                                    </Typography>
                                    {month.branchSummary.map((branch) => (
                                        <Typography key={branch.branch} variant="body2">
                                            {branch.branch}: {branch.count} {branch.count === 1 ? 'day' : 'days'}
                                        </Typography>
                                    ))}
                                </Box>
                            ) : (
                                'No sick days recorded'
                            )
                        }
                        arrow
                        placement="top"
                    >
                        <Box
                            sx={{
                                height: '80px',
                                backgroundColor: '#2D2D2D',
                                borderRadius: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                transition: 'all 0.2s',
                                '&:hover': {
                                    backgroundColor: '#373737',
                                    transform: 'translateY(-2px)'
                                }
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    color: month.totalDays > 0 ? '#90CAF9' : '#666',
                                    fontWeight: 'bold'
                                }}
                            >
                                {month.totalDays}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#B0BEC5',
                                    mt: 0.5
                                }}
                            >
                                {month.label}
                            </Typography>
                        </Box>
                    </Tooltip>
                ))}
            </Box>
        </Box>
    );
};


export default SickDaysOverview;