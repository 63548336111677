import { useState, useEffect } from 'react';

export const useCustomerFilters = (data) => {
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeColorFilters, setActiveColorFilters] = useState({
        red: true, orange: true, green: true
    });

    useEffect(() => {
        if (!data || !Array.isArray(data)) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter((customer) => {
            if (!customer) return false;

            const matchesSearchTerm =
                (customer.customerCode?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                (customer.customerName?.toLowerCase() || '').includes(searchTerm.toLowerCase());

            const hasSales = customer.totalSales > 0;
            const hasQuotes = customer.quotesCount > 0 || customer.quotesValue > 0;
            const color = hasSales ? 'green' : (hasQuotes ? 'orange' : 'red');

            return matchesSearchTerm && activeColorFilters[color];
        });

        const sorted = [...filtered].sort((a, b) =>
            (b.thirtyDaySales || 0) - (a.thirtyDaySales || 0)
        );

        setFilteredData(sorted);
    }, [searchTerm, activeColorFilters, data]);

    return {
        filteredData,
        searchTerm,
        setSearchTerm,
        activeColorFilters,
        setActiveColorFilters
    };
};