import { useState, useEffect } from 'react';

export const useOperators = (token, branch) => {
    const [operators, setOperators] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOperators = async () => {
            if (!token || !branch) {
                setIsLoading(false);
                setOperators([]);
                return;
            }

            setIsLoading(true);

            try {
                let url;
                if (typeof branch === 'object' && branch.status?.toLowerCase() === 'rm') {
                    url = `https://spar.connectplus.parts/api/salesassist/cplusassist/getOperatorsByRegion/${encodeURIComponent(branch.rmName)}`;
                } else {
                    url = `https://spar.connectplus.parts/api/salesassist/cplusassist/getOperators/${branch}`;
                }

                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(await response.text());
                }

                const data = await response.json();
                setOperators(data);
                setError(null);
            } catch (error) {
                setError('Failed to load operators');
                setOperators([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchOperators();
    }, [token, branch]);

    return { operators, isLoading, error };
};