import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import '../App.css';
import { getColorBasedOnProgress } from '../utils';

const SalesProgress = ({ sales, target }) => {
  const progress = Math.min((sales / target) * 100, 100);
  const textColor = 'white';
  const barColor = getColorBasedOnProgress(progress);



  return (
    sales !== null && sales !== undefined ?
      <div style={{
        backgroundColor: '#21242c',
        borderRadius: 15,
        height: 160, // Adjusted height to accommodate the toggle
        padding: 10,
      }}>
        <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: 24 }}>SALES</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography style={{ color: textColor, display: 'flex', flexDirection: 'row', flex: 0.6, justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 style={{ margin: 0 }}>£{Intl.NumberFormat('en-GB', { style: 'decimal', minimumFractionDigits: 2 }).format(sales)}</h1>
          </Typography>

        </div>
        <Box sx={{ position: 'relative', width: '100%', mr: 1, height: 20, marginTop: 1, marginBottom: 2 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: '100%',
              borderRadius: 5,
              '& .MuiLinearProgress-bar': {
                backgroundColor: barColor,
              }
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              left: progress > 10 ? `${progress}%` : '10px',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white'
            }}
          >
            <Typography style={{ color: 'white', fontWeight: 'bold', }} variant="body2" >{`${Math.round(progress)}%`}</Typography>
          </Box>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
            <strong style={{ color: textColor, }}>{progress && progress.toFixed(2)}%</strong>
            <Typography style={{ color: textColor }}>
              <span>🎯 Target: </span>
              <strong>
                £{Intl.NumberFormat('en-GB', { style: 'decimal', minimumFractionDigits: 2 }).format(target)}
              </strong>
            </Typography>
          </div>
        </Box>
      </div>
      :
      <div>
        No data available
      </div>
  );
};

export default SalesProgress;
