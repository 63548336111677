import { useState, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Alert, Radio, Group } from '@mantine/core'
import { Form, useForm } from 'react-hook-form'
import { TextInput, DateInput } from 'react-hook-form-mantine'
import yup from '../lib/yup'
import haxios from '../lib/haxios'
import moment from 'moment-timezone'
import { errorToast, successToast } from '../lib/helpers'
import Button from '../components/Button'

moment.tz.setDefault('Etc/UTC')

const validation = yup.object().shape({
  startDate: yup
    .mixed()
    .required('Start date is required')
    .test('is-date', 'Must be a valid date', (value) => value instanceof Date),
  endDate: yup
    .mixed()
    .required('End date is required')
    .test('is-date', 'Must be a valid date', (value) => value instanceof Date)
    .test('is-after-start', 'End date must be after start date', function (endDate) {
      const startDate = this.parent.startDate
      if (!startDate || !endDate) return true
      return endDate >= startDate
    }),
  reason: yup
    .string()
    .required('Reason is required')
    .min(3, 'Reason must be at least 3 characters')
    .max(500, 'Reason must be less than 500 characters')
})

const HolidayRequestModal = ({ context, id, innerProps }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [daysSelected, setDaysSelected] = useState(null)
  const [dayType, setDayType] = useState('full')
  const [hasOverlap, setHasOverlap] = useState(false)
  const [overlappingDates, setOverlappingDates] = useState(null)

  const { control, watch, setValue } = useForm({
    resolver: yupResolver(validation),
    defaultValues: innerProps.defaultValues || {
      startDate: null,
      endDate: null,
      reason: ''
    }
  })

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  useEffect(() => {
    if (startDate && endDate) {
      const isSameDay = moment(endDate).isSame(moment(startDate), 'day')
      const days = moment(endDate).diff(moment(startDate), 'days') + 1
      setDaysSelected(days)

      if (isSameDay === false) {
        setDayType('full')
      }
    } else {
      setDaysSelected(null)
    }
  }, [startDate, endDate])

  useEffect(() => {
    const checkDateOverlaps = async () => {
      setIsLoading(true)

      try {
        const selectedStart = moment(startDate)
        const selectedEnd = moment(endDate)

        if (moment(selectedEnd).isSame(moment(selectedStart), 'day')) {
          switch (dayType) {
            case 'am':
              selectedStart.hours(0).minutes(0)
              selectedEnd.hours(12).minutes(0)
              break
            case 'pm':
              selectedStart.hours(12).minutes(0)
              selectedEnd.hours(0).minutes(0)
              break
            case 'full':
              selectedStart.hours(0).minutes(0)
              selectedEnd.hours(0).minutes(0)
              break
          }
        } else {
          selectedStart.hours(0).minutes(0)
          selectedEnd.hours(0).minutes(0)
        }

        console.log('dr', selectedStart, selectedEnd)
        const res = await haxios.get(`/salesassistnew/holidayrequests/${innerProps.staffId}/overlap`, {
          params: { start: selectedStart.toDate(), end: selectedEnd.toDate() }
        })

        setHasOverlap(!!res)
        setOverlappingDates(res)
      } catch (error) {
        setHasOverlap(null)
        console.error('Error checking date overlaps:', error)
        errorToast('Error checking date availability')
      }

      setIsLoading(false)
    }

    if (startDate && endDate) {
      checkDateOverlaps()
    }
  }, [startDate, endDate, dayType, innerProps.staffId])

  const handleDayTypeChange = (value) => {
    setDayType(value)
  }

  const isSameDay = startDate && endDate && moment(endDate).isSame(moment(startDate), 'day')
  const availableDays = innerProps.allocation - innerProps.totalBooked
  const exceedingDays = daysSelected > availableDays

  const handleSubmit = async (data) => {
    setIsLoading(true)
    console.log('Raw form data:', {
      startDate: data.startDate,
      endDate: data.endDate
    })

    try {
      const start = moment(data.startDate)
      const end = moment(data.endDate)
      console.log('submit', start, end)

      if (moment(end).isSame(moment(start), 'day')) {
        switch (dayType) {
          case 'am':
            start.hours(0).minutes(0)
            end.hours(12).minutes(0)
            break
          case 'pm':
            start.hours(12).minutes(0)
            end.hours(0).minutes(0)
            break
          case 'full':
            start.hours(0).minutes(0)
            end.hours(0).minutes(0)
            break
        }
      } else {
        start.hours(0).minutes(0)
        end.hours(0).minutes(0)
      }

      const formattedData = {
        staffId: innerProps.staffId,
        dtStart: start.format('YYYY-MM-DD HH:mm:ss'),
        dtEnd: end.format('YYYY-MM-DD HH:mm:ss'),
        reason: data.reason,
        requestDate: moment().format('YYYY-MM-DD'),
        decision: 'PENDING',
        Notes: ''
      }

      if (innerProps.editing) {
        await haxios.put(`/salesassist/cplusassist/holidayrequests/${innerProps.reqId}`, formattedData)
      } else {
        await haxios.post('/salesassist/cplusassist/holidayrequests', formattedData)
      }

      await innerProps.refetch()
      context.closeModal(id)
      successToast('Holiday request submitted.')
    } catch (e) {
      setIsLoading(false)
      errorToast('An error has occurred.')
      console.log(e)
    }
  }

  return (
    <Form control={control} onSubmit={(e) => handleSubmit(e.data)} onError={(e) => console.log(e)}>
      <Grid gutter="xs">
        <Grid.Col span={12}>
          <div className="text-lg font-semibold">
            {innerProps.totalBooked}/{innerProps.allocation} days booked
          </div>
        </Grid.Col>

        <Grid.Col span={6}>
          <DateInput label="Start Date" name="startDate" control={control} />
        </Grid.Col>

        <Grid.Col span={6}>
          <DateInput label="End Date" name="endDate" control={control} />
        </Grid.Col>

        {isSameDay && (
          <Grid.Col span={12}>
            <Radio.Group value={dayType} onChange={handleDayTypeChange} name="dayType">
              <Group mt="xs">
                <Radio value="full" label="Full day" />
                <Radio value="am" label="AM only" />
                <Radio value="pm" label="PM only" />
              </Group>
            </Radio.Group>
          </Grid.Col>
        )}

        {daysSelected && daysSelected > 0 && (
          <Grid.Col span={12}>
            <div className="text-sm text-gray-300">
              Selected period: {daysSelected === 1 && (dayType === 'am' || dayType === 'pm') ? '0.5' : daysSelected} day
              {daysSelected !== 1 || dayType === 'am' || dayType === 'pm' ? 's' : ''}
            </div>
          </Grid.Col>
        )}

        {exceedingDays && (
          <Grid.Col span={12}>
            <Alert color="red">
              You only have {availableDays} holiday day{availableDays !== 1 ? 's' : ''} remaining. Please adjust your
              selection.
            </Alert>
          </Grid.Col>
        )}

        {hasOverlap && (
          <Grid.Col span={12}>
            <Alert color="red">
              The selected dates overlap with an existing holiday booking from{' '}
              {moment(overlappingDates.dtStart).format('DD/MM/YYYY')} to{' '}
              {moment(overlappingDates.dtEnd).format('DD/MM/YYYY')}. Please select different days or adjust your
              existing bookings.
            </Alert>
          </Grid.Col>
        )}

        <Grid.Col span={12}>
          <TextInput label="Reason" name="reason" control={control} multiline rows={4} />
        </Grid.Col>

        <Grid.Col span={12}>
          <div className="text-right">
            <Button loading={isLoading} type="submit" disabled={exceedingDays || hasOverlap !== false}>
              Submit
            </Button>
          </div>
        </Grid.Col>
      </Grid>
    </Form>
  )
}

export default HolidayRequestModal
