import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import moment from 'moment-timezone';
import ClocklogTable from './ClocklogTable';
import ClocklogDetails from './ClocklogDetails';
import useBranchStore from '../newholidays/stores/useBranchStore';
import { GlobalContext } from '../../context/GlobalContext';

const filterOutFutureDates = (records) => {
    const today = moment().endOf('day');
    return records.filter((record) => {
        const recordDate = moment(record.date);
        return recordDate.isSameOrBefore(today);
    }).map(record => ({
        ...record,
        totalInfr: record.totalInfringements || record.totalInfr || 0,
        details: record.details || []
    }));
};

const ClocklogContainer = ({
    selectedYear,
    selectedMonth,
    clockingRecords
}) => {
    const { token, analyzeClockingRecordsForMonth } = useContext(GlobalContext);
    const selectedBranch = useBranchStore((state) => state.selectedBranch);
    const selectedRegion = useBranchStore((state) => state.selectedRegion);

    const [loading, setLoading] = useState(true);
    const [analysisData, setAnalysisData] = useState([]);
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [detailOpen, setDetailOpen] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [shiftAssignments, setShiftAssignments] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);

                const startDate = moment([selectedYear, selectedMonth]).startOf('month').format('YYYY-MM-DD');
                const endDate = moment([selectedYear, selectedMonth]).endOf('month').format('YYYY-MM-DD');

                const [holidaysResponse, sickResponse, staffResponse] = await Promise.all([
                    fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holidays', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': token
                        },
                        body: JSON.stringify({
                            StartDate: startDate,
                            EndDate: endDate,
                            Branch: selectedBranch === 'ALL' ? null : selectedBranch
                        })
                    }),
                    fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Token': token
                        },
                        body: JSON.stringify({
                            StartDate: startDate,
                            EndDate: endDate,
                            Branch: selectedBranch === 'ALL' ? null : selectedBranch
                        })
                    }),
                    fetch('https://vision-web-api-test.azurewebsites.net/api/tma/staff', {
                        headers: { Token: token }
                    })
                ]);

                if (!holidaysResponse.ok) throw new Error(`Holidays API error: ${holidaysResponse.status}`);
                if (!sickResponse.ok) throw new Error(`Sick days API error: ${sickResponse.status}`);
                if (!staffResponse.ok) throw new Error(`Staff API error: ${staffResponse.status}`);

                const [holidays, sick, staffData] = await Promise.all([
                    holidaysResponse.json(),
                    sickResponse.json(),
                    staffResponse.json()
                ]);

                const filteredStaff = staffData.filter(staff =>
                    selectedBranch === 'ALL' ? true : staff.Branch === selectedBranch
                );
                setStaffList(filteredStaff);

                const [shiftAssignmentsResponse, shiftsResponse] = await Promise.all([
                    fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shiftassignments', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }),
                    fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shifts', {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                ]);

                if (!shiftAssignmentsResponse.ok) throw new Error(`Shift assignments API error: ${shiftAssignmentsResponse.status}`);
                if (!shiftsResponse.ok) throw new Error(`Shifts API error: ${shiftsResponse.status}`);

                const [shiftAssignments, shifts] = await Promise.all([
                    shiftAssignmentsResponse.json(),
                    shiftsResponse.json()
                ]);

                setShiftAssignments(shiftAssignments);
                setShifts(shifts);

                const analysis = analyzeClockingRecordsForMonth(
                    clockingRecords,
                    filteredStaff,
                    shiftAssignments,
                    shifts,
                    holidays,
                    sick,
                    selectedMonth + 1,
                    selectedYear,
                    []
                );

                const filteredAnalysis = filterOutFutureDates(analysis || []);
                setAnalysisData(filteredAnalysis);

            } catch (error) {
                console.error('Error in fetchData:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchData();
        }
    }, [token, selectedMonth, selectedYear, selectedBranch, selectedRegion, analyzeClockingRecordsForMonth, clockingRecords]);

    const handleDetailClose = () => {
        setDetailOpen(false);
        setSelectedDetail(null);
    };

    const handleRowClick = (detail, day) => {
        setSelectedDetail({
            ...detail,
            date: day.date,
            parentDay: day
        });
        setDetailOpen(true);
    };

    if (error) {
        return (
            <Box sx={{ p: 2, color: 'error.main' }}>
                Error: {error}
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 200px)', bgcolor: '#121212' }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <ClocklogTable
                    data={analysisData}
                    loading={loading}
                    onRowClick={handleRowClick}
                />
            </Box>

            {detailOpen && (
                <Box sx={{
                    width: 400,
                    borderLeft: 1,
                    borderColor: 'divider',
                    transform: detailOpen ? 'translateX(0)' : 'translateX(100%)',
                    transition: 'transform 0.3s ease-in-out'
                }}>
                    <ClocklogDetails
                        detail={selectedDetail}
                        onClose={handleDetailClose}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ClocklogContainer;
