import React, { useContext, useEffect, useState, useRef } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const NewsFlash = ({ setLastUpdated }) => {
  const { token, operatorData } = useContext(GlobalContext)
  const [newsflashes, setNewsflashes] = useState([])
  const marqueeRef = useRef(null)
  const loopCount = useRef(0)
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const currentMessage = newsflashes[currentMessageIndex]?.Message || ''
  const animationDuration = Math.max(4000, currentMessage.length * 150)

  useEffect(() => {
    const fetchNewsflashes = async () => {
      try {
        const response = await fetch(
          `https://spar.connectplus.parts/api/salesassist/cplusassist/newsflashes?branch=${operatorData.Branch}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        const data = await response.json()
        setNewsflashes(data)
      } catch (error) {
        console.error('Error fetching newsflashes:', error)
      }
    }

    fetchNewsflashes()

    const interval = setInterval(() => {
      fetchNewsflashes()
      setLastUpdated(moment().format('ddd DD/MM/YYYY HH:mm'))
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [token, operatorData.Branch])

  useEffect(() => {
    if (!marqueeRef.current) return

    const handleAnimationEnd = () => {
      loopCount.current += 1
      if (loopCount.current >= 3) {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % newsflashes.length)
        loopCount.current = 0
      }
    }

    const messageElement = marqueeRef.current
    messageElement.addEventListener('animationiteration', handleAnimationEnd)

    return () => {
      messageElement.removeEventListener('animationiteration', handleAnimationEnd)
    }
  }, [newsflashes, currentMessageIndex, marqueeRef.current])

  const handlePrevMessage = () => {
    setCurrentMessageIndex((prevIndex) => (prevIndex - 1 + newsflashes.length) % newsflashes.length)
  }

  const handleNextMessage = () => {
    setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % newsflashes.length)
  }

  return (
    <div className="flex items-center space-x-2">
      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
      <div className="flex-1">
        <div
          className="w-full text-lg font-bold text-black uppercase relative h-[44px] overflow-hidden"
          style={{ background: '#FDE600' }}
        >
          <div
            ref={marqueeRef}
            key={currentMessage}
            className="absolute inset-0 flex items-center justify-center"
            style={{
              animation: `marquee ${animationDuration}ms linear infinite`
            }}
          >
            {currentMessage}
          </div>
        </div>
      </div>
      <div className="flex-none">
        <div className="flex flex-col justify-around items-center text-white w-[50px]">
          <div className="flex items-center space-x-1">
            <FontAwesomeIcon icon={faEnvelope} />
            <div>
              {currentMessageIndex + 1 || 0}/{(Array.isArray(newsflashes) && newsflashes.length) || 0}
            </div>
          </div>
          <div className="space-x-1">
            <FontAwesomeIcon icon={faChevronLeft} style={{ cursor: 'pointer' }} onClick={handlePrevMessage} />
            <FontAwesomeIcon icon={faChevronRight} style={{ cursor: 'pointer' }} onClick={handleNextMessage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsFlash
