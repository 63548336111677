import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mantine/core'
import { Form, useForm } from 'react-hook-form'
import { TextInput, NativeSelect } from 'react-hook-form-mantine'
import yup from '../lib/yup'
import useApi from '../hooks/useApi'
import haxios from '../lib/haxios'
import { errorToast, successToast } from '../lib/helpers'
import { useState } from 'react'
import MultiButton from '../gsys-ui/MultiButton/MultiButton'
import Button from '../components/Button'

const validation = yup
  .object()
  .shape({
    ShiftName: yup.string().required('Shift name is required'),
    Start: yup.string().required('Start time is required'),
    Finish: yup.string().required('End time is required'),
    ShiftId: yup.string()
  })
  .required()

export const ShiftPatternModal = ({ context, id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('ADD')
  const {
    data: shifts = [],
    isLoading: isLoadingData,
    refetch
  } = useApi({
    url: '/salesassist/cplusassist/shifts'
  })

  const { control, reset } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      ShiftName: '',
      Start: '',
      Finish: '',
      ShiftId: ''
    }
  })

  const handleSubmit = async (data) => {
    // Check for duplicate names
    const isDuplicate = shifts.some(
      (shift) => shift.ShiftName.toLowerCase() === data.ShiftName.toLowerCase() && shift._id !== data.ShiftId
    )

    if (isDuplicate) {
      errorToast('A shift with this name already exists.')
      return
    }

    setIsLoading(true)

    try {
      const payload = {
        ShiftName: data.ShiftName,
        Start: data.Start,
        Finish: data.Finish
      }

      if (mode === 'EDIT') {
        await haxios.put(`/salesassist/cplusassist/shifts/${data.ShiftId}`, payload)
      } else {
        await haxios.post('/salesassist/cplusassist/shifts', payload)
      }

      await refetch()
      context.closeModal(id)
      successToast(`Shift pattern ${mode === 'EDIT' ? 'updated' : 'created'} successfully.`)
    } catch (e) {
      setIsLoading(false)
      errorToast('An error has occurred.')
      console.log(e)
    }
  }

  const handleShiftSelect = (value) => {
    const shift = shifts.find((s) => s._id === value)
    if (shift) {
      // Reset form with the selected shift's values
      reset({
        ShiftId: shift._id,
        ShiftName: shift.ShiftName,
        Start: shift.Start,
        Finish: shift.Finish
      })
    } else {
      // Reset form to default values if no shift is selected
      reset({
        ShiftId: '',
        ShiftName: '',
        Start: '',
        Finish: ''
      })
    }
  }

  return (
    <Form control={control} onSubmit={(e) => handleSubmit(e.data)} onError={(e) => console.log(e)}>
      <Grid gutter="xs" className="mt-1">
        <Grid.Col span={12}>
          <MultiButton
            value={mode}
            options={[
              { label: 'Add new', value: 'ADD' },
              { label: 'Edit existing', value: 'EDIT' }
            ]}
            onChange={(newMode) => {
              setMode(newMode)
              // Reset form when switching modes
              reset({
                ShiftId: '',
                ShiftName: '',
                Start: '',
                Finish: ''
              })
            }}
          />
        </Grid.Col>
        {mode === 'EDIT' && (
          <>
            <Grid.Col span={12}>
              <div className="p-2 text-sm font-normal leading-snug text-red-900 bg-red-300 rounded-md">
                Note: This will modify shift times for all staff that this shift pattern is assigned to.
              </div>
            </Grid.Col>
            <Grid.Col span={12}>
              <NativeSelect
                placeholder="Choose shift"
                label="Select shift to edit"
                name="ShiftId"
                control={control}
                data={[
                  { value: '', label: '' },
                  ...(shifts || []).map((el) => ({
                    label: `[${el.ShiftName}] ${el.Start} - ${el.Finish}`,
                    value: el._id
                  }))
                ]}
                onChange={(event) => handleShiftSelect(event.target.value)}
              />
            </Grid.Col>
          </>
        )}
        <Grid.Col span={12}>
          <TextInput label="Shift name" name="ShiftName" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput type="time" label="Start time" name="Start" control={control} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput type="time" label="End time" name="Finish" control={control} />
        </Grid.Col>
        <Grid.Col span={12} />
        <Grid.Col span={8} />
        <Grid.Col span={4}>
          <Button loading={isLoading} type="submit" fullWidth>
            {mode === 'EDIT' ? 'Update shift' : 'Create shift'}
          </Button>
        </Grid.Col>
      </Grid>
    </Form>
  )
}

export default ShiftPatternModal
