import React, { useContext, useEffect, useState } from 'react';
import { formatCurrency } from '../../utils';
import '../../App.css';
import { GlobalContext } from '../../context/GlobalContext';
import * as XLSX from 'xlsx';

function BranchListTable({ operatorData, drillDownBranch, setDrillDownBranch, progress, setLastUpdated, setProgress }) {
  const { token, isToday, setIsToday } = useContext(GlobalContext);
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const [dataForTable, setDataForTable] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [todayData, setTodayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [monthlyRunRate, setMonthlyRunRate] = useState([]);

  const maxPage = Math.ceil(dataForTable.length / itemsPerPage);

  function formatNumber(num) {
    if (num == null) return 'N/A';
    const maxDigits = isToday ? 2 : 0;
    return `£${num.toLocaleString(undefined, {
      minimumFractionDigits: maxDigits,
      maximumFractionDigits: maxDigits
    })}`;
  }

  function safeFormat(num, isPercentage = false) {
    if (num == null) return 'N/A';
    if (isPercentage) {
      return `${num.toFixed(2)}%`;
    } else {
      return `£${num.toFixed(isToday ? 2 : 0)}`;
    }
  }

  const countWorkingDaysUntil = (year, month, day, saturday, sunday) => {
    let workingDays = 0;
    let totalWorkingDays = 0;
    const daysInMonth = new Date(year, month, 0).getDate();

    for (let d = 1; d <= daysInMonth; d++) {
      const weekday = new Date(year, month - 1, d).getDay();
      if ((weekday === 6 && saturday) || (weekday === 0 && sunday) || (weekday >= 1 && weekday <= 5)) {
        totalWorkingDays++;
        if (d <= day) {
          workingDays++;
        }
      }
    }

    return { workingDays, totalWorkingDays };
  };

  const transformData = (data, monthlyData = [], isToday) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const monthlyItem = monthlyData.find(m => m.branch === item.branch) || item;
      const { workingDays, totalWorkingDays } = countWorkingDaysUntil(year, month, day, item.saturdayTrade, item.sundayTrade);

      const salesTarget = item.salesTarget || 0;
      const currentTarget = isToday ? salesTarget : totalWorkingDays * salesTarget; // Adjust target based on `isToday`
      const totalSales = (item.totalSales || 0) + (item.totalCredits || 0);
      const salesDiff = totalSales - currentTarget;
      const salesDiffPercent = currentTarget !== 0 ? (totalSales / currentTarget) * 100 : 0;
      const totalMarginPercent = totalSales !== 0 ? ((item.totalMargin || 0) + (item.creditsMargin || 0)) / totalSales * 100 : 0;

      const remainingSales = Math.max(0, currentTarget - totalSales);
      const remainingDays = Math.max(1, totalWorkingDays - workingDays);
      const requiredRunRate = remainingSales / remainingDays;
      const newRunRate = Math.max(requiredRunRate, salesTarget);

      const progressPercent = salesDiffPercent;

      return {
        branch: item.branch || 'Unknown',
        totalSales: totalSales,
        currentTarget: currentTarget,  // Adjusted target shown here
        poundDiff: salesDiff,
        percentDiff: salesDiffPercent,
        totalMarginPound: (item.totalMargin || 0) + (item.creditsMargin || 0),
        totalMarginPercent: totalMarginPercent,
        marginTarget: item.marginTarget || 0,
        runRate: newRunRate,
        dailyTarget: salesTarget,
        progressPercent: progressPercent,
        quotesCount: item.quotesCount || 0,
        quotesParts: item.quotesParts || 0,
        quotesValue: item.quotesValue || 0
      };
    });
  };

  const handleDownload = () => {
    const formattedData = dataForTable.map(item => ({
      branch: item.branch,
      totalSales: formatNumber(item.totalSales),
      currentTarget: formatNumber(item.currentTarget),
      poundDiff: formatNumber(item.poundDiff),
      percentDiff: safeFormat(item.percentDiff, true),
      totalMarginPound: formatNumber(item.totalMarginPound),
      totalMarginPercent: safeFormat(item.totalMarginPercent, true),
      marginTarget: safeFormat(item.marginTarget * 100, true),
      quotesCount: item.quotesCount,
      quotesParts: item.quotesParts,
      quotesValue: formatNumber(item.quotesValue)
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    const currencyColumns = ['B', 'C', 'D', 'F', 'K'];
    currencyColumns.forEach(col => {
      for (let i = 2; i <= formattedData.length + 1; i++) {
        const cell = worksheet[`${col}${i}`];
        if (cell && cell.v !== 'N/A') {
          cell.z = isToday ? '£#,##0.00' : '£#,##0';
        }
      }
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, 'branch_list.xlsx');
  };

  const saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  };

  const fetchMonthlyData = async () => {
    try {
      let url = operatorData.Status === 'rm'
        ? `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranchCurrentMonth?regionalManager=${encodeURIComponent(operatorData.Name)}`
        : 'https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranchCurrentMonth';

      const response = await fetch(url, { headers: { 'Authorization': `Bearer ${token}` } });
      if (!response.ok) throw new Error('Failed to fetch monthly data');
      const data = await response.json();

      return operatorData.Status === 'bm'
        ? data.filter(branch => branch.branch === operatorData.Branch)
        : data;
    } catch (error) {
      console.error('Error fetching monthly data:', error);
      setFetchError('Failed to fetch monthly data. Please try again later.');
      return [];
    }
  };

  const fetchTodayData = async () => {
    try {
      let url = operatorData.Status === 'rm'
        ? `https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranchToday?regionalManager=${encodeURIComponent(operatorData.Name)}`
        : 'https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranchToday';

      const response = await fetch(url, { headers: { 'Authorization': `Bearer ${token}` } });
      if (!response.ok) throw new Error('Failed to fetch today data');
      const data = await response.json();

      return operatorData.Status === 'bm'
        ? data.filter(branch => branch.branch === operatorData.Branch)
        : data;
    } catch (error) {
      console.error('Error fetching today data:', error);
      setFetchError('Failed to fetch today data. Please try again later.');
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setFetchError(null);
      const monthlyData = await fetchMonthlyData();
      const todayData = await fetchTodayData();

      setMonthlyData(transformData(monthlyData, [], false));
      setTodayData(transformData(todayData, monthlyData, true));

      setLoading(false);
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const displayedData = isToday ? todayData : monthlyData;
    setDataForTable(displayedData.sort(sortData));
  }, [isToday, todayData, monthlyData]);

  const handleSort = (field) => {
    const fieldMap = {
      'Branch': 'branch',
      'Sales': 'totalSales',
      'Target': 'currentTarget',
      '£ Diff': 'poundDiff',
      '% Diff': 'percentDiff',
      'Margin £': 'totalMarginPound',
      'Margin %': 'totalMarginPercent',
      'Margin Target': 'marginTarget',
      'Quotes': 'quotesCount',
      'Parts on Q': 'quotesParts',
      'Quotes Val': 'quotesValue'
    };

    setSortField(fieldMap[field]);
    setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const sortData = (a, b) => {
    if (!sortField) return 0;

    let valA = a[sortField];
    let valB = b[sortField];

    if (typeof valA === 'string') {
      return sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    }

    return sortDirection === 'asc' ? valA - valB : valB - valA;
  };

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return dataForTable.slice(startIndex, endIndex);
  };

  const columnWidths = {
    branch: '12%',
    totalSales: '10%',
    currentTarget: '10%',
    poundDiff: '8%',
    percentDiff: '8%',
    totalMarginPound: '10%',
    totalMarginPercent: '8%',
    marginTarget: '8%',
    quotesCount: '8%',
    quotesParts: '9%',
    quotesValue: '9%',
  };

  return (
    <div style={{}}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <button style={styles.butts} onClick={handleDownload}>
          Download as Excel
        </button>
        {loading && <p style={{ marginLeft: '10px', color: 'red' }}>Fetching data...</p>}
        {fetchError && <p style={{ marginLeft: '10px', color: 'red' }}>{fetchError}</p>}
      </div>
      <table style={styles.table}>
        <colgroup>
          {Object.values(columnWidths).map((width, index) => (
            <col key={index} style={{ width }} />
          ))}
        </colgroup>
        <thead style={styles.thead}>
          <tr>
            {['Branch', 'Sales', 'Target', '£ Diff', '% Diff', 'Margin £', 'Margin %', 'Margin Target', 'Quotes', 'Parts on Q', 'Quotes Val'].map((key) => (
              <th
                key={key}
                style={styles.th}
                onClick={() => handleSort(key)}
              >
                {key}
              </th>
            ))}
          </tr>
        </thead>
      </table>

      <div style={styles.scrollableTableBody}>
        <table style={styles.table}>
          <colgroup>
            {Object.values(columnWidths).map((width, index) => (
              <col key={index} style={{ width }} />
            ))}
          </colgroup>
          <tbody>
            {currentTableData().map((item) => {
              const percentage = Math.min(100, Math.max(0, item.progressPercent));

              return (
                <React.Fragment key={item.branch}>
                  <tr className="tr" style={styles.tr} onClick={() => setDrillDownBranch(item.branch)}>
                    <td style={styles.td}>{item.branch}</td>
                    <td style={styles.td}>{formatNumber(item.totalSales)}</td>
                    <td style={styles.td}>{formatNumber(item.currentTarget)}</td>
                    <td style={{ ...styles.td, color: 'white', backgroundColor: item.poundDiff > 0 ? 'darkgreen' : 'darkred' }}>{formatNumber(item.poundDiff)}</td>
                    <td style={{ ...styles.td, color: 'white', backgroundColor: item.percentDiff > 0 ? 'darkgreen' : 'darkred' }}>{safeFormat(item.percentDiff, true)}</td>
                    <td style={styles.td}>{formatNumber(item.totalMarginPound)}</td>
                    <td style={styles.td}>{safeFormat(item.totalMarginPercent, true)}</td>
                    <td style={styles.td}>{safeFormat(item.marginTarget * 100, true)}</td>
                    <td style={styles.td}>{item.quotesCount}</td>
                    <td style={styles.td}>{item.quotesParts}</td>
                    <td style={styles.td}>{formatNumber(item.quotesValue)}</td>
                  </tr>
                  <tr style={{ color: 'white', background: 'linear-gradient(to right, red, green)' }}>
                    <td style={{ position: 'relative', margin: 10, fontWeight: 'bold', textTransform: 'uppercase' }} colSpan="11">
                      <div style={{ zIndex: 100, marginLeft: 10, marginRight: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: 'white' }}>
                        <p style={{ margin: 0, color: 'white', zIndex: 100 }}>
                          Required run rate: {formatNumber(monthlyRunRate.find(m => m.branch === item.branch)?.runRate || item.runRate)}
                        </p>
                        <p style={{ margin: 0, color: 'white', zIndex: 100 }}>{safeFormat(percentage, true)}</p>
                        <p style={{ margin: 0, zIndex: 100 }}>Daily target: {formatNumber(item.dailyTarget)}</p>
                      </div>
                      <div style={{ fontSize: 1, lineHeight: 20, backgroundColor: 'black', position: 'absolute', top: '50%', right: 0, width: `${100 - percentage}%`, transform: 'translateY(-50%)' }}>
                        .
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const styles = {
  butts: { borderRadius: 5, padding: 5 },
  table: { width: '100%', borderCollapse: 'collapse' },
  thead: { backgroundColor: '#21242c', textAlign: 'center', cursor: 'pointer', color: 'white' },
  th: { padding: '10px', border: '1px solid #ddd', textAlign: 'center' },
  tr: {
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  td: { padding: '8px', border: '1px solid #ddd', textAlign: 'center', color: 'black', fontWeight: 'bold', whiteSpace: 'nowrap' },
  pagination: { display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0', color: 'white', width: '30%', justifyContent: 'space-around', position: 'absolute', bottom: 50 },
  scrollableTableBody: { overflowY: 'auto', height: '75vh' },
};

export default BranchListTable;
