import TimeAgo from 'react-timeago'
import englishStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

const customStrings = {
  ...englishStrings,
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '',
  suffixFromNow: 'from now',
  seconds: 'just now',
  minute: '1 minute ago',
  minutes: '%d minutes ago',
  hour: '1 hour ago',
  hours: '%d hours ago',
  day: '1 day ago',
  days: '%d days ago',
  month: '1 month ago',
  months: '%d months ago',
  year: '1 year ago',
  years: '%d years ago'
}

const formatter = buildFormatter(customStrings)

const Time = ({ date }) => <TimeAgo date={date} formatter={formatter} />

export default Time
