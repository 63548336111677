import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Icon } from '../gsys-ui'
import { useState } from 'react'
import Button from '../components/Button'

export const WarningModal = ({ context, id, innerProps }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    await innerProps.submit()
    setIsLoading(false)
    context.closeModal(id)
  }

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="p-3 bg-red-200 rounded-full">
        <Icon Comp={ExclamationCircleIcon} size={16} className="text-red-700" />
      </div>
      <div className="text-center">{innerProps.message}</div>
      <div className="font-bold">This cannot be undone!</div>
      <div className="flex justify-between items-center w-full">
        <Button onClick={() => context.closeModal(id)}>Cancel</Button>
        <Button loading={isLoading} color="red" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  )
}

export default WarningModal
