import React, { useContext, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import GELeads from '../GELeads'
import GEDash from '../GEDash'
import GELeadContainer from '../GELeadContainer'

function GETab() {
    const { operatorData, username, token } = useContext(GlobalContext)
    const [geState, setGEState] = useState(true)
    return (
        <div style={{ height: 'calc(100vh - 170px)', maxHeight: 'calc(100vh - 170px)', overflow: 'hidden' }}>
            <GELeadContainer geState={geState} setGEState={setGEState} />
        </div>
    )
}

export default React.memo(GETab)
