import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import BranchDetails from './BranchDetails';
import PerformanceTab from '../tabs/PerformanceTab';
import StaffDetails from './StaffDetails';
import QuotesHolder from './QuotesHolder';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function BranchHolder({ operatorData, token, drillDownBranch, setDrillDownBranch, progress, setLastUpdated, setProgress }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', width: '100%' }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Branch Holder Tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Quotes" {...a11yProps(0)} />
                <Tab label="Branch Details" {...a11yProps(1)} />


                {/* <Tab label="Attendance" {...a11yProps(2)} />
                <Tab label="Staff Details" {...a11yProps(3)} /> */}
            </Tabs>
            <TabPanel style={{ width: '85%' }} value={value} index={0}>
                <QuotesHolder drillDownBranch={drillDownBranch} setDrillDownBranch={setDrillDownBranch} progress={progress} setLastUpdated={setLastUpdated} setProgress={setProgress} />

            </TabPanel>
            <TabPanel value={value} index={1} style={{ width: '100%' }}>
                <BranchDetails
                    drillDownBranch={drillDownBranch}
                    setDrillDownBranch={setDrillDownBranch}
                    progress={progress}
                    setLastUpdated={setLastUpdated}
                    setProgress={setProgress} />
            </TabPanel>


            {/* <TabPanel value={value} index={2}>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <StaffDetails setDrillDownBranch={setDrillDownBranch} setLastUpdated={setLastUpdated} token={token} drillDownBranch={drillDownBranch} progress={progress} setProgress={setProgress} />
            </TabPanel> */}
        </Box>
    );
}

export default BranchHolder;