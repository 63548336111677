import { useVirtualizer } from '@tanstack/react-virtual'
import { useEffect, useRef } from 'react'
import clsx from 'clsx'

const VirtualizedScrollingContainer = ({ data, height, RenderElem, className, childProps, overscan = 5 }) => {
  const parentRef = useRef(null)
  const virtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => height,
    overscan
  })

  return (
    <div ref={parentRef} className={clsx('overflow-y-scroll relative h-full', className)}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative'
        }}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const rowData = data[virtualRow.index]
          return (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`
              }}
            >
              <RenderElem data={rowData} {...childProps} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default VirtualizedScrollingContainer
