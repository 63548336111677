import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../context/GlobalContext'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  Box,
  Chip,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment-timezone'

const MyHolidays = () => {
  const { token, operatorData } = useContext(GlobalContext)
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1)
  const [selectedYear, setSelectedYear] = useState(moment().year())
  const [requests, setRequests] = useState([])
  const [loading, setLoading] = useState(true)
  const [allocationLoading, setAllocationLoading] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const [newRequest, setNewRequest] = useState({
    startDate: '',
    endDate: '',
    reason: ''
  })
  const [editingRequest, setEditingRequest] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [requestToDelete, setRequestToDelete] = useState(null)
  const [error, setError] = useState('')
  const [allocation, setAllocation] = useState(null)
  const [bookedDays, setBookedDays] = useState(0)

  useEffect(() => {
    fetchHolidayRequests()
    fetchAllocation()
    fetchBookedHolidays()
  }, [selectedMonth, selectedYear])

  const fetchAllocation = async () => {
    try {
      setAllocationLoading(true)
      const response = await fetch(
        `https://spar.connectplus.parts/api/salesassist/cplusassist/holidayallocations/${operatorData.staffId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      if (!response.ok) throw new Error('Failed to fetch allocation')

      const allocations = await response.json()
      // Since we're already filtering by staffId in the API, just take the first allocation
      setAllocation(allocations[0])
    } catch (error) {
      console.error('Error fetching allocation:', error)
      setError('Failed to load holiday allocation')
    } finally {
      setAllocationLoading(false)
    }
  }

  const fetchBookedHolidays = async () => {
    try {
      const startDate = moment([selectedYear, 0, 1]).format('YYYY-MM-DD')
      const endDate = moment([selectedYear, 11, 31]).format('YYYY-MM-DD')

      const response = await fetch('https://vision-web-api-test.azurewebsites.net/api/tma/holidays', {
        method: 'POST',
        headers: {
          'Token': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          StartDate: startDate,
          EndDate: endDate,
          StaffID: operatorData.staffId
        })
      })

      if (!response.ok) throw new Error('Failed to fetch booked holidays')

      const holidays = await response.json()
      const totalBooked = Array.isArray(holidays)
        ? holidays.reduce((total, holiday) => {
            const start = moment(holiday.StartDatetime)
            const end = moment(holiday.EndDatetime)
            return total + end.diff(start, 'days') + 1
          }, 0)
        : 0

      setBookedDays(totalBooked)
    } catch (error) {
      console.error('Error fetching booked holidays:', error)
      setError('Failed to load booked holidays')
      setBookedDays(0)
    }
  }

  const fetchHolidayRequests = async () => {
    try {
      setLoading(true)
      const response = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/holidayrequests', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log('API Response:', data)

      if (!Array.isArray(data)) {
        console.error('Expected array but got:', typeof data)
        setRequests([])
        return
      }

      const filteredRequests = data.filter((req) => {
        const requestDate = moment(req.dtStart)
        return (
          req.staffId === operatorData.staffId &&
          requestDate.month() + 1 === selectedMonth &&
          requestDate.year() === selectedYear
        )
      })
      setRequests(filteredRequests)
    } catch (error) {
      console.error('Error fetching holiday requests:', error)
      setError('Failed to load holiday requests')
      setRequests([])
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitRequest = async () => {
    if (!newRequest.startDate || !newRequest.endDate || !newRequest.reason) {
      setError('Please fill in all fields')
      return
    }

    try {
      const requestBody = {
        staffId: operatorData.staffId,
        dtStart: moment(newRequest.startDate).format('YYYY-MM-DD'),
        dtEnd: moment(newRequest.endDate).format('YYYY-MM-DD'),
        reason: newRequest.reason,
        requestDate: moment().format('YYYY-MM-DD'),
        decision: 'PENDING',
        Notes: ''
      }

      const url = editingRequest
        ? `https://spar.connectplus.parts/api/salesassist/cplusassist/holidayrequests/${editingRequest._id}`
        : 'https://spar.connectplus.parts/api/salesassist/cplusassist/holidayrequests'

      const method = editingRequest ? 'PUT' : 'POST'

      const response = await fetch(url, {
        method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      })

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Failed to submit request')
      }

      setOpenDialog(false)
      setNewRequest({ startDate: '', endDate: '', reason: '' })
      setEditingRequest(null)
      fetchHolidayRequests()
      setError('')
    } catch (error) {
      console.error('Error submitting request:', error)
      setError(error.message || 'Failed to submit holiday request')
    }
  }

  const handleEditRequest = (request) => {
    setEditingRequest(request)
    setNewRequest({
      startDate: moment(request.dtStart).format('YYYY-MM-DD'),
      endDate: moment(request.dtEnd).format('YYYY-MM-DD'),
      reason: request.reason
    })
    setOpenDialog(true)
  }

  const handleDeleteRequest = async () => {
    if (!requestToDelete) return

    try {
      const response = await fetch(
        `https://spar.connectplus.parts/api/salesassist/cplusassist/holidayrequests/${requestToDelete._id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Failed to delete request')
      }

      setOpenDeleteDialog(false)
      setRequestToDelete(null)
      fetchHolidayRequests()
    } catch (error) {
      console.error('Error deleting request:', error)
      setError('Failed to delete holiday request')
    }
  }

  if (!operatorData.staffId) {
    return (
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        You don't have a staff id setup, please contact your manager / supervisor
      </Box>
    )
  }

  const totalRequests = requests.length
  const rejectedRequests = requests.filter((request) => request.decision === 'REJECTED').length
  const approvedRequests = requests.filter((request) => request.decision === 'APPROVED').length
  const pendingRequests = requests.filter((request) => request.decision === 'PENDING').length

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 2,
          height: 70,
          width: '100%'
        }}
      >
        {/* Holiday Allocation Summary */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flex: 1,
            justifyContent: 'left',
            alignItems: 'center',
            alignContent: 'center'
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              padding: '8px 16px',
              marginLeft: '16px',
              borderRadius: '4px',
              color: 'white'
            }}
          >
            {allocationLoading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} sx={{ color: 'white' }} />
                <Typography>Loading allocation...</Typography>
              </Box>
            ) : !allocation ? (
              <Typography>No allocation found for {selectedYear}</Typography>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-around',
                  alignContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Box style={{ display: 'flex', flex: 1 }}>
                  <Typography variant="h6" component="span">
                    {bookedDays}/{allocation.allocation} days booked
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      ml: 1,
                      color: 'rgba(255,255,255,0.7)',
                      fontSize: '0.9em'
                    }}
                  >
                    ({allocation.allocation - bookedDays} remaining)
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                  <span className="ml-4">Total: {totalRequests}</span>
                  <span className="ml-4">Rejected: {rejectedRequests}</span>
                  <span className="ml-4">Approved: {approvedRequests}</span>
                  <span className="ml-4">Pending: {pendingRequests}</span>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          sx={{
            'height': 36,
            'bgcolor': '#444',
            'color': 'white',
            '& .MuiSelect-icon': { color: 'white' }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                'bgcolor': '#444',
                'color': 'white',
                '& .MuiMenuItem-root': {
                  'color': 'white',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                  }
                }
              }
            }
          }}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {moment().month(i).format('MMMM')}
            </MenuItem>
          ))}
        </Select>

        <Select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          sx={{
            'height': 36,
            'bgcolor': '#444',
            'color': 'white',
            '& .MuiSelect-icon': { color: 'white' }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                'bgcolor': '#444',
                'color': 'white',
                '& .MuiMenuItem-root': {
                  'color': 'white',
                  '&:hover': {
                    bgcolor: 'rgba(255, 255, 255, 0.1)'
                  }
                }
              }
            }
          }}
        >
          {Array.from({ length: 3 }, (_, i) => (
            <MenuItem key={moment().year() - 1 + i} value={moment().year() - 1 + i}>
              {moment().year() - 1 + i}
            </MenuItem>
          ))}
        </Select>

        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditingRequest(null)
            setNewRequest({ startDate: '', endDate: '', reason: '' })
            setOpenDialog(true)
          }}
          sx={{ height: 36 }}
        >
          NEW REQUEST
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ bgcolor: '#444', width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: 'white', fontWeight: 500 }}>Start Date</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 500 }}>End Date</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 500 }}>Days</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 500 }}>Reason</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 500 }}>Status</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 500 }}>Notes</TableCell>
                <TableCell sx={{ color: 'white', fontWeight: 500 }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    sx={{
                      textAlign: 'center',
                      color: 'rgba(255, 255, 255, 0.7)',
                      height: '100px'
                    }}
                  >
                    No holiday requests found for this period
                  </TableCell>
                </TableRow>
              ) : (
                requests.map((request) => (
                  <TableRow key={request._id}>
                    <TableCell sx={{ color: 'white' }}>{moment(request.dtStart).format('DD/MM/YYYY')}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{moment(request.dtEnd).format('DD/MM/YYYY')}</TableCell>
                    <TableCell sx={{ color: 'white' }}>
                      {moment(request.dtEnd).diff(moment(request.dtStart), 'days') + 1}
                    </TableCell>
                    <TableCell sx={{ color: 'white' }}>{request.reason}</TableCell>
                    <TableCell>
                      <Chip
                        label={request.decision}
                        color={
                          request.decision === 'APPROVED'
                            ? 'success'
                            : request.decision === 'REJECTED'
                            ? 'error'
                            : 'warning'
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ color: 'white' }}>{request.Notes}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title="Edit Request">
                          <IconButton size="small" onClick={() => handleEditRequest(request)} sx={{ color: 'white' }}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Request">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setRequestToDelete(request)
                              setOpenDeleteDialog(true)
                            }}
                            sx={{ color: 'white' }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Request Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
          setEditingRequest(null)
        }}
        PaperProps={{
          style: {
            backgroundColor: '#333',
            color: 'white',
            minWidth: '400px'
          }
        }}
      >
        <DialogTitle>{editingRequest ? 'Edit Request' : 'New Holiday Request'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Start Date"
              type="date"
              value={newRequest.startDate}
              onChange={(e) => setNewRequest((prev) => ({ ...prev, startDate: e.target.value }))}
              InputLabelProps={{ shrink: true }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  'color': 'white',
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' }
                },
                '& .MuiInputLabel-root': { color: 'white' }
              }}
            />
            <TextField
              label="End Date"
              type="date"
              value={newRequest.endDate}
              onChange={(e) => setNewRequest((prev) => ({ ...prev, endDate: e.target.value }))}
              InputLabelProps={{ shrink: true }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  'color': 'white',
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' }
                },
                '& .MuiInputLabel-root': { color: 'white' }
              }}
            />
            <TextField
              label="Reason"
              multiline
              rows={3}
              value={newRequest.reason}
              onChange={(e) => setNewRequest((prev) => ({ ...prev, reason: e.target.value }))}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  'color': 'white',
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' }
                },
                '& .MuiInputLabel-root': { color: 'white' }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false)
              setEditingRequest(null)
            }}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmitRequest} variant="contained">
            {editingRequest ? 'Update' : 'Submit'} Request
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: '#333',
            color: 'white'
          }
        }}
      >
        <DialogTitle>Delete Holiday Request</DialogTitle>
        <DialogContent>Are you sure you want to delete this holiday request?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button onClick={handleDeleteRequest} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default MyHolidays
