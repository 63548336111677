import axios from 'axios'
import join from 'url-join'

import { getApirAddr, getCompanyInfo } from './api'

let token = localStorage.getItem('haxtoken')
export let apir = null
export let cinfo = null
const haxios = axios.create()
const isAbsoluteURLRegex = /^(?:\w+:)\/\//

export const setToken = async (newToken) => {
  console.log('setToken', newToken)
  token = newToken
  localStorage.setItem('haxtoken', token)
}

export const getToken = async (username, password, shouldNavigate = true) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `https://api.aa-vision.com/api/authenticate`,
      data: { Username: username, Password: password },
      headers: { 'Content-Type': 'application/json' }
    })

    token = res.data.token
    localStorage.setItem('haxtoken', token)
    return res
  } catch (error) {
    console.error('Error getting token', error)

    if (error.response.status === 406 && shouldNavigate) {
      console.log('haxios 406')
      //if (window.location.pathname !== '/login') window.location.href = '/login'
    } else {
      //throw error
    }
  }
}

export const getCinfo = async () => {
  if (cinfo) return cinfo
  cinfo = await getCompanyInfo()
  return cinfo
}

export const getApir = async () => {
  if (apir) return apir
  if (!cinfo) return null
  const res = await getApirAddr(cinfo.CompanyID)

  if (window.location.hostname !== 'localhost') {
    if (window.location.hostname !== res.CompanyDomain) {
      //throw new Error('This is not your server.')
    }
  }

  apir = `https://${res.CompanyDomain}/api`
  console.log('APIR:', apir)

  if (!apir) {
    window.location.href = '/error'
  }

  return apir
}

haxios.interceptors.request.use(
  async (config) => {
    //if (!config.headers?.token && !token) await getToken()
    console.log('orig headers', config.headers)
    config.headers.token = config.headers?.token || token
    config.headers.Authorization = `Bearer ${config.headers?.token || token}`
    console.log('HAXIOS', config.url, config.headers.token)

    if (!isAbsoluteURLRegex.test(config.url)) {
      if (!apir) throw new Error('Tried APIR req before APIR URL loaded')
      config.url = join(apir, config.url)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

haxios.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error) => {
    //console.log(error)
    const originalRequest = error.config

    if (!error.response) {
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      //await getToken()
      //originalRequest.headers.token = token
      //originalRequest.headers.Authorization = `Bearer ${token}`
      return haxios(originalRequest)
    }

    if (error.response.status === 401) {
      console.log('haxios 401')
      //if (window.location.pathname !== '/login') window.location.href = '/login'
      return
    }

    return Promise.reject(error)
  }
)

export default haxios
