import { Select } from '@mantine/core'
import { Controller } from 'react-hook-form'
import useApi from '../hooks/useApi'
import { useContext, useState } from 'react'
import useBranchStore from '../stores/useBranchStore'
import { GlobalContext } from '../../../context/GlobalContext'

const StaffDropdown = ({ name, control, onSelectStaff, withHoliday = false }) => {
  const [disableSelect, setDisableSelect] = useState(false)
  const { operatorData } = useContext(GlobalContext)
  const regions = useBranchStore((state) => state.regions)

  const {
    data: staffData,
    isLoading: isStaffLoading,
    refetch: refetchStaff
  } = useApi({
    url: withHoliday ? '/salesassistnew/ustafferators/withHoliday' : '/salesassistnew/ustafferators'
  })

  if (!staffData || isStaffLoading) {
    return <Select value="loading" data={[{ value: 'loading', label: 'Loading...' }]} disabled label="Staff member" />
  }

  const staffSorted = staffData.sort((a, b) => {
    const branchComparison = a.Branch.localeCompare(b.Branch)

    if (branchComparison !== 0) {
      return branchComparison
    }

    const aFullName = `${a.FName} ${a.SName}`
    const bFullName = `${b.FName} ${b.SName}`
    return aFullName.localeCompare(bFullName)
  })

  let staffFiltered = staffSorted.map((b) => ({
    value: b.StaffId,
    label: `[${b.Branch}] ${b.FName} ${b.SName}`
  }))

  if (operatorData.Status === 'rm') {
    const rmBranches = (regions[operatorData.Name] || []).map((b) => b.MamCode)

    if (rmBranches.length === 0) {
      staffFiltered = [{ label: 'No assigned branches.', value: 'NONE' }]
      setDisableSelect(true)
    } else {
      staffFiltered = staffSorted
        .filter((s) => rmBranches.includes(s.Branch))
        .map((b) => ({
          value: b.StaffId,
          label: `[${b.Branch}] ${b.FName} ${b.SName}`
        }))
    }
  } else if (operatorData.Status === 'bm') {
    staffFiltered = staffSorted
      .filter((s) => s.Branch === operatorData.Branch)
      .map((b) => ({
        value: b.StaffId,
        label: `[${b.Branch}] ${b.FName} ${b.SName}`
      }))
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          error={error?.message}
          data={staffFiltered}
          searchable
          label="Staff member"
          nothingFound="No staff found"
          maxDropdownHeight={280}
          clearable={false}
          allowDeselect={false}
          disabled={disableSelect}
          onChange={(value) => {
            console.log(value)
            field.onChange(value)

            if (onSelectStaff) {
              const selectedStaffMember = staffData.find((staff) => staff.StaffId === value)
              onSelectStaff(selectedStaffMember)
            }
          }}
        />
      )}
    />
  )
}

export default StaffDropdown
