import React, { useContext, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { GlobalContext } from '../context/GlobalContext';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        maximumFractionDigits: 2,
    }).format(value);
};

const ColorLegend = ({ regionColors, branches, salesMarginData }) => {
    const getRegionTotals = (region) => {
        if (!branches || !salesMarginData) {
            return { totalBranches: 0, totalSales: 0, totalMargin: 0 };
        }

        const regionBranches = branches.filter((branch) => branch.Region === region);
        const regionSalesMarginData = salesMarginData.filter((data) =>
            regionBranches.some((branch) => data.branch.toUpperCase() === branch.MamCode.toUpperCase())
        );

        const totalBranches = regionBranches.length;
        const totalSales = regionSalesMarginData.reduce((sum, data) => sum + data.totalSales, 0);
        const totalMargin = regionSalesMarginData.reduce((sum, data) => sum + data.totalMargin, 0);

        return { totalBranches, totalSales, totalMargin };
    };

    return (
        <div style={{ height: '100%', flex: 0.5, backgroundColor: 'white', padding: 10, borderRadius: 5, marginLeft: 20 }}>
            <h3>Regions</h3>
            {Object.entries(regionColors).map(([region, color]) => {
                const { totalBranches, totalSales, totalMargin } = getRegionTotals(region);
                return (
                    <div key={region} style={{ paddingBottom: 15, display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                        <div
                            style={{
                                width: 15,
                                height: 15,
                                backgroundColor: color,
                                borderRadius: '50%',
                                marginRight: 5,
                            }}
                        />
                        <div>
                            <h4 style={{ margin: 0 }}>{region} ({totalBranches} branches){' '}</h4>
                            <h5 style={{ margin: 0 }}><strong>Total Sales:</strong> {formatCurrency(totalSales)}</h5>
                            <h5 style={{ margin: 0 }}><strong>Total Margin:</strong> {formatCurrency(totalMargin)}</h5>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};


const UKMap = () => {
    const [branches, setBranches] = useState([]);
    const [salesMarginData, setSalesMarginData] = useState([]);
    const [regionColors, setRegionColors] = useState({});
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [infoWindow, setInfoWindow] = useState(null);
    const { username, token, operatorData, setOperatorData, isToday, setIsToday } = useContext(GlobalContext);

    useEffect(() => {


        // Fetch branches from the API
        fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log('Branches:', data);
                setBranches(data);
            })
            .catch((error) => {
                console.error('Error fetching branches:', error);
            });

        // Fetch sales margin data from the new API
        fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/salesMarginByBranch', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                //console.log('Sales margin data:', data);
                setSalesMarginData(data);
            })
            .catch((error) => {
                console.error('Error fetching sales margin data:', error);
            });
    }, []);


    useEffect(() => {
        if (map && maps && branches.length > 0) {
            renderMarkers();
        }
    }, [map, maps, branches]);

    const createMapOptions = (maps) => {
        return {
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_BOTTOM,
                style: maps.ZoomControlStyle.SMALL,
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT,
            },
        };
    };

    const renderMarkers = () => {
        const geocoder = new maps.Geocoder();

        // Assign colors to regions
        const regionColors = {};
        const colorScale = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];
        let colorIndex = 0;
        branches.forEach((branch) => {
            const { Region } = branch;
            if (!regionColors[Region]) {
                regionColors[Region] = colorScale[colorIndex];
                colorIndex = (colorIndex + 1) % colorScale.length;
            }
        });

        // Store the regionColors in state
        setRegionColors(regionColors);

        // Display markers for each postcode
        branches.forEach((branch) => {
            const { Region, PCode, Branch, SalesTarget } = branch;
            const color = regionColors[Region];

            geocoder.geocode({ address: PCode }, (results, status) => {
                if (status === 'OK') {
                    const { lat, lng } = results[0].geometry.location;
                    createMarker({ lat: lat(), lng: lng() }, color, Branch, branch.MamCode, SalesTarget, Region);
                }
            });
        });
    };

    const createMarker = (coords, color, branch, MamCode, salesTarget, region) => {
        const marker = new maps.Marker({
            position: coords,
            map: map,
            icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 8,
                fillColor: color,
                fillOpacity: 1,
                strokeWeight: 1,
            },
        });

        // Add the marker to the markers state
        setMarkers((prevMarkers) => [...prevMarkers, marker]);

        // Find the corresponding sales margin data for the current branch
        const salesMarginDataForBranch = salesMarginData.find(
            (data) => data.branch.toUpperCase() === MamCode.toUpperCase()
        );

        // Create an info window for the marker
        const infoWindowContent = `
        <div style="font-family: Arial, sans-serif; font-size: 14px;">
            <strong>Region:</strong> ${region}<br>
            <strong>Branch:</strong> ${branch}<br>
            ${salesMarginDataForBranch
                ? `<strong>Total Sales:</strong> ${formatCurrency(salesMarginDataForBranch.totalSales)}<br>
                       <strong>Total Margin:</strong> ${formatCurrency(salesMarginDataForBranch.totalMargin)}`
                : ''
            }
        </div>
    `;

        const infoWindowOptions = {
            content: infoWindowContent,
            disableAutoPan: true,
        };

        const markerInfoWindow = new maps.InfoWindow(infoWindowOptions);

        // Show the info window when hovering over the marker
        marker.addListener('mouseover', () => {
            if (infoWindow) {
                infoWindow.close();
            }
            markerInfoWindow.open(map, marker);
            setInfoWindow(markerInfoWindow);
        });

        // Hide the info window when the mouse leaves the marker
        marker.addListener('mouseout', () => {
            markerInfoWindow.close();
        });
    };

    const clearMarkers = () => {
        markers.forEach((marker) => {
            marker.setMap(null);
        });
        setMarkers([]);
    };

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ height: '500px', width: '90%', flex: 1.5 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyD0iJOva-43FLdpuIG7N1GSOJ4fixfSmj0' }}
                    defaultCenter={{ lat: 54.0, lng: -2.0 }}
                    defaultZoom={6}
                    options={createMapOptions}
                    onGoogleApiLoaded={({ map, maps }) => {
                        setMap(map);
                        setMaps(maps);
                    }}
                    yesIWantToUseGoogleMapApiInternals
                />
            </div>
            {branches.length > 0 && salesMarginData.length > 0 && (
                <ColorLegend regionColors={regionColors} branches={branches} salesMarginData={salesMarginData} />
            )}
        </div>
    );
};

export default UKMap;