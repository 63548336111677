import React, { useMemo } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import moment from 'moment-timezone'

const LateLeagueTable = ({ clockingData, shifts, shiftAssignments, isLoading }) => {
  const branchScores = useMemo(() => {
    if (!clockingData?.length || !shifts?.length || !shiftAssignments?.length) return []

    const branchStats = {}

    clockingData.forEach((record) => {
      const branch = record.StaffBranch
      if (!branchStats[branch]) {
        branchStats[branch] = {
          totalShifts: 0,
          lateClockIns: 0,
          earlyClockOuts: 0,
          totalLateMinutes: 0,
          totalEarlyMinutes: 0,
          records: []
        }
      }

      const assignment = shiftAssignments.find((a) => a.staffId === record.StaffID)
      const dayOfWeek = moment(record.ClockInTime).isoWeekday()
      const shift = assignment ? shifts.find((s) => s._id === assignment.shifts[dayOfWeek]) : null

      if (shift) {
        branchStats[branch].totalShifts++
        const shiftStart = moment(`${record.ClockInTime.split('T')[0]}T${shift.Start}`)
        const shiftEnd = moment(`${record.ClockInTime.split('T')[0]}T${shift.Finish}`)
        if (shiftEnd.isBefore(shiftStart)) shiftEnd.add(1, 'day')

        const clockIn = moment(record.ClockInTime)
        const clockOut = record.ClockOutTime ? moment(record.ClockOutTime) : null

        // Check late clock-in
        if (clockIn.isAfter(shiftStart.clone().add(15, 'minutes'))) {
          branchStats[branch].lateClockIns++
          branchStats[branch].totalLateMinutes += clockIn.diff(shiftStart, 'minutes')
        }

        // Check early clock-out
        if (clockOut && clockOut.isBefore(shiftEnd.clone().subtract(15, 'minutes'))) {
          branchStats[branch].earlyClockOuts++
          branchStats[branch].totalEarlyMinutes += shiftEnd.diff(clockOut, 'minutes')
        }
      }
    })

    return Object.entries(branchStats)
      .map(([branch, stats]) => ({
        branch,
        totalIncidents: stats.lateClockIns + stats.earlyClockOuts,
        lateClockIns: stats.lateClockIns,
        earlyClockOuts: stats.earlyClockOuts,
        totalLateMinutes: stats.totalLateMinutes,
        totalEarlyMinutes: stats.totalEarlyMinutes,
        score: ((stats.lateClockIns + stats.earlyClockOuts) / (stats.totalShifts || 1)) * 100
      }))
      .sort((a, b) => a.score - b.score)
  }, [clockingData, shifts, shiftAssignments])

  if (isLoading) return <div style={{ color: 'white' }}>Loading...</div>

  return (
    <TableContainer style={{ height: 'calc(100vh - 350px)', overflow: 'scroll' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: 'white' }}>Branch</TableCell>
            <TableCell align="right" sx={{ color: 'white' }}>
              Incidents
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {branchScores.map((branch) => (
            <Tooltip
              key={branch.branch}
              title={
                <div>
                  <div>Late Clock-ins: {branch.lateClockIns}</div>
                  <div>Early Clock-outs: {branch.earlyClockOuts}</div>
                  <div>Total Late Minutes: {Math.round(branch.totalLateMinutes)}</div>
                  <div>Total Early Minutes: {Math.round(branch.totalEarlyMinutes)}</div>
                  <div>Score: {branch.score.toFixed(1)}%</div>
                </div>
              }
            >
              <TableRow hover>
                <TableCell sx={{ color: 'white' }}>{branch.branch}</TableCell>
                <TableCell align="right" sx={{ color: 'white' }}>
                  {branch.totalIncidents}
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LateLeagueTable
