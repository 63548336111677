import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    IconButton,
    InputAdornment,
    Box,
    Slide,
    Autocomplete,
    CircularProgress,
    Paper,
    Popper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8;

function renderRow(props) {
    const { data, index, style } = props;
    const option = data[index];
    return React.cloneElement(option, {
        style: { ...style, top: style.top + LISTBOX_PADDING }
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 46;
    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={Math.min(8 * itemSize, itemCount * itemSize) + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={() => itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GELeadNewModal = ({ open, onClose, operatorData, token, geleads, setGELeads }) => {
    const formatDateToDateTimeLocal = useCallback((date) => {
        const ten = (i) => (i < 10 ? '0' : '') + i;
        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());
        const HH = ten(date.getHours());
        const mm = ten(date.getMinutes());
        return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
    }, []);

    const [newGELead, setNewGELead] = useState({
        Acct: '',
        Name: '',
        Inits: operatorData.Inits,
        Branch: operatorData.Branch,
        Tel: '',
        IsPartInGEMag: false,
        GEPartNo: '',
        DateTime: formatDateToDateTimeLocal(new Date()),
        Status: 'Pending',
        Value: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const autocompleteRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const fetchCustomers = useCallback(async (query) => {
        setIsLoading(true);
        try {
            let url = 'https://spar.connectplus.parts/api/salesassist/getCustomer';
            if (query && query.length >= 2) {
                url += `?search=${encodeURIComponent(query)}`;
            }
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                setCustomers(response.data);
                // Removed the automatic dropdown opening here
            }
        } catch (error) {
            console.error('Failed to fetch customers:', error);
            setCustomers([]);
        } finally {
            setIsLoading(false);
        }
    }, [token]);

    // Load customers only when needed
    useEffect(() => {
        if (open) {
            fetchCustomers(''); // Initial load when modal opens
        }
    }, [open, fetchCustomers]);

    // Debounced search
    useEffect(() => {
        if (!searchQuery || searchQuery.length < 2) return;

        const debounceTimer = setTimeout(() => {
            fetchCustomers(searchQuery);
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery, fetchCustomers]);

    const handleAddGELead = useCallback(async (e) => {
        if (e) e.preventDefault();
        setIsSubmitting(true);
        try {
            const url = 'https://spar.connectplus.parts/api/salesassist/cplusassist/geleads';
            const method = newGELead._id ? 'PUT' : 'POST';
            const response = await axios({
                method,
                url,
                data: newGELead,
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                if (newGELead._id) {
                    setGELeads(prevGELeads =>
                        prevGELeads.map(gelead => (gelead._id === newGELead._id ? response.data : gelead))
                    );
                } else {
                    setGELeads(prev => [...prev, response.data]);
                }
                setNewGELead({
                    Acct: '',
                    Name: '',
                    Inits: operatorData.Inits,
                    Branch: operatorData.Branch,
                    Tel: '',
                    IsPartInGEMag: false,
                    GEPartNo: '',
                    DateTime: formatDateToDateTimeLocal(new Date()),
                    Status: 'Pending',
                    Value: ''
                });
                setSelectedCustomer(null);
                setSearchQuery('');
                onClose();
            }
        } catch (error) {
            console.error('Failed to save GELead:', error);
        } finally {
            setIsSubmitting(false);
        }
    }, [newGELead, token, setGELeads, operatorData, onClose, formatDateToDateTimeLocal]);

    const handleInputChange = useCallback((field, value) => {
        setNewGELead(prev => ({ ...prev, [field]: value }));
    }, []);

    const handleCustomerSelection = useCallback((customer) => {
        if (customer) {
            setSelectedCustomer(customer);
            setNewGELead(prev => ({
                ...prev,
                Acct: customer.KeyCode,
                Name: customer.Name
            }));
            setDropdownOpen(false);
            setSearchQuery(`${customer.KeyCode} - ${customer.Name}`);
        } else {
            setSelectedCustomer(null);
        }
    }, []);

    const CustomPopper = props => (
        <Popper {...props} placement="bottom-start" style={{ width: props.style.width }}>
            {props.children}
        </Popper>
    );

    const styles = useMemo(() => ({
        dialog: { backgroundColor: '#181a1f', color: 'white' },
        header: { backgroundColor: '#82beff', color: '#000', padding: '12px 16px' },
        section: { backgroundColor: '#2a2d36', color: 'white', marginBottom: '16px', borderRadius: '4px', border: '1px solid #3a3d45' },
        sectionTitle: { color: '#82beff', fontWeight: 'normal', marginLeft: '8px', marginTop: '16px', marginBottom: '16px' },
        input: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: '#555' },
                '&:hover fieldset': { borderColor: '#82beff' },
                '&.Mui-focused fieldset': { borderColor: '#82beff' }
            },
            '& .MuiInputLabel-root': { color: '#ccc' },
            '& .MuiInputBase-input': { color: 'white' },
            backgroundColor: '#21242c'
        },
        checkbox: { color: '#82beff' },
        submitButton: {
            backgroundColor: '#82beff',
            color: '#000',
            '&:hover': { backgroundColor: '#6ba7e5' }
        },
        cancelButton: {
            color: '#ff6b6b',
            borderColor: '#ff6b6b',
            '&:hover': { borderColor: '#ff4545' }
        },
        icon: { color: '#82beff' },
        autocomplete: { '& .MuiAutocomplete-endAdornment': { color: 'white' } },
        paper: { backgroundColor: '#21242c', color: 'white' },
        listbox: { backgroundColor: '#21242c', '& .MuiAutocomplete-option': { color: 'white' } }
    }), []);

    return (
        <Dialog
            open={open}
            onClose={isSubmitting ? undefined : onClose}
            fullWidth
            maxWidth="md"
            TransitionComponent={Transition}
            PaperProps={{ style: styles.dialog }}
        >
            <DialogTitle sx={styles.header}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'medium' }}>
                    {newGELead._id ? 'Edit GE Lead' : 'Add New GE Lead'}
                </Typography>
                <IconButton edge="end" onClick={onClose} disabled={isSubmitting} aria-label="close" sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleAddGELead}>
                <DialogContent dividers sx={{ backgroundColor: '#181a1f', p: 2 }}>
                    <Box sx={{ ...styles.section, p: 2 }}>
                        <Typography variant="h6" sx={styles.sectionTitle}>
                            Customer Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    ref={autocompleteRef}
                                    fullWidth
                                    open={dropdownOpen}
                                    onOpen={() => setDropdownOpen(true)}
                                    onClose={() => setDropdownOpen(false)}
                                    options={customers}
                                    getOptionLabel={(option) => `${option.KeyCode} - ${option.Name}`}
                                    value={selectedCustomer}
                                    inputValue={searchQuery}
                                    onChange={(event, newValue) => handleCustomerSelection(newValue)}
                                    onInputChange={(event, value, reason) => {
                                        // Only update search query if manually typing
                                        if (reason === 'input') {
                                            setSearchQuery(value);

                                            // Clear selected customer if input changes
                                            if (selectedCustomer) {
                                                setSelectedCustomer(null);
                                                setNewGELead(prev => ({
                                                    ...prev,
                                                    Acct: '',
                                                    Name: ''
                                                }));
                                            }
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => option._id === value?._id}
                                    PopperComponent={CustomPopper}
                                    ListboxComponent={ListboxComponent}
                                    loading={isLoading}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Customer by Account or Name *"
                                            required
                                            variant="outlined"
                                            onClick={() => {
                                                if (customers.length > 0) {
                                                    setDropdownOpen(true);
                                                }
                                            }}
                                            sx={styles.input}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon sx={styles.icon} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <>
                                                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ color: 'white' }}>
                                            <strong>{option.KeyCode}</strong> - {option.Name}
                                        </li>
                                    )}
                                    PaperComponent={(props) => <Paper {...props} sx={styles.paper} />}
                                    ListboxProps={{ style: { backgroundColor: '#21242c' } }}
                                    sx={styles.autocomplete}
                                    noOptionsText={isLoading ? "Loading customers..." : "No customers found"}
                                    loadingText="Loading customers..."
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Account"
                                    value={newGELead.Acct}
                                    onChange={(e) => handleInputChange('Acct', e.target.value)}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Customer Name"
                                    value={newGELead.Name}
                                    onChange={(e) => handleInputChange('Name', e.target.value)}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Telephone"
                                    value={newGELead.Tel}
                                    onChange={(e) => handleInputChange('Tel', e.target.value)}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Value"
                                    value={newGELead.Value}
                                    onChange={(e) => handleInputChange('Value', e.target.value)}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoneyIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ ...styles.section, p: 2 }}>
                        <Typography variant="h6" sx={styles.sectionTitle}>
                            Product Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="GE Part Number"
                                    value={newGELead.GEPartNo}
                                    onChange={(e) => handleInputChange('GEPartNo', e.target.value)}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BuildIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={newGELead.IsPartInGEMag}
                                            onChange={(e) => handleInputChange('IsPartInGEMag', e.target.checked)}
                                            sx={styles.checkbox}
                                        />
                                    }
                                    label="Part is available in GE Magazine"
                                    sx={{ color: 'white' }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ ...styles.section, p: 2 }}>
                        <Typography variant="h6" sx={styles.sectionTitle}>
                            Assignment Details
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Assigned To"
                                    value={newGELead.Inits}
                                    onChange={(e) => handleInputChange('Inits', e.target.value)}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Branch"
                                    value={newGELead.Branch}
                                    onChange={(e) => handleInputChange('Branch', e.target.value)}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BusinessIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Date/Time"
                                    type="datetime-local"
                                    value={newGELead.DateTime}
                                    onChange={(e) => handleInputChange('DateTime', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    sx={styles.input}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EventIcon sx={styles.icon} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2, backgroundColor: '#181a1f', justifyContent: 'space-between' }}>
                    <Button onClick={onClose} variant="outlined" disabled={isSubmitting} sx={styles.cancelButton}>
                        CANCEL
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting || (!newGELead.Acct || !newGELead.Name)} sx={styles.submitButton}>
                        SUBMIT
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default GELeadNewModal;