import React from 'react'
import packageJson from '../../../package.json'
import { countWorkingDaysUntil, countWorkingDaysLeft } from '../../utils'
import moment from 'moment-timezone'

const versionNotes = [
  {
    version: '0.8.94',
    date: moment('2025-03-03'),
    notes: [
      'Added some GE Lead changes',
      'Changed infringement analysis (admin only)',
      'Changed customer list colours'
    ]
  }, {
    version: '0.8.93',
    date: moment('2025-02-21'),
    notes: [
      'Fixed GELead screen constant refresh']
  },
  {
    version: '0.8.92',
    date: moment('2025-02-20'),
    notes: [
      'Filtered GE LEads based on operator branch, if bm, sales.']
  },
  {
    version: '0.8.91',
    date: moment('2025-02-13'),
    notes: [
      'Added Regional customers for RMs']
  },
  {
    version: '0.8.9',
    date: moment('2025-02-13'),
    notes: [
      'Gave access to shifts to RMs']
  },
  {
    version: '0.8.8',
    date: moment('2025-02-13'),
    notes: [
      'Added promotions to sales view']
  },
  {
    version: '0.8.7',
    date: moment('2025-02-12'),
    notes: [
      'Added a service worker for automatic updates']
  },
  {
    version: '0.8.6',
    date: moment('2025-02-12'),
    notes: [
      'New GE Leads redesign, phase 1',
      'Added filter for staff member to holiday approvals',
      'Added delete button for approved holiday requests to holiday approvals'
    ]
  },
  {
    version: '0.8.5',
    date: moment('2025-02-11'),
    notes: ['Editing clock records security check']
  },
  {
    version: '0.8.4',
    date: moment('2025-02-05'),
    notes: ['Added promotional PDF feature']
  },
  {
    version: '0.8.3',
    date: moment('2025-02-04'),
    notes: [
      'Fixed issue with some sales logins not showing data',
      'Moved customer page to new tab for sales'
    ]
  },
  {
    version: '0.8.2',
    date: moment('2025-02-04'),
    notes: ['Changed message box colours in GE Leads', 'Updated Linked Customers']
  },
  {
    version: '0.8.1',
    date: moment('2025-01-30'),
    notes: [
      'Fixed problems with holiday booking and system being in different timezones',
      'Fixed GE Leads table overflowing page',
      'Added customers page'
    ]
  },
  {
    version: '0.8.0',
    date: moment('2025-01-26'),
    notes: ['Added support tab']
  },
  {
    version: '0.7.8',
    date: moment('2025-01-23'),
    notes: [
      'Fixed issues with staff data',
      'Fixed bug with editing staff modal',
      'Fixed bug with T&A infractions creating holidays incorrectly',
      'Fixed bug with deleting approved holidays',
      'Corrected validations on staff add modal'
    ]
  },
  {
    version: '0.7.7',
    date: moment('2025-01-23'),
    notes: ['Fixed daily targets tracker', 'Added PDF import to GELeads']
  },
  {
    version: '0.7.6',
    date: moment('2025-01-13'),
    notes: [
      'Corrected count for allocated holiday days',
      'Add calendar to RM and BM settings tabs',
      'Added a check to prevent booking of overlapping holidays'
    ]
  },
  {
    version: '0.7.5',
    date: moment('2025-01-09'),
    notes: [
      'Added switch to holiday approvals modal for showing staff from the region',
      'Added button for admins to delete staff holidays',
      'Added holiday requests for half days',
      "Prevented booking holidays if there aren't enough available days",
      'Prevented RMs and admins from approving their own holiday requests',
      'Hid REP branch for non-admins',
      'Fixed count for used holiday days',
      'Fixed issue with branch dropdown and branch filtering for RMs',
      'Fixed issue with adding break time to staff',
      'Fixed scrolling issue for the holiday approvals modal on small screens'
    ]
  },
  {
    version: '0.7.4',
    date: moment('2025-01-07'),
    notes: [
      'Updated various buttons to show a loading spinner',
      'Made holiday approvals table easier to read',
      'Filter staff dropdown according to rm/bm branch allocations',
      'Fixed missing my holidays tab for sales'
    ]
  },
  {
    version: '0.7.3',
    date: moment('2025-01-06'),
    notes: [
      'Fixed admins booking holidays in the past for staff',
      'Removed month filtering from my holidays',
      'Sorted my holidays by start date'
    ]
  },
  {
    version: '0.7.2',
    date: moment('2025-01-06'),
    notes: [
      'Fixed navigation bar for regional managers',
      'Fixed missing holidays for regional managers',
      'Fixed holidays not loading for branch managers'
    ]
  },
  {
    version: '0.7.1',
    date: moment('2025-01-05'),
    notes: [
      'Added position filtering to the holiday planner',
      'Added option for no shift on shift assignments',
      'Holidays can now be booked in the past',
      'Holidays no longer filtered by month on the approvals page',
      'Fixed "Approved" holiday request filtering',
      'Removed pre-booked holidays for Good Friday',
      'Allow selecting "all branches" when managing staff and holidays',
      'Allow admins to select regions',
      'Staff management page completed'
    ]
  },
  {
    version: '0.7.0',
    date: moment('2025-01-03'),
    notes: [
      'Completed transition to browser app',
      'Fixed year dropdowns not showing 2025',
      'Automated bank holiday booking',
      'Admins can book holiday requests for staff'
    ]
  },
  {
    version: '0.6.3',
    notes: ['Optimised holiday layout', 'Fixed region dropdown not working on holiday layout']
  },
  {
    version: '0.6.2',
    notes: ['Fixed Holiday Planner']
  },
  {
    version: '0.6.1',
    notes: ['Fixed Sales Forecast margin error']
  },
  {
    version: '0.6',
    notes: [
      'Holiday allocations added',
      'Attendance league tables added',
      'Improved linked customer layout',
      'Added Bank Holidays from the uk .gov site',
      'Added indicator for genuine clock in and amended clock in',
      'Create clock event when not clocked in, in T&A',
      'New forecast section on overview screen',
      'New gauge colour layout'
    ]
  },
  {
    version: '0.5.96',
    notes: [
      'Settings menu pop out fix',
      'Added not due for work in T&A',
      'Remove highlight from branch list',
      'Fixed customer scroll on overview'
    ]
  },
  {
    version: '0.5.95',
    notes: ['Targets fix']
  },
  {
    version: '0.5.94',
    notes: [
      'Added holiday planner',
      'Added holiday requests',
      'Added my holiday to request holidays'
    ]
  },
  {
    version: '0.5.93',
    notes: [
      'Added file upload for targets',
      'Caching of sales data on overview screen',
      'Added maternity / paternity',
      'Added alternate shift pattern'
    ]
  },
  {
    version: '0.5.92',
    notes: ['Fixed day of week index error', 'Fixed scrolling issue']
  },
  {
    version: '0.5.91',
    notes: ['Rework of T&A details', 'Linked customers for sales', 'Add notes to customers']
  },
  {
    version: '0.5.9',
    notes: ['Added VALUE to GE Leads', 'Added Edit mode to GE Leads']
  },
  {
    version: '0.5.8',
    notes: ['Dynamic run rate', 'Fixed Credits', 'Added quotes', 'Removed decimals in month view']
  },
  {
    version: '0.5.7',
    notes: ['Properly formatted branches to excel', 'Added export to excel button for quotes']
  },
  {
    version: '0.5.6',
    notes: [
      'Back end speed improvements',
      'Remove duplicated quotes',
      'Add new staff section to admin staff',
      'User monitoring to see when staff were online'
    ]
  },
  {
    version: '0.5.5',
    notes: ['sales and margin adjustments', 'Fixed minimise button']
  },
  {
    version: '0.5.4',
    notes: ['Fixed Quit button', 'Opened up shifts and shift assignment to RMs']
  },
  {
    version: '0.5.3',
    notes: ['Fixed sales figures 🥳🍾']
  },
  {
    version: '0.5.2',
    notes: ['Fixed a miscalculation from 0.5.1 in missing infringments']
  },
  {
    version: '0.5.1',
    notes: [
      'Fixed infringement count 😵‍💫😵‍💫😵‍💫',
      'Fixed compact autopop up every 10m',
      'Renamed sales assist to Spartan Colosseum',
      'Added accept infringement if worked but no shift',
      'Minimise to taskbar'
    ]
  },
  {
    version: '0.5.0',
    notes: ['Added GE Lead Notifications']
  },
  {
    version: '0.4.91',
    notes: ['Fixed shift scroll', 'Login on full and compact rework']
  },
  {
    version: '0.4.9',
    notes: [
      'Fixed T&A autoscrolling out of view 🥳🥳🍾',
      'Added sick, start early, finish late as acceptance reasons',
      'Fixed holidays and sick days not showing on T&A'
    ]
  },
  {
    version: '0.4.8',
    notes: [
      'Added sick, holiday & ndfw options to any staff not clocked in',
      'Added bereavement as absent option'
    ]
  },
  {
    version: '0.4.7',
    notes: [
      "Don't count as absent if on holiday or sick",
      'Add a reason if accepting an infringement',
      'Allow shifts to be sorted in the table',
      'Fixed Casuals not showing after they clock out'
    ]
  },
  {
    version: '0.4.6',
    notes: ['Add record fix if no clock in']
  },
  {
    version: '0.4.5',
    notes: [
      'Casuals improved. Add covering for and approved by',
      'Casual icon fix on branch header',
      'Branch header now lists staff and branches for status stats',
      'Stopped showing days in future on month view',
      'Added days to jump to on month view',
      'Changes to allow BM logins'
    ]
  },
  {
    version: '0.4.4',
    notes: ['Corrected absent and casual figures (promise)']
  },
  {
    version: '0.4.3',
    notes: ['Readded Casuals that got removed in last update']
  },
  {
    version: '0.4.2',
    notes: [
      'Added logout button',
      'Changed blue icon on staff to anyone that should be on site',
      'Added icon for casuals',
      'fixed counts for all status'
    ]
  },
  {
    version: '0.4.1',
    notes: ['Updated Logo to Spartan Head']
  },
  {
    version: '0.4.0',
    notes: [
      'Rewrite T&A to allow moving between different months and years',
      'Implement filter for RM logins',
      'Filter T&A records to show all or only infringements to be dealt with',
      'Include break times on clocking analysis',
      'Add break times in shift assignment',
      'Not Due For Work button added',
      'Add holidays & sick days to branch T&A cards',
      'Styling change on T&A bars'
    ]
  },
  {
    version: '0.3.8',
    notes: ['Device Status']
  },
  {
    version: '0.3.7',
    notes: ['Shift Patterns', 'Regional Manager Login']
  },
  {
    version: '0.3.6',
    notes: ['Partial Stock Check (90% done)', 'Regional Manager Login']
  },
  {
    version: '0.3.5',
    notes: ['API Endpoint change', 'Admin overview layout change']
  },
  {
    version: '0.3.4',
    notes: ['Styling changes', 'Margin calc on overview', 'Excluded IBT on overview']
  },
  {
    version: '0.3.3',
    notes: ['Message colour scheme change']
  },
  {
    version: '0.3.2',
    notes: ['Message fixes']
  },
  {
    version: '0.3.1',
    notes: [
      'Admin can create messages on the settings tab',
      'Messages scroll across the bottom',
      'Added day / month switch',
      'Added download in Excel for GE Leads'
    ]
  },
  {
    version: '0.3.0',
    notes: ['Staff Details (margin contribution)']
  },
  {
    version: '0.2.9',
    notes: ['Add overstrikes to branch details']
  },
  {
    version: '0.2.8',
    notes: [
      'Add margin commitment for staff',
      'Add overstrikes to staff',
      'Allow overstrikes to admin',
      'Add / edit regions & RMs',
      'Add regions to branches',
      'Show regions on map'
    ]
  },
  {
    version: '0.2.7',
    notes: [
      'Allow paste of images in ge comments',
      'Remove GE mag from table',
      'Remove time from table',
      'Allow GE Archive',
      'Allow GE Delete',
      'Add date filter to GE table',
      'Add status filter to GE table',
      'Add branch filter to GE table',
      'Add colour coding to GE table'
    ]
  },
  {
    version: '0.2.6',
    notes: ['Dynamic table resizing for branches']
  }
]

function About() {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth() + 1
  const day = today.getDate()

  return (
    <div style={{ height: '70vh', overflow: 'scroll' }}>
      <div style={{ color: 'white' }}>
        <h2>ABOUT</h2>
        <p>Version: {packageJson.version}</p>
      </div>
      <div>
        <h5>{countWorkingDaysUntil(year, month, day) - 1} working days gone</h5>
      </div>
      <div>
        <h5>{countWorkingDaysLeft(year, month, day) + 1} working days remaining</h5>
      </div>
      <div style={{ overflow: 'scroll', height: '60vh' }}>
        {versionNotes.map((ver, ind) => {
          if (ind === 0) {
            return (
              <React.Fragment key={ver.version}>
                <h1>
                  {ver.version} {ver.version === packageJson.version && '(current)'}{' '}
                  {ver.date && (
                    <span className="pl-2 text-sm">{ver.date.format('DD/MM/YYYY')}</span>
                  )}
                </h1>
                {ver.notes.map((note, ind) => (
                  <p key={note}>{note}</p>
                ))}
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={ver.version}>
                <h2>
                  {ver.version} {ver.version === packageJson.version && '(current)'}
                  {ver.date && (
                    <span className="pl-2 text-sm">{ver.date.format('DD/MM/YYYY')}</span>
                  )}
                </h2>
                {ver.notes.map((note, ind) => (
                  <p key={note}>{note}</p>
                ))}
              </React.Fragment>
            )
          }
        })}
      </div>
    </div>
  )
}

export default About
