import React, { useState, useEffect, useContext } from 'react';
import {
    Tabs,
    Tab,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    CircularProgress,
    Typography,
    TextField
} from '@mui/material';
import axios from 'axios';
import { GlobalContext } from '../../context/GlobalContext';

function ActiveStaff() {
    const { token } = useContext(GlobalContext);
    const [activeStaff, setActiveStaff] = useState([]);
    const [inactiveStaff, setInactiveStaff] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(0);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [processingStaff, setProcessingStaff] = useState(null);

    useEffect(() => {
        fetchAllStaff();
    }, []);

    const fetchAllStaff = async () => {
        setLoading(true);
        setError(null);
        try {
            // Fetch active staff from legacy API
            const activeResponse = await axios.get(
                'https://vision-web-api-test.azurewebsites.net/api/tma/staff',
                { headers: { 'Token': token } }
            );
            setActiveStaff(activeResponse.data);

            // Fetch inactive staff from new API
            const inactiveResponse = await axios.get(
                'https://spar.connectplus.parts/api/salesassist/cplusassist/inactivestaff',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            setInactiveStaff(inactiveResponse.data);
        } catch (error) {
            console.error('Error fetching staff:', error);
            setError('Failed to fetch staff data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const setStaffInactive = async (staff) => {
        setProcessingStaff(staff.StaffId);
        try {
            // First, set staff inactive in legacy API
            await axios.get(
                `https://vision-web-api-test.azurewebsites.net/api/tma/staff/${staff.StaffId}/inactive`,
                { headers: { 'Token': token } }
            );

            // Then add to inactive staff in new API
            await axios.post(
                'https://spar.connectplus.parts/api/salesassist/cplusassist/inactivestaff',
                {
                    StaffId: staff.StaffId,
                    FName: staff.FName,
                    SName: staff.SName
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            // Refresh staff lists
            await fetchAllStaff();
        } catch (error) {
            console.error('Error setting staff inactive:', error);
            setError('Failed to set staff member as inactive.');
        } finally {
            setProcessingStaff(null);
        }
    };

    const setStaffActive = async (staff) => {
        setProcessingStaff(staff.StaffId);
        try {
            // First get the full staff details from legacy API
            const staffResponse = await axios.get(
                `https://vision-web-api-test.azurewebsites.net/api/tma/staff/${staff.StaffId}`,
                { headers: { 'Token': token } }
            );

            // Update the staff object to set as active
            const updatedStaffData = {
                ...staffResponse.data,
                Active: true
            };

            // Update the staff in legacy API
            await axios.post(
                `https://vision-web-api-test.azurewebsites.net/api/tma/staff`,
                updatedStaffData,
                {
                    headers: {
                        'Token': token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            // Then remove from inactive staff in new API
            await axios.delete(
                `https://spar.connectplus.parts/api/salesassist/cplusassist/inactivestaff/${staff.StaffId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );

            // Refresh staff lists
            await fetchAllStaff();
        } catch (error) {
            console.error('Error setting staff active:', error);
            setError('Failed to set staff member as active.');
        } finally {
            setProcessingStaff(null);
        }
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        setError(null); // Clear any errors when switching tabs
        setSearchTerm(''); // Clear search when switching tabs
    };

    const filterStaff = (staffList) => {
        return staffList.filter(staff =>
            staff.FName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            staff.SName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const renderStaffTable = (staffList, isActive) => (
        <TableContainer component={Paper} style={{ backgroundColor: '#333' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ color: 'white', fontWeight: 'bold' }}>First Name</TableCell>
                        <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Surname</TableCell>
                        {isActive && (
                            <>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Branch</TableCell>
                                <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Position</TableCell>
                            </>
                        )}
                        <TableCell style={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filterStaff(staffList).map((staff) => (
                        <TableRow key={staff.StaffId}>
                            <TableCell style={{ color: 'white' }}>{staff.FName}</TableCell>
                            <TableCell style={{ color: 'white' }}>{staff.SName}</TableCell>
                            {isActive && (
                                <>
                                    <TableCell style={{ color: 'white' }}>{staff.Branch}</TableCell>
                                    <TableCell style={{ color: 'white' }}>{staff.Position}</TableCell>
                                </>
                            )}
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color={isActive ? "error" : "success"}
                                    onClick={() => isActive ? setStaffInactive(staff) : setStaffActive(staff)}
                                    disabled={processingStaff === staff.StaffId}
                                    style={{
                                        backgroundColor: isActive ? '#dc3545' : '#28a745',
                                        color: 'white',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {processingStaff === staff.StaffId ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : (
                                        isActive ? 'SET INACTIVE' : 'SET ACTIVE'
                                    )}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Paper style={{ backgroundColor: '#424242', marginBottom: '20px' }}>
                <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    style={{ borderBottom: '1px solid #555', width: 500 }}
                >
                    <Tab
                        label={`ACTIVE (${activeStaff.length})`}
                        style={{ color: 'white', width: 200, }}
                    />
                    <Tab
                        label={`INACTIVE (${inactiveStaff.length})`}
                        style={{ color: 'white', width: 200, }}
                    />
                </Tabs>
            </Paper>

            <TextField
                fullWidth
                variant="outlined"
                label="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                    marginBottom: '20px',
                    backgroundColor: 'white'
                }}
                InputProps={{
                    style: { color: 'black' }
                }}
                InputLabelProps={{
                    style: { color: 'black' }
                }}
            />

            {error && (
                <Typography
                    style={{
                        marginBottom: '20px',
                        color: '#ff6b6b',
                        padding: '10px',
                        backgroundColor: 'rgba(255,107,107,0.1)',
                        borderRadius: '4px'
                    }}
                >
                    {error}
                </Typography>
            )}

            {currentTab === 0 ? (
                renderStaffTable(activeStaff, true)
            ) : (
                renderStaffTable(inactiveStaff, false)
            )}
        </div>
    );
}

export default ActiveStaff;