import React, { useState, useContext, useEffect } from 'react'
import { Button, Select, MenuItem, Grid, Card, CardContent, Typography } from '@mui/material'
import { GlobalContext } from '../../context/GlobalContext'
import AttendanceLeagueTable from './AttendanceLeagueTable'
import SickDaysLeagueTable from './SickDaysLeagueTable'
import LateLeagueTable from './LateLeagueTable'
import AcceptedInfringementsTable from './AcceptedInfringementsTable'
import moment from 'moment-timezone'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 200px)',
    overflow: 'hidden'
  },
  filtersContainer: {
    padding: 2
  },
  leagueTablesContainer: {
    padding: 2,
    flexGrow: 1,
    height: 'calc(100vh - 200px)',
    overflow: 'hidden'
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#333',
    color: 'white'
  },
  cardContent: {
    'flexGrow': 1,
    'overflow': 'auto',
    'padding': '16px !important',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#1a1a1a'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px'
    }
  }
}

const AttendanceAnalysis = ({ onClose }) => {
  const { operatorData, token } = useContext(GlobalContext)
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 6 }, (_, i) => currentYear - 5 + i)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [selectedRegion, setSelectedRegion] = useState('ALL REGIONS')
  const [selectedBranch, setSelectedBranch] = useState(operatorData?.Branch || 'ALL')
  const [regions, setRegions] = useState([])
  const [branches, setBranches] = useState([])
  const [clockingData, setClockingData] = useState([])
  const [shifts, setShifts] = useState([])
  const [shiftAssignments, setShiftAssignments] = useState([])
  const [sickDays, setSickDays] = useState([])
  const [acceptedInfringements, setAcceptedInfringements] = useState([])
  const [staffData, setStaffData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (operatorData?.Status === 'admin') {
          const regionsResponse = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/regions', {
            headers: { Authorization: `Bearer ${token}` }
          })
          const regionsData = await regionsResponse.json()
          setRegions(regionsData)
        }

        const branchesResponse = await fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
          headers: { Authorization: `Bearer ${token}` }
        })
        const branchesData = await branchesResponse.json()

        let filteredBranches
        if (operatorData?.Status === 'admin') {
          filteredBranches =
            selectedRegion === 'ALL REGIONS'
              ? branchesData
              : branchesData.filter((branch) => branch.Region === selectedRegion)
        } else if (operatorData?.Status === 'rm') {
          filteredBranches = branchesData.filter((branch) => branch.Region === operatorData.Name)
        } else if (operatorData?.Status === 'bm') {
          filteredBranches = branchesData.filter((branch) => branch.MamCode === operatorData.Branch)
        }

        setBranches(filteredBranches || [])

        if (filteredBranches?.length === 1) {
          setSelectedBranch(filteredBranches[0].MamCode)
        }
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchData()
  }, [token, operatorData, selectedRegion])

  useEffect(() => {
    const fetchAnalysisData = async () => {
      setIsLoading(true)
      try {
        const [
          clockingResponse,
          shiftsResponse,
          assignmentsResponse,
          sickDaysResponse,
          acceptedInfringementsResponse,
          staffResponse
        ] = await Promise.all([
          fetch('https://vision-web-api-test.azurewebsites.net/api/tma/clocklogs', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Token': token
            },
            body: JSON.stringify({
              startDate: new Date(selectedYear, selectedMonth - 1, 1).toISOString(),
              endDate: new Date(selectedYear, selectedMonth, 0).toISOString()
            })
          }),
          fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shifts', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          fetch('https://spar.connectplus.parts/api/salesassist/cplusassist/shiftassignments', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          fetch('https://vision-web-api-test.azurewebsites.net/api/tma/sickdays', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Token': token
            },
            body: JSON.stringify({
              StartDate: moment(new Date(selectedYear, selectedMonth - 1, 1)).format('YYYY-MM-DD'),
              EndDate: moment(new Date(selectedYear, selectedMonth, 0)).format('YYYY-MM-DD')
            })
          }),
          fetch(
            `https://spar.connectplus.parts/api/salesassist/cplusassist/acceptinfringements/${selectedYear}/${
              selectedMonth - 1
            }`,
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          ),
          fetch('https://vision-web-api-test.azurewebsites.net/api/tma/staff', {
            headers: { Token: token }
          })
        ])

        const [
          clockingResult,
          shiftsResult,
          assignmentsResult,
          sickDaysResult,
          acceptedInfringementsResult,
          staffResult
        ] = await Promise.all([
          clockingResponse.json(),
          shiftsResponse.json(),
          assignmentsResponse.json(),
          sickDaysResponse.json(),
          acceptedInfringementsResponse.json(),
          staffResponse.json()
        ])

        setClockingData(clockingResult)
        setShifts(shiftsResult)
        setShiftAssignments(assignmentsResult)
        setSickDays(sickDaysResult)
        setAcceptedInfringements(acceptedInfringementsResult)
        setStaffData(staffResult)
      } catch (error) {
        console.error('Failed to fetch analysis data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAnalysisData()
  }, [token, selectedMonth, selectedYear, selectedRegion, selectedBranch])

  const selectStyles = {
    'height': 40,
    'minWidth': 140,
    'mr': 1,
    'bgcolor': 'transparent',
    'color': 'white',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
      borderRadius: '4px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.5)'
    },
    '& .MuiSelect-icon': {
      color: 'white'
    }
  }

  const menuProps = {
    PaperProps: {
      sx: {
        'bgcolor': '#333',
        'color': 'white',
        '& .MuiMenuItem-root': {
          'color': 'white',
          '&:hover': {
            bgcolor: 'rgba(255, 255, 255, 0.1)'
          },
          '&.Mui-selected': {
            'bgcolor': 'rgba(255, 255, 255, 0.2)',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.2)'
            }
          }
        }
      }
    }
  }

  return (
    <div style={styles.wrapper}>
      <Grid container spacing={2} alignItems="center" sx={styles.filtersContainer}>
        <Grid item>
          <Button onClick={onClose} variant="contained" sx={{ mr: 2 }}>
            Back
          </Button>
        </Grid>

        <Grid item>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            sx={selectStyles}
            MenuProps={menuProps}
          >
            {months.map((month, idx) => (
              <MenuItem key={idx} value={idx + 1}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            sx={selectStyles}
            MenuProps={menuProps}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* {operatorData?.Status === 'admin' && (
                    <Grid item>
                        <Select
                            value={selectedRegion}
                            onChange={(e) => {
                                setSelectedRegion(e.target.value);
                                setSelectedBranch('ALL');
                            }}
                            sx={selectStyles}
                            MenuProps={menuProps}
                        >
                            <MenuItem value="ALL REGIONS">ALL REGIONS</MenuItem>
                            {regions.map((region) => (
                                <MenuItem key={region.RegionId} value={region.RegionName}>
                                    {region.RegionName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                )} */}

        {/* {(operatorData?.Status === 'admin' || operatorData?.Status === 'rm') && (
                    <Grid item>
                        <Select
                            value={selectedBranch}
                            onChange={(e) => setSelectedBranch(e.target.value)}
                            sx={selectStyles}
                            MenuProps={menuProps}
                        >
                            <MenuItem value="ALL">ALL BRANCHES</MenuItem>
                            {branches.map((branch) => (
                                <MenuItem key={branch.MamCode} value={branch.MamCode}>
                                    {branch.MamCode}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                )} */}
      </Grid>

      <Grid container spacing={2} sx={styles.leagueTablesContainer}>
        <Grid item xs={3}>
          <Card sx={styles.card}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h6">Attendance</Typography>
              <AttendanceLeagueTable
                clockingData={clockingData}
                shifts={shifts}
                shiftAssignments={shiftAssignments}
                isLoading={isLoading}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={styles.card}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h6">Sick Days</Typography>
              <SickDaysLeagueTable sickDays={sickDays} isLoading={isLoading} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={styles.card}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h6">Late</Typography>
              <LateLeagueTable
                clockingData={clockingData}
                shifts={shifts}
                shiftAssignments={shiftAssignments}
                isLoading={isLoading}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={styles.card}>
            <CardContent sx={styles.cardContent}>
              <Typography variant="h6">Accepted Infringements</Typography>
              <AcceptedInfringementsTable
                acceptedInfringements={acceptedInfringements}
                staffData={staffData}
                isLoading={isLoading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default AttendanceAnalysis
