import React, { useState, useEffect } from 'react'
import {
    CardContent,
    Typography,
    Button,
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    List,
    ListItem,
    IconButton,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import moment from 'moment'
import haxios from '../../components/newholidays/lib/haxios'
import { modals } from '@mantine/modals'

const businessReasons = [
    'Customer delivery',
    'Stock collection',
    'Business meeting',
    'Training course (offsite)',
    'Unable to clock due to system error',
    'Unable to clock – no site access (shift pattern)'
]

const hrReasons = [
    'Accident at Work',
    'Adoption Leave',
    'Antenatal Care',
    'Authorised Absence Paid',
    'Authorised Absence Unpaid',
    'AWOL',
    'Bereavement Leave Paid',
    'Bereavement Leave Unpaid',
    'Early Leaving Unpaid',
    'Emergency Leave',
    'Family Leave',
    'Garden Leave',
    'Holiday',
    'In Custody',
    'Jury Service',
    'Lateness',
    'Maternity Leave',
    'Medical Appointment',
    'Parental Leave',
    'Paternity Leave',
    'Rehabilitation',
    'Shift Change',
    'Sickness',
    'Suspended',
]

const BMInfringementHandler = ({
    selectedDetail,       // Contains details including date, shift info and clockingRecords
    setHighlightedRecord, // Function to highlight the selected record in the parent component
    onAcceptInfringement, // Function to send the infringement acceptance payload
    onClose,              // Function to close/reset the view
    refreshClockingRecords // New prop to refresh clocking records after deletion
}) => {
    const [localClockingRecords, setLocalClockingRecords] = useState([])
    const [selectedClockingRecord, setSelectedClockingRecord] = useState(null)
    const [businessReasonOption, setBusinessReasonOption] = useState('')
    const [selectedReason, setSelectedReason] = useState('')
    const [error, setError] = useState(null)

    useEffect(() => {
        if (selectedDetail) {
            const records = selectedDetail.clockingRecords || [selectedDetail]
            setLocalClockingRecords(records)
            setSelectedClockingRecord(records[0])
            setBusinessReasonOption('')
            setSelectedReason('')
            setError(null)
        }
    }, [selectedDetail])

    const handleDeleteRecord = (clockId) => {
        const recordToDelete = localClockingRecords.find(
            record => record?.ClockID === clockId || record?.clockingId === clockId
        )
        modals.openContextModal({
            modal: 'warningModal',
            withCloseButton: false,
            centered: true,
            innerProps: {
                message: `You are about to delete your clock record for ${recordToDelete?.ClockInTime
                    ? moment(recordToDelete.ClockInTime).format('HH:mm')
                    : 'Unknown time'
                    }.`,
                submit: async () => {
                    try {
                        await haxios.delete(`https://vision-web-api-test.azurewebsites.net/api/tma/clocklog/${clockId}`)
                        const updatedRecords = localClockingRecords.filter(
                            record => record?.ClockID !== clockId && record?.clockingId !== clockId
                        )
                        setLocalClockingRecords(updatedRecords)
                        if (updatedRecords.length > 0) {
                            setSelectedClockingRecord(updatedRecords[0])
                        } else {
                            setSelectedClockingRecord(null)
                        }

                        // Call the refreshClockingRecords function to update the parent component
                        if (refreshClockingRecords) {
                            refreshClockingRecords()
                            setHighlightedRecord(null)
                        }
                    } catch (e) {
                        console.log(e)
                        setError('Error deleting clock record.')
                    }
                }
            }
        })
    }

    const isLate = () => {
        if (!selectedClockingRecord || !selectedClockingRecord.ClockInTime) return false
        const shiftStart = moment(`${selectedDetail.date} ${selectedDetail.shift.Start}`, 'YYYY-MM-DD HH:mm')
        const clockIn = moment(selectedClockingRecord.ClockInTime)
        return clockIn.isAfter(shiftStart.clone().add(15, 'minutes'))
    }

    const isEarly = () => {
        if (!selectedClockingRecord || !selectedClockingRecord.ClockOutTime) return false
        const shiftEnd = moment(`${selectedDetail.date} ${selectedDetail.shift.Finish}`, 'YYYY-MM-DD HH:mm')
        const clockOut = moment(selectedClockingRecord.ClockOutTime)
        return clockOut.isBefore(shiftEnd.clone().subtract(15, 'minutes'))
    }

    const isMissingClockIn = () => {
        return !selectedClockingRecord || !selectedClockingRecord.ClockInTime
    }

    if (localClockingRecords.length > 1) {
        return (
            <CardContent>
                <Typography variant="h6">Multiple Clock Records Found</Typography>
                <Typography sx={{ mb: 2 }}>
                    Please delete extra records until only one remains to proceed.
                </Typography>
                <List>
                    {localClockingRecords.map((record) => (
                        <ListItem
                            key={record?.ClockID || record?.clockingId}
                            secondaryAction={
                                <IconButton
                                    onClick={() => handleDeleteRecord(record?.ClockID || record?.clockingId)}
                                    edge="end"
                                    color="error"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <Typography>
                                {record.ClockInTime ? moment(record.ClockInTime).format('HH:mm') : 'No Clock In'} –{' '}
                                {record.ClockOutTime ? moment(record.ClockOutTime).format('HH:mm') : 'No Clock Out'}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ mt: 2 }}>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
            </CardContent>
        )
    }

    let infringementType = ''
    if (isMissingClockIn()) {
        infringementType = 'Missing clock in'
    } else if (isLate() && isEarly()) {
        infringementType = 'Late clock in & Early clock out'
    } else if (isLate()) {
        infringementType = 'Late clock in'
    } else if (isEarly()) {
        infringementType = 'Early clock out'
    }

    if (!infringementType) {
        return (
            <CardContent>
                <Typography variant="h6">No Infringement Found</Typography>
                <Typography>
                    This clock record does not qualify as an infringement.
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Button variant="contained" onClick={onClose}>
                        Close
                    </Button>
                </Box>
            </CardContent>
        )
    }

    const handleAccept = () => {
        if (!businessReasonOption) {
            setError('Please select the Business Reason option.')
            return
        }
        if (!selectedReason) {
            setError('Please select a reason from the dropdown.')
            return
        }
        setError(null)
        const clockId =
            selectedClockingRecord?.ClockID ||
            selectedDetail?.clockingId ||
            '00000000-0000-0000-0000-000000000000'
        const payload = {
            clockId,
            infringementType,
            businessReason: businessReasonOption === 'yes',
            reason: selectedReason
        }

        // Call the onAcceptInfringement function with the payload
        onAcceptInfringement && onAcceptInfringement(payload, () => {
            // This callback will be executed after the infringement is accepted
            // Reset the view back to the details screen
            setHighlightedRecord(null)
            onClose()

            // Refresh clocking records
            if (refreshClockingRecords) {
                refreshClockingRecords()
            }
        })
    }

    return (
        <CardContent>
            <Typography variant="h6">Infringement Analysis</Typography>
            <Typography sx={{ mb: 2 }}>
                Infringement Type: <strong>{infringementType}</strong>
            </Typography>
            <Box sx={{ mb: 2 }}>
                <Typography>Was this due to a Business Reason?</Typography>
                <RadioGroup
                    row
                    value={businessReasonOption}
                    onChange={(e) => {
                        setBusinessReasonOption(e.target.value)
                        setSelectedReason('')
                    }}
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
            </Box>
            {businessReasonOption && (
                <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel>
                            {businessReasonOption === 'yes'
                                ? 'Select Business Reason'
                                : 'Select HR Reason'}
                        </InputLabel>
                        <Select
                            value={selectedReason}
                            label={
                                businessReasonOption === 'yes'
                                    ? 'Select Business Reason'
                                    : 'Select HR Reason'
                            }
                            onChange={(e) => setSelectedReason(e.target.value)}
                        >
                            {(businessReasonOption === 'yes' ? businessReasons : hrReasons).map(
                                (reason) => (
                                    <MenuItem key={reason} value={reason}>
                                        {reason}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </Box>
            )}
            {error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" onClick={handleAccept}>
                    Accept Infringement
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
            </Box>
        </CardContent>
    )
}

export default BMInfringementHandler