import { useState } from 'react'
import { Card, Icon, Table } from './gsys-ui'
import haxios from './lib/haxios'
import useApi from './hooks/useApi'
import { MagnifyingGlassIcon, UsersIcon, PencilIcon, TrashIcon, WrenchIcon } from '@heroicons/react/24/outline'
import { TextInput, Button, ActionIcon } from '@mantine/core'
import { modals } from '@mantine/modals'
import clsx from 'clsx'
import moment from 'moment-timezone'
import BranchDropdown from './components/BranchDropdown'
import useBranchStore from './stores/useBranchStore'
import { CircularProgress } from '@mui/material'

const Staff = () => {
  const {
    data: staffData = [],
    isLoading: isStaffLoading,
    refetch
  } = useApi({
    url: '/salesassistnew/ustafferators'
  })

  const [searchValue, setSearchValue] = useState('')
  const selectedBranch = useBranchStore((state) => state.selectedBranch)
  const selectedRegion = useBranchStore((state) => state.selectedRegion)
  const regions = useBranchStore((state) => state.regions)

  if (isStaffLoading)
    return (
      <div className="flex absolute inset-0 justify-center items-center">
        <CircularProgress />
      </div>
    )

  const handleAddStaff = () => {
    modals.openContextModal({
      modal: 'staffAddModal',
      title: 'Add a staff member',
      centered: true,
      innerProps: {
        submit: (data) => haxios.post(`/salesassistnew/ustafferators`, data),
        refetch
      }
    })
  }

  const handleEditStaff = (staff) => {
    modals.openContextModal({
      modal: 'staffEditModal',
      title: 'Edit a staff member',
      centered: true,
      innerProps: {
        UserId: staff,
        defaultValues: {
          EmployeeNumber: staff.EmployeeNumber,
          FName: staff.FName,
          SName: staff.SName,
          Dob: moment(staff.Dob).toDate(),
          Position: staff.Position,
          Branch: staff.Branch,
          AllowLogin: staff.AllowLogin,
          Email: staff.Email,
          Username: staff.Username,
          Password: staff.Password,
          IsAdmin: staff.IsAdmin,
          Inits: staff.Inits,
          SalesTarget: staff.SalesTarget,
          //Status: 'Active',
          HolidayStartDate: staff.HolidayStartDate,
          HolidayAllocationThisYear: staff.HolidayAllocationThisYear,
          HolidayAllocation: staff.HolidayAllocation
        },
        submit: (data) => haxios.put(`/salesassistnew/ustafferators/${staff.StaffId}`, data),
        refetch
      }
    })
  }

  const handleDelete = (staff) => {
    modals.openContextModal({
      modal: 'warningModal',
      withCloseButton: false,
      centered: true,
      innerProps: {
        message: `You are about to delete ${staff.FName} ${staff.SName} from the system.`,
        submit: async () => {
          await haxios.delete(`/salesassistnew/ustafferators/${staff.StaffId}`)
          await refetch()
          //successToast(`Holiday request deleted.`)
        }
      }
    })
  }

  // New function to handle fixing a staff record using the new API endpoint
  const handleFixStaff = (staff) => {
    modals.openContextModal({
      modal: 'warningModal',
      withCloseButton: false,
      centered: true,
      innerProps: {
        message: `You are about to create a default database entry for ${staff.FName} ${staff.SName}. This will set default values for missing fields.`,
        submit: async () => {
          try {
            // Call our new API endpoint specifically for fixing staff records
            await haxios.post('/salesassistnew/fix-staff-record', {
              FName: staff.FName,
              SName: staff.SName,
              Branch: staff.Branch,
              Position: staff.Position,
              EmployeeNumber: staff.EmployeeNumber,
              staffId: staff.StaffId || staff.EmployeeNumber
            })

            // Refresh the data to show the updated record
            await refetch()
          } catch (error) {
            console.error('Error fixing staff record:', error)
          }
        }
      }
    })
  }

  // Helper function to check if a staff record needs fixing (exists in 3rd party but not in our DB)
  const needsFixing = (staff) => {
    // Check if essential DB fields are empty
    return !staff.SalesTarget &&
      !staff.HolidayStartDate &&
      !staff.HolidayAllocation &&
      !staff.HolidayAllocationThisYear
  }

  const dataFiltered = staffData.filter(
    (el) =>
      `${el.FName} ${el.SName} ${el.Branch}`.toLowerCase().includes(searchValue.toLowerCase()) &&
      (selectedBranch === 'ALL' || el.Branch === selectedBranch) &&
      (selectedRegion ? regions[selectedRegion].some((b) => b.MamCode === el.Branch) : true)
  )

  return (
    <>
      <div className="flex justify-between items-center p-4 text-white">
        <div className="flex items-center space-x-2 text-xl font-semibold">
          <div>Staff settings</div>
        </div>
        <div className="flex items-center space-x-2 font-normal">
          <BranchDropdown />
          <TextInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Type to search..."
            leftSection={<Icon Comp={MagnifyingGlassIcon} />}
          />
          <Button onClick={handleAddStaff}>Add staff</Button>
        </div>
      </div>
      <Card>
        <Table
          data={dataFiltered}
          columns={[
            { header: 'Employee Number', accessorKey: 'EmployeeNumber' },
            { header: 'Name', id: 'name', cell: ({ row }) => `${row.original.FName} ${row.original.SName}` },
            { header: 'Branch', accessorKey: 'Branch' },
            { header: 'Position', accessorKey: 'Position' },
            {
              header: 'Username',
              id: 'username',
              cell: ({ row }) => (row.original.AllowLogin ? row.original.Username : '—')
            },
            { header: 'Sales target', accessorKey: 'SalesTarget' },
            { header: 'Holiday start date', accessorKey: 'HolidayStartDate' },
            { header: 'Holiday allocation', accessorKey: 'HolidayAllocation' },
            {
              header: 'Holiday allocation this year',
              accessorKey: 'HolidayAllocationThisYear',
              cell: ({ row }) => row.original.HolidayAllocationThisYear ?? 'Default Value'
            },
            {
              colWidth: 0,
              id: 'actions',
              cell: ({ row }) => (
                <div className="flex space-x-1">
                  <ActionIcon variant="default" onClick={() => handleEditStaff(row.original)}>
                    <Icon Comp={PencilIcon} size={4.5} />
                  </ActionIcon>
                  <ActionIcon variant="default" onClick={() => handleDelete(row.original)}>
                    <Icon Comp={TrashIcon} size={4.5} />
                  </ActionIcon>
                  {needsFixing(row.original) && (
                    <ActionIcon
                      variant="default"
                      onClick={() => handleFixStaff(row.original)}
                      title="Fix missing database record"
                    >
                      <Icon Comp={WrenchIcon} size={4.5} />
                    </ActionIcon>
                  )}
                </div>
              )
            }
          ]}
        />

      </Card>
    </>
  )
}

const OnlineSpot = ({ isOnline }) => {
  return <div className={clsx('w-5 h-5 rounded-full', isOnline && 'bg-green-500', !isOnline && 'bg-red-500')} />
}

export default Staff