import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

const AcceptedInfringementsTable = ({ acceptedInfringements, staffData, isLoading }) => {
    const branchScores = useMemo(() => {
        if (!acceptedInfringements?.length || !staffData?.length) return [];

        const staffBranchMap = staffData.reduce((map, staff) => {
            map[staff.StaffId] = staff.Branch;
            return map;
        }, {});

        const branchStats = {};

        acceptedInfringements.forEach(infringement => {
            const branch = staffBranchMap[infringement.StaffId];
            if (!branch) return;

            if (!branchStats[branch]) {
                branchStats[branch] = {
                    total: 0,
                    byType: {},
                    byApprover: {},
                    totalMinsOut: 0
                };
            }

            branchStats[branch].total++;
            branchStats[branch].totalMinsOut += (infringement.MinsOut || 0);

            const type = infringement.ApprovedType || 'Unspecified';
            branchStats[branch].byType[type] = (branchStats[branch].byType[type] || 0) + 1;

            const approver = infringement.ApprovedBy || 'Unspecified';
            branchStats[branch].byApprover[approver] = (branchStats[branch].byApprover[approver] || 0) + 1;
        });

        return Object.entries(branchStats)
            .map(([branch, stats]) => ({
                branch,
                total: stats.total,
                byType: stats.byType,
                byApprover: stats.byApprover,
                totalMinsOut: stats.totalMinsOut
            }))
            .sort((a, b) => a.total - b.total);
    }, [acceptedInfringements, staffData]);

    if (isLoading) return <div style={{ color: 'white' }}>Loading...</div>;

    return (
        <TableContainer style={{ height: 'calc(100vh - 350px)', overflow: 'scroll' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ color: 'white' }}>Branch</TableCell>
                        <TableCell align="right" sx={{ color: 'white' }}>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {branchScores.map((branch) => (
                        <Tooltip
                            key={branch.branch}
                            title={
                                <div>
                                    <div>By Type:</div>
                                    {Object.entries(branch.byType).map(([type, count]) => (
                                        <div key={type}>{type}: {count}</div>
                                    ))}
                                    <div style={{ marginTop: '8px' }}>By Approver:</div>
                                    {Object.entries(branch.byApprover).map(([approver, count]) => (
                                        <div key={approver}>{approver}: {count}</div>
                                    ))}
                                    <div style={{ marginTop: '8px' }}>
                                        Total Minutes Out: {branch.totalMinsOut}
                                    </div>
                                </div>
                            }
                        >
                            <TableRow hover>
                                <TableCell sx={{ color: 'white' }}>{branch.branch}</TableCell>
                                <TableCell align="right" sx={{ color: 'white' }}>
                                    {branch.total}
                                </TableCell>
                            </TableRow>
                        </Tooltip>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AcceptedInfringementsTable;
