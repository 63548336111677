import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Button, Table, Modal, Form, Select, DatePicker, Input, Spin } from 'antd'
import { GlobalContext } from '../../context/GlobalContext'
import moment from 'moment-timezone'

const { Option } = Select

function Messages() {
  const [messages, setMessages] = useState([])
  const [visible, setVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [toType, setToType] = useState('')
  const [toDetails, setToDetails] = useState([])
  const [regions, setRegions] = useState([])
  const [branches, setBranches] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])
  const { token, operatorData } = useContext(GlobalContext)
  const [editingMessage, setEditingMessage] = useState(null)
  const [loading, setLoading] = useState(false) // New loading state

  const [form] = Form.useForm()
  const [editForm] = Form.useForm()

  useEffect(() => {
    fetchMessages()
    fetchRegions()
    fetchBranches()
  }, [])

  const fetchMessages = async () => {
    setLoading(true) // Start loading
    try {
      const response = await axios.get('https://spar.connectplus.parts/api/salesassist/cplusassist/newsflashes', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      setMessages(response.data)
    } catch (error) {
      console.error('Error fetching messages:', error)
    } finally {
      setLoading(false) // Stop loading
    }
  }

  const fetchRegions = async () => {
    try {
      const response = await axios.get('/api/regions')
      setRegions(response.data)
    } catch (error) {
      console.error('Error fetching regions:', error)
    }
  }

  const fetchBranches = async () => {
    try {
      const response = await axios.get('https://spar.connectplus.parts/api/salesassist/cplusassist/branches', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      setBranches(response.data)
    } catch (error) {
      console.error('Error fetching branches:', error)
    }
  }

  const handleNewMessage = () => {
    setVisible(true)
  }

  const handleBranchSelection = (selectedOptions) => {
    if (toType === 'Region') {
      const selectedRegion = selectedOptions
      const selectedBranchObjects = branches.filter((branch) => branch.Region === selectedRegion)
      setSelectedBranches(selectedBranchObjects)
    } else if (toType === 'Branch') {
      const selectedBranchObjects = branches.filter((branch) => selectedOptions.includes(branch.MamCode))
      setSelectedBranches(selectedBranchObjects)
    }
  }

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      let selectedBranchCodes = []
      if (toType === 'All') {
        selectedBranchCodes = branches.map((branch) => branch.MamCode)
      } else {
        selectedBranchCodes = selectedBranches.map((branch) => branch.MamCode)
      }
      const newMessage = {
        toType: values.toType,
        toDetails: selectedBranchCodes,
        message: values.message,
        expiry: values.expiry ? values.expiry.format('YYYY-MM-DD') : null,
        user: operatorData.Name
      }
      await axios.post('https://spar.connectplus.parts/api/salesassist/cplusassist/newsflash', newMessage, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      setVisible(false)
      form.resetFields()
      setSelectedBranches([])
      fetchMessages()
    } catch (error) {
      console.error('Error creating message:', error)
    }
  }

  const handleDelete = async (messageId) => {
    try {
      const confirmed = await new Promise((resolve, reject) => {
        Modal.confirm({
          title: 'Confirm Delete',
          content: 'Are you sure you want to delete this message?',
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
          okText: 'Delete',
          cancelText: 'Cancel'
        })
      })

      if (!confirmed) {
        return
      }

      await axios.delete(`https://spar.connectplus.parts/api/salesassist/cplusassist/newsflash/${messageId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      fetchMessages()
    } catch (error) {
      console.error('Error deleting message:', error)
    }
  }

  const handleEdit = (record) => {
    setEditingMessage(record)
    setEditVisible(true)
    editForm.setFieldsValue({
      toType: record.ToType,
      toDetails: record.ToDetails,
      message: record.Message,
      expiry: record.Expiry ? moment(record.Expiry, 'YYYY-MM-DD') : null
    })
  }

  const handleUpdate = async () => {
    try {
      const values = await editForm.validateFields()
      const updatedMessage = {
        toType: values.toType,
        toDetails: values.toDetails,
        message: values.message,
        expiry: values.expiry ? values.expiry.format('YYYY-MM-DD') : null
      }
      await axios.put(
        `https://spar.connectplus.parts/api/salesassist/cplusassist/newsflash/${editingMessage._id}`,
        updatedMessage,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      setEditVisible(false)
      editForm.resetFields()
      setEditingMessage(null)
      fetchMessages() // Refetch messages after successful update
    } catch (error) {
      console.error('Error updating message:', error)
    }
  }

  const columns = [
    {
      title: 'To Type',
      dataIndex: 'ToType',
      key: 'ToType'
    },
    {
      title: 'To Details',
      dataIndex: 'ToDetails',
      key: 'ToDetails',
      render: (toDetails) => (Array.isArray(toDetails) ? toDetails.join(', ') : toDetails)
    },
    {
      title: 'Message',
      dataIndex: 'Message',
      key: 'Message'
    },
    {
      title: 'From',
      dataIndex: 'CreatedBy',
      key: 'CreatedBy'
    },
    {
      title: 'Expiry',
      dataIndex: 'Expiry',
      key: 'Expiry',
      render: (expiry) => (expiry ? expiry.split('T')[0] : 'No Expiry')
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record._id)}>
            Delete
          </Button>
        </>
      )
    }
  ]

  return (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      <Button type="primary" onClick={handleNewMessage}>
        New Message
      </Button>
      <Spin spinning={loading}> {/* Wrap the content with Spin */}
        <div style={{ maxHeight: '600px', overflowY: 'scroll', paddingTop: 25 }}>
          <Table columns={columns} dataSource={messages} rowKey="_id" />
        </div>
      </Spin>
      <Modal open={visible} title="New Message" onOk={handleSubmit} onCancel={() => setVisible(false)} width={'80%'}>
        <div style={{ display: 'flex' }}>
          <Form form={form} layout="vertical" style={{ flex: 1 }}>
            <Form.Item name="toType" label="To Type" rules={[{ required: true, message: 'Please select a type' }]}>
              <Select
                onChange={(value) => {
                  setToType(value)
                  if (value === 'All') {
                    setSelectedBranches(branches)
                  } else {
                    setSelectedBranches([])
                    form.resetFields(['toDetails'])
                  }
                }}
              >
                <Option value="Region">Region</Option>
                <Option value="Branch">Branch</Option>
                <Option value="All">All</Option>
              </Select>
            </Form.Item>
            {toType === 'Region' && (
              <Form.Item
                name="toDetails"
                label="Regions"
                rules={[{ required: true, message: 'Please select a region' }]}
              >
                <Select onChange={handleBranchSelection}>
                  {Array.from(new Set(branches.map((branch) => branch.Region))).map((region) => (
                    <Option key={region} value={region}>
                      {region}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {toType === 'Branch' && (
              <Form.Item
                name="toDetails"
                label="Branches"
                rules={[{ required: true, message: 'Please select branches' }]}
              >
                <Select
                  mode="multiple"
                  onChange={handleBranchSelection}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {branches.map((branch) => (
                    <Option key={branch._id} value={branch.MamCode}>
                      {branch.Branch}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please enter a message' }]}>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item name="expiry" label="Expiry">
              <DatePicker />
            </Form.Item>
          </Form>
          <div
            style={{
              marginLeft: '20px',
              flex: 1,
              maxHeight: '300px',
              overflowY: 'auto',
              paddingRight: '10px'
            }}
          >
            <h3>Selected Branches:</h3>
            {selectedBranches.map((branch) => (
              <div key={branch._id}>{branch.Branch}</div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        open={editVisible}
        title="Edit Message"
        onOk={handleUpdate}
        onCancel={() => setEditVisible(false)}
        width={'80%'}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item name="toType" label="To Type" rules={[{ required: true, message: 'Please select a type' }]}>
            <Select>
              <Option value="Region">Region</Option>
              <Option value="Branch">Branch</Option>
              <Option value="All">All</Option>
            </Select>
          </Form.Item>
          <Form.Item name="toDetails" label="To Details" rules={[{ required: true, message: 'Please select details' }]}>
            <Select mode="multiple">
              {editingMessage?.ToDetails.map((detail) => (
                <Option key={detail} value={detail}>
                  {detail}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please enter a message' }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="expiry" label="Expiry">
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Messages