const StatusChip = ({ status }) => {
  const text = {
    open: 'OPEN',
    pending: 'PENDING',
    on_hold: 'ON HOLD',
    closed: 'CLOSED'
  }

  const color = {
    open: '#4ade80',
    pending: '#f87171',
    on_hold: '#fb923c',
    closed: '#06b6d4'
  }

  return (
    <div
      className="inline-block px-2 py-1 text-xs font-semibold text-white rounded"
      style={{ backgroundColor: color[status] }}
    >
      {text[status]}
    </div>
  )
}

export default StatusChip
