import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mantine/core'
import { Form, useForm } from 'react-hook-form'
import { TextInput, NativeSelect } from 'react-hook-form-mantine'
import yup from '../lib/yup'
import useApi from '../hooks/useApi'
import haxios from '../lib/haxios'
import { errorToast, successToast } from '../lib/helpers'
import Button from '../components/Button'
import { useState } from 'react'

const validation = yup
  .object()
  .shape({
    Shift: yup.string().alphanumeric(),
    Break: yup
      .number()
      .transform((curr, orig) => (orig === '' || curr === null ? 0 : curr))
      .test('isInteger', 'Break must be a whole number', (value) => {
        return Number.isInteger(value)
      })
  })
  .required()

export const ShiftEditModal = ({ context, id, innerProps }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { control } = useForm({
    resolver: yupResolver(validation),
    defaultValues: innerProps.defaultValues || {
      Shift: '',
      Break: 0
    }
  })

  const handleSubmit = async (data) => {
    setIsLoading(true)

    try {
      await haxios.put(`/salesassistnew/tma/shifts/${innerProps.id}`, {
        Day: innerProps.day,
        Shift: data.Shift,
        Break: data.Break
      })

      await innerProps.refetch()
      context.closeModal(id)
      successToast('Shift updated successfully.')
    } catch (e) {
      setIsLoading(false)
      errorToast('An error has occurred.')
      console.log(e)
    }
  }

  return (
    <>
      <Form control={control} onSubmit={(e) => handleSubmit(e.data)} onError={(e) => console.log(e)}>
        <Grid gutter="xs">
          <Grid.Col span={6}>
            <ShiftSelect label="Shift pattern" name="Shift" control={control} />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput type="number" label="Break (minutes)" name="Break" control={control} />
          </Grid.Col>
          <Grid.Col span={12} />

          <Grid.Col span={12}>
            <div className="text-right">
              <Button loading={isLoading} type="submit">
                Submit
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </Form>
    </>
  )
}

const ShiftSelect = ({ label, name, control }) => {
  const { data, isLoading } = useApi({ url: '/salesassist/cplusassist/shifts' })

  const options = [
    { value: '', label: 'No shift' }, // Add empty option
    ...(isLoading ? [] : data).map((el) => ({
      label: `[${el.ShiftName}] ${el.Start} - ${el.Finish}`,
      value: el._id
    }))
  ]

  return <NativeSelect placeholder="Choose shift" label={label} name={name} control={control} data={options} />
}

export default ShiftEditModal
